import cx from 'classnames';
import { RIT, actions, selectors } from '@groove-labs/groove-ui';
import { Map } from 'immutable';
import { Phone as PhoneIcon } from '@material-ui/icons';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { GLOBAL_SEARCH_GROUP_ID } from 'Modules/App/constants';
import UserDisplayAndMenu from 'Modules/App/containers/UserDisplayAndMenu';
import LoginAsDialog from 'Modules/App/containers/LoginAsDialog';
import TopNavSearchBar from 'Modules/App/containers/TopNavSearchBar';
import MoboDropdown from 'Modules/App/containers/MoboDropdown';
import { getShowTopNavTabs } from 'Modules/App/selectors';
import { getDialerEnabled } from 'Modules/Shared/selectors/dialer';
import { location } from 'Modules/Shared/selectors/location';
import {
  currentUser,
  loginAsActive as getLoginAsActive,
} from 'Modules/Shared/selectors/users';
import { LEAVE_PAGE_CONFIRMATION_KEY_PATH } from 'Modules/Shared/constants/index';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'Utils/history';
import { featureFlags } from 'Modules/Shared/selectors/featureFlags';
import { getEditorHasBeenModified } from 'Modules/Shared/selectors/ui';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LeavePageConfirmation from 'Modules/Shared/containers/LeavePageConfirmation';
import TopNavBarTabs from 'Modules/App/containers/TopNavBarTabs';
import DebugText from 'Modules/App/containers/DebugText';
import GrooveWordLogo from 'Modules/Shared/icons/GrooveWordLogo';

import styles from './styles';
import { ROUTES } from '../../../../routing/routesSaga';
import { ROUTE_NAMES } from '../../../../routing/constants';
import cable from 'GrooveHTTPClient/cable';

const { setProperty } = actions.ui;
const { getProperty } = selectors.ui;
const TOP_NAV_LEAVE_CONFIRMATION = 'topNavLeaveConfirmation';

@withStyles(styles, { withTheme: true })
@connect(
  state => {
    return {
      location: location(state),
      currentUser: currentUser(state),
      showTabs: getShowTopNavTabs(state),
      isDialerEnabled: getDialerEnabled(state),
      featureFlags: featureFlags(state),
      editorHasBeenModified: getEditorHasBeenModified(state),
      leavePageConfirmationData: getProperty(
        state,
        LEAVE_PAGE_CONFIRMATION_KEY_PATH,
        Map({
          show: false,
          type: TOP_NAV_LEAVE_CONFIRMATION,
        })
      ),
      loginAsActive: getLoginAsActive(state),
    };
  },
  {
    setProperty,
  }
)
export default class TopNav extends Component {
  static propTypes = {
    location: PropTypes.instanceOf(Map).isRequired,
    currentUser: PropTypes.instanceOf(Map),
    classes: PropTypes.objectOf(PropTypes.string),
    setProperty: PropTypes.func.isRequired,
    showTabs: PropTypes.bool.isRequired,
    isDialerEnabled: PropTypes.bool,
    featureFlags: PropTypes.instanceOf(Map).isRequired,
    leavePageConfirmationData: ImmutablePropTypes.mapContains({
      show: PropTypes.bool.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
    loginAsActive: PropTypes.bool.isRequired,
    editorHasBeenModified: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    userName: '',
    classes: {},
    currentUser: null,
    isDialerEnabled: false,
  };

  state = {
    userMenuOpen: false,
    userMenuAnchorEl: null,
  };

  fetchTabsForRoute() {
    const { location, currentUser, featureFlags } = this.props;
    const routeName = location.get('routeName');
    const route = ROUTES.find(route => route.routeName === routeName);

    if (!route) return [];
    return route.tabs.filter(tab => {
      if (tab.shouldRender) {
        return tab.shouldRender({ currentUser, featureFlags });
      }

      return true;
    });
  }

  openLeaveConfirmationDialog = () =>
    this.props.setProperty({
      uiKeyPath: LEAVE_PAGE_CONFIRMATION_KEY_PATH,
      data: {
        show: true,
        type: TOP_NAV_LEAVE_CONFIRMATION,
      },
    });

  shouldRenderLeaveConfirmation() {
    const { leavePageConfirmationData } = this.props;
    return (
      leavePageConfirmationData.get('show') &&
      leavePageConfirmationData.get('type') === TOP_NAV_LEAVE_CONFIRMATION
    );
  }

  shouldRenderDialerButton() {
    const { isDialerEnabled, location } = this.props;

    if (!isDialerEnabled) {
      return false;
    }

    const routeName = location.get('routeName');
    if (routeName === ROUTE_NAMES.SETTINGS) {
      return true;
    }

    return true;
  }

  handleGrooveLogoClick = () => {
    const { editorHasBeenModified } = this.props;
    if (!editorHasBeenModified) {
      history.push('/');
    } else {
      this.openLeaveConfirmationDialog();
    }
  };

  handleOnConfirm = () => history.push('/');

  handleDialerClick = () => {
    cable.subscriptions
      .create({
        channel: 'DialerChannel',
      })
      .send({
        type: 'EFFECTS/EXTERNAL/OPEN_DIALER',
      });
  };

  render() {
    const { currentUser, classes, loginAsActive, showTabs, theme } = this.props;
    const tabCount = this.fetchTabsForRoute().length;

    return (
      <React.Fragment>
        {RIT(
          this.shouldRenderLeaveConfirmation(),
          <LeavePageConfirmation onConfirm={this.handleOnConfirm} />
        )}
        <div className={classes.root}>
          <AppBar
            color="default"
            position="static"
            classes={{
              root: cx({
                [classes.appBarRoot]: true,
                [classes.appBarRootNoTabs]: tabCount <= 1,
                [classes.appBarRootWithTabs]: showTabs && tabCount > 1,
              }),
              colorDefault: loginAsActive
                ? classes.appBarOrangeColor
                : classes.appBarPrimaryColor,
            }}
          >
            <Toolbar className={classes.toolbar}>
              <a
                className={classes.logoTextContainer}
                role="link"
                tabIndex="-1"
                onClick={this.handleGrooveLogoClick}
              >
                <GrooveWordLogo />
              </a>

              <DebugText />

              <TopNavSearchBar groupId={GLOBAL_SEARCH_GROUP_ID} />

              <div className={classes.rightAlignedContent}>
                <MoboDropdown />
                {RIT(this.shouldRenderDialerButton(), () => (
                  <div>
                    <Button
                      id="febes-dialer-button"
                      color="primary"
                      onClick={this.handleDialerClick}
                    >
                      <PhoneIcon htmlColor={theme.palette.primary.light} />
                    </Button>
                  </div>
                ))}
                {RIT(!!currentUser, <UserDisplayAndMenu />)}
              </div>
            </Toolbar>
            <Toolbar className={classes.routeTabsContainer}>
              {RIT(showTabs, <TopNavBarTabs tabCount={tabCount} />)}
            </Toolbar>
          </AppBar>
          <LoginAsDialog />
        </div>
      </React.Fragment>
    );
  }
}
