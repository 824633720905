import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => {
  return {
    root: {
      height: 20,
      width: 20,
      fill: '#FFFFFF',
    },
  };
};

const LinkedInIcon = ({ classes, className }) => {
  let selectedClass = className;
  if (!selectedClass) {
    selectedClass = classes.root;
  }

  return (
    <svg
      className={selectedClass}
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(-796.000000, -28.000000)">
          <g transform="translate(788.000000, 20.000000)">
            <g transform="translate(6.000000, 6.000000)">
              <rect
                fillOpacity="0.7"
                opacity="0"
                x="0"
                y="0"
                width="24"
                height="24"
              />
              <path
                d="M4,2 L20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22
                2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 Z M18.9,18.6363636 L18.9,13.8632376 C18.9,11.2043985
                17.3872472,9.96964846 15.4617768,9.96964846 C13.9097954,9.96964846 12.9372531,10.8103465 12.7574553,11.3994016
                L12.7574553,10.0521809 L9.7139699,10.0521809 C9.75319849,10.767462 9.7139699,18.6363636 9.7139699,18.6363636
                L12.7574553,18.6363636 L12.7574553,13.991082 C12.7574553,13.7329658 12.7451964,13.4748497 12.8236536,13.2895563
                C13.0328728,12.7733239 13.4823671,12.2376722 14.2906397,12.2376722 C15.3473601,12.2376722 15.8279104,13.0314401
                15.8279104,14.1941765 L15.8279104,18.6363636 L18.9,18.6363636 Z M6.63615945,8.97845006 C7.65446849,8.97845006
                8.28866416,8.31980883 8.28866416,7.48801132 C8.26904986,6.64084011 7.65446849,6 6.65495649,6 C5.65462723,6 5,6.64245839
                5,7.49043874 C5,8.32223626 5.6358302,8.97845006 6.61654516,8.97845006 L6.63615945,8.97845006 Z M5.3506056,10.0521809
                L5.3506056,18.6363636 L8.05983067,18.6363636 L8.05983067,10.0521809 L5.3506056,10.0521809 Z"
                className={selectedClass}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LinkedInIcon.defaultProps = {
  className: null,
};

LinkedInIcon.displayName = 'LinkedInIcon';
LinkedInIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(LinkedInIcon);
