import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import { isNil } from 'lodash-es';
import { eventChannel } from 'redux-saga';
import {
  actionTypes as dialerActionTypes,
  setDialerOpened,
  loadSettings,
  setCallActivityCache,
  setCallInProgress,
} from 'Modules/Shared/actions/dialer';
import {
  makeFetchCallActivityCacheValue,
  isCallInProgress,
} from 'Modules/Shared/selectors/dialer';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
import { show as getDialerSettings } from 'GrooveHTTPClient/dialer/settings';
import {
  actionTypes as workStepActionTypes,
  removePersonBySfdcId,
} from 'Modules/WorkStep/actions';
import { actionTypes as composeActionTypes } from 'Modules/ActionCompose/actions/outgoing';
import cable from 'GrooveHTTPClient/cable';

function createIncomingActionEventChannel() {
  return eventChannel(emitter => {
    const handler = message => {
      if (message.type === 'CALL_LOGGED') {
        emitter({
          type: 'OUTBOUND/CALL_LOGGED',
          payload: {
            action_id: message.actionId || null,
            person: {
              id: message.person.id,
              sfdc_id: message.person.sfdcId,
            },
          },
        });
      }
    };

    cable.subscriptions.create(
      { channel: 'ExtensionChannel' },
      { received: handler }
    );
    return () => window.removeEventListener('message', handler);
  });
}

function* processActionFromChromeExtensionDialer(action) {
  const { type, payload } = action;

  const location = yield select(locationSelector);

  // TODO
  // constantize action types. This would be a great use case for importing the groove-dialer
  // package as a package to share constant names... It is already a UMD build, after all.
  switch (type) {
    case 'OUTBOUND/SET_CALL_IN_PROGRESS': {
      yield put(setCallInProgress(payload));
      break;
    }

    case 'CALL_ACTIVITY_UPDATED': {
      if (location.get('routeName') !== 'workStep') {
        return;
      }

      const { getActivePerson } = yield call(() =>
        import('Modules/WorkStep/selectors')
      );
      const activePerson = yield select(getActivePerson);

      yield put(
        setCallActivityCache(activePerson.get('id'), {
          template_type: payload.template_type,
          notes: payload.notes,
          subject: payload.subject,
        })
      );

      break;
    }

    case 'OUTBOUND/CALL_LOGGED': {
      if (location.get('routeName') === 'workStep') {
        const { getPersonWithSfdcIdExists, getTemplateType } = yield call(() =>
          import('Modules/WorkStep/selectors')
        );
        const { TEMPLATE_TYPES } = yield call(() =>
          import('Modules/WorkStep/constants')
        );
        const templateType = yield select(getTemplateType);
        if (templateType !== TEMPLATE_TYPES.CALL) {
          return;
        }
        const personWithSfdcIdExists = yield select(
          getPersonWithSfdcIdExists(payload.person.sfdc_id)
        );
        if (!personWithSfdcIdExists) {
          console.debug(
            'Person with sfdc id already removed, ignore.',
            payload.person.sfdc_id
          ); // eslint-disable-line no-console
          return;
        }
        // handle call logging from workstep page for call steps
        const { findNextPersonId } = yield call(() =>
          import('Modules/WorkStep/sagas')
        );
        const { handleLogRequestSuccess } = yield call(() =>
          import('Modules/WorkStep/callOrOtherStepSagas')
        );
        const removePersonAction = removePersonBySfdcId({
          sfdcId: payload.person.sfdc_id,
        });
        const nextPersonId = yield call(findNextPersonId, removePersonAction);
        const stepIsComplete = isNil(nextPersonId);
        // Ensure that you remove the person after the next person Id has been calculated, since the we use the
        // current active person in payload.person.id to determine who the next one is
        yield put(removePersonAction);
        yield* handleLogRequestSuccess(
          stepIsComplete,
          payload.person.id,
          nextPersonId,
          'Last call logged! Redirecting you to the flow.',
          { onlyShowMessageIfComplete: true, dispatchLogCallAction: false }
        );
      } else if (location.get('routeName') === 'actions') {
        // when this arrives the call was already logged successfully in dialer
        // now we dispatch the Action Id to clear it out from Actions
        const activeActionId = payload?.action_id;
        yield put({
          type: composeActionTypes.ACTION_COMPLETED,
          payload: { id: activeActionId },
        });
      } else {
        console.debug(
          'Ignored external Call log action, the route is not supported'
        ); // eslint-disable-line no-console
      }

      break;
    }

    case 'OUTBOUND/ACTIONS_REMOVE_FROM_FLOW': {
      if (location.get('routeName') === 'actions') {
        // when this arrives the call was already logged successfully in dialer
        // now we dispatch the Action Id to clear it out from Actions
        const actionIdDialer = payload.action_id;
        const personFlowId = payload.person_flow_id;
        yield put({
          type: composeActionTypes.REMOVE_DESTROYED_PERSON_FLOW_ACTIONS,
          payload: { personFlowId },
        });
        yield put({
          type: composeActionTypes.ACTION_COMPLETED,
          payload: { id: actionIdDialer },
        });
      } else {
        console.debug(
          'Ignored external Call log action, the route is not supported'
        ); // eslint-disable-line no-console
      }

      break;
    }

    case 'OUTBOUND/DIALER_OPENED': {
      yield put(setDialerOpened(payload));

      break;
    }

    default: {
      console.debug('Ignored action from Chrome extension dialer', action); // eslint-disable-line no-console
    }
  }

  yield null;
}

function* watchForIncomingActionsFromChromeExtensionDialer() {
  const channel = createIncomingActionEventChannel();

  while (true) {
    const action = yield take(channel);
    yield call(processActionFromChromeExtensionDialer, action);
  }
}

function* loadDialerSettings() {
  const dialerSettingsResponse = yield call(getDialerSettings);
  yield put(loadSettings(dialerSettingsResponse.data));
}

let dialerChannelSubscription = null;
const getDialerChannel = () => {
  dialerChannelSubscription =
    dialerChannelSubscription ||
    cable.subscriptions.create({
      channel: 'DialerChannel',
    });

  return dialerChannelSubscription;
};

export function* postMessageToDialer({ type, payload = {} }) {
  yield getDialerChannel().send({ type: `EFFECTS/EXTERNAL/${type}`, payload });
}

export function* handleWorkStepActivePersonChange({ toPhoneNumber }) {
  const { getActivePerson, getTemplateType } = yield call(() =>
    import('Modules/WorkStep/selectors')
  );
  const templateType = yield select(getTemplateType);

  // Guarding against dialer popping up in email steps
  if (!templateType || templateType !== 'call') {
    return;
  }

  const activePerson = yield select(getActivePerson);

  let callActivity = null;

  // Call activity data loaded into the dialer. When changes are made in the dialer client, they
  // are synced back to store.dialer.callActivityCache. This tries reading from that cache to post
  // the altered version of that data instead of the zero-state data.
  const cachedCallActivity = yield select(
    makeFetchCallActivityCacheValue(activePerson.get('id'))
  );
  if (cachedCallActivity) {
    callActivity = cachedCallActivity.toJS();
  } else {
    callActivity = {
      template_type: activePerson.get('templateTypeValue'),
      notes: activePerson.get('preppedEmailBody'),
      subject: activePerson.get('preppedEmailSubject'),
      person_step_id: activePerson.get('personStepId'),
      person_flow_id: activePerson.get('personFlowId'),
    };

    // Initialize the cache
    yield put(setCallActivityCache(activePerson.get('id'), callActivity));
  }

  const payload = {
    personSfdcId: activePerson.get('sfdcId'),
    toPhoneNumber:
      toPhoneNumber ||
      activePerson.getIn(['fields', 'phone', 'raw']) ||
      activePerson.getIn(['fields', 'mobilephone', 'raw']),
    callActivity,
  };

  yield* postMessageToDialer({
    type: 'FLOW/LOAD_WORK_STEP_DATA',
    payload,
  });

  // If a phone number is included in the payload, then the dialer should switch to the dial tab
  if (toPhoneNumber) {
    yield* postMessageToDialer({
      type: 'SET_ACTIVE_TAB',
      payload: { tab: 'dial' },
    });
  }

  // Also, make sure the dialer is opened
  yield* postMessageToDialer({ type: 'OPEN_DIALER' });
}

function* handleLoadNonFlowClickToCall({ payload }) {
  const { sfdcId, phoneNumber } = payload;

  if (!sfdcId || !phoneNumber) {
    return;
  }

  yield* postMessageToDialer({
    type: 'CLICK_TO_CALL',
    payload: {
      sfdcId,
      toPhoneNumber: phoneNumber,
    },
  });
}

function* handleLocationUpdate() {
  if (!(yield select(isCallInProgress))) {
    yield* postMessageToDialer({ type: 'CLOSE_DIALER' });
  }
}

function* handleDialerSettingUpdated({ payload }) {
  yield* postMessageToDialer({
    type: 'SETTING_UPDATED',
    payload,
  });
}

function* watchForWorkStepSetActivePersonId() {
  yield takeEvery(
    workStepActionTypes.SET_ACTIVE_PERSON_ID,
    handleWorkStepActivePersonChange
  );
}

function* watchNonFlowClickToCall() {
  yield takeEvery(
    dialerActionTypes.NON_FLOW_CLICK_TO_CALL,
    handleLoadNonFlowClickToCall
  );
}

function* watchFetchDialerSettings() {
  yield takeEvery(dialerActionTypes.FETCH_SETTINGS, loadDialerSettings);
}

function* watchLocationUpdate() {
  yield takeLatest(locationActionTypes.START_CHANGE, handleLocationUpdate);
}

function* watchDialerSettingUpdated() {
  yield takeEvery(
    dialerActionTypes.DIALER_SETTING_UPDATED,
    handleDialerSettingUpdated
  );
}

export default function* root() {
  yield all([
    fork(watchFetchDialerSettings),
    fork(watchForIncomingActionsFromChromeExtensionDialer),
    fork(watchForWorkStepSetActivePersonId),
    fork(watchNonFlowClickToCall),
    fork(watchLocationUpdate),
    fork(watchDialerSettingUpdated),
  ]);
}
