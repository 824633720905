export default {
  flowListContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingBottom: '8px',
    '&:hover': {
      cursor: 'default',
    },
  },
  flowTitle: {
    marginLeft: '50px',
    fontSize: '15px',
    color: 'rgba(0, 0, 0, 0.75)',
  },
  flowList: {
    marginLeft: '47px',
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 55px)',
  },
};
