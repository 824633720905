import { isImmutable } from 'immutable';
import { findKey, isUndefined, reduce } from 'lodash-es';
import moment from 'moment';
import { ASC } from 'Modules/Shared/components/Table/constants.js';
import { STEP_SUBTYPES } from 'Modules/Shared/constants';
import {
  EMAIL_ACTIVITY_RECORDS,
  EMAIL_ACTIVITY_TYPES,
} from 'Modules/FlowsShow/constants/index.ts';
import { OTHER_STEP } from 'Modules/App/constants';
/**
 * Build all steps people list.
 * Incoming format: {
 *  stepId: [...listOfPeople],
 * }
 * steps are ordered by day_number.
 *
 * @param {Object} stepMap
 * @param {String} filter
 * @param {List} steps
 *
 * @return {Array}
 */

// create list of people - allows duplicates
export const buildAllPeopleList = stepMap => {
  return reduce(
    stepMap,
    (peopleList, stepPeople, stepId) => {
      stepPeople.forEach(person => {
        peopleList.push({
          personId: parseInt(person.id, 10),
          stepId: parseInt(stepId, 10),
        });
      });
      return peopleList;
    },
    []
  );
};

export const buildAllStepsPeopleList = (stepMap, filter) => {
  return reduce(
    stepMap,
    (peopleList, stepPeople, stepId) => {
      stepPeople.forEach(person => {
        // only include people that haven't already been added to avoid duplicates
        const personIsNotInPeopleList = !peopleList.some(
          addedPerson => addedPerson.personId === person.id
        );
        // for completed only return people who completed flow
        const personShouldBeAddedToList =
          filter === 'completed'
            ? person.flowCompleted && personIsNotInPeopleList
            : personIsNotInPeopleList;

        if (personShouldBeAddedToList) {
          peopleList.push({
            personId: parseInt(person.id, 10),
            stepId: parseInt(stepId, 10),
          });
        }
      });
      return peopleList;
    },
    []
  );
};

/**
 * Build a map of people keyed by Id.
 *
 * @param {Object} stepMap
 *
 * @return {Object}
 */
export const isEmailsStatsFilterApplied = filter =>
  filter === EMAIL_ACTIVITY_TYPES.OPENED ||
  filter === EMAIL_ACTIVITY_TYPES.REPLIED ||
  filter === EMAIL_ACTIVITY_RECORDS.CLICKED;

export const getActivityName = filter => {
  if (filter === EMAIL_ACTIVITY_TYPES.OPENED)
    return EMAIL_ACTIVITY_RECORDS.OPENED;
  else if (filter === EMAIL_ACTIVITY_TYPES.REPLIED)
    return EMAIL_ACTIVITY_RECORDS.REPLIED;
  else if (filter === EMAIL_ACTIVITY_TYPES.CLICKED)
    return EMAIL_ACTIVITY_RECORDS.CLICKED;
  return 'completedDate';
};

// returns a map of people with the most recent email activity record (ex. last email opened, last email clicked, last email)
export const buildPeopleByIdWithLatestActivityRecord = (stepMap, filter) => {
  const activityName = getActivityName(filter);
  return reduce(
    stepMap,
    (peopleMap, stepPeople) => {
      stepPeople.forEach(person => {
        const currentPerson = peopleMap[person.id];
        const isLatestEmailActivity =
          peopleMap[person.id] &&
          moment(person[activityName]) > moment(currentPerson[activityName]);
        if (!currentPerson || isLatestEmailActivity) {
          peopleMap[person.id] = person;
        }
      });
      return peopleMap;
    },
    {}
  );
};

export const buildPeopleById = stepMap => {
  return reduce(
    stepMap,
    (peopleMap, stepPeople) => {
      stepPeople.forEach(person => {
        peopleMap[person.id] = person;
      });
      return peopleMap;
    },
    {}
  );
};

/**
 * Sort passed people list.
 *
 * @param {List} people
 * @param {List} orderBy
 * @param {List} orderDir
 *
 * @return {List}
 */
export const sortPeople = (people, orderBy, orderDir) => {
  if (!orderDir) return people;

  if (orderDir === ASC) {
    return people.sortBy(person => person.get(orderBy) || '');
  }
  return people.sortBy(person => person.get(orderBy) || '').reverse();
};

/**
 * Remaps step type/subtype received from the backend.
 * Return a step object with remapped type, if applicable
 * Meant to be used in conjunction with steps/index and steps/show in GrooveHTTPClient
 *
 * @param {object} data
 *
 * @return {object}
 * */
export const stepMapSubtypes = step => {
  const stepSubtype = isImmutable(step)
    ? step.get('stepSubtype')
    : step.stepSubtype;

  // Rewrite step types that are actually subtypes of 'OTHER'
  const mappedStepType = findKey(STEP_SUBTYPES, value => value === stepSubtype);
  if (!isUndefined(mappedStepType)) {
    if (isImmutable(step)) {
      return step.set('stepType', mappedStepType).set('stepSubtype', undefined);
    }

    step.stepType = mappedStepType;
    step.stepSubtype = undefined;
  }

  return step;
};

/**
 * Remaps step type/subtype received from the backend.
 * Return a list of Flow objects with remapped step types, if applicable
 * Meant to be used in conjunction with flows/index in GrooveHTTPClient
 *
 * @param {object} data
 *
 * @return {object}
 * */
export const flowsMapStepSubtypes = flows => {
  return flows.map(flow => {
    const formattedSteps = flow.get('steps').map(stepMapSubtypes);
    return flow.set('steps', formattedSteps);
  });
};

export const getFromTemplateOrStep = (step, key) => {
  if (shouldOverrideTemplate(step)) {
    return step.getIn(['templateOverride', 'template', key]);
  }

  if (step.get('template')) {
    return step.getIn(['template', key]);
  }

  return step.get(key);
};

export const getStepDayNumberLabel = step => {
  return `Day ${step.get('dayNumber')} - `;
};

export const getStepLabel = step => {
  if (step.get('stepType') === OTHER_STEP) {
    return 'Action';
  }

  return getFromTemplateOrStep(step, 'name') || 'Action';
};

export const getStepSubject = step => {
  if (shouldOverrideTemplate(step)) {
    return step.getIn(['templateOverride', 'template', 'subject']);
  }

  if (step.get('template')) {
    return step.getIn(['template', 'subject']);
  }

  return step.get('subjectLine');
};

export const getStepBody = step => {
  if (shouldOverrideTemplate(step)) {
    return (
      step.getIn(['templateOverride', 'template', 'htmlBody']) ||
      step.getIn(['templateOverride', 'template', 'textBody'])
    );
  }

  if (step.get('template')) {
    return (
      step.getIn(['template', 'htmlBody']) ||
      step.getIn(['template', 'textBody'])
    );
  }

  return step.get('htmlBody') || step.get('textBody');
};

const shouldOverrideTemplate = step => {
  return !!(
    step.get('otherUsersCanOverrideTemplate') && step.get('templateOverride')
  );
};
