import cx from 'classnames';
import {
  RIT,
  SearchableDropdownMenu,
  formGroupConnect,
  actions,
} from '@groove-labs/groove-ui';
import noop from 'lodash-es/noop';
import { List } from 'immutable';
import { Cancel } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import {
  searchFilterUpdated,
  searchQueryUpdated,
  searchSubmitted,
  searchInputFocused,
  searchSelect,
  clearSearchResults,
} from 'Modules/App/actions';
import SearchProgress from 'Modules/App/components/SearchProgress';
import {
  GLOBAL_SEARCH_FIELD_ID,
  GLOBAL_SEARCH_GROUP_ID,
  GLOBAL_SEARCH_WIDTH,
} from 'Modules/App/constants';
import { omnibarOpen } from 'Modules/Shared/selectors/omnibar';
import GlobalPersonSearchItem from 'Modules/App/containers/GlobalPersonSearchItem';
import {
  getSearchInProgress,
  getTypeaheadSearchResults,
} from 'Modules/App/selectors';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Person } from 'Modules/People/records';

const { resetField } = actions.form;

const searchFilter = state => state.getIn(['app', 'searchFilter']);

const styles = theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      minWidth: '500px',
    },
    rootWithOmnibarOpen: {
      flex: 1,
      paddingLeft: theme.spacing.unit * 3,
    },
    searchContainer: {
      width: '100%',
      height: '100%',
    },
    inputContainerRoot: {
      display: 'flex',
      alignItems: 'center',
      background: grey[200],
      width: GLOBAL_SEARCH_WIDTH,
      height: 36,
    },
    inputContainerWithOmnibar: {
      flex: 1,
      width: 'unset',
    },
    shareIcon: {
      paddingLeft: theme.groove.unit * 3,
      paddingRight: theme.groove.unit * 3,
    },
    muiInputRoot: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
    },
    muiButtonClear: {
      height: 36,
      minWidth: 36,
      padding: 0,
      fontWeight: 'initial',
      color: grey[400],
    },
    suggestionsContainer: {
      marginTop: '0px !important',
      position: 'fixed',
      width: GLOBAL_SEARCH_WIDTH,
      maxHeight: '450px',
    },
    searchItem: {
      height: 'inherit',
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 0,
      paddingRight: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    muiInput: {
      fontSize: '14px !important',
      alignSelf: 'center',
      marginLeft: '9px !important',
    },
    materialInputSearchContainer: {
      height: '100%',
    },
    materialInputSearchIcon: {
      top: 0,
    },
  };
};

@formGroupConnect()
@withStyles(styles)
@connect(
  state => {
    return {
      searchFilter: searchFilter(state),
      searchResults: getTypeaheadSearchResults(state),
      searchInProgress: getSearchInProgress(state),
      omnibarOpen: omnibarOpen(state),
    };
  },
  dispatch => {
    return {
      onCancelClick: () => {
        dispatch(clearSearchResults());
        dispatch(searchQueryUpdated(''));
        dispatch(
          resetField({
            initialValue: '',
            groupId: GLOBAL_SEARCH_GROUP_ID,
            fieldId: GLOBAL_SEARCH_FIELD_ID,
          })
        );
      },
      searchSubmitted: () => dispatch(searchSubmitted()),
      searchFilterUpdated: filterOption =>
        dispatch(searchFilterUpdated(filterOption)),
      searchInputFocused: () => dispatch(searchInputFocused()),
      searchQueryUpdated: query => dispatch(searchQueryUpdated(query)),
      searchSelect: personRecord => dispatch(searchSelect(personRecord)),
    };
  }
)
export default class TopNavSearchBar extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    searchQueryUpdated: PropTypes.func.isRequired,
    searchSubmitted: PropTypes.func.isRequired,
    searchFilterUpdated: PropTypes.func.isRequired,
    searchInputFocused: PropTypes.func.isRequired,
    searchFilter: PropTypes.string.isRequired,
    searchResults: PropTypes.instanceOf(List),
    searchInProgress: PropTypes.bool.isRequired,
    groupId: PropTypes.string.isRequired,
    omnibarOpen: PropTypes.bool.isRequired,
    searchSelect: PropTypes.func,
    clearSearchResults: PropTypes.func,
    resetField: PropTypes.func,
    onCancelClick: PropTypes.func,
  };

  static defaultProps = {
    searchResults: new List(),
    searchSelect: noop,
    clearSearchResults: noop,
    resetField: noop,
    onCancelClick: noop,
  };

  // TODO: This needs to change based on account/template/person/flow
  getSuggestionValue = suggestion => {
    return `${suggestion.name} ${suggestion.companyName} ${suggestion.email}`;
  };

  handleChangeCb = event => {
    const { searchQueryUpdated } = this.props;
    const searchQuery = event.target.value;

    // don't search if only arrow keys have been pressed
    const keyCodesNotToSearchOn = [38, 40];
    const { keyCode } = event;
    if (!keyCodesNotToSearchOn.includes(keyCode)) {
      searchQueryUpdated(searchQuery);
    }
  };

  handleKeyPressCb = (event, { suggestion }) => {
    if (event.key === 'Enter') {
      const { searchSubmitted, searchSelect } = this.props;
      const personRecord = new Person(suggestion);
      searchSelect(personRecord);
      event.target.blur();
      searchSubmitted();
    }
  };

  /**
   * @param {string} filterOptions The filter option that was selected from the menu.
   * */
  handleFilterMenuItemClickCb(filterOption) {
    const { searchFilterUpdated } = this.props;
    // Update the app reduce with the selected filter
    searchFilterUpdated(filterOption);

    // Close the menu
    this.handleDropdownRequestCloseCb();
  }

  handleDropdownRequestCloseCb() {
    this.setState({
      filterOptionsOpen: false,
      filterOptionsButtonRef: null,
    });
  }

  renderHighlightedSuggestionValue = (parts, classesOverride) => {
    const { classes } = this.props;
    let keyCounter = 0;
    return parts.map(part => {
      const text = part.text;
      /* eslint-disable no-unused-expressions */
      return do {
        if (part.highlight) {
          <strong
            key={(keyCounter += 1)}
            className={classesOverride || classes.suggestionValue}
          >
            {text}
          </strong>;
        } else {
          <span
            key={(keyCounter += 1)}
            className={classesOverride || classes.suggestionValue}
          >
            {text}
          </span>;
        }
      };
      /* eslint-enable no-unused-expressions */
    });
  };

  renderSuggestion = (
    suggestion,
    { isHighlighted, getSuggestionValue, query }
  ) => {
    const { classes } = this.props;
    const suggestionValue = getSuggestionValue(suggestion);

    return (
      <MenuItem
        selected={isHighlighted}
        component="div"
        classes={{ root: classes.searchItem }}
      >
        <GlobalPersonSearchItem
          suggestion={suggestion}
          suggestionValue={suggestionValue}
          renderHighlightedSuggestionValue={
            this.renderHighlightedSuggestionValue
          }
          query={query}
        />
      </MenuItem>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    const { searchInProgress } = this.props;
    return (
      <div {...containerProps}>
        {RIT(searchInProgress, () => (
          <SearchProgress />
        ))}
        {children}
      </div>
    );
  };

  render() {
    const {
      classes,
      searchResults,
      searchInputFocused,
      omnibarOpen,
      onCancelClick,
    } = this.props;

    return (
      <div
        className={cx({
          [classes.root]: true,
          [classes.rootWithOmnibarOpen]: omnibarOpen,
        })}
      >
        <div
          className={cx({
            [classes.inputContainerRoot]: true,
            [classes.inputContainerWithOmnibar]: omnibarOpen,
          })}
        >
          <SearchableDropdownMenu
            classes={{
              container: classes.searchContainer,
              suggestionsContainer: classes.suggestionsContainer,
              materialInputSearchContainer:
                classes.materialInputSearchContainer,
              materialInputSearchIcon: classes.materialInputSearchIcon,
            }}
            fieldId={GLOBAL_SEARCH_FIELD_ID}
            groupId={GLOBAL_SEARCH_GROUP_ID}
            suggestions={searchResults}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            inputPlaceholder="Search for people"
            inputCompProps={{
              onKeyPress: this.handleKeyPressCb,
              onFocus: searchInputFocused,
              hideErrorHelperTextBlock: true,
            }}
            muiInputProps={{
              disableUnderline: true,
              classes: { input: classes.muiInput },
            }}
            onSelectItem={this.handleKeyPressCb.bind(this)}
            expandListOnInputFocus
            onInputChange={this.handleChangeCb}
            disableInputChangeOnItemSelect
            hideArrowDropdown
          />
          {RIT(!searchResults.isEmpty(), () => (
            <Button
              classes={{ root: classes.muiButtonClear }}
              onClick={onCancelClick}
            >
              <Cancel />
            </Button>
          ))}
        </div>
      </div>
    );
  }
}
