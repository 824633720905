import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    USERS: {
      ...keyMirror([
        'LOGIN_SUCCESS',
        'LOGIN_FAIL',
        'SET_CSRF_TOKEN',
        'FETCH_CURRENT_USER',
        'SET_CURRENT_USER',
        'SET_IS_LOGGED_IN',
        'LOGOUT',
        'LOAD_FIRESTORE_USER_DOC',
        'DELETE_ALERT',
        'ADD_GLOBAL_SETTING',
        'RESUME_PAUSED_FLOWS',
        'UPDATE_ORG_SETTING',
        'SFDC_PROFILE_SETTINGS',
        'UPDATE_ONE_FLOW_RESTIONSTION_SETTING',
        'UPDATE_CAN_IMPORT_ON_BEHALF_OF_SETTING',
      ]),
      FETCH_LINKEDIN_SAT: standardActions(),
    },
  },
}).SHARED.USERS;

export const updateOrgSetting = ({ settingKey, value }) => ({
  type: actionTypes.UPDATE_ORG_SETTING,
  payload: {
    settingKey,
    value,
  },
});

export function setCurrentUser(currentUser) {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: currentUser,
  };
}

export const fetchCurrentUser = () => ({
  type: actionTypes.FETCH_CURRENT_USER,
});

export const resumePausedFlows = () => ({
  type: actionTypes.RESUME_PAUSED_FLOWS,
});

export function loginSuccess() {
  return {
    type: actionTypes.LOGIN_SUCCESS,
  };
}

export function setCsrfToken(csrfToken) {
  return {
    type: actionTypes.SET_CSRF_TOKEN,
    payload: csrfToken,
  };
}

export function setIsLoggedIn(isLoggedIn) {
  return {
    type: actionTypes.SET_IS_LOGGED_IN,
    payload: isLoggedIn,
  };
}

export function logout() {
  return {
    type: actionTypes.LOGOUT,
  };
}

export function loadFirestoreUserDoc({ userDoc }) {
  return {
    type: actionTypes.LOAD_FIRESTORE_USER_DOC,
    payload: { userDoc },
  };
}

export function deleteAlert({ alertUid }) {
  return {
    type: actionTypes.DELETE_ALERT,
    payload: { alertUid },
  };
}

export function addGlobalSetting({ setting, value }) {
  return {
    type: actionTypes.ADD_GLOBAL_SETTING,
    payload: { setting, value },
  };
}

export function setSfdcProfileSettings({ settings }) {
  return {
    type: actionTypes.SFDC_PROFILE_SETTINGS,
    payload: settings,
  };
}

export function setUserOneFlowRestrictionSettings(payload) {
  return {
    type: actionTypes.UPDATE_ONE_FLOW_RESTIONSTION_SETTING,
    payload,
  };
}

export function setUserCanImportOnBehalfOf(payload) {
  return {
    type: actionTypes.UPDATE_CAN_IMPORT_ON_BEHALF_OF_SETTING,
    payload,
  };
}

export function beginFetchLinkedInSAT() {
  return {
    type: actionTypes.FETCH_LINKEDIN_SAT.BEGIN,
  };
}

export function resetLinkedInSAT() {
  return {
    type: actionTypes.FETCH_LINKEDIN_SAT.FAILURE,
  };
}
