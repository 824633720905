import * as React from 'react';
import { Typography, createStyles, withStyles } from '@material-ui/core';
import {
  isFEBESReviewApp,
  isUsingGEReviewApp,
  isUsingGEDemo,
  getGEPRNumber,
} from 'Utils/reviewAppUtils';

const styles = createStyles({
  root: {
    marginRight: 'auto',
  },
  debugText: {
    display: 'inline-block',
    color: 'black',
  },
});

type DebugTextPropTypes = {
  classes: {
    root: string;
    debugText: string;
  };
};

const DebugText: React.FC<DebugTextPropTypes> = ({ classes }) => {
  const usingFEBESReviewApp = isFEBESReviewApp();
  const usingGEReviewApp = isUsingGEReviewApp();
  const usingGEDemo = isUsingGEDemo();

  const gePrNumber = getGEPRNumber();

  if (!usingFEBESReviewApp && !usingGEReviewApp && !usingGEDemo) {
    return null;
  }

  return (
    <Typography variant="body2" color="secondary" className={classes.root}>
      {usingFEBESReviewApp && (
        <>
          {!!process.env.GITHUB_PR_NUMBER && (
            <div>
              Frontend PR:{' '}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`https://github.com/GrooveLabs/monorepo/pull/${process.env.GITHUB_PR_NUMBER}`}
              >
                #{process.env.GITHUB_PR_NUMBER}
              </a>
            </div>
          )}
          {!process.env.GITHUB_PR_NUMBER && (
            <div>
              Frontend Commit:{' '}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={`https://github.com/GrooveLabs/monorepo/commit/${process.env.REACT_APP_VERSION}`}
              >
                {process.env.REACT_APP_VERSION.slice(0, 8)}
              </a>
            </div>
          )}
        </>
      )}
      {usingGEReviewApp && (
        <div>
          Backend PR:{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`https://github.com/GrooveLabs/grooveEngine/pull/${gePrNumber}`}
          >
            #{gePrNumber}
          </a>
          {' — '}
          <a
            href={`${process.env.NX_GROOVE_ENGINE_ROOT_URL}?febes_url=${process.env.PUBLIC_URL}`}
          >
            Reset Backend
          </a>
        </div>
      )}
      {usingGEDemo && <div>DEMO</div>}
    </Typography>
  );
};

DebugText.displayName = 'DebugText';

export default withStyles(styles)(DebugText);
