import { parse } from 'cookie';

// eslint-disable-next-line import/no-mutable-exports
let geUrl = process.env.NX_GROOVE_ENGINE_ROOT_URL || 'https://engine.groove.co';

if (typeof document !== 'undefined') {
  geUrl = parse(document.cookie).ge_url || geUrl;
}

export default geUrl;
