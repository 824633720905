import * as GrooveLocalStorage from 'GrooveLocalStorage';
import { actionTypes as appActionTypes } from 'Modules/App/actions';
import globalSearchSaga from 'Modules/App/globalSearchSaga';
import loginAsSaga from 'Modules/App/sagas/loginAs';
import {
  setSnackbarClosed,
  setSnackbarOpen,
  actionTypes as sharedActionTypes,
  shiftSnackbarMessage,
  setContentLoading,
} from 'Modules/Shared/actions/app';
import {
  setCurrentUser,
  setCsrfToken,
  setIsLoggedIn,
} from 'Modules/Shared/actions/users';

import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import upstartSagaErrorHandler from 'ErrorHandling/upstartSagaErrorHandler';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import snackbarWarnings from 'Modules/App/sagas/snackbarWarnings';
import analytics from 'Modules/App/sagas/analytics';
import determineUpstart from 'Modules/App/sagas/upstarts';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';
import { isFEBESReviewApp } from 'Utils/reviewAppUtils';

// -------------- Handlers --------------
function* runAppUpstart() {
  try {
    const location = yield select(locationSelector);
    const routeName = location.get('routeName');
    const upstart = determineUpstart({ routeName });
    yield call(upstart);
  } catch (e) {
    yield put({ type: appActionTypes.UPSTART.FAILURE });
    yield* upstartSagaErrorHandler(e);
  }
}

function* pushSnackbarMessageHandler(action) {
  const isOpen = yield select(state => state.getIn(['app', 'snackbarOpen']));

  if (!isOpen) {
    yield put(setSnackbarOpen());
  } else {
    yield put(setSnackbarClosed());
    yield delay(195);
    yield put(setSnackbarOpen());
  }
  yield put({
    type: sharedActionTypes.UPDATE_SNACKBAR_MESSAGE,
    payload: action.payload,
  });
}

function* pushRequestSnackbarCloseHandler() {
  yield put(setSnackbarClosed());
  yield delay(195); // TODO get from theme trasition.js duration.leaveScreen
  yield put(shiftSnackbarMessage());

  const queue = yield select(state => state.getIn(['app', 'snackbarQueue']));

  if (queue.size > 0) {
    yield put(setSnackbarOpen());
  }
}

function* logout() {
  const loginAsActive = yield call(GrooveLocalStorage.fetch, 'loginAsActive');

  // If the user is logging out while logged in as another user,
  // we want to reload their original localStorage before
  // proceeding with logout logic. This prevents the app
  // from thinking they are using the 'login as' feature
  // the next time they log back in as themselves.
  if (loginAsActive) {
    yield call(GrooveLocalStorage.reloadOriginalStore);
  }

  // make sure to turn on the content loader
  // so that anything relying on the user object
  // in the current page doesn't break
  yield put(setContentLoading(true));
  yield put(setCsrfToken(null));
  yield put(setCurrentUser(null));
  yield put(setIsLoggedIn(false));
  yield call(GrooveLocalStorage.remove, 'csrfToken');
  yield call(GrooveLocalStorage.save, 'loginAsActive', false);
  yield call(GrooveLocalStorage.remove, 'loginAsTime');
  if (isFEBESReviewApp()) {
    yield call(
      window.location.assign.bind(window.location),
      `${grooveEngineRootUrl}/signout?febes_url=${process.env.PUBLIC_URL}`
    );
  } else {
    yield call(
      window.location.assign.bind(window.location),
      `${grooveEngineRootUrl}/signout`
    );
  }
}

// -------------- Watchers --------------
function* watchAppUpstart() {
  yield takeEvery(appActionTypes.UPSTART.BEGIN, runAppUpstart);
}

function* watchPushSnackbarMessage() {
  yield takeEvery(
    sharedActionTypes.PUSH_SNACKBAR_MESSAGE,
    pushSnackbarMessageHandler
  );
}

function* watchRequestSnackbarMessage() {
  yield takeEvery(
    sharedActionTypes.REQUEST_SNACKBAR_CLOSE,
    pushRequestSnackbarCloseHandler
  );
}

function* watchLogout() {
  yield takeEvery(sharedActionTypes.LOGOUT, logout);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchAppUpstart),
    fork(watchPushSnackbarMessage),
    fork(watchRequestSnackbarMessage),
    fork(watchLogout),
    fork(globalSearchSaga),
    fork(analytics),
    fork(loginAsSaga),
    fork(snackbarWarnings),
  ]);
}
