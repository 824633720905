import React, { FC, useEffect, useRef, useState } from 'react';
import tinymce from 'tinymce';
import ky from 'ky';
import { UserManager } from 'oidc-client';
import { makeStyles } from '@material-ui/core/styles';

import isJWTExpired from '../utils/isJWTExpired';
import { ErrorType, seismicStoreKey } from '../constants';

import { liveSyncLink } from './SeismicHTML';
import {
  Document,
  LiveSend,
  Options,
  OptionsParam,
  SeismicContainerProps,
  User,
} from './Seismic';
import WaitingForUser from './WaitingForUser';
import LoginError from './LoginError';
import SeismicButton from './SeismicButton';
import LiveSendErrors from './LiveSendErrors';

const tokenExpiryOffset = 15 * 60; // 15 minutes
const liveSendExpiryOffset = 90 * 24 * 60 * 60 * 1000; // 90 days

const settings = (tenant: string) => {
  let redirectUrl = 'https://app.groove.co/redirect';
  // This is to make sure we can test this on staging
  if (window.location.hostname === 'staging.groove.co') {
    redirectUrl = `https://staging.groove.co/redirect`;
  }
  return {
    authority: `https://auth.seismic.com/tenants/${tenant}`,
    client_id: '716e7f99-3a03-40cc-acd5-fc4c4fcec143',
    redirect_uri: redirectUrl,
    silent_redirect_uri: redirectUrl,
    scope: 'seismic.self.view seismic.search seismic.delivery',
    response_type: 'token',
    loadUserInfo: false,
  };
};

const liveSendURL = 'https://api.seismic.com/integration/v2/liveSend/links';

const options = ({ token, onChange }: OptionsParam): Options => ({
  settings: {
    language: 'en-US',
    selectionMode: 'multi',
    allowFolderSelection: false,
    repositories: ['recents', 'favorites', 'doccenter', 'workspace', 'library'],
    initialFolderId: {},
    defaultContext: 'workspace',
    viewMode: 'wide',
    contentTypeFilter: [],
    hideContentTypeFilteredItems: false,
    deliveryOptionsFilter: ['Generate LiveSend Link'],
    hideDeliveryOptionsFilteredItems: true,
  },
  apiOptions: {
    authTokenResolver: () => token,
  },
  callbacks: {
    onSelectionChanged: onChange,
  },
});

const liveSendData = (document: Document, token: string) => ({
  json: {
    settings: {
      expiresAt: new Date(Date.now() + liveSendExpiryOffset).toISOString(),
      allowDownload: true,
      notificationType: 'All',
    },
    content: [document],
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
  throwHttpErrors: false,
});

const useStyles = makeStyles({
  buttonContainer: {
    flex: 1,
    textAlign: 'center',
  },
  buttonRow: {
    display: 'flex',
    margin: 'auto',
    width: 380,
    padding: 15,
  },
  hidePicker: {
    display: 'none',
  },
  pickerContainer: {
    borderBottom: '1px solid #EDEDED',
    maxHeight: 450,
  },
});

const SeismicContainer: FC<SeismicContainerProps> = ({
  clearTenant,
  onClose,
}) => {
  const { buttonContainer, buttonRow, hidePicker, pickerContainer } =
    useStyles();
  const seismicRef = useRef(null);
  const [isWaitingOnUser, setIsWaitingOnUser] = useState(true);
  const [signinError, setSigninError] = useState<ErrorType>(ErrorType.null);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [liveSendError, setLiveSendError] = useState('');
  const [token, setToken] = useState('');

  const reloader = () => {
    setReloadTrigger(!reloadTrigger);
    setSigninError(ErrorType.null);
  };

  const closeLiveSendError = () => setLiveSendError('');

  const onDone = async () => {
    let docString = '';
    try {
      const promiseCalls = documents.map(document => {
        if (document.libraryContent && document.libraryContent.versionId) {
          document.versionId = document.libraryContent.versionId;
        }
        return ky
          .post(liveSendURL, liveSendData(document, token))
          .json() as Promise<LiveSend>;
      });
      const liveSendArray = await Promise.all(promiseCalls);
      liveSendArray.forEach(liveSend => {
        if (liveSend.error) throw liveSend;
        const imageLink = liveSyncLink({
          link: liveSend.url,
          name: liveSend.content[0].name,
        });
        docString += imageLink;
      });
      tinymce.activeEditor.insertContent(docString);
      onClose();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      if (e.error && e.error.code) {
        if (e.error.message) {
          setLiveSendError(e.error.message);
        } else {
          setLiveSendError('unknown');
        }
      } else {
        setSigninError(ErrorType.other);
      }
    }
  };

  // We need to check if the token has expired every time a user does anything
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (token && isJWTExpired(token, tokenExpiryOffset)) {
      setSigninError(ErrorType.sessionEnded);
    }
  });

  useEffect(() => {
    const onChange = (items: Document[]) => setDocuments(items);

    if (token && SeismicPlatform && SeismicPlatform.SeismicBootstrapper) {
      const { SeismicBootstrapper } = SeismicPlatform;
      if (SeismicBootstrapper.init) SeismicBootstrapper.init({ token });
      if (SeismicBootstrapper.attach) {
        SeismicBootstrapper.attach(
          'universal-content-picker@1',
          options({ token, onChange }),
          seismicRef.current,
        ).then(() => {
          setIsWaitingOnUser(false);
          // Have to do this stupid hack to make sure that we can control the height of the picker
          const style = document.createElement('style');
          style.innerHTML =
            '.seismic-ucp-layout-container { min-height: 450px; }';
          document.getElementsByTagName('head')[0].appendChild(style);
        });
      }
    }
  }, [reloadTrigger, token]);

  useEffect(() => {
    const tenant = localStorage.getItem(seismicStoreKey) || '';
    const manager = new UserManager(settings(tenant));

    manager.getUser().then((user: User | null) => {
      if (user && !isJWTExpired(user.access_token, tokenExpiryOffset)) {
        setToken(user.access_token);
      } else {
        setIsWaitingOnUser(true);
        manager
          .signinPopup({
            popupWindowFeatures: 'location=no,toolbar=no,width=680,height=710',
          })
          .catch(error => {
            setIsWaitingOnUser(false);
            if (error.message.includes('Popup window closed')) {
              setSigninError(ErrorType.onClose);
            } else {
              setSigninError(ErrorType.other);
            }
          });

        manager.events.addUserLoaded(async loadedUser => {
          manager.storeUser(loadedUser);
          setToken(loadedUser.access_token);
        });
      }
    });
  }, [reloadTrigger]);

  let additionalScreen = null;
  if (isWaitingOnUser) {
    additionalScreen = <WaitingForUser />;
  } else if (signinError !== ErrorType.null) {
    additionalScreen = (
      <LoginError
        clearTenant={clearTenant}
        errorType={signinError}
        reloader={reloader}
      />
    );
  }

  return (
    <>
      {additionalScreen}
      <div className={additionalScreen ? hidePicker : ''}>
        <div ref={seismicRef} className={pickerContainer} />
        <div className={buttonRow}>
          <div className={buttonContainer}>
            <SeismicButton onClick={onClose} inverse>
              Cancel
            </SeismicButton>
          </div>
          <div className={buttonContainer}>
            <SeismicButton disabled={documents.length <= 0} onClick={onDone}>
              Insert
            </SeismicButton>
          </div>
        </div>
      </div>
      {liveSendError && (
        <LiveSendErrors
          onClose={closeLiveSendError}
          errorMessage={liveSendError}
        />
      )}
    </>
  );
};

export default SeismicContainer;
