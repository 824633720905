import { actions, selectors } from '@groove-labs/groove-ui';
import { fromJS } from 'immutable';
import * as peopleImportHTTPClient from 'GrooveHTTPClient/peopleImport';
import * as savedSearchesHTTPClient from 'GrooveHTTPClient/savedSearches';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { capitalize, isUndefined, isNull, isString, mapKeys } from 'lodash-es';
import humps from 'humps';
import {
  actionTypes as peopleImportActionTypes,
  requestProcessSearchResults,
  setNoResultsFound,
  setSalesforceObject,
  setSelectedObjectValue,
  unsetSalesforceObject,
  setSearching,
} from 'Modules/PeopleImportDialog/actions';
import { resetTableResults } from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import {
  actionTypes,
  addFilter,
  clearFilters,
  deleteFilter,
  updateFilter,
  requestSearch,
} from 'Modules/PeopleImportDialog/submodules/advancedSearch/actions';
import {
  salesforceObject as salesforceObjectSelector,
  relationshipName as relationshipNameSelector,
  selectedObjectValue,
} from 'Modules/PeopleImportDialog/selectors';
import {
  filters as filtersSelector,
  savedSearchEnabled as savedSearchEnabledSelector,
  selectedSavedSearch as selectedSavedSearchSelector,
} from 'Modules/PeopleImportDialog/submodules/advancedSearch/selectors';
import {
  SAVED_SEARCH_GROUP_ID,
  SAVED_SEARCH_SELECT_FIELD_ID,
  REQUEST_IN_PROGRESS_KEY_PATH,
  SCOPE_SELECTOR_GROUP_ID,
  SCOPE_SELECTOR_FIELD_ID,
  SAVE_SEARCH_GROUP_ID,
  SAVE_SEARCH_NAME_FIELD_ID,
  SAVE_SEARCH_DIALOG_OPEN_KEYPATH,
  DELETE_SEARCH_DIALOG_OPEN_KEYPATH,
  FILTER_WARNING_DIALOG_KEYPATH,
  OBJECT_NAME_FIELD_ID,
  VALUE_FIELD_ID,
  SOQL_QUERY_REQUEST_LIMIT,
  SOQL_QUERY_REQUEST_OFFSET_LIMIT,
} from 'Modules/PeopleImportDialog/submodules/advancedSearch/constants';
import SoqlQueryBuilder, {
  Condition,
  OPERATORS,
} from 'Modules/PeopleImportDialog/utils/SoqlQueryBuilder';
import { FIELD_TYPES } from 'Modules/Shared/constants/salesforce';
import { SALESFORCE_OBJECTS } from 'Modules/Shared/constants';
import { contactChildren as contactChildrenSelector } from 'Modules/Shared/selectors/salesforceMeta';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { PEOPLE_IMPORT_ROOT_UI_KEY_PATH } from 'Modules/PeopleImportDialog/constants';
import SavedSearchRequestBuilder from 'Modules/PeopleImportDialog/utils/SavedSearchRequestBuilder';
import {
  successfullyFetchedSavedSearch,
  setSavedSearches,
} from 'Modules/Shared/actions/savedSearches';
import { updateSalesforceMeta } from 'Modules/Shared/actions/salesforceMeta';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import shortId from 'shortid';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import { getFlowId } from 'Modules/FlowsShow/selectors';

const {
  actionTypes: formActionTypes,
  deleteField,
  setToInitialFieldState,
  updateFieldValue,
} = actions.form;
const { setProperty, batchSetProperty, deleteProperty } = actions.ui;
const { makeGetFormData, makeGetFieldValue } = selectors.form;

// ------------ Utils -----
const cleanUpName = name => {
  // remove periods from name and replace with underscore
  const nameArray = name.split('.');
  const cleanName = nameArray.length > 1 ? nameArray.join('_') : name;
  return cleanName;
};

const buildFilterCondition = filter => {
  const { fieldName, fieldType, operator } = filter;

  let value = filter.value;
  if (
    [OPERATORS.LIKE, OPERATORS.NOT_LIKE, OPERATORS.NOTLIKE].includes(operator)
  ) {
    value = `%${value}%`;
  } else if (
    [OPERATORS.EQUALS, OPERATORS.NOT_EQUALS].includes(operator) &&
    isUndefined(value)
  ) {
    value = '';
  } else if (fieldType === FIELD_TYPES.BOOLEAN && isString(value)) {
    value = value === 'true';
  }

  return new Condition({
    field: fieldName,
    type: fieldType,
    value,

    // Just default to equals. Covers the boolean case at least.
    operator: operator || OPERATORS.EQUALS,
  });
};

// -------------- Handlers --------------

const buildNewFilter = () => {
  return {
    fieldName: null,
    fieldType: null,
    operator: null,
    value: null,
  };
};

function* getScopeFilter() {
  const isMine = yield select(makeGetFieldValue(SCOPE_SELECTOR_FIELD_ID), {
    groupId: SCOPE_SELECTOR_GROUP_ID,
  });

  return isMine ? 'mine' : 'everything';
}

function* refreshSavedSearches() {
  const savedSearches = yield call(savedSearchesHTTPClient.index);
  yield put(setSavedSearches(savedSearches.data));
}

function* createFilterHandler(filter = null, filterGroupId = null) {
  if (filter) {
    // make sure isContactCondition is a boolean value
    filter.isContactCondition =
      filter.isContactCondition === true ||
      filter.isContactCondition === 'true';
  }

  let groupId;
  // Add the new filter to redux state
  if (isNull(filterGroupId)) {
    const newFilter = buildNewFilter();
    groupId = `filter-${shortId.generate()}`;
    yield put(addFilter(groupId, newFilter));
  } else {
    groupId = filterGroupId;
    yield put(addFilter(groupId, filter));
  }

  // Fork off process that watches the form group to make updates to the filter.
  while (true) {
    const { update, requestDelete } = yield race({
      update: take(formActionTypes.UPDATE_FIELD_VALUE),
      requestDelete: take(actionTypes.REQUEST_DELETE_FILTER),
    });

    // If the field's form group was updated, get new data
    if (update && update.payload.groupId === groupId) {
      const formDataSelector = makeGetFormData();
      let updates = yield select(state => formDataSelector(state, { groupId }));

      if (update.payload.fieldId === OBJECT_NAME_FIELD_ID) {
        // Each time selected object field is changed we destroy "value"
        // TODO I'm not able to delete field, I've tried REQUEST_DELETE_FIELD as well
        // https://github.com/GrooveLabs/febes/issues/2860
        yield put(
          deleteField({
            groupId,
            fieldId: VALUE_FIELD_ID,
          })
        );
      }

      // Fetch the field type when it's the field that is being selected.
      if (
        update.payload.fieldId === OBJECT_NAME_FIELD_ID &&
        update.payload.value
      ) {
        updates = updates
          .set('fieldType', update.payload.value.get('type'))
          .set('fieldName', update.payload.value.get('name'))
          .set(
            'isContactCondition',
            update.payload.value.get('isContactCondition')
          );
      } else {
        // Hacky, but do not override the fieldName value that was already set in the reducer if
        // this update was not explicitly the field SDDM.
        updates = updates
          .delete('fieldType')
          .delete('fieldName')
          .delete('isContactCondition');
      }

      // Update the filter data in the reducer
      yield put(updateFilter(groupId, updates));
    }

    // If the field's form group was updated, get new data
    if (requestDelete && requestDelete.payload === groupId) {
      yield put(deleteFilter(groupId));
    }
  }
}

function* searchHandler({
  payload: { requestLimit = SOQL_QUERY_REQUEST_LIMIT },
}) {
  const filters = yield select(filtersSelector);
  const selectedObject = yield select(selectedObjectValue);
  const shouldBuildContactConditions = ['account', 'opportunity'].includes(
    selectedObject
  );

  // check if the filters are valid. For Accounts + Contacts and Deal + Contacts searches, atleast one
  // filter of the primary object (Account and Opportunity) needs to be present, not just contact fields.
  if (
    shouldBuildContactConditions &&
    !filters.some(filter => !filter.get('isContactCondition'))
  ) {
    yield put(
      setProperty({
        uiKeyPath: FILTER_WARNING_DIALOG_KEYPATH,
        data: true,
      })
    );
    return;
  }

  // reset table to remove pagination
  yield put(resetTableResults());

  // Before searching ensure the no results indicator is set to false.
  yield put(setNoResultsFound(false));

  yield put(setSearching(true));
  // Do not close the drawer, to allow the user to continue building their query

  let salesforceObject = yield select(salesforceObjectSelector);
  salesforceObject = `${salesforceObject
    .charAt(0)
    .toUpperCase()}${salesforceObject.slice(1)}`;

  // Convert filters OrderedMap to POJO Array for the rest of the operations.
  const filtersArray = filters.valueSeq().toJS();
  try {
    let contactConditions;
    if (shouldBuildContactConditions) {
      contactConditions = filtersArray
        .filter(filter => filter.isContactCondition)
        .map(filter => buildFilterCondition(filter).toString())
        .join(' AND ');
    }

    const conditions = filtersArray
      .filter(
        filter =>
          (shouldBuildContactConditions && !filter.isContactCondition) ||
          !shouldBuildContactConditions
      )
      .map(filter => buildFilterCondition(filter));

    // By default, select Id and Name fields.
    const selectFields = ['Id', 'Name', 'Title'];

    if (
      salesforceObject.toLowerCase() ===
      SALESFORCE_OBJECTS.Contact.toLowerCase()
    ) {
      // Add default Contact select fields
      ['Email', 'Account.Name'].forEach(field => selectFields.push(field));
    }

    if (
      salesforceObject.toLowerCase() === SALESFORCE_OBJECTS.Lead.toLowerCase()
    ) {
      // Add default Lead select fields
      ['Email', 'Company'].forEach(field => selectFields.push(field));

      // Unconverted Leads only
      conditions.push(
        new Condition({
          field: 'isConverted',
          operator: OPERATORS.EQUALS,
          value: false,
          fieldType: FIELD_TYPES.BOOLEAN,
        })
      );
    }

    // Add other field names after the defaults
    filtersArray.forEach(filter => {
      if (
        !selectFields.includes(filter.fieldName) &&
        (!shouldBuildContactConditions ||
          (shouldBuildContactConditions && !filter.isContactCondition))
      ) {
        selectFields.push(filter.fieldName);
      }
    });

    const scope = yield call(getScopeFilter);
    const relationshipName = yield select(relationshipNameSelector);
    const queryBuilder = new SoqlQueryBuilder(salesforceObject)
      .fields(selectFields)
      .conditions(...conditions)
      .scope(scope);

    let offset = 0;
    let keepFetching;

    const responses = [];

    do {
      // Perform search and parse results
      // and cancel the action when a tab is switched
      const flowId = yield select(getFlowId);
      const { response, cancel } = yield race({
        response: call(
          handleInvalidSalesforceConnectionHTTPRequest,
          peopleImportHTTPClient.searchBySoqlQuery,
          {
            objectName: salesforceObject,
            soqlQuery: queryBuilder.toString(),
            relationshipName,
            contactConditions,
            limit: requestLimit,
            offset,
            flowId,
          }
        ),
        cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
      });

      if (cancel) {
        yield put(setSearching(false));
        return;
      }

      offset += requestLimit;

      responses.push(response);

      keepFetching = response.data.results.length === requestLimit;

      // When the offset exceed 2000 Salesforce returns "NUMBER_OUTSIDE_VALID_RANGE: Maximum SOQL offset allowed is 2000"
      // error
      // For now we just stop iteration, so it works similar to the default way with 2000 records default limit.
      if (offset > SOQL_QUERY_REQUEST_OFFSET_LIMIT) {
        yield put(
          pushSnackbarMessage({
            message:
              'Some results was skipped because of the limit. Please add more filters',
          })
        );

        keepFetching = false;
      }
    } while (keepFetching);

    const aggregatedResponse = responses.reduce(
      (agg, response) => {
        const { data } = agg;
        data.columnData = response.data.columnData;
        data.hasContactConditions = response.data.hasContactConditions;
        data.intersectionEmails = [
          ...(data.intersectionEmails || []),
          ...(response.data.intersectionEmails || []),
        ];
        data.intersectionIds = [
          ...(data.intersectionIds || []),
          ...(response.data.intersectionIds || []),
        ];
        data.opportunityContacts = {
          ...(data.opportunityContacts || {}),
          ...(response.data.opportunityContacts || {}),
        };
        data.peopleResults = [
          ...(data.peopleResults || []),
          ...(response.data.peopleResults || []),
        ];
        data.results = [
          ...(data.results || []),
          ...(response.data.results || []),
        ];
        data.importRuleViolations = {
          ...(data.importRuleViolations || {}),
          ...(response.data.importRuleViolations || {}),
        };

        agg.data = data;
        return agg;
      },
      { data: {} }
    );

    // Does the heavy lifting of parsing the shitty response and loading row/column data into the
    // peopleTable reducer.
    yield put(requestProcessSearchResults(aggregatedResponse));
  } catch (e) {
    // retry once with lower per request limit
    if (requestLimit >= SOQL_QUERY_REQUEST_LIMIT) {
      yield put(
        requestSearch({
          requestLimit: requestLimit / 2,
        })
      );
    } else {
      console.error(e);
      yield put(
        pushSnackbarMessage({
          message:
            'There was an issue returning your results. Refine your search and try again.',
        })
      );
      yield put(setSearching(false));
    }
  }
}

function* clearSelectedSavedSearch() {
  yield put(
    setToInitialFieldState({
      groupId: SAVED_SEARCH_GROUP_ID,
      fieldId: SAVED_SEARCH_SELECT_FIELD_ID,
    })
  );
}

function* resetSavedSearchState() {
  yield put(clearFilters());
  yield put(unsetSalesforceObject());
  yield* clearSelectedSavedSearch();
}

function* handleSavedSearchFilter(filter) {
  const groupId = `filter-${shortId.generate()}`;

  yield call(createFilterHandler, filter, groupId);
}

function* setSalesforceObjectHandler(action) {
  yield put(clearFilters());

  // If a saved search is selected, clear it as well.
  const savedSearchEnabled = yield select(savedSearchEnabledSelector);
  if (savedSearchEnabled) {
    yield fork(clearSelectedSavedSearch);
  }
  if (['account', 'contact', 'lead'].includes(action.payload)) {
    // specialised handling for account objects to get the searchable fields
    // not this object must be capitalised
    const searchObject = capitalize(action.payload);
    const response = yield* handleInvalidSalesforceConnectionHTTPRequest(
      peopleImportHTTPClient.getFilterableFields,
      searchObject
    );

    if (response.meta.success) {
      // we have successfully obtained the filterable fields
      // update the fields for the specific salesforce object
      const { filterableFields } = response.data;

      const formattedFields = filterableFields.reduce(
        (currentField, nextField) =>
          currentField.set(
            humps.camelize(cleanUpName(nextField.name)),
            fromJS(nextField)
          ),
        new Map()
      );

      // TODO It seems we should refactor it where we override salesforceMeta when people advanced search dialog is opened.
      // Then it could break other code where we expect salesforceMata is exactly user_config.sfdc_meta from GE without mutation.
      //
      // Instead we should store filterableFields as a new state and update advanced search components to use it.
      yield put(
        updateSalesforceMeta({
          salesforceObject: action.payload,
          fields: formattedFields,
        })
      );
    }
  }

  yield put(setSalesforceObject(action.payload));

  yield call(createFilterHandler);
}

function* savedSearchSelectedHandler(action) {
  const savedSearch = action.payload;

  yield put(clearFilters());
  const lowerSalesforceObjects = mapKeys(SALESFORCE_OBJECTS, (_, key) =>
    key.toLowerCase()
  );
  // Saved searches object types can either be one of `SALESFORCE_OBJECTS` above or a contact related object.
  // If it is contact related, set the contact child's objectName as the selected salesforce object.
  if (lowerSalesforceObjects[savedSearch.objectType.toLowerCase()]) {
    yield put(setSalesforceObject(savedSearch.objectType.toLowerCase()));
    yield put(setSelectedObjectValue(savedSearch.objectType.toLowerCase()));
  } else {
    const contactChildren = yield select(contactChildrenSelector);
    const contactChild = contactChildren.find(
      object => object.get('objectQueryKey') === savedSearch.objectType
    );
    const salesforceObject =
      (contactChild && contactChild.get('objectName')) || 'contact';
    yield put(setSalesforceObject(salesforceObject));
    yield put(setSelectedObjectValue(savedSearch.objectType));
  }

  // set the selected saved search scope directly onto the form reducer
  // This is to ensure that the include my records checkbox has the right scope populated
  const scopeValue = savedSearch.definition.scope === 'mine';
  yield put(
    updateFieldValue({
      fieldId: SCOPE_SELECTOR_FIELD_ID,
      groupId: SCOPE_SELECTOR_GROUP_ID,
      value: scopeValue,
    })
  );

  yield all(
    savedSearch.definition.conditions.map(filter =>
      call(handleSavedSearchFilter, filter)
    )
  );
}

function* savedSearchEnabledHandler() {
  yield put(clearFilters());
  yield put(unsetSalesforceObject());
}

function* handleResetAdvancedSearch() {
  // when the dialog is closed, clear any UI data under the 'peopleImportDialog' key path
  yield put(
    deleteProperty({
      uiKeyPath: PEOPLE_IMPORT_ROOT_UI_KEY_PATH,
    })
  );
}

function* handleSaveSearch() {
  // set in progress to true
  yield put(
    setProperty({
      uiKeyPath: REQUEST_IN_PROGRESS_KEY_PATH,
      data: true,
    })
  );

  // Build the request from the filters.
  const filters = yield select(filtersSelector);
  const salesforceObject = yield select(selectedObjectValue);
  const relationshipName = yield select(relationshipNameSelector);
  const scope = yield call(getScopeFilter);
  const name = yield select(makeGetFieldValue(SAVE_SEARCH_NAME_FIELD_ID), {
    groupId: SAVE_SEARCH_GROUP_ID,
  });
  const savedSearchRequestBody = new SavedSearchRequestBuilder(
    name,
    salesforceObject,
    scope,
    filters.toList().toJS(),
    relationshipName
  ).build();

  try {
    const response = yield call(
      savedSearchesHTTPClient.create,
      savedSearchRequestBody
    );
    yield put(pushSnackbarMessage({ message: `${name} saved successfully.` }));
    yield put(successfullyFetchedSavedSearch(response.data));
  } catch (e) {
    if (e instanceof HTTPError) {
      yield put(pushSnackbarMessage({ message: 'Save failed.' }));
    } else {
      throw e;
    }
  }

  // remove in progress indicator and close save modal
  yield put(
    batchSetProperty([
      {
        uiKeyPath: REQUEST_IN_PROGRESS_KEY_PATH,
        data: false,
      },
      {
        uiKeyPath: SAVE_SEARCH_DIALOG_OPEN_KEYPATH,
        data: false,
      },
    ])
  );

  yield put(requestSearch());
}

function* handleUpdateSavedSearch() {
  const filters = yield select(filtersSelector);
  const salesforceObject = yield select(selectedObjectValue);
  const relationshipName = yield select(relationshipNameSelector);
  const scope = yield call(getScopeFilter);
  const selectedSavedSearch = yield select(selectedSavedSearchSelector, {
    groupId: SAVED_SEARCH_GROUP_ID,
  });
  const definition = new SavedSearchRequestBuilder(
    null,
    salesforceObject,
    scope,
    filters.toList().toJS(),
    relationshipName
  ).getDefinition();

  try {
    yield call(savedSearchesHTTPClient.update, {
      id: selectedSavedSearch.get('id'),
      definition,
    });
    yield put(pushSnackbarMessage({ message: 'Updated successfully.' }));
    yield put(requestSearch());
  } catch (e) {
    if (e instanceof HTTPError) {
      return yield put(pushSnackbarMessage({ message: 'Update failed.' }));
    }
    throw e;
  }
  return yield fork(refreshSavedSearches);
}

function* handleDeleteSavedSearch() {
  // set in progress to true
  yield put(
    setProperty({
      uiKeyPath: REQUEST_IN_PROGRESS_KEY_PATH,
      data: true,
    })
  );

  const selectedSavedSearch = yield select(selectedSavedSearchSelector, {
    groupId: SAVED_SEARCH_GROUP_ID,
  });
  yield call(savedSearchesHTTPClient.destroy, selectedSavedSearch.get('id'));

  yield put(pushSnackbarMessage({ message: 'Deleted successfully.' }));
  yield fork(refreshSavedSearches);

  // remove in progress indicator and close save modal
  yield put(
    batchSetProperty([
      {
        uiKeyPath: REQUEST_IN_PROGRESS_KEY_PATH,
        data: false,
      },
      {
        uiKeyPath: DELETE_SEARCH_DIALOG_OPEN_KEYPATH,
        data: false,
      },
    ])
  );

  yield* resetSavedSearchState();
}

// -------------- Watchers --------------

function* watchRequestCreateFilter() {
  yield takeEvery(actionTypes.REQUEST_CREATE_FILTER, createFilterHandler);
}

function* watchRequestSetSalesforceObject() {
  yield takeEvery(
    peopleImportActionTypes.REQUEST_SET_SALESFORCE_OBJECT,
    setSalesforceObjectHandler
  );
}

function* watchRequestSearch() {
  yield takeLatest(actionTypes.REQUEST_SEARCH, searchHandler);
}

function* watchEnableSavedSearch() {
  yield takeEvery(actionTypes.ENABLE_SAVED_SEARCH, savedSearchEnabledHandler);
}

function* watchSelectSavedSearch() {
  yield takeEvery(actionTypes.SELECT_SAVED_SEARCH, savedSearchSelectedHandler);
}

function* watchResetPeopleImportDialog() {
  yield takeEvery(peopleImportActionTypes.RESET, handleResetAdvancedSearch);
}

function* watchSaveSearch() {
  yield takeEvery(actionTypes.SAVE_SEARCH, handleSaveSearch);
}

function* watchUpdateSavedSearch() {
  yield takeEvery(actionTypes.UPDATE_SAVED_SEARCH, handleUpdateSavedSearch);
}

function* watchDeleteSavedSearch() {
  yield takeEvery(actionTypes.DELETE_SAVED_SEARCH, handleDeleteSavedSearch);
}

// -------------- Exporting the root saga for integration with the store --------------

export default function* root() {
  yield all([
    fork(watchRequestCreateFilter),
    fork(watchRequestSetSalesforceObject),
    fork(watchRequestSearch),
    fork(watchSelectSavedSearch),
    fork(watchEnableSavedSearch),
    fork(watchResetPeopleImportDialog),
    fork(watchSaveSearch),
    fork(watchUpdateSavedSearch),
    fork(watchDeleteSavedSearch),
  ]);
}
