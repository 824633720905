export const successGreen = '#4caf50';
export const failedRed = '#ee6352';
export const warningRed = '#f44336';

export const SUCCESS_DARK_GREEN = '#0A4D0A';
export const SUCCESS_LIGHT_GREEN = '#DFF6DD';

export const MESSAGE_BAR_ACTION_BORDER = '#757575';
export const MESSAGE_BAR_RED_FONT_COLOR = '#831515';
export const MESSAGE_BAR_RED_BACKGROUND = '#FDE7E9';

export const ROLLUP_EFFORT_COLOR = '#FCB02B';
export const ROLLUP_ENGAGEMENT_COLOR = '#553E9B';

export const GREY_BORDER_COLOR = '#E0E0E0';
export const WHITE_SMOKE = '#f5f5f5';
export const LIGHT_GREY = 'rgba(0, 0, 0, 0.54)';

export const DISABLED_ITEM = 'rgba(0, 0, 0, 0.26)';

export const HIGH_PRIORITY_COLOR = '#DF2D16';
export const MEDIUM_PRIORITY_COLOR = '#FF8000';

export const SPOT_ON_COTTON = '#F8F7F7';

export const GROOVEY_BLUEY = '#29D5D5';
export const GROOVEY_BLUEY_1 = '#B8ECED';
export const GROOVEY_BLUEY_2 = '#E4F8F9';
export const GROOVEY_BLUEY_3 = '#A4E2E3';
export const GROOVEY_BLUEY_4 = '#03A3BB';
export const GROOVEY_BLUEY_5 = '#007e91';
export const GROOVEY_BLUEY_6 = '#eef6f7';

export const TANGERINE_DREAM = '#FBC122';

export const WAVY_NAVY = '#04294C';
export const WAVY_NAVY_1 = '#577693';
export const WAVY_NAVY_2 = '#C6DAEC';
export const WAVY_NAVY_3 = '#E1ECF7';
export const SURREAL_TEAL = '#0960B2';

export const GRAY_1 = '#757575';
export const GRAY_2 = '#9E9E9E';
export const GRAY_3 = '#CACACA';
export const GRAY_4 = '#F3F2F1';
export const GRAY_5 = '#EEEEEE';

export const BLACK = '#303030';
export const WHITE = '#FFFFFF';

export const SWITCH_TRACK_BLUE = '#99E4E6';
