import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import shortId from 'shortid';

import LoaderRow from 'Modules/Shared/components/ActionsRouteLoader/LoaderRow';
import LoaderFilterButton from 'Modules/Shared/components/ActionsRouteLoader/LoaderFilterButton';
import LoaderSearchInput from 'Modules/Shared/components/ActionsRouteLoader/LoaderSearchInput';
import sharedStyles from 'Modules/Actions/containers/Table/styles';
import { styles as moduleRootStyles } from 'Modules/Actions/containers/ModuleRoot/styles';

const styles = {
  root: {
    padding: 24,
  },
  headerSection: moduleRootStyles.headerContainer,
  filtersRow: {
    display: 'flex',
    height: 45,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
  },
  headerRow: {
    height: 56,
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    ...sharedStyles.tableRoot,
    height: 'calc(100vh - 224px)',
    overflow: 'hidden',
    scroll: 'none',
  },
};

const rows = [...Array(31)].fill().map(shortId.generate);

const ActionsRouteLoader = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.headerSection}>
      <Typography variant="h3">Actions</Typography>
    </div>
    <div className={classes.filtersRow}>
      <LoaderFilterButton />
      <LoaderSearchInput />
    </div>
    <div className={classes.table}>
      {rows.map(value => (
        <LoaderRow key={value} />
      ))}
    </div>
  </div>
);

ActionsRouteLoader.displayName = 'ActionsRouteLoader';
ActionsRouteLoader.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ActionsRouteLoader);
