import React from 'react';
import PropTypes from 'prop-types';

const defaultColor = '#FFF';

const OutcomesIcon = ({ className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
      style={style}
    >
      <path
        d="M21 5.25a2.25 2.25 0 0 1-2.25 2.25H5.25a2.25 2.25 0 0 1 0-4.498h13.5A2.25 2.25 0 0 1 21 5.25zm-4 6.5A2.249 2.249 0 0 1 14.75 14h-9.5a2.25 2.25 0 1 1 0-4.498h9.5A2.25 2.25 0 0 1 17 11.75zm-4 6.5a2.249 2.249 0 0 1-2.25 2.25h-5.5a2.25 2.25 0 1 1 0-4.498h5.5A2.25 2.25 0 0 1 13 18.25z"
        fill={style?.color || defaultColor}
      />
    </svg>
  );
};

OutcomesIcon.displayName = 'OutcomesIcon';
OutcomesIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

OutcomesIcon.defaultProps = {
  className: '',
  style: {
    color: defaultColor,
  },
};

export default OutcomesIcon;
