const ACCOUNTS_ROUTE_NAME = 'accounts';
const SPACES_SHOW_ROUTE_NAME = 'spacesShow';

export const ROUTE_NAMES = {
  HOME: 'home',
  EMAILS: 'emails',
  FLOWS: 'flows',
  FLOWS_SHOW: 'flowsShow',
  WORKSTEP: 'workStep',
  FLOWS_WIZARD: 'flowsWizard',
  TEMPLATES: 'templates',
  ANALYTICS: 'analytics',
  ACCOUNTS: 'accounts',
  ACTIVITY_LOG: 'activityLog',
  SCHEDULED_EMAILS: 'scheduledEmails',
  EMAIL_REMINDERS: 'emailReminders',
  PEOPLE: 'people',
  SETTINGS: 'settings',
  SPACES: 'spaces',
  SPACES_SHOW: 'spacesShow',
  COACHING: 'coaching',
  LOGIN: 'login',
  LOGIN_CALLBACK: 'loginCallback',
  SETUP_WIZARD: 'setupWizard',
  ERROR: 'error',
  ACTIONS: 'actions',
  ACTION_COMPOSE: 'actionCompose',
  ONBOARDING: 'onboarding',
  ONBOARDING_STEPS: 'onboardingSteps',
  ONBOARDING_MICROSOFT_STEPS: 'microsoftOnboardingSteps',
  SALESNAV_CONNECTED: 'salesNavConnected',
  SHARED_CONVERSATION: 'sharedConversation',
};

export { ACCOUNTS_ROUTE_NAME, SPACES_SHOW_ROUTE_NAME };
