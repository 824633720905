import { Map, List } from 'immutable';
import { isNil, isNull, keys } from 'lodash-es';
import moment from 'moment-timezone';
import { DEFAULT_SCHEDULE_WIDGET_VALUES } from 'Modules/WorkStep/constants';

export function guessTimeZone() {
  try {
    return moment.tz.guess();
  } catch (err) {
    return 'UTC';
  }
}

/**
 * Check to see if there's any unmerged fields in a single instance of person data
 * We're specifically looking in the email subject or the email body for unmerged fields
 * using a regex.
 *
 * @param {Object} personData
 * @param {Object} wysiwygData
 *
 * @return unmergedField
 */
export function findUnmergedFields(personData, wysiwygData = new Map()) {
  // null if there are no unmerged fields
  // other case returns found unmerged field
  const mergeFieldRegexp = /{![^{}]+}/;
  let unmergedField;

  const emailSubject =
    wysiwygData.get('subject') ||
    personData.previousThreadEmailSubject ||
    personData.preppedEmailSubject ||
    '';
  const emailBody =
    wysiwygData.get('body') || personData.preppedEmailBody || '';

  // look for in subject
  unmergedField = emailSubject.match(mergeFieldRegexp);
  if (unmergedField) {
    return unmergedField[0];
  }

  // look for in body
  unmergedField = emailBody.match(mergeFieldRegexp);
  if (unmergedField) {
    return unmergedField[0];
  }

  return null;
}

/**
 * Iterate all people to fill in "ready to send", "opted out" and "unmerged" collections
 *
 * @param {Object} args
 * @param {OrderedMap} args.people
 * @param {Map} args.wysiwygData
 * @param {Integer=} args.sendSize
 * @param {Boolean} args.optOutSafeguardDisabled
 *
 * @return {Object} peopleCollection
 */
export function classifyPeople({
  people,
  wysiwygData,
  sendSize = null,
  optOutSafeguardDisabled,
}) {
  const peopleCollection = {
    peopleReadyToSend: {}, // collect people that counld be send
    peopleOptedOutOfEmail: [], // keeps people that are not going to be send because of OptedOutOfEmail
    peopleMissingEmail: [], // keeps people that are not going to be send because no email address
    peopleWithUnmergedFields: [], // keeps people where unmerged fields was detected
  };
  // early exit
  if (sendSize !== null && sendSize < 1) {
    return peopleCollection;
  }
  // convert to array to preserve OrderedMap order
  const peopleArr = people.toList().toJS();
  for (let i = 0; i < peopleArr.length; i += 1) {
    const personObj = peopleArr[i];
    const personId = personObj.id;
    const personWysiwygData = wysiwygData.get(personId, new Map());
    if (!isNull(findUnmergedFields(personObj, personWysiwygData))) {
      peopleCollection.peopleWithUnmergedFields.push(personObj);
    } else if (
      // only evaluate people opted out email if safeguard is enabled
      !optOutSafeguardDisabled &&
      personObj?.fields?.hasoptedoutofemail?.raw
    ) {
      peopleCollection.peopleOptedOutOfEmail.push(personObj);
    } else if (!personObj.email) {
      peopleCollection.peopleMissingEmail.push(personObj);
    } else {
      peopleCollection.peopleReadyToSend[personId] = personObj;
      // early exit once we have found enough people to send, only if sendSize is specified
      if (
        sendSize &&
        keys(peopleCollection.peopleReadyToSend).length === sendSize
      ) {
        break;
      }
    }
  }
  return peopleCollection;
}

/**
 * Returns true if the passed string has an unmerged field.
 * Unmerged fields are in the form {!sfdcfield}
 *
 * @param {String} text
 *
 * @return {Boolean}
 */
export function hasUnmergedField(text) {
  const re = /{![^{}]+}/g; // regex for merge fields

  // Check if either the email boduy or subject has a merge field
  return text.match(re) !== null;
}

/**
 * Returns String that's used to sort rows by alert column
 */
export function prepareAlertValueForSort(person, editorData) {
  const bodyAndSubjectContent = editorData
    ? `${editorData.get('body')} ${editorData.get('subject')}`
    : `${person.get('preppedEmailBody')} ${person.get('preppedEmailSubject')}`;

  return [
    !isNil(person.get('autoImportId')) ? '1' : '0',
    !isNull(person.get('bouncedReason')) ? '1' : '0',
    person.get('repliedToLastStep') ? '1' : '0',
    person.get('openedPreviousEmail') ? '1' : '0',
    person.get('clickedLinkInPreviousEmail') ? '1' : '0',
    person.get('hasSfdcActivity') ? '1' : '0',
    person.get('invitedAt') ? '1' : '0',
    person.get('tzid') && person.get('tzid').includes('Europe/') ? '1' : '0',
    person.getIn(['fields', 'hasoptedoutofemail', 'raw']) ? '1' : '0',
    hasUnmergedField(bodyAndSubjectContent) ? '1' : '0',
  ].join('');
}

/**
 * This function will accept an offset String
 * and return a valid date time String
 * for use in the workstep schedulelater components
 *
 * @param {String} offset
 *
 * @return {String}
 */
export function offsetToDateString(dateOffset) {
  switch (dateOffset) {
    case DEFAULT_SCHEDULE_WIDGET_VALUES.oneHour:
      return moment().add(1, 'hour').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.fourHours:
      return moment().add(4, 'hour').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.twelveHours:
      return moment().add(12, 'hour').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.nextMorning:
      return moment().add(1, 'day').startOf('day').add(8, 'hours').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.twoDays:
      return moment().add(2, 'day').startOf('day').add(11, 'hours').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.threeDays:
      return moment().add(3, 'day').startOf('day').add(11, 'hours').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.fourDays:
      return moment().add(4, 'day').startOf('day').add(11, 'hours').format();
    case DEFAULT_SCHEDULE_WIDGET_VALUES.fiveDays:
      return moment().add(5, 'day').startOf('day').add(11, 'hours').format();
    case 'Immediately':
      return moment().format();
    default:
      return dateOffset;
  }
}

/**
 * Build a string of comma separated emails from an array of objects containing an email
 *
 * @param {List} ccPeopleList
 *
 * @return {String}
 *
 */
export function buildCCString(ccPeopleList) {
  if (!List.isList(ccPeopleList)) return '';
  return ccPeopleList
    .filter(email => !!email.get('email'))
    .map(emailObj => emailObj.get('email'))
    .join(', ');
}

/**
 * Get a map of personId and cc fields to be merged into the UI reducer
 *
 * @param {Object} workstepPayload
 *
 * @return {Object}
 */
export const extractCCData = people =>
  Object.keys(people).reduce((acc, personId) => {
    const recipients = {
      cc: people[personId].ccRecipients,
      bcc: people[personId].bccRecipients,
    };

    acc[personId] = recipients;
    return acc;
  }, {});
