import keyMirror from 'Utils/key-mirror';

export const LABEL_CATEGORIES = keyMirror({
  ORG_CUSTOM: 'org_custom',
  PERSONA: 'persona',
  GROOVE_SYSTEM: 'groove_system',
  SALESFORCE_CUSTOM_TAGGING: 'salesforce_custom_tagging',
});
export const LABEL_OWNER_TYPES = keyMirror({
  ORGANIZATION: 'organization',
});

export const LABELABLE_TYPES = {
  FLOW: 'FLOW',
  TEMPLATE: 'TEMPLATE',
};

export const LABEL_IDS_TO_UPDATE_UI_KEY_PATH = [
  'checkedLabels',
  'labelIdsToUpdate',
];

export const LABEL_IDS_TO_FILTER_UI_KEY_PATH = [
  'checkedLabels',
  'labelIdsToFilter',
];
