export const getCsvLookupRows = state =>
  state.getIn(['PeopleImportDialog', 'csvLookup', 'csvRows']);
export const getCsvLookupHeader = state =>
  state.getIn(['PeopleImportDialog', 'csvLookup', 'csvHeader']);
export const getPeopleNotFoundInSalesforce = state =>
  state.getIn([
    'PeopleImportDialog',
    'peopleTable',
    'missingPeopleSearchValues',
  ]);
export const getSelectedObjectField = state =>
  state.getIn(['PeopleImportDialog', 'csvLookup', 'selectedObjectField']);
export const getLookupByColumnIdx = state =>
  state.getIn(['PeopleImportDialog', 'csvLookup', 'initialLookupByColumnIdx']);

export const makeGetCsvRows = state =>
  state.getIn(['autoCreationOfRecords', 'csvRows']);
export const getCsvHeaders = state =>
  state.getIn(['autoCreationOfRecords', 'csvHeaders']);
export const getCsvMappedHeaders = state =>
  state.getIn(['autoCreationOfRecords', 'mappedHeaders']);
export const getSelectedSObj = state =>
  state.getIn(['autoCreationOfRecords', 'selectedSObject']);
export const getIsLoading = state =>
  state.getIn(['autoCreationOfRecords', 'isLoading']);
export const getSearchResults = state =>
  state.getIn(['autoCreationOfRecords', 'searchResults']);
export const getIsSfdcFieldMappingModalOpen = state =>
  state.getIn(['ui', 'autoCreationOfRecords', 'isSfdcFieldMappingDialogOpen']);
export const getIsAutoCreationOfRecordsConfirmationModalOpen = state =>
  state.getIn(['ui', 'autoCreationOfRecords', 'isConfirmationModalOpen']);
export const getSalesforceObjectFieldSet = state =>
  state.getIn(['autoCreationOfRecords', 'salesforceObjectFieldSet']);
export const getRecommendedAccounts = state =>
  state.getIn(['autoCreationOfRecords', 'recommendedAccounts']);
export const getErrors = state =>
  state.getIn(['autoCreationOfRecords', 'errors']);
export const getRecordsFailed = state =>
  state.getIn(['autoCreationOfRecords', 'recordsFailed']);
export const getRecordsProcessed = state =>
  state.getIn(['autoCreationOfRecords', 'recordsProcessed']);
export const getRecordCreationStatus = state =>
  state.getIn(['autoCreationOfRecords', 'bulkCreationStatus']);
export const getAddToFlowInProgress = state =>
  state.getIn(['autoCreationOfRecords', 'addToFlowInProgress']);

export const getAddToFlowStatus = state =>
  state.getIn(['autoCreationOfRecords', 'addToFlowStatus']);
export const getAddToFlowError = state =>
  state.getIn(['autoCreationOfRecords', 'addToFlowError']);

export const getSelectedRecords = state =>
  state.getIn(['autoCreationOfRecords', 'selectedRecords']);
export const getWarnings = state =>
  state.getIn(['autoCreationOfRecords', 'warnings']);
export const getAutoCreationRecordCount = state =>
  state.getIn(['autoCreationOfRecords', 'autoCreationCount']);
export const getAutoCreationRecordLimit = state =>
  state.getIn(['autoCreationOfRecords', 'autoCreationLimit']);
export const getRequiredFields = state =>
  state.getIn(['autoCreationOfRecords', 'requiredSalesforceFields']);

export const isFlowShowOpen = state =>
  state.getIn(['ui', 'flowShow', 'peopleImportDialogOpen']);
export const isFlowWizardOpen = state =>
  state.getIn(['ui', 'showPeopleImportDialog']);
export const getFlowShowId = state => state.getIn(['flowsShow', 'flow', 'id']);
export const getFlowWizardId = state =>
  state.getIn(['flowsWizard', 'flow', 'id']);
export const getContactFieldSets = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'contact', 'fields']);
export const getLeadFieldSets = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'lead', 'fields']);
export const isContactCreatable = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'contact', 'createable']);
export const isLeadCreatable = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'lead', 'createable']);
