export const STAT_TABS_GROUPS_ORDER = [
  'dues',
  'statuses',
  'completed',
  'stats',
];

export const DEFAULT_STAT_TABS = {
  dues: [
    {
      value: 'dueToday',
      label: 'Due',
      tooltip: 'People who are due for action right now',
    },
    {
      value: 'notYetDue',
      label: 'Not Yet Due',
      tooltip:
        'People who are not due for action and waiting for an upcoming step',
    },
  ],
  statuses: [
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Invalid contacts',
    },
  ],
  completed: [
    {
      value: 'completed',
      label: 'Completed',
      tooltip:
        'Number of Times this Step is completed. People added multiple times will be counted more than once',
    },
  ],
};

export const TEMPLATE_STAT_TABS = {
  ...DEFAULT_STAT_TABS,
  statuses: [
    {
      value: 'outbox',
      label: 'Outbox',
      tooltip: 'People who are scheduled to be sent an email',
    },
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Emails that Groove could not send due to some reason',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
  ],
  stats: [
    {
      value: 'opened',
      label: 'Opens',
      tooltip: 'People who opened your email',
    },
    {
      value: 'clicked',
      label: 'Clicks',
      tooltip: 'People who clicked on a link in your email',
    },
    {
      value: 'replied',
      label: 'Replies',
      tooltip: 'People who replied to your email',
    },
  ],
};

// TODO: this will be the default once v3 is flipped for everyone.
export const DEFAULT_STAT_TABS_V3 = {
  dues: [
    {
      value: 'dueToday',
      label: 'Due',
      tooltip: 'People who are due for action right now',
    },
    {
      value: 'notYetDue',
      label: 'Not Yet Due',
      tooltip:
        'People who are not due for action and waiting for an upcoming step',
    },
  ],
  statuses: [
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Invalid contacts',
    },
    {
      value: 'skipped',
      label: 'Skipped',
      tooltip: 'People you have skipped for this step.',
    },
  ],
  completed: [
    {
      value: 'completed',
      label: 'Completed',
      tooltip:
        'Number of Times this Step is completed. People added multiple times will be counted more than once',
    },
  ],
};

export const EMAIL_ACTIVITY_RECORDS = {
  OPENED: 'lastOpenedDate',
  CLICKED: 'lastClickedDate',
  REPLIED: 'lastRepliedDate',
};

export const EMAIL_ACTIVITY_TYPES = {
  OPENED: 'opened',
  CLICKED: 'clicked',
  REPLIED: 'replied',
};

export const TEMPLATE_STAT_TABS_V3 = {
  ...DEFAULT_STAT_TABS_V3,
  statuses: [
    {
      value: 'outbox',
      label: 'Outbox',
      tooltip: 'People who are scheduled to be sent an email',
    },
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Emails that Groove could not send due to some reason',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
    {
      value: 'skipped',
      label: 'Skipped',
      tooltip: 'People you have skipped for this step.',
    },
  ],
  stats: [
    {
      value: EMAIL_ACTIVITY_TYPES.OPENED,
      label: 'Opens',
      tooltip: 'People who opened your email',
    },
    {
      value: EMAIL_ACTIVITY_TYPES.CLICKED,
      label: 'Clicks',
      tooltip: 'People who clicked on a link in your email',
    },
    {
      value: EMAIL_ACTIVITY_TYPES.REPLIED,
      label: 'Replies',
      tooltip: 'People who replied to your email',
    },
  ],
};

export const DEFAULT_ALL_STATS_TABS = {
  dues: [
    {
      value: 'dueToday',
      label: 'Due',
      tooltip: 'People who are due for action right now',
    },
    {
      value: 'notYetDue',
      label: 'Not Yet Due',
      tooltip:
        'People who are not due for action and waiting for an upcoming step',
    },
  ],
  statuses: [
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Invalid contacts',
    },
    {
      value: 'skipped',
      label: 'Skipped',
      tooltip: 'People who have skipped steps in this flow.',
    },
  ],
  completed: [
    {
      value: 'completed',
      label: 'Completed',
      tooltip: 'People who have completed all step in this flow.',
    },
  ],
};

export const TEMPLATE_ALL_STAT_TABS_V3 = {
  ...DEFAULT_ALL_STATS_TABS,
  statuses: [
    {
      value: 'outbox',
      label: 'Outbox',
      tooltip: 'People who are scheduled to be sent an email',
    },
    {
      value: 'paused',
      label: 'Paused',
      tooltip: 'People who have been paused',
    },
    {
      value: 'failed',
      label: 'Failed',
      tooltip: 'Emails that Groove could not send due to some reason',
    },
    {
      value: 'exited',
      label: 'Exited',
      tooltip: 'People who have exited this flow',
    },
    {
      value: 'skipped',
      label: 'Skipped',
      tooltip: 'People who have skipped steps in this flow.',
    },
  ],
  stats: [
    {
      value: EMAIL_ACTIVITY_TYPES.OPENED,
      label: 'Opens',
      tooltip: 'People who opened your emails',
    },
    {
      value: EMAIL_ACTIVITY_TYPES.CLICKED,
      label: 'Clicks',
      tooltip: 'People who clicked on a link in your email',
    },
    {
      value: EMAIL_ACTIVITY_TYPES.REPLIED,
      label: 'Replies',
      tooltip: 'People who replied to your email',
    },
  ],
};
/**
 * Each key represents a action within the people table and the value
 * represents the list of tabs where the button which triggers the action should appear.
 */
const MOVE_TO_STEP_ACTIONS = [
  'dueToday',
  'notYetDue',
  'paused',
  'completed',
  'opened',
  'clicked',
  'replied',
];
const MOVE_ACTIONS = [...MOVE_TO_STEP_ACTIONS, 'exited'];
export const PEOPLE_STATS_TAB_ACTIONS = {
  moveToStep: MOVE_TO_STEP_ACTIONS,
  moveToFlow: MOVE_ACTIONS,
  cancel: ['outbox'],
  remove: [
    'dueToday',
    'notYetDue',
    'paused',
    'completed',
    'opened',
    'clicked',
    'replied',
    'failed',
    'skipped',
  ],
  resume: ['paused'],
};

export const PEOPLE_IMPORT_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'peopleImportDialogOpen',
];

export const FLOW_SHARING_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'sharingDialogOpen',
];

export const COPY_FLOW_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'copyFlowDialogOpen',
];

export const DELETE_FLOW_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'deleteFlowDialogOpen',
];

export const EMAIL_ALIAS_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'emailAliasDialogOpen',
];

export const ARCHIVE_FLOW_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'archiveFlowDialogOpen',
];

export const AUTO_IMPORT_FLOW_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'autoImportFlowDialogOpen',
];

export const COPYING_FLOW_IMPORT_KEY_PATH = ['flowShow', 'copyingFlow'];

export const DELETING_FLOW_IMPORT_KEY_PATH = ['flowShow', 'deletingFlow'];

export const ARCHIVING_FLOW_IMPORT_KEY_PATH = ['flowShow', 'archivingFlow'];

export const ACTIVATING_FLOW_IMPORT_KEY_PATH = ['flowShow', 'activatingFlow'];

export const EDIT_FLOW_DIALOG_KEY_PATH = ['flowShow', 'editingFlow'];

export const EDIT_FLOW_DIALOG_IN_PROGRESS_KEY_PATH = [
  'flowShow',
  'flowSubmitInProgress',
];

export const EDIT_FLOW_DIALOG_GROUP_ID = 'editFlowDialog';

export const MOVE_TO_STEP_DIALOG_KEY_PATH = [
  'flowShow',
  'moveToStepDialogOpen',
];

export const MOVE_TO_STEP_ID_KEY_PATH = [
  'flowShow',
  'moveToStepSelectedStepId',
];

export const DELETE_STEP_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'deleteStepDialogOpen',
];

export const CANCEL_DIALOG_OPEN_KEY_PATH = ['flowsShow', 'cancelDialogOpen'];

export const AUTO_IMPORT_GROUP_ID = 'autoImport';

export const AUTO_IMPORT_TYPES = {
  SAVED_SEARCH: 'SavedSearch',
  SALESFORCE_REPORT: 'SalesforceReport',
};

export const AUTO_IMPORT_VIA_OPTIONS = [
  {
    label: 'Saved Search',
    value: AUTO_IMPORT_TYPES.SAVED_SEARCH,
  },
  {
    label: 'Salesforce Report',
    value: AUTO_IMPORT_TYPES.SALESFORCE_REPORT,
  },
];
export const AUTO_IMPORT_EDIT_MODE_PATH = ['autoImportEditMode'];

export const AUTO_IMPORT_FIELDS = {
  IMPORT_VIA: 'importVia',
  REPORT: 'report',
  SAVED_SEARCH_ID: 'ssid',
  ASSIGN_OWNER: 'assignOwner',
  FOLDERS: 'reportFolders',
  ASSIGN_CUSTOM_OWNER: 'assignCustomOwner',
  CUSTOM_OWNER_FIELD: 'customOwnerField',
  CUSTOM_OWNER_FIELD_VALUE: 'customOwnerFieldValue',
};

export const CUSTOM_MERGE_FIELDS_GROUP_ID = 'customMergeFields';

export const ALLOWED_FILTER_VALUES = [...MOVE_ACTIONS, 'outbox', 'failed'];

export const ADD_PEOPLE_DIALOG_OPEN_KEY_PATH = [
  'flowShow',
  'addPeopleToNewStepDialogOpen',
];

export const FLOW_PEOPLE_TABLE_TIME_FORMAT = 'MMM DD YYYY, h:mma';
export const FLOW_PEOPLE_TABLE_TIME_CSV_FORMAT = 'MMM DD YYYY, h:mma';

export const FLOW_OVERVIEW_TAB_NAME = '';
export const FLOW_PEOPLE_TAB_NAME = 'people';
export const FLOW_AUTOMATED_ACTIONS_TAB_NAME = 'automated_actions';
export const FLOW_HISTORY_TAB_NAME = 'history';

export const FLOW_PEOPLE_TABLE_TIME_COLUMN_IDS = [
  'lastOpenedDate',
  'completedDate',
  'lastClickedDate',
  'lastRepliedDate',
  'exitDate',
];

export const FLOW_PEOPLE_TABLE_TIME_AGO_COLUMN_IDS = ['scheduledSendDate'];

export const FLOW_PEOPLE_TABLE_REPLACE_UNDERSCORE_COLUMN_IDS = [
  'failedSendReason',
];

export const STATS_CSV_BASE_COLUMN_IDS = ['name', 'email', 'sfdcId'];

export const STATS_CSV_EXCUDE_COLUMN_IDS = ['salesforceLink'];

export const STATS_CSV_BASE_COLUMN_LABELS = ['Name', 'Email', 'SFDC ID'];

export const ALL_STEPS_SELECTED = 'all';
export const STAGED_PEOPLE_QUEUE_STEP = 'stagedPeopleQueue';

export const ADD_VARIANT_DIALOG_FORM_GROUP_ID =
  'ADD_VARIANT_DIALOG_FORM_GROUP_ID';

export const CONTACT_NOT_FOUND_FAILURE = 'contact_not_found';

export const OPTIONAL_SFDC_OBJECT_FIELDS = ['opportunity', 'lead'];

export const MOVE_PEOPLE_TO_STEP_JOB = 'flowMovePeopleToStep';

export const AUTO_SEND_IMMEDIATELY = 31;

export const BATCH_ADD_PEOPLE_LIMIT = 30;

export const DEFAULT_ADDRESS_FIELD_NAMES = {
  lead: {
    email: 'defaultLeadEmailFieldName',
    phone: 'defaultLeadPhoneFieldName',
  },
  contact: {
    email: 'defaultContactEmailFieldName',
    phone: 'defaultContactPhoneFieldName',
  },
};
