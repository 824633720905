import { fromJS, List } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/autoCreationOfRecords';
import { validateCsvRows } from '../containers/AutoCreationOfRecords/utils';
import { findWarnings } from 'Modules/Shared/sagas/autoCreationOfRecords';
import _ from 'lodash-es';

const initialState = fromJS({
  csvHeaders: null,
  salesforceObjectFieldSet: null,
  csvRows: null,
  mappedHeaders: null,
  selectedSObject: null,
  selectedLookupField: null,
  isLoading: false,
  searchResults: null,
  isSfdcFieldMappingDialogOpen: false,
  recommendedAccounts: null,
  errors: null,
  recordsFailed: null,
  recordsProcessed: null,
  bulkCreationStatus: null,
  addToFlowInProgress: null,
  addToFlowStatus: null,
  addToFlowError: '',
  selectedRecords: List(),
  warnings: null,
  autoCreationLimit: 0,
  autoCreationCount: 0,
  requiredSalesforceFields: {
    email: {
      relationshipName: null,
      updateable: true,
      name: 'email',
      filterable: true,
      label: 'Email',
      nillable: false,
      type: 'string',
      defaultedOnCreate: false,
    },
  },
});

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.FETCH_DATA.BEGIN: {
      return state.set('isLoading', true);
    }

    case actionTypes.FETCH_DATA.SUCCESS: {
      return state.merge(fromJS(action.payload));
    }

    case actionTypes.FETCH_FIELD_SET_DATA.BEGIN: {
      return state.set('isLoading', true);
    }

    case actionTypes.FETCH_FIELD_SET_DATA.SUCCESS: {
      const {
        mappedHeaders,
        salesforceObjectFieldSet,
        warnings,
        requiredSalesforceFields,
      } = action.payload;
      return state
        .set('mappedHeaders', mappedHeaders)
        .set('salesforceObjectFieldSet', salesforceObjectFieldSet)
        .set('isLoading', false)
        .set('warnings', warnings)
        .mergeIn(['requiredSalesforceFields'], requiredSalesforceFields);
    }

    case actionTypes.OPEN_SFDC_FIELD_MAPPING_DIALOG: {
      return state.set('isLoading', true);
    }

    case actionTypes.CLOSE_SFDC_FIELD_MAPPING_DIALOG: {
      const warnings = findWarnings(
        state.get('mappedHeaders'),
        state.get('csvRows')
      );
      return state.set('isLoading', false).set('warnings', warnings);
    }

    case actionTypes.HANDLE_SOBJ_CHANGE: {
      return state
        .set('selectedSObject', action.payload)
        .set('errors', null)
        .set('recordsFailed', null)
        .set('recordsProcessed', null);
    }

    case actionTypes.INPUT_CHANGE: {
      const { rowIdx, colIdx, value } = action.payload;
      return state.setIn(['csvRows', rowIdx, colIdx], value);
    }

    case actionTypes.REFERENCE_FIELD_SEARCH.SUCCESS: {
      const { data } = action.payload;
      return state.set('searchResults', fromJS(data));
    }

    case actionTypes.CLEAR_SEARCH_RESULTS: {
      return state.set('searchResults', null);
    }

    case actionTypes.MAP_FIELD: {
      const { fieldKey, sFieldName, csvHeader } = action.payload;
      const sObjField = state.getIn(['salesforceObjectFieldSet', sFieldName]);
      const idx = state.get('csvHeaders').indexOf(csvHeader);

      const data = {
        isMapped: 'manual',
        picklist: sObjField.get('picklist') ? sObjField.get('picklist') : null,
        csvHeader,
        label: sObjField.get('label') ? sObjField.get('label') : null,
        name: sObjField.get('name') ? sObjField.get('name') : null,
        type: sObjField.get('type') ? sObjField.get('type') : null,
        length: sObjField.get('length') || sObjField.get('maxLength') || null,
        required:
          !sObjField.get('nillable') ||
          sObjField.get('name').toLowerCase() === 'email',
        idx,
        referenceTo: sObjField.get('referenceTo')
          ? sObjField.get('referenceTo')
          : null,
      };
      const updatedRows = validateCsvRows(state.get('csvRows'), idx, data);
      return state
        .mergeIn(['mappedHeaders', fieldKey], data)
        .setIn(['csvRows'], updatedRows);
    }

    case actionTypes.UNMAP_FIELD: {
      const { fieldKey } = action.payload;
      const mappedField = state.getIn(['mappedHeaders', fieldKey]);
      let data = {};
      if (mappedField.get('required')) {
        data = {
          isMapped: null,
          csvHeader: null,
        };
      } else {
        data = {
          isMapped: null,
          picklist: null,
          csvHeader: null,
          label: null,
          name: null,
          type: null,
          length: null,
          required: null,
          referenceTo: null,
        };
      }
      return state.mergeIn(['mappedHeaders', fieldKey], data);
    }

    case actionTypes.CLOSE_DIALOG: {
      return initialState;
    }

    case actionTypes.RECOMMENDED_ACCOUNT_SEARCH.SUCCESS: {
      const { data } = action.payload;
      return state.set('recommendedAccounts', fromJS(data));
    }

    case actionTypes.BULK_CREATE.SUCCESS: {
      const { batchResponse, recordsFailed, recordsProcessed } = action.payload;
      const selectedRecords = state.get('selectedRecords');
      let csvRows = state.get('csvRows');
      const nonSelectedRecords = csvRows.filter(
        (_, index) => !selectedRecords.includes(index)
      );
      csvRows = selectedRecords.map(records => csvRows.get(records));
      const updatedCsvRows = batchResponse
        .map((response, idx) => {
          return response.get('success') === 'true' ? null : csvRows.get(idx);
        })
        .filter(row => row !== null);

      const newCsvRows = fromJS([
        ...updatedCsvRows.toJS(),
        ...nonSelectedRecords.toJS(),
      ]);
      const warnings = findWarnings(state.get('mappedHeaders'), newCsvRows);
      return state
        .set('errors', batchResponse)
        .set('recordsFailed', recordsFailed)
        .set('recordsProcessed', recordsProcessed)
        .set('csvRows', fromJS(newCsvRows))
        .set('warnings', warnings)
        .set('selectedRecords', List());
    }

    case actionTypes.BULK_CREATION_STATUS: {
      const status = action.payload;
      return state.set('bulkCreationStatus', status);
    }

    case actionTypes.ADD_TO_FLOW_IN_PROGRESS: {
      const status = action.payload;
      return state.set('addToFlowInProgress', status);
    }

    case actionTypes.ADD_TO_FLOW_STATUS.BEGIN: {
      return state
        .set('addToFlowStatus', 'inProgress')
        .set('addToFlowError', '');
    }
    case actionTypes.ADD_TO_FLOW_STATUS.SUCCESS: {
      return state
        .set('addToFlowStatus', 'successful')
        .set('addToFlowError', '');
    }
    case actionTypes.ADD_TO_FLOW_STATUS.FAILURE: {
      return state
        .set('addToFlowStatus', 'failed')
        .set('addToFlowError', action.payload);
    }

    case actionTypes.IS_LOADING: {
      const status = action.payload;
      return state.set('isLoading', status);
    }

    case actionTypes.SELECT_ALL: {
      const warnings = state.get('warnings');
      const csvRows = state.get('csvRows');
      const warningsRows =
        warnings !== null
          ? warnings
              .filter(warning => warning.valueSeq().toArray().includes(true))
              .keySeq()
              .toArray()
          : [];
      const updatedSelectedRows = _.difference(
        csvRows.keySeq().toArray(),
        warningsRows
      );
      return state.set('selectedRecords', fromJS(updatedSelectedRows));
    }

    case actionTypes.UNSELECT_ALL: {
      return state.set('selectedRecords', List());
    }

    case actionTypes.UNSELECT_ALL_WITH_WARNINGS: {
      const selectedRecords = action.payload;
      return state.set('selectedRecords', fromJS(selectedRecords));
    }

    case actionTypes.SELECT_ALL_WITHOUT_WARNINGS: {
      const selectedRecords = action.payload;
      return state.set('selectedRecords', fromJS(selectedRecords));
    }

    case actionTypes.SELECT_RECORD: {
      const selectedRecord = action.payload;
      return state.set('selectedRecords', fromJS(selectedRecord));
    }

    case actionTypes.UNSELECT_RECORD: {
      const selectedRecord = action.payload;
      return state.set('selectedRecords', fromJS(selectedRecord));
    }

    case actionTypes.FLOW_SETTINGS.SUCCESS: {
      const { autoCreatedRecordLimit, autoCreatedRecordCount } = action.payload;

      return state
        .set('autoCreationLimit', autoCreatedRecordLimit)
        .set('autoCreationCount', autoCreatedRecordCount);
    }

    case actionTypes.RESET_WARNING: {
      const { rowIdx, colIdx } = action.payload;
      return state.setIn(['warnings', rowIdx, colIdx], false);
    }

    default: {
      return state;
    }
  }
}
