/* eslint-disable @typescript-eslint/no-explicit-any */
// This is a file for all the util functions related to flows
import { Map } from 'immutable';
import { camelCase } from 'lodash-es';

import {
  UNKNOWN_TAG_MESSAGE,
  UNKNOWN_PICKLIST_OPTION_MESSAGE,
} from './constants';

// TODO: Turn flow and currentUser into properly typed Maps/Collections
export type StepGroupParameters = {
  flow: Map<string, any>;
  currentUser: Map<string, any>;
};

export type StepGroupReturn = undefined | true;

type AddingCurrentTaskFieldToCustomTagParam = {
  currentTaskField: string;
  customTags: Map<string, any>;
};

export type KnownSalesforceTaskFieldOptionsHelperParam = {
  currentTaskField: string;
  fieldName: string;
  fieldValue: string;
  getAllCurrentOptions: boolean;
  immutableMeta: Map<string, any>;
};

export type UnknownSalesforceTaskFieldOptionsHelperParam = {
  fieldName: string;
  fieldValue: string;
};

export type SalesforceTaskFieldOptionsGeneratorParam = {
  customTags: Map<string, any>;
  taskFieldsMeta: Map<string, any>;
  currentTaskField: string;
  getAllCurrentOptions?: boolean;
};

type PicklistOptions = {
  value: string;
  label: string;
};

export type TaskFieldDetails = {
  picklist: PicklistOptions[];
  name?: string;
  label?: string;
  type?: string;
  restrictedpicklist?: boolean;
};

// Return undefined (instead of false) to signal to the component
// that it can decide if the step should be disabled or not.
export const stepGroupIsDisabled = ({
  flow = Map(),
  currentUser = Map(),
}: StepGroupParameters): StepGroupReturn => {
  if (flow.get('isMasterFlow')) {
    return undefined;
  }

  if (!flow.get('isMasterFlow')) {
    if (Number(flow.get('userId')) === Number(currentUser.get('id'))) {
      return undefined;
    }
    return true;
  }

  return undefined;
};

const addingCurrentTaskFieldToCustomTag = ({
  currentTaskField,
  customTags,
}: AddingCurrentTaskFieldToCustomTagParam) => {
  if (!customTags.has(currentTaskField)) {
    return customTags.set(
      currentTaskField,
      Map({ field_name: currentTaskField })
    );
  }

  return customTags;
};

export const knownSalesforceTaskFieldOptionsHelper = ({
  currentTaskField,
  fieldName,
  fieldValue,
  getAllCurrentOptions,
  immutableMeta,
}: KnownSalesforceTaskFieldOptionsHelperParam): TaskFieldDetails => {
  const jsMeta = immutableMeta.toJS() as TaskFieldDetails;
  // Only time we do not want to filter out the picklist options is when
  // `getAllCurrentOptions` to true and it is currentTaskField
  if (!getAllCurrentOptions || currentTaskField !== fieldName) {
    const picklistOptions: { [key: string]: PicklistOptions } = {};
    const selectedPicklistOptions: PicklistOptions[] = [];

    if (fieldValue) {
      jsMeta.picklist.forEach((option: PicklistOptions) => {
        picklistOptions[option.value] = option;
      });

      fieldValue.split(';').forEach((value: string) => {
        if (picklistOptions[value]) {
          selectedPicklistOptions.push(picklistOptions[value]);
        } else {
          selectedPicklistOptions.push({
            value,
            label: `${UNKNOWN_PICKLIST_OPTION_MESSAGE} ${jsMeta.label}`,
          });
        }
      });
    }
    jsMeta.picklist = selectedPicklistOptions;
  }
  return jsMeta;
};

export const unknownSalesforceTaskFieldOptionsHelper = ({
  fieldName,
  fieldValue,
}: UnknownSalesforceTaskFieldOptionsHelperParam): TaskFieldDetails => {
  const unknownTaskField: TaskFieldDetails = {
    name: fieldName,
    picklist: [],
  };

  if (fieldValue) {
    fieldValue.split(';').forEach((value: string) =>
      unknownTaskField.picklist.push({
        label: UNKNOWN_TAG_MESSAGE,
        value,
      })
    );
  }

  return unknownTaskField;
};

export const salesforceTaskFieldOptionsGenerator = ({
  customTags,
  taskFieldsMeta,
  currentTaskField,
  getAllCurrentOptions,
}: SalesforceTaskFieldOptionsGeneratorParam): TaskFieldDetails[] => {
  const oldTaskFieldResult: TaskFieldDetails[] = [];
  const currentTaskFieldResult: TaskFieldDetails[] = [];

  const correctedCustomTags = addingCurrentTaskFieldToCustomTag({
    currentTaskField,
    customTags,
  });

  correctedCustomTags.map(taskField => {
    const fieldName = taskField.get('field_name', '') || ''; // for some reason this becomes undefined
    const fieldValue = taskField.get('field_value', '') || '';

    // Because GE lowercases every key and then FEBES Camelcases every key
    // Getting the individual Taskfield meta data from SFDC Task Meta Data
    const immutableMeta = taskFieldsMeta.get(
      camelCase(fieldName.toLowerCase())
    );

    let jsMeta: TaskFieldDetails;

    if (!immutableMeta) {
      jsMeta = unknownSalesforceTaskFieldOptionsHelper({
        fieldName,
        fieldValue,
      });
    } else {
      jsMeta = knownSalesforceTaskFieldOptionsHelper({
        currentTaskField,
        fieldName,
        fieldValue,
        getAllCurrentOptions,
        immutableMeta,
      });
    }

    // Don't bother to store jsMeta if there is nothing in the picklist
    if (jsMeta.picklist.length < 1) return true;

    if (currentTaskField.toLowerCase() === fieldName.toLowerCase()) {
      currentTaskFieldResult.push(jsMeta);
    } else {
      oldTaskFieldResult.push(jsMeta);
    }

    return true;
  });
  // Need to make sure that the old custom tags show up before the new ones since you can only
  // remove and not add
  return [...oldTaskFieldResult, ...currentTaskFieldResult];
};
