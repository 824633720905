import { fromJS, OrderedMap } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/labels';

const initialState = fromJS({
  customLabels: new OrderedMap(),
});

export default function labels(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_LABELS: {
      const { labels } = action.payload;
      return state.set('customLabels', labels);
    }

    case actionTypes.ADD_LABEL: {
      const { label } = action.payload;
      return state.updateIn(['customLabels'], customLabels =>
        customLabels.set(label.get('id'), label)
      );
    }

    case actionTypes.UPDATE_LABEL: {
      const { label } = action.payload;
      return state.setIn(['customLabels', label.get('id')], label);
    }

    default: {
      return state;
    }
  }
}
