import {
  createActions,
  standardActions,
  keyMirror,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  APP: {
    ...keyMirror([
      'READY',
      'TOP_NAV_TAB_CLICKED',
      'SEARCH_INPUT_FOCUSED',
      'SEARCH_QUERY_UPDATED',
      'SEARCH_SUBMITTED',
      'SEARCH_FILTER_UPDATED',
      'SET_SALESFORCE_META',
      'SET_TOP_NAV_TAB_VISIBILITY',
      'SEARCH_ITEM_SELECT',
      'LOAD_REMOVE_FROM_FLOW_CONFIRM_DIALOG_DATA',
      'LOAD_REMOVE_FROM_ALL_FLOWS_CONFIRM_DIALOG_DATA',
      'TYPEAHEAD_SEARCH_CLEAR_SEARCH_RESULTS',
      'SWITCH_TO_CLASSIC_UI',
      'UPDATE_RELEASE_VERSION',
      'SET_RETRY_COUNT',
      'UPDATE_LOCAL_SEARCH_RESULTS',
      'FORCE_RELOAD_ON_MODULE_CHANGE',
    ]),
    UPSTART: standardActions(),
    TYPEAHEAD_SEARCH: standardActions(),
    REMOVE_PERSON_FROM_FLOW: standardActions(),
    REMOVE_PERSON_FROM_ALL_FLOWS: standardActions(),
    LOGIN_AS_USER: standardActions(),
    LOGIN_AS_SEARCH_USERS: standardActions(),
  },
}).APP;

export function triggerAppUpstart() {
  return {
    type: actionTypes.UPSTART.BEGIN,
  };
}

export function topNavTabClicked(namespace, tabValue) {
  return {
    type: actionTypes.TOP_NAV_TAB_CLICKED,
    payload: { namespace, tabValue },
  };
}

// This is used only for Mixpanel tracking
// See Modules/Shared/sagas/analytics.js
export function searchInputFocused() {
  return {
    type: actionTypes.SEARCH_INPUT_FOCUSED,
  };
}

export function searchQueryUpdated(query) {
  return {
    type: actionTypes.SEARCH_QUERY_UPDATED,
    payload: query,
  };
}

export function searchSubmitted() {
  return {
    type: actionTypes.SEARCH_SUBMITTED,
  };
}

export function searchFilterUpdated(searchFilter) {
  return {
    type: actionTypes.SEARCH_FILTER_UPDATED,
    payload: searchFilter,
  };
}

export function clearSearchResults() {
  return {
    type: actionTypes.TYPEAHEAD_SEARCH_CLEAR_SEARCH_RESULTS,
  };
}

export function ready() {
  return {
    type: actionTypes.READY,
  };
}

export function setTopNavTabVisibility(visible) {
  return {
    type: actionTypes.SET_TOP_NAV_TAB_VISIBILITY,
    payload: visible,
  };
}

export function searchSelect(searchItem) {
  return {
    type: actionTypes.SEARCH_ITEM_SELECT,
    payload: searchItem,
  };
}

export function loadRemoveFromFlowData({ person, flow }) {
  return {
    type: actionTypes.LOAD_REMOVE_FROM_FLOW_CONFIRM_DIALOG_DATA,
    payload: { person, flow },
  };
}

export function loadRemoveFromAllFlowsData({ person }) {
  return {
    type: actionTypes.LOAD_REMOVE_FROM_ALL_FLOWS_CONFIRM_DIALOG_DATA,
    payload: { person },
  };
}

export function confirmRemovePersonFromAllFlows(person) {
  return {
    type: actionTypes.REMOVE_PERSON_FROM_ALL_FLOWS.BEGIN,
    payload: person,
  };
}

export function confirmRemovePersonFromFlow({ personId, flowId }) {
  return {
    type: actionTypes.REMOVE_PERSON_FROM_FLOW.BEGIN,
    payload: { personId, flowId },
  };
}

export function updateReleaseVersion({ version }) {
  return {
    type: actionTypes.UPDATE_RELEASE_VERSION,
    payload: { version },
  };
}

export function forceReloadOnModuleChange() {
  return {
    type: actionTypes.FORCE_RELOAD_ON_MODULE_CHANGE,
  };
}

export function setRetryCount({ retryCount }) {
  return {
    type: actionTypes.SET_RETRY_COUNT,
    payload: {
      retryCount,
    },
  };
}

export function loginAsSearchUsersBegin(payload) {
  return {
    type: actionTypes.LOGIN_AS_SEARCH_USERS.BEGIN,
    payload,
  };
}

export function loginAsSearchUsersSuccess(payload) {
  return {
    type: actionTypes.LOGIN_AS_SEARCH_USERS.SUCCESS,
    payload,
  };
}

export function loginAsSearchUsersFailure(payload) {
  return {
    type: actionTypes.LOGIN_AS_SEARCH_USERS.FAILURE,
    payload,
  };
}

export function loginAsUserBegin(payload) {
  return {
    type: actionTypes.LOGIN_AS_USER.BEGIN,
    payload,
  };
}

export function loginAsUserSuccess(payload) {
  return {
    type: actionTypes.LOGIN_AS_USER.SUCCESS,
    payload,
  };
}

export function loginAsUserFailure({ errorMessage }) {
  return {
    type: actionTypes.LOGIN_AS_USER.FAILURE,
    payload: {
      errorMessage,
    },
  };
}
