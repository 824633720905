import { keyMirror } from '@groove-labs/action-utils';
import * as HttpStatusCodes from 'http-status-codes';
import moment from 'moment-timezone';

import { guessTimeZone } from 'Modules/WorkStep/utils';
import { TYPES } from 'Modules/Actions/constants';

import { ActionType, LocationSource } from './actionComposeTypes';

export const ACTION_PREFIX = 'ACTION_COMPOSE';

export const VISIBILITY_STATES = keyMirror([
  'MAXIMIZED',
  'MINIMIZED',
  'CLOSED',
]);

export const DESTRUCTION_STATES = {
  SKIP: 'skip',
  REMOVE_FROM_FLOW: 'removeFromFlow',
  DELETE: 'delete',
};

export const MERGE_FIELD_KEYS = {
  subject: 'hasUnmergedSubject',
  body: 'hasUnmergedBody',
};

// UI CONSTANTS
export const UI_KEY = 'actionCompose';

export const IS_PREVIEW_ACTIVE_UI_KEY_PATH = [UI_KEY, 'isPreviewModeActive'];

export const DISPLAY_GROOVE_CONNECTION_ERROR_UI_KEY_PATH = [
  'errors',
  'displayGrooveConnectionError',
];

// POST MESSAGE CONSTANTS
export const INCOMING_POST_MESSAGE_TARGET = 'actionCompose';

export const POST_MESSAGE_GMAIL_SOURCE = 'Gmail';

export const POST_MESSAGE_FEBES_SOURCE = 'febes';

export const POST_MESSAGE_OUTLOOK_SOURCE = 'outlook';

export const POST_MESSAGE_SALEFORCE_SOURCE = 'salesforce';

export const POST_MESSAGE_ZOOMINFO_SOURCE = 'zoominfo';

export const ACTION_SOURCE: { [key: string]: LocationSource } = {
  [POST_MESSAGE_GMAIL_SOURCE]: 'gmail',
  [POST_MESSAGE_FEBES_SOURCE]: 'febes',
  [POST_MESSAGE_SALEFORCE_SOURCE]: 'salesforce',
  [POST_MESSAGE_ZOOMINFO_SOURCE]: 'zoominfo',
};

export const IFRAME_NAME = 'actionCompose';

export const MORE_OPTIONS_TYPES = {
  LOG_AND_REMOVE: 'LOG_AND_REMOVE',
  MARK_AS_COMPLETE_DONT_LOG: 'MARK_AS_COMPLETE_DONT_LOG',
  COMPLETE_AND_TRY_AGAIN: 'COMPLETE_AND_TRY_AGAIN',
  COMPLETE_AND_REMOVE_FROM_FLOW: 'COMPLETE_AND_REMOVE_FROM_FLOW',
  COMPLETE_AND_PAUSE_NEXT_STEP: 'COMPLETE_AND_PAUSE_NEXT_STEP',
};

export const RESPONSE_CODES_TO_CATCH = [
  HttpStatusCodes.NOT_FOUND,
  HttpStatusCodes.INTERNAL_SERVER_ERROR,
];

export const LOGIN_WINDOW_NAME = 'compose-pane-login-window';

export const VIEW_MODES = {
  STANDARD: 'standard',
  TEMPLATES: 'templates',
};

type Types = typeof TYPES;

export const TYPED_TYPES = TYPES as { [key in keyof Types]: ActionType };
export const DEFAULT_SCHEDULED_VALUES = {
  dateTime: moment().add(1, 'hour').format(),
  date: moment().startOf('day').format('YYYY-MM-DD'),
  time: moment().add(1, 'hour').format('h:mm a'),
  timeZone: guessTimeZone(),
};

export const BLANK_PLACEHOLDER = '%%';
