import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar as MuiSnackbar, SnackbarContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { requestSnackbarClose } from 'Modules/Shared/actions/app';
import { GROOVEY_BLUEY } from 'Modules/Shared/constants/colors';
import { isSnackbarOpen, getSnackbarQueue } from 'Modules/App/selectors';
import { List } from 'immutable';

const styles = {
  actionButtonStyle: {
    color: GROOVEY_BLUEY,
    textTransform: 'capitalize',
  },
  snackbarContent: {
    whiteSpace: 'pre',
  },
};

@withStyles(styles)
@connect(
  state => {
    // Arguments: state, ownProps
    return {
      isOpen: isSnackbarOpen(state),
      queue: getSnackbarQueue(state),
    };
  },
  {
    requestSnackbarClose,
  }
)
export default class Snackbar extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    requestSnackbarClose: PropTypes.func.isRequired,
    queue: PropTypes.instanceOf(List).isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  handleRequestClose = (_e, reason) => {
    if (!['timeout', 'close'].includes(reason)) {
      return;
    }

    const { requestSnackbarClose } = this.props;

    requestSnackbarClose();
  };

  handleSnackBarLink = link => {
    window.open(link, '_blank');
  };

  render() {
    const { queue, isOpen, classes } = this.props;

    const nextMessage = queue.first();

    const messageText = nextMessage ? nextMessage.get('message') : '';
    const messageDuration = nextMessage ? nextMessage.get('duration') : 3000;
    const linkText = nextMessage ? nextMessage.get('linkText') : '';
    const link = nextMessage ? nextMessage.get('link') : '';

    const actions = [
      <IconButton
        key="close"
        aria-label="Close"
        className={classes.actionButtonStyle}
        onClick={() => this.handleRequestClose({}, 'close')}
      >
        <CloseIcon />
      </IconButton>,
    ];

    if (link) {
      actions.unshift(
        <Button
          key="link"
          aria-label="View activity"
          className={classes.actionButtonStyle}
          onClick={() => this.handleSnackBarLink(link)}
        >
          {linkText}
        </Button>
      );
    }

    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={messageDuration}
        onClose={this.handleRequestClose}
      >
        <SnackbarContent
          message={messageText}
          action={actions}
          classes={{
            message: classes.snackbarContent,
          }}
        />
      </MuiSnackbar>
    );
  }
}
