import keyMirror from 'Utils/key-mirror';

export const actionTypes = keyMirror(
  {
    FETCH_TEMPLATE_FOLDER_STRUCTURE: null,
    FETCH_TEMPLATE_HTML_DATA: null,
    FETCH_TEMPLATE_HTML_DATA_FOR_UI_STATE: null,
    FETCH_TEMPLATE_FOLDER_STRUCTURE_WITHOUT_HTML_BODY: null,
    FETCH_INITIAL_DATA: null,
  },
  'SHARED/TEMPLATES'
);

export function fetchTemplateFolderStructure() {
  return {
    type: actionTypes.FETCH_TEMPLATE_FOLDER_STRUCTURE,
  };
}

export function fetchTemplateFolderStructureWithoutHtmlData() {
  return {
    type: actionTypes.FETCH_TEMPLATE_FOLDER_STRUCTURE_WITHOUT_HTML_BODY,
  };
}

export function fetchTemplateHtmlData(payload) {
  return {
    type: actionTypes.FETCH_TEMPLATE_HTML_DATA,
    payload,
  };
}

export function fetchTemplateHtmlDataForUiState(payload) {
  return {
    type: actionTypes.FETCH_TEMPLATE_HTML_DATA_FOR_UI_STATE,
    payload,
  };
}

export function fetchInitialTemplateData(payload) {
  return {
    type: actionTypes.FETCH_INITIAL_DATA,
    payload,
  };
}
