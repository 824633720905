import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { LABEL_CATEGORIES } from 'Modules/Shared/constants/labels';

export const getLabels = state => state.getIn(['labels', 'customLabels']);

export const getCheckedLabelIds = (state, uiKeyPath) =>
  state.getIn(['ui', ...uiKeyPath], new Set());

export const makeGetLabelsByCategory = category =>
  createSelector(getLabels, labels =>
    labels.filter(labelData => labelData.get('category') === category)
  );

export const getCustomLabels = state =>
  getLabels(state).filter(
    labelData => labelData.get('category') === LABEL_CATEGORIES.ORG_CUSTOM
  );
