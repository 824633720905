import ky, { Options } from 'ky';

import getClient from '../../getClient';

// eslint-disable-next-line import/no-mutable-exports
export let options: Options | null | undefined;

export const configureClient = (clientOptions: Options | null): void => {
  options = clientOptions;
};

export default (): typeof ky => {
  if (options && !options.prefixUrl) {
    throw new Error(
      'The services client requires a prefix URL to be specified.',
    );
  }
  if (options === null) {
    throw new Error(
      'The services client has been set to null, most likely because the user is not logged in.',
    );
  }
  if (options === undefined) {
    throw new Error(
      'The services client is not configured. Please call `configureClient` before using it.',
    );
  }
  return getClient().extend(options);
};
