import { compact, keys, isArray, intersection, uniqBy, union } from 'lodash-es';
import { OrderedSet } from 'immutable';
import { CUSTOM_MERGE_FIELD_PREFIX } from 'Modules/PeopleImportDialog/constants';

const SFDC_ID_REGEXP =
  /\b003[A-Za-z0-9]{12}\b|\b003[A-Za-z0-9]{15}\b|\b00Q[A-Za-z0-9]{12}\b|\b00Q[A-Za-z0-9]{15}\b/i;
export const emailRegexp =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;

export function isEmailColumnSelected(firstRow, lookupByColumnIdx) {
  if (!firstRow) return false;

  return (
    !!firstRow.get(lookupByColumnIdx) &&
    !!firstRow.get(lookupByColumnIdx).trim().match(emailRegexp)
  );
}

export function isValidSfdcId(sfdcId) {
  return !!sfdcId && SFDC_ID_REGEXP.test(sfdcId);
}

export function isSfdcIdColumnSelected(firstRow, lookupByColumnIdx) {
  if (!firstRow) return false;
  // true when the first row cell value length is exactely 18 and contains only numbers or letters
  return (
    !!firstRow.get(lookupByColumnIdx) &&
    isValidSfdcId(firstRow.get(lookupByColumnIdx).trim())
  );
}

export function isColumnValid(firstRow, lookupColumnOptions) {
  return lookupColumnOptions.filter(
    (value, idx) =>
      firstRow &&
      firstRow.get(idx) &&
      (isSfdcIdColumnSelected(firstRow, idx) ||
        isEmailColumnSelected(firstRow, idx))
  );
}

export function guessImportByColumnIdx(firstRow, csvHeader) {
  let i = 0;
  while (i < csvHeader.size) {
    if (firstRow.get(i)) {
      if (
        isSfdcIdColumnSelected(firstRow, i) ||
        isEmailColumnSelected(firstRow, i)
      ) {
        return i;
      }
    }
    i += 1;
  }
  return undefined;
}

export function initializeSelectedObject(
  lookupByColumnIdx,
  csvHeader,
  firstRow,
  keyPrefixes
) {
  // we preset SF Object Type when the selected column is SFDC ID
  if (isSfdcIdColumnSelected(firstRow, lookupByColumnIdx)) {
    const firstRowValue = firstRow.get(lookupByColumnIdx).trim();

    let detectedObjectName;

    keyPrefixes.forEach((prefix, objectName) => {
      const regex = new RegExp(`^${prefix}.*`, 'i');
      if (prefix && firstRowValue.match(regex)) {
        detectedObjectName = objectName;
      }
    });

    return detectedObjectName;
  }
  return null;
}

export function buildSearchSoqlQuery(
  selectedObject,
  selectedObjectField,
  fields,
  values
) {
  const uniqueFields = OrderedSet(fields);
  let soqlQuery = `SELECT ${uniqueFields.join(
    ', '
  )} FROM ${selectedObject} WHERE `;

  if (selectedObject.toLowerCase() === 'lead') {
    soqlQuery += '(isConverted = false) AND ';
  }

  soqlQuery += selectedObjectField;

  // remove blank values
  let fieldValues = compact(values.toJS());

  if (selectedObjectField === 'Email') {
    // Field cleanup
    fieldValues = fieldValues.map(email =>
      email.replace("'", '').replace('"', '').toLowerCase()
    );
  }

  let inClauseValue = fieldValues.join("', '");

  // when all values are blank to avoid soql like
  // SELECT Name, Email, Account.Name, Id FROM Contact WHERE Email IN ('')
  if (!inClauseValue) {
    // set the not existing value, so then the returned soql gives an empty restforce response
    inClauseValue = 'VALUE_NOT_EXIST_EXPECT_EMPTY_RESPONSE';
  }

  soqlQuery += ` IN ('${inClauseValue}') `;

  return soqlQuery;
}

/**
 * Take in a list of people emails and concatenates them
 * @param {List} values
 * @returns {String} A concatenated string of people emails
 */
export const buildPeopleSearchParams = values =>
  compact(values.toJS()).reduce(
    (valueString, value) => `${valueString},${value}`
  );

/**
 * Combine responses from sfdc query to a single response.
 * Takes any list (other than column data) and concats them
 *
 * @param {Array} responses
 *
 * @return {Object}
 */
export function combineResponses(responses) {
  return responses.reduce((combinedData, response) => {
    const data = response.data;
    keys(data).forEach(dataKey => {
      if (
        isArray(data[dataKey]) &&
        combinedData[dataKey] &&
        dataKey !== 'columnData'
      ) {
        combinedData[dataKey] = combinedData[dataKey].concat(data[dataKey]);
      } else {
        combinedData[dataKey] = data[dataKey];
      }
    });
    return combinedData;
  }, {});
}

export function combineContactAndLeadResponses(contactResults, leadResults) {
  const combinedMissingPeopleSearchValues = intersection(
    contactResults.missingPeopleSearchValues,
    leadResults.missingPeopleSearchValues
  );
  const combinedPeopleResults = uniqBy(
    [...contactResults.peopleResults, ...leadResults.peopleResults],
    person => person.email
  );

  return {
    ...leadResults,
    ...contactResults,
    results: combinedPeopleResults,
    peopleResults: combinedPeopleResults,
    intersectionIds: union(
      contactResults.intersectionIds,
      leadResults.intersectionIds
    ),
    intersectionEmails: union(
      contactResults.intersectionEmails,
      leadResults.intersectionEmails
    ),
    missingPeopleSearchValues: combinedMissingPeopleSearchValues,
  };
}

export function addCustomField(
  data,
  lookupField,
  lowerCasingKeys,
  customMergeValues,
  customFields
) {
  if (!data) {
    return;
  }

  const lookupValue = data[lookupField];

  if (!lookupValue) {
    return;
  }

  const randomCasingKey = lowerCasingKeys[lookupValue.toLowerCase()];

  if (!randomCasingKey) {
    return;
  }

  const fieldValues = customMergeValues[randomCasingKey];

  if (!fieldValues) {
    return;
  }

  customFields.forEach(field => {
    data[`${CUSTOM_MERGE_FIELD_PREFIX}${field}`] = fieldValues[field];
  });
}
