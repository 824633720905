export const ROW_HEIGHT = 56;
export const CELL_PADDING = 16;
export const TYPE_CELL_WIDTH = 104;

const cell = {
  padding: `0px ${CELL_PADDING}px`,
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  fontSize: 13,
  minWidth: 100,
};

const stickyCell = {
  ...cell,
  position: 'sticky',
  zIndex: 1,
  fontWeight: 500,
};

export default {
  cell,
  stickyCell,
  tableRoot: {
    backgroundColor: 'white',
    borderRadius: 2,
    flex: 1,
  },
  stickyColumnCell: {
    ...stickyCell,
    width: TYPE_CELL_WIDTH,
    minWidth: TYPE_CELL_WIDTH,
    maxWidth: TYPE_CELL_WIDTH,
    left: 50,
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    height: ROW_HEIGHT,
  },
};
