import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as shortId from 'shortid';

import LoaderRow from 'Modules/Shared/components/WorkStepRouteLoader/LoaderRow';
import sharedStyles from 'Modules/Actions/containers/Table/styles';
import pulse from 'Modules/Shared/components/WorkStepRouteLoader/pulse';

const useStyles = makeStyles({
  root: {
    padding: 24,
  },
  title: {
    ...pulse,
    height: 20,
    width: 250,
  },
  subtitle: {
    ...pulse,
    height: 20,
    width: 50,
  },
  headerSection: {
    minHeight: 65,
    maxHeight: 65,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 8,
  },
  filtersRow: {
    marginTop: 8,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: 45,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
  },
  rowHeader: {
    ...pulse,
    width: 50,
    height: 14,
  },
  headerRow: {
    height: 56,
    borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: 'rgb(202,199,199)',
    },
    '50%': {
      backgroundColor: 'rgb(235, 235, 235)',
    },
    '100%': {
      backgroundColor: 'rgb(202,199,199)',
    },
  },
  table: {
    ...sharedStyles.tableRoot,
    overflow: 'hidden',
    scroll: 'none',
    borderRadius: 2,
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.12), 0 0 2px 0 rgba(0,0,0,0.24)',
  },
});

const headerRows = [...Array(8)].fill(null).map(shortId.generate);
const rows = [...Array(15)].fill(null).map(shortId.generate);

const WorkStepRouteLoader: React.FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>
        <div className={classes.title} />
      </div>
      <div className={classes.subtitle} />
      <div className={classes.filtersRow}>
        {headerRows.map(value => (
          <div className={classes.rowHeader} key={value} />
        ))}
      </div>
      <div className={classes.table}>
        {rows.map(value => (
          <LoaderRow key={value} />
        ))}
      </div>
    </div>
  );
};

WorkStepRouteLoader.displayName = 'WorkStepRouteLoader';

export default WorkStepRouteLoader;
