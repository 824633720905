import { Map, List, isImmutable, fromJS } from 'immutable';
import { findKey, isArray, isUndefined } from 'lodash-es';
import { STEP_SUBTYPES } from 'Modules/Shared/constants';
import {
  FOLDER_ORDER_BY_OPTIONS,
  TEMPLATE_ORDER_BY_OPTIONS,
} from 'Modules/Templates/constants';
import { ASC } from 'Modules/Shared/components/SortDirectionButton';
import listToOrderedMap from 'Utils/list-to-ordered-map';
import LabelInstance from 'Modules/Shared/records/LabelInstance';

/**
 * Check if the current folder belongs to the tab.
 * Used to filter the list of folders displayed.
 *
 * @param {string} tab
 * @param {number} folderUserId
 * @param {number} currentUserId
 *
 * @return {boolean}
 */
export const folderBelongsToTab = (tab, folderUserId, currentUserId) => {
  if (
    tab === 'all' ||
    (tab === 'mine' && folderUserId === currentUserId) ||
    (tab === 'shared' && folderUserId !== currentUserId)
  ) {
    return true;
  }

  return false;
};

/**
 * @param {string} context
 *
 * @return {Array}
 */
export const getSortingOptions = context => {
  return context === 'template'
    ? TEMPLATE_ORDER_BY_OPTIONS
    : FOLDER_ORDER_BY_OPTIONS;
};

/**
 * Check if an orderByMap value is within the available options for it's context.
 *
 * @param {Immutable.Map} orderByMap
 * @param {string} context
 *
 * @return {boolean}
 */
export const orderByMapIsValid = (orderByMap, context) => {
  const orderByValue = orderByMap.get('value');
  const options = getSortingOptions(context);

  return options.some(option => {
    return option.value === orderByValue;
  });
};

/**
 * @param {string} context
 *
 * @return {Immutable.Map}
 */
export const getDefaultOrderByForContext = context => {
  const options = getSortingOptions(context);

  return new Map({
    direction: ASC,
    value: options[0].value,
  });
};

/**
 * Filter the provided list by using the passed in query string.
 * The attributeSelector function is run on each value of the target list to obtain
 * the target attributes being filtered.
 * The attribute selector should either return a string or an array (or List) of
 * strings for the query string to be matched against.
 *
 * @param {Immutable.List} targetList
 * @param {string} queryString
 * @param {func} attributeSelector
 *
 * @return {Immutable.List}
 */
export const filterListByString = (
  targetList,
  queryString,
  attributeSelector
) => {
  return targetList.filter(item => {
    const targetAttribute = attributeSelector(item);
    const queryStrLower = queryString.toLowerCase();
    let matched = false;
    if (isArray(targetAttribute) || List.isList(targetAttribute)) {
      matched = targetAttribute.some(entry => {
        return entry.toLowerCase().indexOf(queryStrLower) > -1;
      });
    } else {
      matched = targetAttribute.toLowerCase().indexOf(queryStrLower) > -1;
    }

    return matched;
  });
};

const convertTemplateSubtype = template => {
  const mappedTemplateType = findKey(
    STEP_SUBTYPES,
    value => value === template.templateSubtype
  );
  if (!isUndefined(mappedTemplateType)) {
    template.templateType = mappedTemplateType;
    template.templateSubtype = undefined;
  }

  return template;
};

export const convertImmutableTemplateSubtype = template => {
  const mappedTemplateType = findKey(
    STEP_SUBTYPES,
    value => value === template.get('templateSubtype')
  );
  if (!isUndefined(mappedTemplateType)) {
    return template
      .set('templateType', mappedTemplateType)
      .set('templateSubtype', undefined);
  }

  return template;
};

export const mapTemplatesSubTypes = templates => {
  const conversionFunction = isImmutable(templates)
    ? convertImmutableTemplateSubtype
    : convertTemplateSubtype;

  return templates.map(conversionFunction);
};

/**
 * Remaps template types/subtypes received from the backend.
 * Return a list of template folders and their templates each with type
 * Meant to be used in conjunction with templates/index in GrooveHTTPClient
 *
 * @param {object} data
 *
 * @return {object}
 * */
export const templatesMapSubtypesForFolder = data => {
  return data.map(folder => {
    // Rewrite template types that are actually subtypes of 'OTHER'
    folder.templates = folder.templates.map(template =>
      convertTemplateSubtype(template)
    );

    return folder;
  });
};

export const formatTemplateLabelInstances = template => {
  let unformattedLabelInstances = template.get('labelInstances');

  if (!isImmutable(unformattedLabelInstances)) {
    unformattedLabelInstances = fromJS(unformattedLabelInstances);
  }

  const formattedLabelInstances = unformattedLabelInstances.map(instance =>
    LabelInstance.from({ labelInstance: instance })
  );

  const labelInstances = listToOrderedMap(
    formattedLabelInstances,
    labelInstance => labelInstance.get('id')
  );

  return template.set('labelInstances', labelInstances);
};

// label instances from List -> OrderedMap & templates -> orderedMap
export const formatTemplatesLabelInstances = templates =>
  templates.map(formatTemplateLabelInstances);

export const formatTemplates = templates => {
  const templatesWithFormattedInstances =
    formatTemplatesLabelInstances(templates);
  return listToOrderedMap(templatesWithFormattedInstances, template =>
    template.get('id')
  );
};
