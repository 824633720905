import {
  isArray,
  isEmpty,
  isNumber,
  isString,
  isNull,
  isUndefined,
  isBoolean,
  values,
  toNumber,
  flattenDeep,
} from 'lodash-es';
import { DATE_LITERALS } from 'Modules/PeopleImportDialog/constants';
import moment from 'moment';

import { FIELD_TYPES } from 'Modules/Shared/constants/salesforce';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';

export const SCOPES = {
  MINE: 'mine',
  EVERYTHING: 'everything',
};

export const NULL = 'NULL';

export const OPERATORS = {
  EQUALS: '=',
  NOT_EQUALS: '!=',
  GREATER_THAN: '>',
  GREATER_THAN_OR_EQUAL_TO: '>=',
  LESS_THAN: '<',
  LESS_THAN_OR_EQUAL_TO: '<=',
  LIKE: 'LIKE',
  NOT_LIKE: 'NOT',
  IN: 'IN',
  NOT_IN: 'NOT IN',
  INCLUDES: 'INCLUDES',
  EXCLUDES: 'EXCLUDES',
  EXISTS: 'EXISTS',
  IS: 'IS',
  IS_NOT: 'IS NOT',
  NOTLIKE: 'NOTLIKE', // Legacy operator that is breaking the app
};

export const CONJUNCTIONS = {
  OR: 'OR',
  AND: 'AND',
};

export const DESC = 'DESC';
export const ASC = 'ASC';

export class Condition {
  constructor({ field, operator, value = undefined, type = undefined }) {
    if (
      [
        OPERATORS.IN,
        OPERATORS.NOT_IN,
        OPERATORS.INCLUDES,
        OPERATORS.EXCLUDES,
      ].includes(operator)
    ) {
      if (!Array.isArray(value)) {
        throw new Error('Value must be Array for IN queries');
      }
    }

    const operatorValues = values(OPERATORS);
    if (!operatorValues.includes(operator)) {
      throw new Error(
        `Operator must be one of [${operatorValues.join(
          ', '
        )}. You specified ${operator}]`
      );
    }

    this._field = field;
    this._operator = operator;
    this._value = value;
    this._type = type;
  }

  valueToQueryPart = value => {
    if (
      isNumber(value) ||
      [
        FIELD_TYPES.INT,
        FIELD_TYPES.DOUBLE,
        FIELD_TYPES.CURRENCY,
        FIELD_TYPES.PERCENT,
      ].includes(this._type)
    ) {
      return toNumber(value);
    }

    if (isNull(value)) {
      return NULL;
    }

    if (isBoolean(value)) {
      return value.toString();
    }

    if (isArray(value)) {
      const rangeItems = flattenDeep(this._value).map(this.valueToQueryPart);
      return `(${rangeItems.join(', ')})`;
    }

    // Formatting:
    // YYYY-MM-DD | 1999-01-01
    // https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql_select_dateformats.htm
    if (this._type === FIELD_TYPES.DATE) {
      if (DATE_LITERALS.includes(value)) return value;
      // Parse date so we can normalize it
      const date = moment(value);
      return date.format('YYYY-MM-DD');
    }

    // Formatting:
    // YYYY-MM-DDThh:mm:ss+hh:mm | 1999-01-01T23:01:01+01:00
    // YYYY-MM-DDThh:mm:ss-hh:mm | 1999-01-01T23:01:01-08:00
    // YYYY-MM-DDThh:mm:ssZ      | 1999-01-01T23:01:01Z
    // https://developer.salesforce.com/docs/atlas.en-us.soql_sosl.meta/soql_sosl/sforce_api_calls_soql_select_dateformats.htm
    if (this._type === FIELD_TYPES.DATE_TIME) {
      if (DATE_LITERALS.includes(value)) return value;
      // Parse date so we can normalize it
      // By default, we use 23:59 as the time value in utc.
      return moment.utc(value).format('YYYY-MM-DD[T]23:59:00[Z]');
    }

    if (isString(value)) {
      return `'${value.replace(/'/g, "\\'")}'`;
    }

    return `'${value}'`;
  };

  toString() {
    // SOQL conditions follow the pattern of field-operator-value with the exception of NOT LIKE
    // Which takes the format NOT-field-LIKE-value
    // https://developer.salesforce.com/forums/?id=906F00000008kAQIAY
    let parts = [this._field, this._operator];

    if ([OPERATORS.NOT_LIKE, OPERATORS.NOTLIKE].includes(this._operator)) {
      parts = [OPERATORS.NOT_LIKE, this._field, 'LIKE'];
    }
    try {
      const orOperatorParts = [];
      const splitValues = this._value && this._value.split(',');
      if (splitValues && splitValues.length > 1) {
        splitValues.forEach(value => {
          const cleanedValue = value.trim();
          parts.push(this.valueToQueryPart(cleanedValue));
          orOperatorParts.push(`(${parts.join(' ')})`);
          parts.pop();
        });
        return `(${orOperatorParts.join(' OR ')})`;
      }
    } catch (e) {
      logErrorToSentry(e);
    }

    if (!isUndefined(this._value)) {
      parts.push(this.valueToQueryPart(this._value));
    }

    return `(${parts.join(' ')})`;
  }
}

export class ConditionGroup {
  /**
   * @param {string} conjunction
   * @param {Array.<string>} conditions
   * */
  constructor(conjunction, ...conditions) {
    const conjunctionValues = values(CONJUNCTIONS);
    if (!conjunctionValues.includes(conjunction)) {
      throw new Error(
        `Operator must be one of [${conjunctionValues.join(
          ', '
        )}. You specified ${conjunction}]`
      );
    }

    this._conjunction = conjunction;
    this._conditions = conditions;
  }

  toString() {
    const parts = this._conditions.map(condition => condition.toString());
    const conjoinedConditions = parts.join(` ${this._conjunction} `);
    return `(${conjoinedConditions})`;
  }
}

export default class SoqlQueryBuilder {
  /**
   * @param {string} salesforceObject
   * */
  constructor(salesforceObject) {
    if (!salesforceObject) {
      throw new Error('Missing salesforceObject');
    }

    this._salesforceObject = salesforceObject;
    this._fields = [];
    this._scope = null;
    this._conditions = [];
    this._orderBy = null;
    this._orderDirection = null;
    this._limit = null;
    this._offset = null;
  }

  /**
   * @param {Array.<string>} fields
   *
   * @return {SoqlQueryBuilder}
   * */
  fields(fields) {
    this._fields = fields.slice(0);

    return this;
  }

  // scope can be mine or everything
  /**
   * @param {string} scope
   *
   * @return {SoqlQueryBuilder}
   * */
  scope(scope) {
    const scopeValues = values(SCOPES);
    if (!scopeValues.includes(scope)) {
      throw new Error(
        `Scope must be one of [${scopeValues.join(
          ', '
        )}]. You specified ${scope}`
      );
    }

    this._scope = scope;

    return this;
  }

  /**
   * @param {Array.<Condition|ConditionGroup>} conditions
   *
   * @return {SoqlQueryBuilder}
   * */
  conditions(...conditions) {
    this._conditions = this._conditions.concat(conditions);

    return this;
  }

  /**
   * @param {string} field
   * @param {string} direction
   *
   * @return {SoqlQueryBuilder}
   * */
  orderBy(field, direction) {
    if (![DESC, ASC].includes(direction)) {
      throw new Error(
        `Direction must be one of [${DESC}, ${ASC}]. You specified ${direction}`
      );
    }
    this._orderBy = field;
    this._orderDirection = direction;

    return this;
  }

  /**
   * @param {number} number
   *
   * @return {SoqlQueryBuilder}
   * */
  limit(number) {
    this._limit = number;

    return this;
  }

  /**
   * @param {number} number
   *
   * @return {SoqlQueryBuilder}
   * */
  offset(number) {
    this._offset = number;

    return this;
  }

  /**
   * @return {string}
   * */
  toString() {
    if (isEmpty(this._fields)) {
      throw new Error(
        `Must specify at least one field to select from ${this._salesforceObject}`
      );
    }

    const soqlParts = [];

    // SELECT [fields] FROM [object]
    const fieldsString = this._fields.join(', ');
    soqlParts.push(`SELECT ${fieldsString} FROM ${this._salesforceObject}`);

    // If specified: USING [scope]
    if (this._scope) {
      soqlParts.push(`USING SCOPE ${this._scope}`);
    }

    // If specified: WHERE (...conditions)
    if (!isEmpty(this._conditions)) {
      soqlParts.push('WHERE');

      const conditionsStrings = this._conditions.map(c => c.toString());

      // TODO allow more conjunctions than just a single list of things AND'd together
      soqlParts.push(conditionsStrings.join(` ${CONJUNCTIONS.AND} `));
    }

    // If specified: ORDER BY [field] [direction]
    if (!isNull(this._orderBy)) {
      soqlParts.push(`ORDER BY ${this._orderBy} ${this._orderDirection}`);
    }

    // If specified: LIMIT [number]
    if (!isNull(this._limit)) soqlParts.push(`LIMIT ${this._limit}`);

    // If specified: OFFSET [number]
    if (!isNull(this._offset)) soqlParts.push(`OFFSET ${this._offset}`);

    return soqlParts.join(' ');
  }
}
