import Button from '@material-ui/core/Button';

import MuiSnackbar from '@material-ui/core/Snackbar';
import WarningIcon from '@material-ui/icons/Warning';
import Typography from '@material-ui/core/Typography';

import { amber, red } from '@material-ui/core/colors';

import { ALERT } from 'Modules/App/constants';

import {
  isSnackbarOpen,
  getLatestSnackbarWarning,
} from 'Modules/App/selectors';

import { dismiss as dismissWarning } from 'Modules/App/actions/snackbarWarnings';

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { GROOVEY_BLUEY } from 'Modules/Shared/constants/colors';

const styles = theme => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing.unit * 1.5,
    paddingBottom: theme.spacing.unit * 1.5,
    flexGrow: 1,
    flexWrap: 'wrap',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: 'rgb(50, 50, 50)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  alertIcon: {
    color: red[500],
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  warningIcon: {
    color: amber[500],
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  dismissButton: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    color: GROOVEY_BLUEY,
  },
  messageText: {
    color: '#fff',
    lineHeight: 1.3,
  },
});

@withStyles(styles)
@connect(
  state => ({
    isNoticeSnackbarOpen: isSnackbarOpen(state),
    warning: getLatestSnackbarWarning(state),
  }),
  {
    dismissWarning,
  }
)
export default class SnackbarWarning extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    dismissWarning: PropTypes.func.isRequired,
    isNoticeSnackbarOpen: PropTypes.bool,
    warning: ImmutablePropTypes.mapContains({
      key: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      submessage: PropTypes.string,
      variant: PropTypes.string,
    }),
  };

  static defaultProps = {
    isNoticeSnackbarOpen: false,
    warning: null,
  };

  handleDismiss = () => {
    const { warning, dismissWarning } = this.props;

    dismissWarning({ key: warning.get('key') });
  };

  render() {
    const { classes, isNoticeSnackbarOpen } = this.props;

    if (!this.props.warning) return null;

    const { warning } = this.props;

    // Warning is hidden when notice sidebar is pushed.
    // Maybe we should consider to use https://github.com/iamhosseindhv/notistack instead.

    return (
      <MuiSnackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        key={warning.get('key')}
        open={!isNoticeSnackbarOpen}
      >
        <div className={classes.content}>
          <WarningIcon
            className={
              warning.get('variant') === ALERT
                ? classes.alertIcon
                : classes.warningIcon
            }
          />
          <div className={classes.messageContainer}>
            <Typography classes={{ root: classes.messageText }} variant="body2">
              {warning.get('message')}
            </Typography>
            <Typography classes={{ root: classes.messageText }} variant="body1">
              {warning.get('submessage')}
            </Typography>
          </div>
          <Button
            classes={{ root: classes.dismissButton }}
            size="small"
            onClick={this.handleDismiss}
          >
            Dismiss
          </Button>
        </div>
      </MuiSnackbar>
    );
  }
}
