import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography, Avatar } from '@material-ui/core';
import EmailEventTimeDetails from 'Modules/Emails/components/EmailEventTimeDetails';
import EmailEventRecord from 'Modules/Emails/data/EmailEvent';
import {
  EMAIL_EVENT_TYPE_ICONS,
  EMAIL_EVENT_DEVICE_TYPE_ICONS,
} from 'Modules/Emails/constants';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.unit,
    '&:not(:last-child)': {
      '& $avatar:after': {
        top: 20,
        width: 1,
        height: 40,
        content: '""',
        display: 'block',
        position: 'absolute',
        backgroundColor: 'rgb(216, 216, 216)',
      },
    },
  },
  avatar: {
    width: 20,
    height: 20,
    marginRight: theme.spacing.unit * 2,
    overflow: 'visible',
  },
  icon: {
    width: 14,
    height: 14,
    verticalAlign: 'middle',
  },
  divider: {
    paddingLeft: 4,
    paddingRight: 4,
  },
  typography: {
    fontSize: 14,
  },
});

const EmailEvent = ({ classes, emailEvent }) => {
  const EventTypeIcon = EMAIL_EVENT_TYPE_ICONS[emailEvent.type];
  const DeviceTypeIcon = EMAIL_EVENT_DEVICE_TYPE_ICONS[emailEvent.deviceType];

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>
        {emailEvent.type && <EventTypeIcon className={classes.icon} />}
      </Avatar>
      <Typography variant="caption" className={classes.typography}>
        <EmailEventTimeDetails emailEvent={emailEvent} />
        <div>
          {emailEvent.location || 'Location Unknown'}
          {emailEvent.deviceType && (
            <Fragment>
              <span className={classes.divider}>|</span>
              <DeviceTypeIcon className={classes.icon} />
            </Fragment>
          )}
        </div>
      </Typography>
    </div>
  );
};

EmailEvent.displayName = 'EmailEvent';
EmailEvent.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    divider: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    root: PropTypes.string.isRequired,
    typography: PropTypes.string.isRequired,
  }).isRequired,
  emailEvent: PropTypes.instanceOf(EmailEventRecord).isRequired,
};

export default withStyles(styles)(EmailEvent);
