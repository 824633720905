import 'tinymce/themes/silver/theme';
import 'tinymce/plugins/code';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { IProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor';
import tinymce, { Editor as TinyMCEEditor } from 'tinymce';
import { useTheme } from '@material-ui/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { renderToString } from 'react-dom/server';

import { REGEX_TO_REMOVE_HEADERS } from './constants';
import getConfig from './config';
import MergeFieldDialog, { MergeFieldGroup } from './MergeFieldDialog';
import FontCleanDialog from './FontCleanDialog';
import detectInconsistentFormating from './utils/detectInconsistentFormating';
import FileUploadDialog from './FileUploadDialog';
import HighspotDialog from './HighspotDialog';
import InconsistentFontWarning from './InconsistentFontWarning';
// eslint-disable-next-line
import VidyardDialog from './VidyardDialog';
// eslint-disable-next-line
import SeismicDialog from './SeismicDialog';
// eslint-disable-next-line
import vidyardIcon from './config/images/vidyard.svg';
// eslint-disable-next-line
import highspotIcon from './config/images/highspot.svg';
// eslint-disable-next-line
import seismicIcon from './config/images/seismic.svg';
// eslint-disable-next-line
import schedulerIcon from './config/images/scheduler.svg';
// eslint-disable-next-line
import templateIcon from './config/images/template.svg';
import UploadedFile from './UploadedFile';

const queryClient = new QueryClient();

export type WysiwygEditorProps = {
  mergeFieldProps: {
    mergeFields: MergeFieldGroup[];
    fallbackRequired?: boolean;
  };
  trueAttachmentsProps?: {
    isTrueAttachments: boolean;
    orignalAttachments?: Array<{
      name: string;
      uploadLocation: string;
      size: number;
      id: string;
    }>;
    onAttachmentsChange?: (
      a: Array<{
        name: string;
        uploadLocation: string;
        size: number;
        id: string;
      }>,
    ) => void;
  };
  initialValue?: string;
  recipientEmail?: string;
  config?: IProps['init'];
  onChange?: (a: string, editor: TinyMCEEditor) => void;
  integrations?: {
    highspot?: boolean;
    seismic?: boolean;
    vidyard?: boolean;
    showVidyardLink?: boolean;
  };
  inconsistentFormattingFeatureFlag?: boolean;
  onSchedulerClick?: () => void;
  onTemplateClick?: () => void;
  disabled?: boolean;
};

const WysiwygEditor: FC<WysiwygEditorProps> = ({
  mergeFieldProps,
  initialValue,
  recipientEmail,
  config,
  onChange,
  trueAttachmentsProps,
  integrations,
  inconsistentFormattingFeatureFlag,
  onSchedulerClick,
  onTemplateClick,
  disabled,
}) => {
  const theme = useTheme();
  const [mergeFieldsDialogOpen, setMergeFieldsDialogOpen] = useState(false);
  const [fontCleanDialogOpen, setFontCleanDialogOpen] = useState(false);
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [highspotDialogOpen, setHighspotDialogOpen] = useState(false);
  const [seismicDialogOpen, setSeismicDialogOpen] = useState(false);
  const [vidyardDialogOpen, setVidyardDialogOpen] = useState(false);
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [attachments, setAttachments] = useState<
    { name: string; uploadLocation: string; size: number; id: string }[]
  >([]);

  const [nonStandardFonts, setNonStandardFonts] = useState(new Set<string>());
  const [allowedFonts, setAllowedFonts] = useState(new Set<string>());
  const [nodeToBePasted, setNodeToBePasted] = useState<HTMLElement | null>(
    null,
  );

  const handleCloseMergeFieldsDialog = () => setMergeFieldsDialogOpen(false);
  const handleCloseFontCleanDialog = () => setFontCleanDialogOpen(false);
  const isTrueAttachments = trueAttachmentsProps?.isTrueAttachments || false;
  const orignalAttachments = trueAttachmentsProps?.orignalAttachments;
  const onAttachmentsChange = trueAttachmentsProps?.onAttachmentsChange;

  const [inconsistentFontWarning, setInconsistentFontWarning] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const [bottom, setBottom] = useState('3px');
  const [backgroundColor, setBackgroundColor] = useState('#fff4ce');
  const [undoFixButton, setUndoFixButton] = useState(false);
  const [oldHtmlString, setOldHtmlString] = useState('');
  const [keyboardUndo, setKeyboardUndo] = useState(false);

  const handleCancelFontCleanDialog = () => {
    setAllowedFonts(new Set(nonStandardFonts));
    setNonStandardFonts(new Set<string>());
    setFontCleanDialogOpen(false);
  };

  const getAttachmentsFormBody = (body: string) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(body, 'text/html');
    const footer = htmlDoc.getElementsByClassName('attachmentFooter');
    const arr = Array.from(footer);
    if (arr.length) {
      const footerDoc = parser.parseFromString(arr[0].innerHTML, 'text/html');
      const links = footerDoc.getElementsByClassName('fr-file');
      const linksArr = Array.from(links);

      const currentAttachments = linksArr.map(link => {
        const id = link?.getAttribute('id') || '';
        const idParts = id.split('*');
        const size = idParts.length > 1 ? Number(idParts[1]) : 0;
        return {
          name: link?.textContent || '',
          uploadLocation: link?.getAttribute('href') || '',
          size,
          id,
        };
      });
      return currentAttachments;
    }
    return [];
  };

  const resetHiddenAttachments = useCallback(
    (
      latestAttachments: {
        uploadLocation: string;
        name: string;
        size: number;
        id: string;
      }[],
    ) => {
      const currentContent = tinymce.activeEditor.getContent();
      const footerPattern = /<div class="attachmentFooter(.|\n)*<\/div>/;
      const cleanContent = currentContent.replace(footerPattern, '');
      const attachmentsBody = latestAttachments
        .map(attachment => {
          return renderToString(
            <div>
              <UploadedFile
                uploadLocation={attachment.uploadLocation}
                fileName={attachment.name}
                id={attachment.id}
              />
            </div>,
          );
        })
        .join('</br>');
      const attachmentsHtml = `<div class="attachmentFooter" style='display: none;' > ${attachmentsBody}</div>`;
      tinymce.activeEditor.setContent(cleanContent + attachmentsHtml);
    },
    [],
  );

  const handleCloseFileUploadDialog = useCallback(
    attachment => {
      if (attachment != null) {
        setTotalFilesSize(
          prevTotalFilesSize => prevTotalFilesSize + attachment.size,
        );
        setAttachments(current => {
          const latestAttachments = [...current, attachment];
          resetHiddenAttachments(latestAttachments);
          if (onAttachmentsChange) {
            onAttachmentsChange(latestAttachments);
          }
          return latestAttachments;
        });
      }
      setFileUploadDialogOpen(false);
    },
    [onAttachmentsChange, resetHiddenAttachments],
  );

  const handleCloseHighspotDialog = useCallback(() => {
    setHighspotDialogOpen(false);
  }, []);
  const handleCloseSeismicDialog = () => setSeismicDialogOpen(false);
  const handleCloseVidyardDialog = useCallback(() => {
    setVidyardDialogOpen(false);
  }, []);

  useEffect(() => {
    if (nodeToBePasted) {
      const regex = REGEX_TO_REMOVE_HEADERS;
      const htmlToInsert = nodeToBePasted.innerHTML.replace(regex, '');

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlToInsert, 'text/html');
      doc
        .querySelectorAll<HTMLElement>(
          '[style*="font-family"],[style*="font-size"]',
        )
        .forEach(el => {
          el.style.fontFamily = '';
          el.style.fontSize = '';
        });

      tinymce.activeEditor.insertContent(doc.documentElement.innerHTML);
    }
  }, [allowedFonts, nodeToBePasted]);

  const height = config ? config.height : null;
  useEffect(() => {
    if (typeof height === 'string' && tinymce.activeEditor?.editorContainer) {
      tinymce.activeEditor.editorContainer.style.height = height;
    }
  }, [height]);

  const getTotalAttachmentsSize = (attachments: { size: number }[]) => {
    return attachments.reduce((prev, cur) => {
      return prev + cur.size;
    }, 0);
  };

  useEffect(() => {
    if (orignalAttachments) {
      setAttachments(orignalAttachments);
      const totalAttachmentsSize = getTotalAttachmentsSize(orignalAttachments);
      setTotalFilesSize(totalAttachmentsSize);
    } else if (initialValue) {
      const attachments = getAttachmentsFormBody(initialValue);
      setAttachments(attachments);
      const totalAttachmentsSize = getTotalAttachmentsSize(attachments);
      setTotalFilesSize(totalAttachmentsSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemoveAttachment = (fileId: string) => {
    setAttachments(current => {
      const latestAttachments = current.filter(attachment => {
        if (attachment.id === fileId) {
          setTotalFilesSize(totalFilesSize => totalFilesSize - attachment.size);
          return false;
        }
        return true;
      });
      resetHiddenAttachments(latestAttachments);
      return latestAttachments;
    });
  };

  const setStateValues = (value: boolean, backgroundColor: string) => {
    setInconsistentFontWarning(value);
    setUndoFixButton(!value);
    setKeyboardUndo(!value);
    setBottom('55px');
    setBackgroundColor(backgroundColor);
  };

  const onClose = () => {
    setInconsistentFontWarning(false);
    setUndoFixButton(false);
    setBottom('3px');
    setBackgroundColor('#fff4ce');
  };

  const onEditorChange = (htmlString: string, editor: TinyMCEEditor) => {
    if (inconsistentFormattingFeatureFlag) {
      setKeyboardUndo(false);
      const regex = REGEX_TO_REMOVE_HEADERS;
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        htmlString.replace(regex, ''),
        'text/html',
      );

      if (detectInconsistentFormating(doc.body as HTMLElement)) {
        setStateValues(true, '#fff4ce');
      } else {
        onClose();
      }
      setHtmlString(htmlString);
    }

    onChange?.(htmlString, editor);
  };

  const onFix = () => {
    setOldHtmlString(htmlString);
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    doc
      .querySelectorAll<HTMLElement>(
        '[style*="font-family"],[style*="font-size"]',
      )
      .forEach(el => {
        el.style.fontFamily = '';
        el.style.fontSize = '';
      });

    setHtmlString(doc.documentElement.innerHTML);
    tinymce.activeEditor.resetContent(doc.documentElement.innerHTML);
    setStateValues(false, '#DFF6DD');
  };

  const undoInconsistentWarning = () => {
    setHtmlString(oldHtmlString);
    tinymce.activeEditor.resetContent(oldHtmlString);
    setStateValues(true, '#fff4ce');
  };

  const handleKeyboardUndo = (event: KeyboardEvent) => {
    event.stopImmediatePropagation();
    if (keyboardUndo && event.key === 'z' && (event.ctrlKey || event.metaKey)) {
      setHtmlString(oldHtmlString);
      tinymce.activeEditor.resetContent(oldHtmlString);
      setStateValues(true, '#fff4ce');
    }
  };

  useEffect(() => {
    let undoFixButtonTimeout: NodeJS.Timeout;
    if (undoFixButton) undoFixButtonTimeout = setTimeout(() => onClose(), 5000);
    return () => clearTimeout(undoFixButtonTimeout);
  }, [undoFixButton]);

  return (
    <div
      style={{
        position: 'relative',
        height: height || 'auto',
      }}
    >
      <FontCleanDialog
        isOpen={fontCleanDialogOpen}
        onClose={handleCloseFontCleanDialog}
        onCancel={handleCancelFontCleanDialog}
        nonStandardFonts={nonStandardFonts}
        node={nodeToBePasted}
      />
      <MergeFieldDialog
        isOpen={mergeFieldsDialogOpen}
        onClose={handleCloseMergeFieldsDialog}
        mergeFieldProps={mergeFieldProps}
      />
      <HighspotDialog
        isOpen={highspotDialogOpen}
        onClose={handleCloseHighspotDialog}
        recipientEmail={recipientEmail}
      />
      <SeismicDialog
        isOpen={seismicDialogOpen}
        onClose={handleCloseSeismicDialog}
        recipientEmail={recipientEmail}
      />
      <VidyardDialog
        isOpen={vidyardDialogOpen}
        onClose={handleCloseVidyardDialog}
        recipientEmail={recipientEmail}
        showVidyardLink={integrations?.showVidyardLink}
      />
      <QueryClientProvider client={queryClient}>
        <FileUploadDialog
          isOpen={fileUploadDialogOpen}
          isTrueAttachments={isTrueAttachments}
          onClose={handleCloseFileUploadDialog}
          totalFilesSize={totalFilesSize}
        />
      </QueryClientProvider>
      <Editor
        initialValue={initialValue}
        disabled={disabled}
        init={{
          ...getConfig(theme),
          paste_postprocess: (
            _plugin: unknown,
            args: { preventDefault: () => void; node: HTMLElement },
          ) => {
            args.preventDefault();
            setNodeToBePasted(args.node);
          },
          setup: (editor: TinyMCEEditor) => {
            editor.ui.registry.addButton('merge-fields', {
              text: '{!..}',
              tooltip: 'Insert Merge Field',
              onAction: () => {
                setMergeFieldsDialogOpen(true);
              },
            });

            editor.ui.registry.addButton('file', {
              text: '📎',
              tooltip: 'Upload File',
              onAction: () => {
                setFileUploadDialogOpen(true);
              },
            });

            if (onSchedulerClick) {
              editor.ui.registry.addIcon(
                'schedulerIcon',
                `<img src="${schedulerIcon}" />`,
              );
              editor.ui.registry.addButton('scheduler', {
                tooltip: 'Open Groove scheduler',
                icon: 'schedulerIcon',
                onAction: onSchedulerClick,
              });
            }

            if (onTemplateClick) {
              editor.ui.registry.addIcon(
                'templateIcon',
                `<img src="${templateIcon}" />`,
              );
              editor.ui.registry.addButton('template', {
                tooltip: 'Open templates',
                icon: 'templateIcon',
                onAction: onTemplateClick,
              });
            }

            if (integrations) {
              if (integrations.highspot) {
                editor.ui.registry.addIcon(
                  'highspot',
                  `<img src="${highspotIcon}" />`,
                );
                editor.ui.registry.addButton('highspot', {
                  tooltip: 'Insert Highspot content',
                  icon: 'highspot',
                  onAction: () => {
                    setHighspotDialogOpen(true);
                  },
                });
              }

              if (integrations.seismic) {
                editor.ui.registry.addIcon(
                  'seismic',
                  `<img src="${seismicIcon}" />`,
                );
                editor.ui.registry.addButton('seismic', {
                  tooltip: 'Insert Seismic content',
                  icon: 'seismic',
                  onAction: () => {
                    setSeismicDialogOpen(true);
                  },
                });
              }

              if (integrations.vidyard) {
                editor.ui.registry.addIcon(
                  'vidyard',
                  `<img src="${vidyardIcon}" />`,
                );
                editor.ui.registry.addButton('vidyard', {
                  tooltip: 'Insert a Vidyard video',
                  icon: 'vidyard',
                  onAction: () => {
                    setVidyardDialogOpen(true);
                  },
                });
              }
            }

            editor.on('init', args => {
              const fluentLayer = document.getElementById(
                'fluent-default-layer-host',
              );
              if (fluentLayer) {
                // this has a default zindex of 1000000, we need to lower it so popups in tinymce show.
                fluentLayer.style.zIndex = '1001';
              }
            });
          },
          ...config,
        }}
        onEditorChange={onEditorChange}
        onKeyDown={handleKeyboardUndo}
      />
      <div
        style={{
          maxHeight: 49,
          overflow: 'auto',
          bottom: 1,
          left: 0,
          right: 0,
          zIndex: 100,
          position: 'absolute',
          backgroundColor,
          marginLeft: 1,
          marginRight: 1,
        }}
      >
        {inconsistentFormattingFeatureFlag &&
          (inconsistentFontWarning || undoFixButton) && (
            <InconsistentFontWarning
              onClose={onClose}
              onFix={onFix}
              undoInconsistentWarning={
                undoFixButton ? undoInconsistentWarning : undefined
              }
            />
          )}
      </div>
      <div
        style={{
          maxHeight: 60,
          overflow: 'auto',
          width: '100%',
          bottom,
          left: 0,
          right: 0,
          zIndex: 100,
          position: 'absolute',
        }}
      >
        {attachments &&
          attachments.map(attachment => {
            return (
              <span style={{ padding: 2 }}>
                <UploadedFile
                  uploadLocation={attachment.uploadLocation}
                  fileName={attachment.name}
                  id={attachment.id}
                  onDelete={handleRemoveAttachment}
                />
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default WysiwygEditor;
