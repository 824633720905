import produce from 'immer';
import create, { SetState, GetState } from 'zustand';

import sendMessageToActionCompose from 'Modules/Actions/sagas/outgoing/sendMessageToActionCompose';
import { actionTypes as incomingActionTypes } from 'Modules/ActionCompose/actions/incoming';
import { history } from 'Utils/history';

export type MoboUser = {
  name: string;
  id: string;
  email: string;
};

export type Store = {
  moboUser?: MoboUser;
  setMoboUser: (moboUser?: MoboUser) => void;
  clearMoboUser: () => void;
};

const useStore = create<Store>(
  (set: SetState<Store>, get: GetState<Store>) => ({
    moboUser: undefined,
    clearMoboUser: () => {
      get().setMoboUser(undefined);
    },
    setMoboUser: (moboUser?: MoboUser) => {
      set(
        produce(draft => {
          draft.moboUser = moboUser;
        })
      );

      // Inform ActionCompose Iframe that the mobo user has changed
      sendMessageToActionCompose({
        type: incomingActionTypes.SET_MOBO_USER,
        payload: { moboUser },
      });

      // redirect to the root flows
      const route = window.location.pathname;
      if (/\/flows/.test(route)) {
        history.push('/flows');
      }
    },
  })
);

export default useStore;
