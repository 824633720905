import { call, put } from 'redux-saga/effects';
import { createFirebaseJwtToken } from 'GrooveHTTPClient/session';
import { logError } from 'Modules/Shared/actions/errors';
import firebase from 'Utils/firebase';
import logout from 'ErrorHandling/logout';
import HTTPError from 'GrooveHTTPClient/HTTPError';

let firestore = null;
let initializingFirestore = false;

/*
 * Gets a Firestore instance
 * On the first invocation, will also auth the user to Firebase
 *
 * @return Firestore instance
 * */
export function* getFirestore() {
  if (firestore) {
    return firestore;
  }
  if (initializingFirestore) {
    yield call(
      () =>
        new Promise(resolve => {
          const handler = () => {
            if (firestore) {
              resolve();
            } else {
              setTimeout(handler, 100);
            }
          };
          setTimeout(handler, 100);
        })
    );
    return firestore;
  }
  initializingFirestore = true;

  try {
    // Fetches a custom JWT token encoded with our Firebase info from GrooveEngine
    const {
      data: { token },
    } = yield call(createFirebaseJwtToken);

    yield call(
      firebase.auth().signInWithCustomToken.bind(firebase.auth()),
      token
    );
  } catch (e) {
    // Temporary measure to prevent /error on app upstart
    if (e instanceof HTTPError && e.response.status === 422) {
      yield call(logout, e);
    } else {
      yield put(logError({ error: e, isUiError: false }));
    }
  }

  firestore = firebase.firestore();

  // This setting is required to avoid an issue with a future
  // Firestore release deprecating JS dates.
  // See - https://github.com/firebase/firebase-js-sdk/issues/726
  const settings = { timestampsInSnapshots: true };

  firestore.settings(settings);
  return firestore;
}
