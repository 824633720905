import { List } from 'immutable';
import { keyMirror } from '@groove-labs/action-utils';
import {
  Email as EmailIcon,
  Call as CallIcon,
  ContactMail as DirectMailIcon,
  ChatBubble as SmsIcon,
  Business as InPersonVisitIcon,
} from '@material-ui/icons';
import { PRIORITIES } from 'Modules/Shared/constants/index';
import LinkedInIcon from 'Modules/Shared/icons/linkedIn';
import OtherIcon from 'Modules/ActionCompose/components/Pin';
import PinIcon from 'Modules/Actions/components/PinIcon';
import SendosoIcon from 'Modules/Shared/icons/sendoso';

export const TYPES = {
  EMAIL: 'TEMPLATE',
  CALL: 'CALL',
  IN_MAIL: 'STEP_SUBTYPE_LINKEDIN_INMAIL',
  LINKEDIN_CONNECT: 'STEP_SUBTYPE_LINKEDIN_CONNECT',
  SENDOSO: 'STEP_SUBTYPE_SENDOSO',
  SMS: 'STEP_SUBTYPE_SMS',
  IN_PERSON_VISIT: 'STEP_SUBTYPE_IN_PERSON_VISIT',
  DIRECT_MAIL: 'STEP_SUBTYPE_DIRECT_MAIL',
  OTHER: 'OTHER',
  WORKSPACE: 'WORKSPACE_ACCOUNT_TASK',
  SALESFORCE: 'UPDATE_SALESFORCE',
  GENERAL: 'GENERAL',
};

export const TYPE_NAME = {
  [TYPES.EMAIL]: 'Email',
  [TYPES.CALL]: 'Call',
  [TYPES.DIRECT_MAIL]: 'Direct mail',
  [TYPES.SENDOSO]: 'Sendoso',
  [TYPES.SMS]: 'SMS',
  [TYPES.IN_PERSON_VISIT]: 'In-person visit',
  [TYPES.LINKEDIN_CONNECT]: 'LinkedIn Connect',
  [TYPES.IN_MAIL]: 'InMail',
  [TYPES.OTHER]: 'Other',
  WORKSPACE_ACCOUNT_TASK: 'Spaces Task',
  [TYPES.GENERAL]: 'Task',
};

export const TYPE_ICONS = {
  [TYPES.EMAIL]: EmailIcon,
  [TYPES.CALL]: CallIcon,
  [TYPES.OTHER]: OtherIcon,
  [TYPES.IN_PERSON_VISIT]: InPersonVisitIcon,
  [TYPES.SMS]: SmsIcon,
  [TYPES.DIRECT_MAIL]: DirectMailIcon,
  [TYPES.LINKEDIN_CONNECT]: LinkedInIcon,
  [TYPES.IN_MAIL]: LinkedInIcon,
  [TYPES.SENDOSO]: SendosoIcon,
  [TYPES.GENERAL]: OtherIcon,
};

export const SOURCE_TYPE_ICONS = {
  [TYPES.EMAIL]: EmailIcon,
  [TYPES.CALL]: CallIcon,
  [TYPES.OTHER]: PinIcon,
  [TYPES.IN_PERSON_VISIT]: InPersonVisitIcon,
  [TYPES.SMS]: SmsIcon,
  [TYPES.DIRECT_MAIL]: DirectMailIcon,
  [TYPES.LINKEDIN_CONNECT]: LinkedInIcon,
  [TYPES.IN_MAIL]: LinkedInIcon,
  [TYPES.SENDOSO]: SendosoIcon,
  [TYPES.GENERAL]: PinIcon,
};

export const TYPE_DATA = new List([
  {
    value: TYPES.EMAIL,
    label: 'Email',
  },
  {
    value: TYPES.CALL,
    label: 'Call',
  },
  {
    value: TYPES.IN_MAIL,
    label: 'InMail',
  },
  {
    value: TYPES.LINKEDIN_CONNECT,
    label: 'LinkedIn Connect',
  },
  {
    value: TYPES.SENDOSO,
    label: 'Sendoso',
  },
  {
    value: TYPES.SMS,
    label: 'SMS',
  },
  {
    value: TYPES.IN_PERSON_VISIT,
    label: 'In Person Visit',
  },
  {
    value: TYPES.DIRECT_MAIL,
    label: 'Direct Mail',
  },
  {
    value: TYPES.OTHER,
    label: 'Other',
  },
  {
    value: TYPES.GENERAL,
    label: 'Task',
  },
]);

export const DUE_DATA = new List([
  {
    value: 'ON',
    label: 'Today',
  },
  {
    value: 'BEFORE',
    label: 'Past Due',
  },
  {
    value: 'AFTER',
    label: 'Later',
  },
]);

export const SOURCES = {
  ...keyMirror(['FLOW', 'WORKSPACE', 'ONE_OFF', 'PLAY']),
};

export const STATUS = {
  ...keyMirror(['COMPLETE', 'INCOMPLETE', 'FAILED']),
};

export const SCOPE = {
  ...keyMirror(['CREATOR', 'ASSIGNEE']),
};

export const SOURCE_READABLE_NAME = {
  [SOURCES.FLOW]: 'Flow',
  [SOURCES.WORKSPACE]: 'Spaces',
  [SOURCES.ONE_OFF]: 'One Off',
  [SOURCES.PLAY]: 'Play',
};

export const SOURCES_DATA = new List([
  {
    label: SOURCE_READABLE_NAME[SOURCES.FLOW],
    value: SOURCES.FLOW,
  },
  {
    label: SOURCE_READABLE_NAME[SOURCES.WORKSPACE],
    value: SOURCES.WORKSPACE,
  },
  {
    label: SOURCE_READABLE_NAME[SOURCES.ONE_OFF],
    value: SOURCES.ONE_OFF,
  },
  {
    label: SOURCE_READABLE_NAME[SOURCES.PLAY],
    value: SOURCES.PLAY,
  },
]);

export const READABLE_NAME_LOOK_UP = Object.freeze({
  [SOURCES.FLOW]: 'Flows',
  [SOURCES.WORKSPACE]: 'Spaces Task',
  [SOURCES.ONE_OFF]: 'One Off',
  [SOURCES.PLAY]: 'Play',
  ON: 'Due Today',
  BEFORE: 'Past Due',
  AFTER: 'Due Later',
  [PRIORITIES.HIGH]: 'High',
  [PRIORITIES.LOW]: 'Low',
  [PRIORITIES.NORMAL]: 'Medium',
  [PRIORITIES.NONE]: 'None',
  [PRIORITIES.NONE_FOR_FILTER]: 'None',
  [TYPES.EMAIL]: 'Email',
  [TYPES.CALL]: 'Call',
  [TYPES.IN_MAIL]: 'LinkedIn InMail',
  [TYPES.LINKEDIN_CONNECT]: 'LinkedIn Connect',
  [TYPES.SENDOSO]: 'Sendoso',
  [TYPES.SMS]: 'SMS',
  [TYPES.IN_PERSON_VISIT]: 'In Person Visit',
  [TYPES.DIRECT_MAIL]: 'Direct Mail',
  [TYPES.OTHER]: 'Other',
  [TYPES.GENERAL]: 'Task',
});

export const GRAPHQL_RESPONSE_VIEWER_KEYPATH = ['data', 'viewer'];

export const GRAPHQL_RESPONSE_ACTIONS_KEYPATH = [
  ...GRAPHQL_RESPONSE_VIEWER_KEYPATH,
  'actions',
];

export const INCOMING_POST_MESSAGE_TARGET = 'febes';
export const INCOMING_POST_MESSAGE_SOURCE = 'actionCompose';
export const OUTGOING_POST_SOURCE_SOURCE = 'febes';

export const NEW_ONE_OFF_ACTION_ID = 'NEW_ONE_OFF_ACTION';
