import cx from 'classnames';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';
import { location } from 'Modules/Shared/selectors/location';
import { topNavTabClicked } from 'Modules/App/actions';
import { setActiveTab } from 'Modules/Shared/actions/location';
import { makeGetModuleTabs } from 'Modules/App/selectors';
import { Map } from 'immutable';
import { ROUTES } from 'Routing/routesSaga';

const styles = theme => {
  return {
    toolbar: {
      justifyContent: 'center',
      minHeight: 0,
    },
    tab: {
      height: theme.spacing.unit * 6,
    },
    indicator: {
      height: 4,
    },
    tabHidden: {
      display: 'none',
    },
  };
};

@withStyles(styles)
@connect(
  state => ({
    location: location(state),
    moduleTabs: makeGetModuleTabs(ROUTES)(state),
  }),
  {
    topNavTabClicked,
    setActiveTab,
  }
)
export default class TopNavBarTabs extends Component {
  static propTypes = {
    location: PropTypes.instanceOf(Map).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    topNavTabClicked: PropTypes.func.isRequired,
    setActiveTab: PropTypes.func.isRequired,
    moduleTabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ).isRequired,
    tabCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    currentUser: null,
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.location.get('activeTab') !==
        nextProps.location.get('activeTab') ||
      JSON.stringify(this.props.moduleTabs) !==
        JSON.stringify(nextProps.moduleTabs)
    );
  }

  getActiveTab() {
    const { location, moduleTabs } = this.props;
    const activeTab = location.get('activeTab');
    const tabValues = moduleTabs.map(tabName => tabName.value);
    if (tabValues.includes(activeTab)) {
      return activeTab;
    }
    return (moduleTabs[0] || {}).value;
  }

  handleTabChange = (e, tabIndex) => {
    const { location, topNavTabClicked, setActiveTab, moduleTabs } = this.props;

    const rootPath = location.get('rootPath');
    const activeTab = location.get('activeTab');

    const tab = moduleTabs.find(tab => tab.value === tabIndex);

    if (activeTab !== tab.value) {
      setActiveTab({ activeTab: tab.value, clearAllOtherParameters: false });
    }

    topNavTabClicked(rootPath, tabIndex);
  };

  renderTabs() {
    const { classes, moduleTabs } = this.props;
    return moduleTabs.map(tabName => {
      const { label, value } = tabName;
      return (
        <Tab key={value} label={label} value={value} className={classes.tab} />
      );
    });
  }

  render() {
    const { classes, tabCount } = this.props;
    return (
      <div className={cx({ [classes.tabHidden]: tabCount <= 1 })}>
        <Tabs
          classes={{
            indicator: classes.indicator,
          }}
          value={this.getActiveTab()}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {this.renderTabs()}
        </Tabs>
      </div>
    );
  }
}
