import { Map } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/batchJobs';

const initialState = new Map({
  jobs: new Map(),
});

const createNewJob = (id, name) => {
  if (!id) throw new Error('id is required');
  if (!name) throw new Error('name is required');

  return new Map({
    id,
    name,
    progress: 0,
  });
};

export default function ui(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.ADD_JOB: {
      const { id, name } = action.payload;
      return state.setIn(['jobs', id], createNewJob(id, name));
    }

    case actionTypes.UPDATE_JOB_PROGRESS: {
      const { id, progress } = action.payload;
      return state.mergeIn(['jobs', id], { progress });
    }

    case actionTypes.DELETE_JOB: {
      const { id } = action.payload;
      return state.deleteIn(['jobs', id]);
    }

    default:
      return state;
  }
}
