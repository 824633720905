import { actions } from '@groove-labs/groove-ui';
import { camelize } from 'humps';
import * as peopleImportHTTPClient from 'GrooveHTTPClient/peopleImport';
import HTTPError from 'GrooveHTTPClient/HTTPError';
import HttpStatusCodes from 'http-status-codes';
import { assignableUsers } from 'GrooveHTTPClient/flows';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { fromJS } from 'immutable';
import { isEmpty, omitBy } from 'lodash-es';
import {
  actionTypes,
  openDrawer,
  reset,
  setActiveTab,
  setNoResultsFound,
  setSearching,
  submitSuccess,
  submitFailure,
  submitCsvLookupBegin,
  showImportOnBehalfOfUsers,
} from 'Modules/PeopleImportDialog/actions';
import {
  ADD_PEOPLE_JOB_ID_KEYPATH,
  CUSTOM_MERGE_FIELD_PREFIX,
  CUSTOM_MERGE_FIELD_REGEXP,
  TABS,
} from 'Modules/PeopleImportDialog/constants';
import { clearFilters as advancedSearchReset } from 'Modules/PeopleImportDialog/submodules/advancedSearch/actions';
import { clearFilters as listViewReset } from 'Modules/PeopleImportDialog/submodules/listView/actions';
import {
  activeTab as currentlyActiveTab,
  selectedObjectValue,
  relationshipName,
  getCanImportOnBehalfOf,
  selectFlowId,
  selectUiKeyPath,
  selectCreateSfdcCampaign,
  selectPersonFlowOwnerId,
  selectShowDuplicates,
} from 'Modules/PeopleImportDialog/selectors';
import { getInitialSelectedObject } from 'Modules/PeopleImportDialog/submodules/csvLookup/selectors';
import { getSfdcWhatIdMap } from 'Modules/PeopleImportDialog/submodules/reports/selectors/index';
import advancedSearchRootSaga from 'Modules/PeopleImportDialog/submodules/advancedSearch/sagas';
import basicSearchRootSaga from 'Modules/PeopleImportDialog/submodules/basicSearch/sagas';
import listViewRootSaga from 'Modules/PeopleImportDialog/submodules/listView/sagas';
import peopleTableRootSaga from 'Modules/PeopleImportDialog/submodules/peopleTable/sagas';
import csvLookupRootSaga from 'Modules/PeopleImportDialog/submodules/csvLookup/sagas';
import {
  loadData as loadPeopleTableData,
  resetTableResults,
} from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import {
  hideDuplicatesFromSearchResults,
  selectedRows,
} from 'Modules/PeopleImportDialog/submodules/peopleTable/selectors';
import reportsRootSaga from 'Modules/PeopleImportDialog/submodules/reports/sagas/index';
import { SearchDataParser } from 'Modules/PeopleImportDialog/utils';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import { addJob } from 'Modules/Shared/actions/batchJobs';
import { watchJob } from 'Modules/Shared/sagas/batchJobs';
import numeral from 'numeral';
import pluralize from 'pluralize';
import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import listToOrderedMap from 'Utils/list-to-ordered-map';
import { fetchReportFolders } from 'Modules/PeopleImportDialog/submodules/reports/actions/index';
import { getLdClient } from 'Utils/ldClient';
import { openAutoCreationModal } from 'Modules/PeopleImportDialog/submodules/csvLookup/actions';
import { getPeopleNotFoundInSalesforce } from 'Modules/Shared/selectors/autoCreationOfRecords';
import { getOrgOneFlowRestrictionEnabled } from 'Modules/Shared/selectors/users';
import { logErrorToSentry } from 'Modules/Shared/sagas/errors';
import trackFlowAssigneeUpdated from 'Modules/PeopleImportDialog/sagas/analytics/trackFlowAssigneeUpdated';

const { setProperty } = actions.ui;

// ------- Utility function ------------
// eslint-disable-next-line require-yield

export function* loadAssignableUsers(flowId) {
  try {
    const response = yield call(assignableUsers, {
      flowId,
    });

    if (response.meta.success) {
      yield put(showImportOnBehalfOfUsers(response.data));
    } else {
      throw new Error('Unable to load assignable users');
    }
  } catch (error) {
    logErrorToSentry(error);
    yield put(showImportOnBehalfOfUsers([]));
  }
}

// -------------- Handlers --------------
function* peopleImportDialogUpstart({ payload }) {
  yield fork(function* delayOpenShit() {
    yield delay(200);
    yield put(openDrawer());
  });

  const { flowId } = payload;
  const canImportOnBehalfOf = yield select(getCanImportOnBehalfOf);
  if (canImportOnBehalfOf) {
    yield call(loadAssignableUsers, flowId);
  }
}

function* peopleImportDialogTeardown() {
  const intercomButton = document.getElementsByClassName(
    'intercom-lightweight-app-launcher'
  )[0];
  if (intercomButton && intercomButton.style.visibility === 'hidden') {
    // detect we manipulated it successfully and set it back.
    intercomButton.style.visibility = 'visible';
  }
  yield put(reset());
}

export function* handleSubmit() {
  const [
    rows,
    flowId,
    isOpenUiKeyPath,
    createSfdcCampaign,
    personFlowOwnerId,
    duplicatedContactsVisible,
  ] = yield all([
    select(selectedRows),
    select(selectFlowId),
    select(selectUiKeyPath),
    select(selectCreateSfdcCampaign),
    select(selectPersonFlowOwnerId),
    select(selectShowDuplicates),
  ]);

  // look for custom merge fields
  const firstRow = rows.first();
  const customMergeFieldNames = firstRow
    .keySeq()
    .filter(key => CUSTOM_MERGE_FIELD_REGEXP.test(key))
    .map(key => key.match(CUSTOM_MERGE_FIELD_REGEXP)[1]);

  const currentTab = yield select(currentlyActiveTab);
  const isBasicSearchTab = currentTab === TABS.search;
  const isCsvLookup = currentTab === TABS.csvLookup;

  const peopleData = rows.entrySeq().reduce((acc, [sfdcId, row]) => {
    // whatId can be one of opportunityId, campaignId, accountId or whatId,
    // whichever one is defined.
    // Don't set whatId when import by basicSearch or csvLookup unless defined in csv

    const whatId =
      !isBasicSearchTab &&
      !isCsvLookup &&
      (row.get('whatId') ||
        row.get('opportunityId') ||
        row.get('campaignId') ||
        row.get('accountId'));

    acc[sfdcId] = omitBy(
      {
        sfdc_id: sfdcId,
        name: row.get('name'),
        email: row.get('email'),
        phone: row.get('phone'),
        mobile_phone: row.get('mobile_phone'),
        other_phone: row.get('other_phone'),
        home_phone: row.get('home_phone'),
        company_name: row.get('company_name'),
        opted_out_of_email: row
          .getIn(['warnings', 'optedOutOfEmail'], false)
          .toString(),
        sfdc_what_id: whatId,
      },
      isEmpty
    );

    if (!customMergeFieldNames.isEmpty()) {
      const customMergeFieldValues = customMergeFieldNames.reduce(
        (fields, fieldName) => {
          fields[fieldName] = row.get(
            `${CUSTOM_MERGE_FIELD_PREFIX}${fieldName}`
          );
          return fields;
        },
        {}
      );

      acc[sfdcId].custom_merge_field_values = customMergeFieldValues;
    }

    return acc;
  }, {});

  let response;
  try {
    response = yield* handleInvalidSalesforceConnectionHTTPRequest(
      peopleImportHTTPClient.importPeople,
      flowId,
      peopleData,
      personFlowOwnerId,
      createSfdcCampaign,
      duplicatedContactsVisible
    );
  } catch (e) {
    if (
      e instanceof HTTPError &&
      (e?.response?.status === HttpStatusCodes.UNPROCESSABLE_ENTITY ||
        e?.response?.status === HttpStatusCodes.FORBIDDEN)
    ) {
      yield put(submitFailure());
      yield put(
        pushSnackbarMessage({
          message:
            e?.response?.data?.message ||
            'There was a problem importing people. Please try again',
        })
      );
      return;
    } else {
      throw e;
    }
  }

  // If the server tells us that a batch job was submitted for processing, kick off the batch job
  // watcher to block until it is done to move on.
  if (response.data.batchId) {
    const jobId = response.data.batchId;

    // Begin polling job for completion
    yield put(addJob({ id: jobId, name: 'People import' }));
    yield put(
      setProperty({
        uiKeyPath: ADD_PEOPLE_JOB_ID_KEYPATH,
        data: jobId,
      })
    );

    // Wait until this job is finished
    yield* watchJob(jobId);
  }

  const autoCreateRecordsEnabled =
    currentTab === TABS.csvLookup &&
    getLdClient().variation('auto-create-records');
  const peopleNotFound = yield select(getPeopleNotFoundInSalesforce);
  if (autoCreateRecordsEnabled && peopleNotFound.size > 0) {
    yield put(submitCsvLookupBegin());
    yield put(openAutoCreationModal());
  } else {
    yield put(submitSuccess());

    // Give the progress button animation some time to complete
    yield delay(1000);

    // Close the dialog
    yield put(
      setProperty({
        uiKeyPath: isOpenUiKeyPath,
        data: false,
      })
    );

    // Fork so we don't block rendering of the snackbar
    yield fork(peopleImportDialogTeardown);

    // Publish success message to snackbar queue
    yield put(
      pushSnackbarMessage({
        message: `${numeral(rows.size).format('0,0')} ${pluralize(
          'person',
          rows.size
        )} added to Flow`,
      })
    );
  }
}

function* csvHandleSubmit({ payload }) {
  const [rows, isOpenUiKeyPath] = yield all([
    select(selectedRows),
    select(state =>
      state.getIn(['PeopleImportDialog', 'root', 'isOpenUiKeyPath'])
    ),
  ]);

  let recordsSize;
  if (payload && payload.recordsImported) {
    recordsSize = payload.recordsImported;
  } else {
    recordsSize = rows.size;
  }
  yield put(submitSuccess());

  // Give the progress button animation some time to complete
  yield delay(1000);

  // Close the dialog
  yield put(
    setProperty({
      uiKeyPath: isOpenUiKeyPath,
      data: false,
    })
  );

  // Fork so we don't block rendering of the snackbar
  yield fork(peopleImportDialogTeardown);

  // Publish success message to snackbar queue
  yield put(
    pushSnackbarMessage({
      message: `${numeral(recordsSize).format('0,0')} ${pluralize(
        'person',
        recordsSize
      )} added to Flow`,
    })
  );
}

function* processSearchResults(action) {
  const response = action.payload;
  const duplicatePeopleInFlow = fromJS(
    response.data?.duplicatePeopleInFlow ?? {}
  );

  const salesforceObject = yield select(selectedObjectValue);
  let contactChildFieldName = yield select(relationshipName);
  if (contactChildFieldName) {
    // Relationship name is given with a __c instead of __r. Convert it
    contactChildFieldName = camelize(
      contactChildFieldName.trim().replace(/__c$/i, '__r')
    );
  }
  const duplicatePeopleInFlowSfdcIds = duplicatePeopleInFlow.keySeq();

  const sfdcWhatIdMap = yield select(getSfdcWhatIdMap);
  const orgOneFlowRestrictionEnabled = yield select(
    getOrgOneFlowRestrictionEnabled
  );

  const parser = new SearchDataParser(
    response.data,
    duplicatePeopleInFlowSfdcIds,
    salesforceObject,
    contactChildFieldName,
    sfdcWhatIdMap,
    orgOneFlowRestrictionEnabled
  );
  const rows = parser.rows(2000);
  const columns = parser.columns();

  const accounts = parser.accounts();
  const campaigns = parser.campaigns();
  const opportunities = parser.opportunities();
  const customObjects = parser.customObjects();

  const selectedRowIds = parser.selectedRows();
  const selectedRows = rows.filter(row => selectedRowIds.includes(row.id));
  const selectedObjectCsv = yield select(getInitialSelectedObject);
  const duplicateRows = rows.filter(row => !!duplicatePeopleInFlow.get(row.id));
  const hideDuplicates = yield select(hideDuplicatesFromSearchResults);
  const duplicateRowIds = duplicateRows.map(row => row.id);
  let importableRows = null;
  if (!hideDuplicates) {
    importableRows = rows;
  } else {
    importableRows = rows.filter(row => !duplicatePeopleInFlow.get(row.id));
  }

  const data = {
    rows: fromJS(rows),
    columns: fromJS(columns),
    accounts: listToOrderedMap(accounts, account => account.id),
    campaigns: listToOrderedMap(campaigns, campaign => campaign.id),
    opportunities: listToOrderedMap(
      opportunities,
      opportunity => opportunity.id
    ),
    customObjects: listToOrderedMap(
      customObjects,
      customObject => customObject.id
    ),
    selectedRows: listToOrderedMap(selectedRows, row => row.id),
    selectAllWithoutWarnings: selectedRows.length >= 1,
    activeSalesforceObject: salesforceObject || selectedObjectCsv,
    missingPeopleSearchValues: fromJS(response.data.missingPeopleSearchValues),
    duplicatePeopleCount: duplicateRows?.length,
    duplicateRows: fromJS(duplicateRows),
    duplicateRowIds: fromJS(duplicateRowIds),
    importableRows: fromJS(importableRows),
    hasWarningPresentInRows: rows.some(row =>
      Object.values(row.warnings).some(value => !!value)
    ),
  };

  // Load data into reducer
  yield put(loadPeopleTableData(data));

  // Remove the searching progress indicator
  yield put(setSearching(false));

  if (importableRows.length < 1 && duplicateRows.length < 1) {
    yield put(setNoResultsFound(true));

    // Also, open the drawer again to make searching again easier
    yield put(openDrawer());
  }
}

export function* tabChangeHandler(action) {
  const currentTab = yield select(currentlyActiveTab);

  if (currentTab === 'advancedSearch') {
    yield put(advancedSearchReset());
  } else if (currentTab === 'salesforceListView') {
    yield put(listViewReset());
  }

  yield put(resetTableResults());
  const newTabValue = action.payload;

  if (newTabValue === 'salesforceReport') {
    yield put(fetchReportFolders());
  }

  yield put(setActiveTab(newTabValue));
  yield put(openDrawer());
  yield put(setNoResultsFound(false));
}

// -------------- Watchers --------------

function* watchPeopleImportDialogUpstart() {
  yield takeEvery(actionTypes.UPSTART, peopleImportDialogUpstart);
}

function* watchPeopleImportDialogTeardown() {
  yield takeEvery(actionTypes.TEARDOWN, peopleImportDialogTeardown);
}

function* watchPeopleImportDialogSubmit() {
  yield takeEvery(actionTypes.SUBMIT.BEGIN, handleSubmit);
}

function* watchPeopleImportCsvSubmit() {
  yield takeEvery(actionTypes.CSV_SUBMIT.SUCCESS, csvHandleSubmit);
}

function* watchPeopleImportProcessSearchResults() {
  yield takeLatest(
    actionTypes.REQUEST_PROCESS_SEARCH_RESULTS,
    processSearchResults
  );
}

function* watchPeopleImportTabChange() {
  yield takeLatest(actionTypes.REQUEST_SET_ACTIVE_TAB, tabChangeHandler);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(basicSearchRootSaga),
    fork(advancedSearchRootSaga),
    fork(listViewRootSaga),
    fork(reportsRootSaga),
    fork(csvLookupRootSaga),
    fork(peopleTableRootSaga),
    fork(watchPeopleImportTabChange),
    fork(watchPeopleImportDialogUpstart),
    fork(watchPeopleImportDialogTeardown),
    fork(watchPeopleImportDialogSubmit),
    fork(watchPeopleImportProcessSearchResults),
    fork(watchPeopleImportCsvSubmit),
    fork(trackFlowAssigneeUpdated),
  ]);
}
