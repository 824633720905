import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  FLOWS: {
    LOAD_ADDABLE_FLOWS: standardActions(),
    LOAD_PAGINATED_FLOWS: standardActions(),
    ...keyMirror([
      'LOAD_DATA',
      'TRIGGER_SORTING_CHANGE',
      'UPDATE_SORTING',
      'ENABLE_LIST_VIEW',
      'OPEN_FLOW_PREVIEW',
      'UPDATE_FLOW_STEPS_TEMPLATES',
      'UPDATE_PAGINATION',
      'RESET_REDUCER',
      'FLOWS_LOADING',
      'REQUEST_SEARCH',
      'SET_FLOW_FILTERS',
      'REQUEST_NEXT_PAGE',
      'REQUEST_PREVIOUS_PAGE',
      'SET_STEP_COUNTS',
    ]),
  },
}).FLOWS;

export function loadAddableFlows() {
  return {
    type: actionTypes.LOAD_ADDABLE_FLOWS.BEGIN,
  };
}

export function loadData(data) {
  return {
    type: actionTypes.LOAD_DATA,
    payload: data,
  };
}

export function triggerSortingChange({ value, direction }) {
  return {
    type: actionTypes.TRIGGER_SORTING_CHANGE,
    payload: { value, direction },
  };
}

export function updateSorting(payload) {
  return {
    type: actionTypes.UPDATE_SORTING,
    payload,
  };
}

export function enableListView(payload) {
  return {
    type: actionTypes.ENABLE_LIST_VIEW,
    payload,
  };
}

export function requestNextPage() {
  return {
    type: actionTypes.REQUEST_NEXT_PAGE,
  };
}

export function requestPreviousPage() {
  return {
    type: actionTypes.REQUEST_PREVIOUS_PAGE,
  };
}

export function updateFlowStepsTemplates(payload) {
  return {
    type: actionTypes.UPDATE_FLOW_STEPS_TEMPLATES,
    payload,
  };
}

export function updatePagination(payload) {
  return {
    type: actionTypes.UPDATE_PAGINATION,
    payload,
  };
}

export function openFlowPreview(flowId) {
  return {
    type: actionTypes.OPEN_FLOW_PREVIEW,
    payload: { flowId },
  };
}

export function requestSearch() {
  return {
    type: actionTypes.REQUEST_SEARCH,
  };
}

export function setFlowsLoading(payload) {
  return {
    type: actionTypes.FLOWS_LOADING,
    payload,
  };
}

export function setFlowFilters({ users, teams }) {
  return {
    type: actionTypes.SET_FLOW_FILTERS,
    payload: { users, teams },
  };
}

export function setStepCounts(payload) {
  return {
    type: actionTypes.SET_STEP_COUNTS,
    payload,
  };
}
