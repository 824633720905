import { fromJS, Map } from 'immutable';
import { actionTypes } from 'Modules/Shared/actions/users';
import { actionTypes as healthActionTypes } from 'Modules/Shared/actions/users/health';
import { actionTypes as myOrgActionTypes } from 'Modules/Settings/actions/myOrg';
import * as GrooveLocalStorage from 'GrooveLocalStorage';
import { SERVICE_CONNECTION_TYPES } from 'Modules/Troubleshooting/constants';

const initialState = fromJS({
  csrfToken: GrooveLocalStorage.fetch('csrfToken') || null,
  linkedInSAT: '',
  currentUser: null,
  firestoreUserDoc: new Map(),
  emailSyncSettingEnabled: null,
  globalSettings: new Map(),
  isLoggedIn: !!GrooveLocalStorage.fetch('csrfToken'),
  health: {
    lastCheckedAt: null,
    items: {
      [SERVICE_CONNECTION_TYPES.sfdcToken]: {
        isValid: true,
      },
      [SERVICE_CONNECTION_TYPES.oauthToken]: {
        isValid: true,
      },
      [SERVICE_CONNECTION_TYPES.grooveLicense]: {
        isValid: true,
      },
      [SERVICE_CONNECTION_TYPES.sfdcApiAccess]: {
        isValid: true,
      },
      [SERVICE_CONNECTION_TYPES.sfdcMeta]: {
        isValid: true,
        fixInProgress: false,
        isFixedByUserAction: false,
      },
    },
  },
});

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_CSRF_TOKEN: {
      return state.set('csrfToken', action.payload);
    }

    case actionTypes.SET_IS_LOGGED_IN: {
      return state.set('isLoggedIn', action.payload);
    }

    case actionTypes.SET_CURRENT_USER: {
      return state.set('currentUser', fromJS(action.payload));
    }

    case actionTypes.LOAD_FIRESTORE_USER_DOC: {
      const { userDoc } = action.payload;
      return state.set('firestoreUserDoc', fromJS(userDoc));
    }

    case actionTypes.ADD_GLOBAL_SETTING: {
      const { setting, value } = action.payload;
      const globalSettings = state.get('globalSettings');
      const updatedGlobalSettings = globalSettings.set(setting, fromJS(value));
      return state.set('globalSettings', updatedGlobalSettings);
    }

    case actionTypes.UPDATE_ORG_SETTING: {
      const { settingKey, value } = action.payload;

      return state.setIn(['currentUser', 'orgSettings', settingKey], value);
    }

    case healthActionTypes.SET_ITEM_HEALTH: {
      const { key, status } = action.payload;
      return state.setIn(['health', 'items', key, 'isValid'], status);
    }

    case healthActionTypes.SET_LAST_CHECKED_AT:
      return state.setIn(['health', 'lastCheckedAt'], action.payload.dateTime);

    case healthActionTypes.FIX_HEALTH_ITEM.BEGIN: {
      const { key } = action.payload;

      return state.setIn(['health', 'items', key, 'fixInProgress'], true);
    }

    case healthActionTypes.FIX_HEALTH_ITEM.SUCCESS: {
      const { key, isFixed } = action.payload;

      return state
        .setIn(['health', 'items', key, 'fixInProgress'], false)
        .setIn(['health', 'items', key, 'isFixedByUserAction'], isFixed);
    }

    case healthActionTypes.FIX_HEALTH_ITEM.FAILURE: {
      const { key } = action.payload;

      return state
        .setIn(['health', 'items', key, 'fixInProgress'], false)
        .setIn(['health', 'items', key, 'isFixedByUserAction'], false);
    }

    case actionTypes.SFDC_PROFILE_SETTINGS: {
      const settings = action.payload;

      return state
        .set('emailSyncSettingEnabled', settings.emailSyncSettingEnabled)
        .set('calendarSyncSettingEnabled', settings.calendarSyncSettingEnabled);
    }

    case actionTypes.UPDATE_ONE_FLOW_RESTIONSTION_SETTING: {
      return state.setIn(
        ['currentUser', 'orgSettings', 'oneFlowRestrictionEnabled'],
        action.payload
      );
    }

    case myOrgActionTypes.SET_USER_ORG_SETTING: {
      const { key, value } = action.payload;
      return state.setIn(['currentUser', 'orgSettings', key], value);
    }

    case actionTypes.UPDATE_CAN_IMPORT_ON_BEHALF_OF_SETTING: {
      return state.setIn(
        ['currentUser', 'canImportOnBehalfOf'],
        action.payload
      );
    }

    case actionTypes.FETCH_LINKEDIN_SAT.SUCCESS: {
      return state.set('linkedInSAT', action.payload);
    }

    case actionTypes.FETCH_LINKEDIN_SAT.FAILURE: {
      return state.set('linkedInSAT', '');
    }

    default:
      return state;
  }
}
