import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

export const isUsingGEReviewApp = (): boolean =>
  new URL(grooveEngineRootUrl).hostname.startsWith('engine-pr-');
export const isUsingGEStaging = (): boolean =>
  grooveEngineRootUrl === 'https://engine.staging.groove.co';
export const isUsingGEQA = (): boolean =>
  [
    'https://engine.manual1.qa.groove.co',
    'https://engine.manual2.qa.groove.co',
    'https://engine.automation-clean.qa.groove.co',
    'https://engine.automation-dirty.qa.groove.co',
  ].includes(grooveEngineRootUrl);
export const isUsingGEDemo = (): boolean =>
  grooveEngineRootUrl === 'https://demo.groove.co';
export const isUsingGEProduction = (): boolean =>
  grooveEngineRootUrl === 'https://engine.groove.co';
export const isFEBESReviewApp = (): boolean =>
  window.location.pathname.startsWith('/review/') ||
  window.location.pathname.startsWith('/commits/');
export const getGEPRNumber = (): string =>
  (/engine-pr-(\d+)/.exec(grooveEngineRootUrl) || [])[1];
