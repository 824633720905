import React from 'react';

import PropTypes from 'prop-types';
import TuneIcon from '@material-ui/icons/Tune';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

export const styles = theme => ({
  buttonStyles: {
    color: theme.palette.quaternary.main,
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 1,
  },
  root: {
    height: 45,
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    padding: '0 15px 0 6px',
    outline: 'none',
  },
  iconStyles: {
    color: '#888686',
  },
});

const LoaderFilterButton = ({ classes }) => {
  return (
    <div className={classes.root}>
      <TuneIcon className={classes.icon} />
      <Typography className={classes.button}>FILTER</Typography>
    </div>
  );
};

LoaderFilterButton.displayName = 'LoaderFilterButton';
LoaderFilterButton.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(LoaderFilterButton);
