import React from 'react';
import PropTypes from 'prop-types';
import { GROOVEY_BLUEY } from 'Modules/Shared/constants/colors';

const GrooveWordLogo = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 141 47"
    preserveAspectRatio="xMinYMin slice"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.3402708,1.35690407 C45.9415275,1.35690407 47.2241262,2.61446898 47.2241262,4.18480505 C47.2241262,5.75399753 45.9415275,7.01156245 44.3402708,7.01156245 C40.4446762,7.01156245
      36.5484987,8.73933237 36.5484987,13.0342654 L36.5484987,21.9876015 C36.5484987,23.7157527 35.1075425,25.1292267 33.345791,25.1292267 C31.5826793,25.1292267 30.0888726,23.7157527
      30.0888726,21.9876015 L30.0888726,4.76079189 C30.0888726,3.03302196 31.5826793,1.56656175 33.345791,1.56656175 C35.1075425,1.56656175 36.4952594,3.03302196 36.4952594,4.76079189
      C38.7905689,2.14292979 40.8181279,1.35690407 44.3402708,1.35690407 Z M113.757401,1.56656175 C115.35788,1.56656175 116.639896,2.87425392 116.639896,4.44706775 C116.639896,4.86562073
      116.533223,5.2315687 116.3737,5.59789785 L108.044678,23.4544429 C107.564359,24.4491734 106.550288,25.1292267 105.430127,25.1292267 C104.308218,25.1292267 103.293564,24.4491734
      102.813051,23.4544429 L94.4881096,5.59789785 C94.3274204,5.2315687 94.2201648,4.86562073 94.2201648,4.44706775 C94.2201648,2.87425392 95.5008204,1.56656175 97.1018828,1.56656175
      C98.2245695,1.56656175 99.291491,2.24737744 99.7723927,3.24344204 L105.430127,15.441898 L111.087668,3.24344204 C111.56857,2.24737744 112.635491,1.56656175 113.757401,1.56656175
      Z M128.756285,6.69802891 C131.58729,6.69802891 133.615237,8.47840386 134.148795,10.9388321 L134.148795,10.9388321 L123.418763,10.9388321 C124.006531,8.320017 125.928195,6.69802891
      128.756285,6.69802891 Z M8.79202258,33.9514315 L9.62986008,34.0032741 L9.63568919,34.0032741 L15.2703018,34.3493999 C18.4514418,34.5194132 20.1597598,35.1114103 20.1597598,36.4741852
      C20.1597598,37.9497941 19.061944,41.0637826 13.7663913,40.8747094 C6.65545923,40.6240732 8.03579263,34.6015609 8.79202258,33.9514315 Z M128.756285,1.35690407 C135.48269,1.35690407
      140.5,6.38506716 140.5,13.3470365 C140.5,14.5519964 139.43269,15.3913895 138.206439,15.3913895 L123.31481,15.3913895 C123.633857,18.1136993 125.928195,19.9987125 129.077275,19.9987125
      C131.320705,19.9987125 132.868917,19.2635764 134.094585,18.323357 C134.841877,17.7466077 135.643185,17.4325024 136.390088,17.4325024 C137.030513,17.4325024 137.671716,17.6936215
      138.206439,18.1659231 C138.845893,18.74191 139.218567,19.5279357 139.218567,20.3114836 C139.218567,20.8882329 138.951788,21.4649821 138.418813,21.9357589 C135.748692,24.3432009
      132.121236,25.3369784 128.811273,25.3369784 C122.084868,25.3369784 116.639896,19.9455357 116.639896,13.3470365 C116.639896,6.75101512 122.031434,1.35690407 128.756285,1.35690407
      Z M13.940876,7.37236426 C17.2988323,7.37236426 20.0286048,10.0508365 20.0286048,13.3457023 C20.0286048,16.6398058 17.2988323,19.3190404 13.940876,19.3190404 C10.5817538,19.3190404
      7.85042691,16.6398058 7.85042691,13.3466553 C7.85042691,10.0508365 10.5817538,7.37236426 13.940876,7.37236426 L13.940876,7.37236426 Z M13.940876,1.35690407 C16.7744067,1.35690407
      19.3804077,2.30589099 21.4534338,3.89471505 C22.0542208,2.81574036 23.2198486,2.08517863 24.5609327,2.08517863 C26.5154334,2.08517863 28.1013401,3.64007626 28.1013401,5.55863468
      C28.1013401,7.22255432 26.9046237,8.61144119 25.3101677,8.95165843 C25.8579098,10.3111932 26.1618007,11.7934731 26.1618007,13.3457023 C26.1618007,17.9402552 23.5256826,21.929088
      19.6572905,23.9431359 C19.3619489,24.0988544 19.0576693,24.24409 18.7392056,24.3746496 L18.7392056,24.3746496 L18.677417,24.4007615 C18.6218461,24.4226803 18.5656924,24.4425024
      18.5081785,24.4661366 C17.0967565,25.023826 15.5563169,25.3341194 13.940876,25.3341194 C13.1556948,25.3341194 12.3885838,25.2578802 11.6447893,25.1198874 C10.6105108,24.9719834
      9.67785309,24.7093395 8.85031369,24.3047002 C8.44421898,24.6449174 8.2133862,25.155148 8.2133862,25.7804997 C8.2133862,26.9145571 8.96359274,27.6506462 10.757793,27.7116375
      L10.757793,27.7116375 L16.8321149,27.9931506 C23.5429756,28.3343209 26.9376554,31.2493251 26.9376554,35.9593803 C26.9376554,41.0675945 23.5429756,46.6050352 13.7663913,46.6050352
      C5.01203864,46.6050352 0.617472142,41.4964398 0.522069031,36.9390535 L0.522069031,36.9390535 C0.517988654,36.9428655 0.135404693,32.5352891 2.89898604,30.2191435 C2.07086373,28.9434718
      1.94165177,27.2927034 1.94165177,27.2927034 C1.94165177,27.2927034 1.15569336,22.6872864 3.72477671,19.9249511 C2.45791667,18.0353636 1.71781392,15.7771597 1.71781392,13.3466553
      C1.71781392,6.7260468 7.19154311,1.35690407 13.940876,1.35690407 Z M80.9932679,0.648127416 L81.3708619,0.653407231 C83.5150031,0.711158394 85.6498177,1.18631895 87.5297059,2.19820318
      C90.4930314,3.7908392 92.7473428,6.52363183 93.7037056,9.70280474 C94.6897968,12.9858535 94.2510591,16.6007332 92.4994113,19.5612903 C90.8058604,22.4295981 87.9513449,24.5236971
      84.7012271,25.43952 C75.9439599,27.9039508 71.1670038,20.839821 71.1324177,20.5741275 L71.1324177,20.5741275 L74.8624656,14.3760746 C74.8624656,14.3760746 76.6589975,18.0576635
      78.6513875,19.1274895 C80.0488196,19.8773016 81.8436028,19.9219015 83.3477076,19.4240598 C86.6491216,18.3254536 87.7242039,14.5481845 87.0589081,11.4709814 C86.7361696,9.98222126
      85.934084,8.58361389 84.6283632,7.71925243 C83.3321633,6.86003712 81.7691845,6.65152302 80.2351569,6.92445921 C75.9748542,7.67636791 74.6378505,13.7591092 70.3950352,19.5990287
      C68.3968161,22.3520247 66.1728161,24.6574969 62.2000829,25.6026718 C58.5784565,26.4611248 54.5035195,25.5047045 51.5506864,23.3032988 C48.8430645,21.2844859 47.0193301,18.2366349
      46.5728202,14.9246153 C46.1156236,11.5466488 47.0954971,8.07681409 49.2600402,5.4126367 C51.3853339,2.79877715 54.5320822,1.01763981 57.9290935,0.723356659 C65.059456,0.10810665
      69.3154839,5.53557234 69.8167874,6.1826522 L69.8167874,6.1826522 L66.3677027,12.1483663 C64.4382671,8.59924292 59.7940203,5.64878749 56.3534849,7.52064943 C53.4088125,9.12300594
      52.7553692,13.1753078 53.8866053,16.0735394 C55.5756873,20.4073544 60.0073658,19.8748239 61.4318061,19.3203746 C64.6453948,18.0729113 65.3219602,16.0289395 66.5235342,13.9199738
      C69.8538994,8.07681409 72.8560857,0.418400025 81.3708619,0.653407231 Z"
      id="groove-word-logo-home"
      fill={GROOVEY_BLUEY}
      fillRule="evenodd"
    />
  </svg>
);

GrooveWordLogo.displayName = 'GrooveWordLogo';
GrooveWordLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

GrooveWordLogo.defaultProps = {
  width: 82.25,
  height: 27,
};

export default GrooveWordLogo;
