import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => {
  return {
    root: {
      height: 20,
      width: 20,
      fill: '#FFFFFF',
    },
  };
};

const SendosoIcon = ({ classes, className }) => {
  let selectedClass = className;
  if (!selectedClass) {
    selectedClass = classes.root;
  }

  return (
    <svg
      className={selectedClass}
      width="134"
      height="134"
      viewBox="0 0 134 134"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" fill="none" fillRule="evenodd">
        <path
          d="M133.8,29.6 L102,0.3 L98.9,16 L56.4,15.8 C35.7,15.7 13.7,28.3 13.6,51.8 C13.5,71.8 30.1,80.6 42.2,87.1 C48.9,90.7 54.8,93.8 54.8,97.3 C54.8,105.4 43.5,105.5 43,105.5 L6.3,105.4
    L0.5,133.5 L42.4,133.6 C66.3,133.7 85.1,117.8 85.2,97.4 C85.3,77.3 68.7,68.7 56.7,62.4 C49.8,58.8 43.8,55.7 43.8,51.9 C43.8,45.8 52,43.9 55.9,43.9 L93.4,44 L90.4,59.2 L133.8,29.6 Z"
          id="Path"
          fill="#FA8C24"
          fillRule="nonzero"
          className={selectedClass}
        />
      </g>
    </svg>
  );
};

SendosoIcon.defaultProps = {
  className: null,
};

SendosoIcon.displayName = 'SendosoIcon';
SendosoIcon.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default withStyles(styles)(SendosoIcon);
