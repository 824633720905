export const FONT_FORMATS = Object.freeze([
  'Arial=arial,helvetica,sans-serif',
  'Calibri=Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif',
  'Segoe UI="Segoe UI", Optima, Arial, sans-serif',
  'Courier New=courier new,courier,monospace',
  'Georgia=georgia,palatino',
  'Times New Roman=times new roman,times',
  'Verdana=verdana,geneva,sans-serif',
]);

export const FONT_SIZE_FORMATS = Object.freeze([
  '8px',
  '10px',
  '11px',
  '12px',
  '14px',
  '18px',
  '24px',
  '36px',
]);
