import { actions, selectors } from '@groove-labs/groove-ui';
import { actionTypes } from 'Modules/Shared/actions/errors';
import { location as locationSelector } from 'Modules/Shared/selectors/location';
import {
  actionChannel,
  take,
  call,
  all,
  fork,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { setRetryCount } from 'Modules/App/actions';
import { isUndefined } from 'lodash-es';
import {
  ERROR_DIALOG_UI_KEY_PATH,
  ERROR_TYPES,
} from 'Modules/Shared/constants';
import * as Sentry from '@sentry/browser';
import { contentLoading, getRetryCount } from 'Modules/App/selectors';
import { history } from 'Utils/history';
import { trackEvent } from 'Utils/segment';
import { ANALYTICS_BASE_PROPERTIES } from 'Modules/Shared/sagas/analytics';
import LogRocket from 'logrocket';
import { ErrorHandlingError } from 'Utils/errors';

const { getProperty } = selectors.ui;
const { setProperty } = actions.ui;

export const logErrorToSentry = error => {
  Sentry.withScope(scope => {
    scope.setExtra('sagaStack', error.sagaStack);
    Sentry.captureException(error);
  });
  if (window.newrelic) {
    window.newrelic.noticeError(error);
  }
};

export const logWarningToSentry = ({ error, type = null }) =>
  Sentry.withScope(scope => {
    if (error instanceof Error) {
      scope.setLevel('warning');
      if (type) scope.setTag('type', type);
      scope.setExtra('sagaStack', error.sagaStack);
      Sentry.captureException(error);
    }
  });

function* logErrorHandler({
  payload: {
    error,
    errorType = ERROR_TYPES.GENERIC,
    isUiError,
    logSilently = false,
  },
}) {
  if (isUndefined(error)) {
    return;
  }
  console.error(error);
  logErrorToSentry(error);

  const location = yield select(locationSelector);
  const isErrorDialogOpen = yield select(state =>
    getProperty(state, ERROR_DIALOG_UI_KEY_PATH)
  );
  const isOnErrorPage = location.get('routeName') === 'error';

  if (isErrorDialogOpen || isOnErrorPage) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    if (error.sagaStack) {
      console.error(
        error.sagaStack.reduce((agg, item) => {
          if (item.effect) {
            const location = item.effect['@@redux-saga/LOCATION'];
            return `${agg}\n\tat ${item.meta.name}$ (webpack:///./${location.fileName}:${location.lineNumber})`;
          }

          return agg;
        }, (error.stack || '').split('\n').splice(0, 2).join('\n') || error.message)
      );
    } else {
      console.error(error); // eslint-disable-line no-console
    }
  }

  const retryCount = yield select(getRetryCount);

  yield put(setRetryCount({ retryCount: retryCount + 1 }));

  const isContentLoading = yield select(contentLoading);

  const shouldRedirectToErrorPage = isContentLoading || isUiError;

  if (!logSilently) {
    if (shouldRedirectToErrorPage) {
      yield call(history.push, '/error');
    } else {
      yield put(
        setProperty({
          uiKeyPath: ERROR_DIALOG_UI_KEY_PATH,
          data: true,
        })
      );
    }
  }

  if (process.env.NODE_ENV === 'production') {
    if (LogRocket._isInitialized) {
      LogRocket.track(`A ${errorType} Error Occurred`);
    }
    trackEvent('Error Occurred', {
      ...ANALYTICS_BASE_PROPERTIES,
      type: shouldRedirectToErrorPage ? 'page' : 'modal',
      errorType,
      sentryEventId: Sentry.lastEventId(),
    });
  }
}

function* showReportDialog() {
  yield put(
    setProperty({
      uiKeyPath: ERROR_DIALOG_UI_KEY_PATH,
      data: false,
    })
  );
  Sentry.showReportDialog();
}

function* watchShowReportDialog() {
  yield takeEvery(actionTypes.SHOW_REPORT_DIALOG, showReportDialog);
}

function* watchLogError() {
  const logErrorChannel = yield actionChannel(actionTypes.LOG_ERROR);

  while (true) {
    const action = yield take(logErrorChannel);
    try {
      yield call(logErrorHandler, action);
    } catch (e) {
      Sentry.captureException(new ErrorHandlingError(e));
    }
  }
}

export default function* root() {
  yield all([fork(watchLogError), fork(watchShowReportDialog)]);
}
