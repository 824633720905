import { List } from 'immutable';
import { createSelector } from 'reselect';

const ROOT_KEY_PATH = ['PeopleImportDialog', 'csvLookup'];

export const getUploading = state =>
  state.getIn([...ROOT_KEY_PATH, 'uploading']);

export const getCsvRows = state => state.getIn([...ROOT_KEY_PATH, 'csvRows']);
export const getFoundValidRow = state =>
  state.getIn([...ROOT_KEY_PATH, 'foundValidRow']);
export const getErrorMessage = state =>
  state.getIn([...ROOT_KEY_PATH, 'error']);
export const getCsvHeader = state =>
  state.getIn([...ROOT_KEY_PATH, 'csvHeader']);
export const getKeyPrefixes = state =>
  state.getIn([...ROOT_KEY_PATH, 'keyPrefixes']);
export const getGroupId = state => state.getIn([...ROOT_KEY_PATH, 'groupId']);
export const getFetching = state => state.getIn([...ROOT_KEY_PATH, 'fetching']);
export const getInitialLookupByColumnIdx = state =>
  state.getIn([...ROOT_KEY_PATH, 'initialLookupByColumnIdx']);
export const getInitialSelectedObject = state =>
  state.getIn([...ROOT_KEY_PATH, 'initialSelectedObject']);
export const getAvailableObjectFields = state =>
  state.getIn([...ROOT_KEY_PATH, 'availableObjectFields']);
export const getMandatoryObjectFields = state =>
  state.getIn([...ROOT_KEY_PATH, 'mandatoryObjectFields']);
export const getSelectedObjectField = state =>
  state.getIn([...ROOT_KEY_PATH, 'selectedObjectField']);
export const getRelationshipName = state =>
  state.getIn([...ROOT_KEY_PATH, 'relationshipName']);
export const getUploadErrorMessage = state =>
  state.getIn([...ROOT_KEY_PATH, 'uploadErrorMessage']);

export const getLookupColumnOptions = createSelector(
  getCsvHeader,
  csvHeader => {
    if (!csvHeader) return null;
    return csvHeader.reduce((options, label, idx) => {
      return options.push({
        value: idx,
        label,
      });
    }, new List());
  }
);
