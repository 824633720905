import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Typography, Collapse, IconButton } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import EmailEvent from 'Modules/Emails/components/EmailEvent';
import EmailEventTimeDetails from 'Modules/Emails/components/EmailEventTimeDetails';
import EmailRecord from 'Modules/Emails/data/Email';
import { markEmailAsRead, setIsEmailMuted } from 'Modules/Emails/actions';
import EmailRecipients from 'Modules/Emails/components/EmailRecipients';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
  engagementText: {
    display: 'inline-block',
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: 14,
    paddingBottom: theme.spacing.unit,
  },
  showHistoryButton: {
    cursor: 'pointer',
    fontSize: 12,
  },
  primaryRecipient: {
    position: 'relative',
  },
  unreadDot: {
    background: 'rgb(238, 99, 82)',
    height: theme.spacing.unit,
    width: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    position: 'absolute',
    top: theme.spacing.unit,
    left: -2 * theme.spacing.unit,
  },
  actionsDot: {
    background: '#9b9b9b',
    height: 3,
    width: 3,
    borderRadius: 3,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
});

@withStyles(styles)
@connect(() => ({}), {
  onToggleNotificationsClick: setIsEmailMuted,
  markEmailAsRead,
})
class Email extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      actions: PropTypes.string.isRequired,
      engagementText: PropTypes.string.isRequired,
      primaryRecipient: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
      showHistoryButton: PropTypes.string.isRequired,
      unreadDot: PropTypes.string.isRequired,
    }).isRequired,
    email: PropTypes.instanceOf(EmailRecord).isRequired,
    onToggleNotificationsClick: PropTypes.func.isRequired,
    markEmailAsRead: PropTypes.func.isRequired,
  };
  state = {
    showHistory: false,
  };

  handleToggleShowRecipients = () =>
    this.setState({ showRecipients: !this.state.showRecipients });
  handleToggleHistory = () => {
    const { email, markEmailAsRead } = this.props;

    const newValue = !this.state.showHistory;
    if (newValue && email.isUnread()) {
      markEmailAsRead(email.id);
    }
    this.setState({ showHistory: newValue });
  };
  handleToggleMuteNotifications = () => {
    const { email, onToggleNotificationsClick } = this.props;

    onToggleNotificationsClick({ id: email.id, value: !email.mutedAt });
  };
  render() {
    const { classes, email } = this.props;
    const { showHistory, showRecipients } = this.state;

    const emailOpensCount = email.getOpensCount();
    const nonEmailOpensCount = email.events.size - emailOpensCount;
    return (
      <div className={classes.root}>
        <Typography variant="body2" className={classes.primaryRecipient}>
          {email.isUnread() && <div className={classes.unreadDot} />}
          {email.primaryRecipient}
          {email.recipients && email.recipients.hasMultiple() && (
            <IconButton onClick={this.handleToggleShowRecipients}>
              {showRecipients && <KeyboardArrowUp />}
              {!showRecipients && <KeyboardArrowDown />}
            </IconButton>
          )}
        </Typography>
        {email.recipients && (
          <Collapse in={showRecipients}>
            <EmailRecipients recipients={email.recipients} />
          </Collapse>
        )}
        <Typography variant="body2" color="primary">
          {email.subject}
        </Typography>
        {!email.events.isEmpty() && (
          <Fragment>
            <div>
              <Typography variant="caption" className={classes.engagementText}>
                <EmailEventTimeDetails emailEvent={email.events.first()} />
                {' • '}
                {emailOpensCount} Views
                {nonEmailOpensCount > 0 && ` + ${nonEmailOpensCount} more`}
              </Typography>
            </div>
            <div className={classes.actions}>
              <Typography
                color="primary"
                variant="body2"
                className={classes.showHistoryButton}
                onClick={this.handleToggleHistory}
              >
                {showHistory ? 'Hide ' : 'Show '}
                History ({email.events.size})
              </Typography>
              <div className={classes.actionsDot} />
              <Typography
                color="primary"
                variant="body2"
                className={classes.showHistoryButton}
                onClick={this.handleToggleMuteNotifications}
              >
                {email.mutedAt ? 'Unmute ' : 'Mute '}
                Notifications
              </Typography>
            </div>
          </Fragment>
        )}
        <Collapse in={showHistory}>
          {email.events.map(emailEvent => (
            <EmailEvent key={emailEvent.id} emailEvent={emailEvent} />
          ))}
        </Collapse>
      </div>
    );
  }
}

export default Email;
