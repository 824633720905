import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { RIT } from '@groove-labs/groove-ui';
import { isEmpty } from 'lodash-es';
import { Person as PersonIcon } from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import PersonSearchFlowsList from 'Modules/App/components/PersonSearchFlowsList';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    outline: 'none',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: grey[300],
    },
  },
  personIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingLeft: 10,
  },
  personContainer: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 43,
    width: '100%',
  },
  personNameContainer: {
    marginLeft: 16,
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    marginRight: 45,
    width: '100%',
  },
  personCaption: {
    marginTop: '-5px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  removeFromAllFlowContainer: {
    whiteSpace: 'initial', // Need this because parent MenuItem's classes make this `nowrap`
    textAlign: 'center',
    lineHeight: 0.8,
    maxWidth: '80px',
    paddingRight: '10px',
    outline: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  nameContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  removeFromFlowText: {
    fontSize: 10,
  },
  personSearchItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleAndCompany: {
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  email: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
};

const generateParts = (suggestionValue, query) => {
  const matches = match(suggestionValue || '', query);
  return parse(suggestionValue || '', matches);
};

const PersonSearchItem = ({
  classes,
  person,
  query,
  renderHighlightedSuggestionValue,
  onRemoveFromAllFlowsClick,
  onRemoveFromFlowClick,
  onPersonClick,
  currentUserId,
}) => {
  const { name, email = '', companyName = '', title = '' } = person;

  const nameComponent = renderHighlightedSuggestionValue(
    generateParts(name, query)
  );
  const emailComponent = renderHighlightedSuggestionValue(
    generateParts(email, query),
    classes.email
  );
  const nameAndEmailSeperator = !isEmpty(name) && !isEmpty(email) ? ' · ' : '';

  const titleComponent = renderHighlightedSuggestionValue(
    generateParts(title, query),
    classes.titleAndCompany
  );
  const companyNameComponent = renderHighlightedSuggestionValue(
    generateParts(companyName, query),
    classes.titleAndCompany
  );
  const companyNameAndTitleSeperator =
    !isEmpty(companyName) && !isEmpty(title) ? ' · ' : '';
  const activeFlows = person.flows.filter(flow => !flow.disabledAt);

  const captionLine =
    `${titleComponent} ${companyNameAndTitleSeperator} ${companyNameComponent}`.trim();
  return (
    <div className={classes.personSearchItemContainer}>
      <div
        role="button"
        tabIndex="-1"
        className={classes.root}
        onClick={() => onPersonClick(person)}
      >
        <div className={classes.personContainer}>
          <PersonIcon classes={{ root: classes.personIcon }} />
          <div className={classes.personNameContainer}>
            <div className={classes.nameContainer}>
              {nameComponent} {nameAndEmailSeperator} {emailComponent}
            </div>
            {RIT(
              !isEmpty(captionLine),
              <div className={classes.personCaption}>
                {titleComponent} {companyNameAndTitleSeperator}{' '}
                {companyNameComponent}
              </div>
            )}
          </div>
        </div>

        {RIT(activeFlows.length > 0, () => (
          <div
            role="link"
            tabIndex="0"
            className={classes.removeFromAllFlowContainer}
            onClick={onRemoveFromAllFlowsClick}
          >
            <a className={classes.removeFromFlowText}>
              Remove from all of my flows
            </a>
          </div>
        ))}
      </div>
      {RIT(
        person.flows && person.flows.length > 0,
        <PersonSearchFlowsList
          flows={person.flows}
          currentUserId={currentUserId}
          onRemoveFromFlowClick={onRemoveFromFlowClick}
        />
      )}
      <Divider light />
    </div>
  );
};

PersonSearchItem.displayName = 'PersonSearchItem';
PersonSearchItem.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  person: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
      PropTypes.shape({
        createdAt: PropTypes.string,
        type: PropTypes.string,
      }),
    ])
  ).isRequired,
  query: PropTypes.string.isRequired,
  renderHighlightedSuggestionValue: PropTypes.func.isRequired,
  onRemoveFromAllFlowsClick: PropTypes.func.isRequired,
  onRemoveFromFlowClick: PropTypes.func.isRequired,
  onPersonClick: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
};

PersonSearchItem.defaultProps = {
  currentUserId: 0,
};

export default withStyles(styles)(PersonSearchItem);
