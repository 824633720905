import humps from 'humps';
import makeHTTPRequest from '../makeHTTPRequest';
import { POST, DELETE } from '../HTTPMethod';

const BASE_URL = '/api/v2/label_instances';

export const batchCreate = ({ labelIds, labelable, labelOwner }) =>
  makeHTTPRequest({
    path: `${BASE_URL}/batch_create`,
    method: POST,
    body: humps.decamelizeKeys({
      labelIds,
      labelableId: labelable.id,
      labelableType: labelable.type,
      labelOwnerType: labelOwner.type,
      labelOwnerId: labelOwner.id,
    }),
  });

export const batchDestroy = ({ labelInstanceIds }) =>
  makeHTTPRequest({
    path: `${BASE_URL}/batch_destroy`,
    method: DELETE,
    queryParameters: humps.decamelizeKeys({
      labelInstanceIds,
    }),
  });
