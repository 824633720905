import cx from 'classnames';
import { RIT } from '@groove-labs/groove-ui';
import { Map } from 'immutable';
import { withStyles } from '@material-ui/core/styles';
import SidebarNav, {
  SIDE_BAR_NAV_WIDTH,
} from 'Modules/App/components/SidebarNav';
import SearchRemoveFromFlowDialog from 'Modules/App/containers/SearchRemoveFromFlowDialog';
import Snackbar from 'Modules/App/containers/Snackbar';
import SnackbarWarning from 'Modules/App/containers/SnackbarWarning';
import ErrorDialog from 'Modules/Shared/containers/ErrorDialog';
import { MoboBar } from 'Modules/App/containers/MoboBar';
import TopNav from 'Modules/App/containers/TopNav';
import { inLoadingState } from 'Modules/App/selectors';
import { logError } from 'Modules/Shared/actions/errors';
import { location } from 'Modules/Shared/selectors/location';
import {
  omnibarOpen,
  shouldShowOmnibar,
} from 'Modules/Shared/selectors/omnibar';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { OMNIBAR_WIDTH } from 'Modules/App/constants';
import { ROUTES } from 'Routing/routesSaga';
import { ROUTE_NAMES } from 'Routing/constants';
import { hot } from 'react-hot-loader';

const styles = theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    textRendering: 'geometricPrecision',
  },
  '@global body': {
    margin: 0,
    padding: 0,
    fontFamily: '"Open Sans", "Arial", sans-serif',
    WebkitFontSmoothing: 'antialiased',
    overflow: 'hidden',
    backgroundColor: theme.palette.text.backgroundColor,
  },
  '@global a': {
    textDecoration: 'none',
    color: theme.palette.quaternary.main,
  },
  // sentry user feedback modal
  '@global .sentry-error-embed-wrapper': {
    paddingTop: '10%',
  },
  container: {
    flexGrow: 1,
    transition: 'width 0.3s',
    maxHeight: '100vh',
    overflow: 'auto',
  },
  containerWithOmnibarOpen: {
    width: `calc(100% - ${OMNIBAR_WIDTH}px)`,
  },
  topNav: {
    transition: 'width 0.3s',
  },
  topNavWithOmnibarOpen: {
    width: `calc(100% - ${OMNIBAR_WIDTH}px)`,
  },
  moduleRootTransitionBegin: {
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  moduleRootActive: {
    opacity: 1,
    height: '100%',
  },
  omnibarHidden: {
    visibility: 'hidden',
  },
  sidebarOffset: {
    // Compensate for the fixed position of the sidebar
    marginLeft: SIDE_BAR_NAV_WIDTH,
  },
  positionRelation: {
    height: '100%',
  },
});

@withStyles(styles)
@connect(
  state => {
    return {
      location: location(state),
      inLoadingState: inLoadingState(state),
      omnibarOpen: omnibarOpen(state),
      showOmnibar: shouldShowOmnibar(state),
    };
  },
  {
    logError,
  }
)
class AppModuleRoot extends Component {
  static propTypes = {
    location: PropTypes.instanceOf(Map).isRequired,
    logError: PropTypes.func.isRequired,
    inLoadingState: PropTypes.bool.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    omnibarOpen: PropTypes.bool.isRequired,
    showOmnibar: PropTypes.bool.isRequired,
  };

  componentDidCatch(error) {
    const { logError } = this.props;

    logError({ error, isUiError: true });
  }

  // Renders the correct module root based off of browser location
  renderActiveModule = route => {
    const { classes, inLoadingState } = this.props;

    // Route does not have anything to render
    if (!route.moduleRoot) return null;

    return (
      <div
        className={cx({
          [classes.moduleRootTransitionBegin]:
            !route.disableModuleRootTransition && true,
          [classes.moduleRootActive]: !inLoadingState,
        })}
      >
        {RIT(!inLoadingState, () => (
          <route.moduleRoot />
        ))}
      </div>
    );
  };

  render() {
    const { classes, location, inLoadingState, omnibarOpen, showOmnibar } =
      this.props;

    const rootPath = location.get('rootPath');

    const routeName = location.get('routeName');
    const previousRouteName = location.get('previousRouteNames').last();
    const route = ROUTES.find(route => route.routeName === routeName);
    const previousRoute = ROUTES.find(
      route => route.routeName === previousRouteName
    );

    if (!route) return null;

    const hideLayout =
      route.hideLayout ||
      (routeName === ROUTE_NAMES.ERROR && previousRoute?.hideLayout);
    const { loadingComponent: Loader } = route;

    if (rootPath !== 'login') {
      return (
        <div className={classes.root}>
          {RIT(
            !hideLayout,
            <TopNav
              classes={{
                root: cx({
                  [classes.topNav]: true,
                  [classes.topNavWithOmnibarOpen]: omnibarOpen,
                }),
              }}
            />
          )}
          <div className={classes.paginationContainer}>
            <MoboBar />
          </div>
          <SearchRemoveFromFlowDialog />
          <div
            name="AppModuleRoot"
            className={cx({
              [classes.container]: true,
              [classes.containerWithOmnibarOpen]: omnibarOpen,
            })}
          >
            {RIT(!hideLayout, <SidebarNav rootPath={rootPath} />)}
            <div
              className={cx(
                {
                  [classes.sidebarOffset]: !hideLayout,
                },
                classes.positionRelation
              )}
              id="app-root"
            >
              {RIT(
                (inLoadingState || route.loading) && rootPath !== 'login',
                <Loader />
              )}
              {RIT(!route.loading, () => this.renderActiveModule(route))}
            </div>
          </div>
          <SnackbarWarning />
          <Snackbar />
          <ErrorDialog />
          <div
            id="omnibar-root"
            className={cx({
              [classes.omnibarHidden]: inLoadingState || !showOmnibar,
            })}
          />
        </div>
      );
    }

    return RIT(!inLoadingState, () => this.renderActiveModule(route));
  }
}

export default hot(module)(AppModuleRoot);
