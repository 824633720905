import PropTypes from 'prop-types';
import humps from 'humps';
import shortid from 'shortid';
import { isNull, merge, values } from 'lodash-es';
import moment from 'moment';
import { isLead, isContact } from 'Utils/salesforce-object-identifier';
import { SALESFORCE_OBJECTS } from 'Modules/Shared/constants';
import { COLUMN_TYPES } from 'Modules/Shared/components/Table/constants';
import { CUSTOM_MERGE_FIELD_REGEXP } from 'Modules/PeopleImportDialog/constants';
import { FIELD_TYPES } from 'Modules/Shared/constants/salesforce';
import { getLastActiveDuplicate } from 'Modules/PeopleImportDialog/utils/getLastActiveDuplicate';

export default class SearchDataParser {
  static standardFields = [
    'id',
    'type',
    'name',
    'email',
    'company',
    'phone',
    'title',
    'accountId',
    'campaignId',
    'opportunityId',
  ];

  static isNestedPath(columnId) {
    return columnId.includes('.');
  }

  static castAttributeValue(data, valueType) {
    switch (valueType) {
      case COLUMN_TYPES.dateTime:
        return moment(data).format('YYYY-MM-DD');
      case COLUMN_TYPES.boolean:
        return data ? 'Yes' : 'No';
      default:
        return data;
    }
  }

  constructor(
    searchData,
    peopleSfdcIdsAlreadyInFlow = [],
    salesforceObject = null,
    contactChildFieldName = null,
    sfdcWhatIdMap = null,
    orgOneFlowRestrictionEnabled = false
  ) {
    this.data = humps.camelizeKeys(searchData, (key, convert) =>
      CUSTOM_MERGE_FIELD_REGEXP.test(key) ? key : convert(key)
    );
    this.peopleSfdcIdsAlreadyInFlow = peopleSfdcIdsAlreadyInFlow;
    this.sfdcWhatIdMap = sfdcWhatIdMap;
    this.columnData = this.data.columnData || [];
    this.peopleData = this.data.peopleResults || [];
    this.topLevelResults = this.data.results || [];
    this.importRuleViolationHash = this.data.importRuleViolations || {};
    this._accounts = null;
    this._campaigns = null;
    this._opportunities = null;
    this._customObjects = null;

    this._columns = null;
    this._rows = null;
    this._salesforceObject = salesforceObject;
    this._contactChildFieldName = contactChildFieldName;

    // Calculate duplicated email addresses in search results and cache.
    this.emailCounts = {};
    this.lastActiveDuplicate = {};

    this.peopleData.forEach(person => {
      const personEmail =
        person.email ||
        (person.lead ? person.lead.email : null) ||
        (person.contact ? person.contact.email : null);

      if (personEmail) {
        this.emailCounts[personEmail] =
          (this.emailCounts[personEmail] || 0) + 1;
        this.lastActiveDuplicate[personEmail] = getLastActiveDuplicate(
          this.lastActiveDuplicate[personEmail],
          person,
          personEmail
        );
      }
    });

    this.columnIds = this.columns().map(c => c.id);
    this.orgOneFlowRestrictionEnabled = orgOneFlowRestrictionEnabled;
  }

  optedOutOfEmail = row => {
    return row.hasOptedOutOfEmail || false;
  };

  inAnotherFlow = row => {
    return (
      this.data.intersectionIds.some(id => id === row.id) ||
      this.data.intersectionEmails.some(email => email === row.email)
    );
  };

  emailDuplicatedInResults = row => {
    return this.emailCounts[row.email] > 1;
  };

  isDuplicateAndLastActivePerson = row => {
    return this.lastActiveDuplicate[row.email]?.person_id === row.id;
  };

  inThisFlow = row => {
    return this.peopleSfdcIdsAlreadyInFlow.includes(row.id);
  };

  violatesImportRule = row => {
    return !!this.importRuleViolationHash[row.id];
  };

  buildImportRuleViolationText = row => {
    if (!this.violatesImportRule(row)) return null;

    const [violatedRule] = Object.values(this.importRuleViolationHash[row.id]);

    return `Import blocked - This person cannot be added due to the following rule: ${violatedRule.violatedRuleName}`;
  };

  oneFlowRestricted = row =>
    this.inAnotherFlow(row) && this.orgOneFlowRestrictionEnabled;

  checkboxDisabled = row => {
    if (isContact(row.id) || isLead(row.id)) {
      return this.oneFlowRestricted(row) || this.violatesImportRule(row);
    }
    return false;
  };

  shouldRowBeSelectedByDefault = row => {
    if (isContact(row.id) || isLead(row.id)) {
      return (
        values(row.warnings).every(value => value === false) ||
        (row.warnings.emailDuplicatedInResults &&
          row.isDuplicateAndLastActivePerson &&
          !this.oneFlowRestricted(row) &&
          !this.violatesImportRule(row))
      );
    }

    // var contactRecords = this.getRelatedContacts(record, passedOpptyData)
    // if(contactRecords && contactRecords.length > 0){
    //   contactRecords.forEach(function(contactRecord){
    //     if(this.contactShouldBeChecked(contactRecord, intersectionData)){
    //       importData = this.buildImportDataFromRecordData(contactRecord);
    //       checkedContacts[importData.sfdc_id] = importData;
    //     }
    //   }.bind(this));
    // }

    return false;
  };

  columns() {
    if (!isNull(this._columns)) return this._columns;

    this._columns = this.columnData
      .filter(column => !column.hidden)
      .map(column => {
        const transformedColumn = {
          id: CUSTOM_MERGE_FIELD_REGEXP.test(column.fieldNameOrPath)
            ? column.fieldNameOrPath
            : humps.camelize(column.fieldNameOrPath),
          label: column.label,
          sortable: column.sortable,
        };

        switch (column.type) {
          case FIELD_TYPES.DATE: {
            transformedColumn.type = COLUMN_TYPES.date;
            break;
          }
          case FIELD_TYPES.DATE_TIME: {
            transformedColumn.type = COLUMN_TYPES.dateTime;
            break;
          }
          case FIELD_TYPES.BOOLEAN: {
            transformedColumn.type = COLUMN_TYPES.boolean;
            break;
          }
          // TODO map more salesforce column types
          default: {
            transformedColumn.type = COLUMN_TYPES.string;
          }
        }

        return transformedColumn;
      });

    return this._columns;
  }

  transformedRowData = (row, objectId = null) => {
    const personRow = {
      id: row.id,
      reactKey: shortid.generate(),
      type: row.attributes.type,
      warnings: {
        optedOutOfEmail: this.optedOutOfEmail(row),
        inAnotherFlow: this.inAnotherFlow(row),
        emailDuplicatedInResults: this.emailDuplicatedInResults(row),
        inThisFlow: this.inThisFlow(row),
        violatesImportRule: this.violatesImportRule(row),
      },
      isDuplicateAndLastActivePerson: this.isDuplicateAndLastActivePerson(row),
      checkboxDisabled: this.checkboxDisabled(row),
      importRuleViolationText: this.buildImportRuleViolationText(row),
    };
    // Fallback for ID when nested Contact from Account/Opportunity/Campaign query
    // TODO perhaps more, like leadId?
    if (!personRow.id) {
      personRow.id = row.contactId;
    }

    if (row.name) personRow.name = row.name;
    if (row.email) personRow.email = row.email;
    if (row.phone) personRow.phone = row.phone;
    if (row.title) personRow.title = row.title;
    if (row.company) personRow.company = row.company;
    if (row.accountId) personRow.accountId = row.accountId;
    if (row.campaignId) personRow.campaignId = row.campaignId;
    if (row.opportunityId) personRow.opportunityId = row.opportunityId;

    // Cover the case where Contact does not have a Company column, whereas Lead does.
    if (
      !personRow.company &&
      row.attributes.type === SALESFORCE_OBJECTS.Contact &&
      row.account
    ) {
      personRow.company = row.account.name;
    }

    // For custom objects and in contact-related searches, add a 'whatId' to the personRow
    if (
      !['campaign', 'opportunity', 'account'].includes(this._salesforceObject)
    ) {
      personRow.whatId = objectId;
    }

    if (this.sfdcWhatIdMap && this.sfdcWhatIdMap.get(personRow.id)) {
      personRow.whatId = this.sfdcWhatIdMap.get(personRow.id);
    }

    // Add the other non-standard field values to the personRow.
    return this._columns.reduce((personRowMap, column) => {
      const columnId = column.id;
      const columnType = column.type;
      if (!this.constructor.standardFields.includes(columnId)) {
        let rowValue;
        // For nested fields, fetch the value on the path.
        if (this.constructor.isNestedPath(columnId)) {
          rowValue = columnId.split('.').reduce((prev, curr) => {
            return prev ? prev[humps.camelize(curr)] : undefined;
          }, row);
        } else {
          rowValue = row[columnId];
        }
        personRowMap[columnId] = this.constructor.castAttributeValue(
          rowValue,
          columnType
        );
      }

      return personRowMap;
    }, personRow);
  };

  isRowContactOrLead = row => {
    const sfdcId = row.id;

    if (!sfdcId) return false;

    return isContact(sfdcId) || isLead(sfdcId);
  };

  peopleForCampaign = campaignId => {
    return this.peopleData
      .filter(data => {
        return campaignId === data.campaignId;
      })
      .map(campaignMember => {
        return merge(campaignMember.contact || campaignMember.lead, {
          id: campaignMember.contactId || campaignMember.leadId,
          campaignId,
        });
      });
  };

  peopleForOpportunity = opportunityId => {
    return this.data.opportunityContacts[opportunityId]
      .filter(opportunity => opportunity)
      .map(opportunity => {
        return merge(opportunity, {
          opportunityId,
        });
      });
  };

  rows(limit = null) {
    let tempRowCount = 0;
    if (!isNull(this._rows)) return this._rows;

    this._rows = [];
    this.data.results.forEach(row => {
      if (this._salesforceObject === SALESFORCE_OBJECTS.Account.toLowerCase()) {
        const nestedContacts = row.contacts;

        // Contacts can be null if there are none. Skip iteration.
        if (!nestedContacts) return;

        nestedContacts.forEach(contact => {
          if (limit === null || tempRowCount < limit) {
            this._rows.push(this.transformedRowData(contact));
            tempRowCount += 1;
          }
        });
      } else if (
        this._salesforceObject === SALESFORCE_OBJECTS.Campaign.toLowerCase()
      ) {
        // Push nested contact as the person
        const campaignId = row.id;
        const campaignMembers = this.peopleForCampaign(campaignId);

        campaignMembers.forEach(contact => {
          if (limit === null || tempRowCount < limit) {
            this._rows.push(this.transformedRowData(contact));
            tempRowCount += 1;
          }
        });
      } else if (
        this._salesforceObject === SALESFORCE_OBJECTS.Opportunity.toLowerCase()
      ) {
        const opportunityId = row.id;

        // if there are no contacts for the opportunity, skip iteration
        if (!this.data.opportunityContacts[opportunityId]) return;
        const opportunityContacts = this.peopleForOpportunity(opportunityId);

        opportunityContacts.forEach(contact => {
          if (limit === null || tempRowCount < limit) {
            this._rows.push(this.transformedRowData(contact));
            tempRowCount += 1;
          }
        });
      } else if (this.isRowContactOrLead(row)) {
        if (limit === null || tempRowCount < limit) {
          this._rows.push(this.transformedRowData(row));
          tempRowCount += 1;
        }
      } else {
        // In this case, the row is contact-related
        const whoObject = row[this._contactChildFieldName];

        if (!whoObject) return;
        if (limit === null || tempRowCount < limit) {
          this._rows.push(this.transformedRowData(whoObject, row.id));
          tempRowCount += 1;
        }
      }
    });

    return this._rows;
  }

  /**
   * @return {Array.<string>} Row IDs that should be selected by default.
   * */
  selectedRows() {
    return this.rows()
      .filter(this.shouldRowBeSelectedByDefault)
      .map(row => row.id);
  }

  accounts() {
    if (this._accounts) return this._accounts;

    this._accounts = this.data.results
      .filter(
        data =>
          data.attributes && data.attributes.type === SALESFORCE_OBJECTS.Account
      )
      .map(data => {
        const { contacts, ...neededFields } = data; // flex my JS muscles and delete contacts using destructuring :)
        return neededFields;
      });

    return this._accounts;
  }

  campaigns() {
    if (this._campaigns) return this._campaigns;

    this._campaigns = this.data.results
      .filter(
        data =>
          data.attributes &&
          data.attributes.type === SALESFORCE_OBJECTS.Campaign
      )
      .map(data => ({
        id: data.id,
        name: data.name,
      }));

    return this._campaigns;
  }

  opportunities() {
    if (this._opportunities) return this._opportunities;

    this._opportunities = this.data.results.filter(
      data =>
        data.attributes &&
        data.attributes.type === SALESFORCE_OBJECTS.Opportunity
    );

    return this._opportunities;
  }

  customObjects() {
    if (this._customObjects) return this._customObjects;
    const standardWhatObjects = [
      SALESFORCE_OBJECTS.ACCOUNT,
      SALESFORCE_OBJECTS.CAMPAIGN,
      SALESFORCE_OBJECTS.OPPORTUNITY,
    ];

    this._customObjects = this.data.results
      .filter(
        data =>
          !standardWhatObjects.includes(data.attributes && data.attributes.type)
      )
      .map(data => ({
        id: data.id,
        name: data.name,
      }));

    return this._customObjects;
  }
}

export const columnShape = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  sortable: PropTypes.bool.isRequired,
};

export const rowShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  phone: PropTypes.string,
  title: PropTypes.string,
  optedOutOfEmail: PropTypes.bool,
};
