import {
  WAVY_NAVY,
  WHITE,
  GROOVEY_BLUEY_1,
  GROOVEY_BLUEY_2,
} from 'Modules/Shared/constants/colors';

const secondaryButtonStyles = {
  fontWeight: 600,
  color: `${WAVY_NAVY} !important`,
  backgroundColor: WHITE,
  '&:hover': {
    backgroundColor: `${GROOVEY_BLUEY_2} !important`,
    color: WAVY_NAVY,
  },
  '&:active': {
    background: GROOVEY_BLUEY_1,
    color: WAVY_NAVY,
  },
  '&:disabled': {
    background: `${WHITE} !important`,
    color: WAVY_NAVY,
  },
};

export default secondaryButtonStyles;
