import { selectors, listToOrderedMap } from '@groove-labs/groove-ui';
import { createSelector } from 'reselect';
import { List, Map, OrderedMap, Set } from 'immutable';
import Step from 'Modules/Shared/records/Step';
import StepVariant from 'Modules/Shared/data/StepVariant';
import { currentUser, getUserId } from 'Modules/Shared/selectors/users';
import toNumber from 'lodash-es/toNumber';
import { sortPeople } from 'Modules/FlowsShow/utils';
import {
  ADD_EDIT_GROUP_ID_FALLBACK_VALUE,
  DESC,
} from 'Modules/Shared/constants';

import { NEW_AUTOMATED_ACTION_ID } from 'Modules/Shared/constants/automatedActions';
import {
  ADD_VARIANT_DIALOG_FORM_GROUP_ID,
  ALL_STEPS_SELECTED,
  DEFAULT_ADDRESS_FIELD_NAMES,
  EMAIL_ACTIVITY_TYPES,
  TEMPLATE_STAT_TABS,
  STAGED_PEOPLE_QUEUE_STEP,
} from 'Modules/FlowsShow/constants/index.ts';
import { COLUMNS } from 'Modules/FlowsShow/components/VariantStatisticsDialog/Table/constants';
import { FIELD_ID as LINK_TRACKING_FIELD_ID } from 'Modules/FlowsShow/components/TemplateDialog/LinkTrackingCheckbox';
import { FIELD_ID as SELECTED_TEMPLATE_FIELD_ID } from 'Modules/FlowsShow/components/TemplateDialog/TemplateSelector';

const { getProperty } = selectors.ui;
const { makeGetFieldValue, makeIsFieldDirty, makeIsFieldValid } =
  selectors.form;

export const getFlow = state => state.getIn(['flowsShow', 'flow']);
export const getFlowId = state => state.getIn(['flowsShow', 'flow', 'id']);
export const getFlowName = state => state.getIn(['flowsShow', 'flow', 'name']);
export const getIsMasterFlow = state =>
  state.getIn(['flowsShow', 'flow', 'isMasterFlow']);
export const getLabelInstances = state =>
  state.getIn(['flowsShow', 'flow', 'labelInstances']);
export const customTagsSelector = state =>
  state.getIn(['flowsShow', 'flow', 'taskFieldValues'], Map());
export const getHasFlowImportThrottlingEnabled = state =>
  state.getIn(['flowsShow', 'flow', 'hasFlowImportThrottlingEnabled']);

export const getCustomTags = createSelector(customTagsSelector, customTags => {
  let results = Map();
  customTags.keySeq().forEach(taskField => {
    let taskFieldValue = Map();
    customTags
      .getIn([taskField, 'field_value'], '')
      .split(';')
      .forEach(
        value =>
          (taskFieldValue = taskFieldValue.set(
            value,
            customTags.getIn([taskField, 'field_name'])
          ))
      );
    results = results.set(taskField, taskFieldValue);
  });
  return results;
});

export const getCustomTagIds = createSelector(
  customTagsSelector,
  customTags => {
    let customTagSet = Set();
    customTags.forEach(taskField => {
      const taskFieldName = taskField.get('field_name');
      if (taskField.has('field_value')) {
        taskField
          .get('field_value')
          .split(';')
          .forEach(value => {
            customTagSet = customTagSet.add(`${taskFieldName}${value}`);
          });
      }
    });
    return customTagSet;
  }
);

export const getFlowAllowToViewAllActivities = state =>
  state.getIn(['flowsShow', 'flow', 'allowToViewAllActivities']);
export const getShowStatsScope = state =>
  state.getIn(['flowsShow', 'showStatsScope']);
export const getShowAllStats = createSelector(
  getShowStatsScope,
  scope => scope === ALL_STEPS_SELECTED
);

export const getSelectedStepFilter = state =>
  state.getIn(['ui', 'selectedStatFilter']);
export const hasStatisticsFilter = state => {
  const filters = TEMPLATE_STAT_TABS.stats.map(stat => stat.value);
  return filters.includes(getSelectedStepFilter(state));
};
export const getAutomatedActions = state =>
  state.getIn(['flowsShow', 'automatedActions']);
export const getRemoveOnMoveOption = state =>
  state.getIn(['flowsShow', 'removeOnMoveOption']);
export const getSelectedStepId = state => state.getIn(['ui', 'selectedStep']);
export const getPeople = state => state.getIn(['flowsShow', 'people', 'byId']);
export const fetchPeopleInProgress = state =>
  state.getIn(['flowsShow', 'people', 'isFetchingStepPeople']);
export const getHasPeopleFailure = state =>
  state.getIn(['flowsShow', 'people', 'hasFailure']);
export const getPeopleInFlow = (state, props) =>
  state.getIn(['flows', 'flows', props.flowId, 'people']) || 0;
export const getPeopleByStepId = state =>
  state.getIn(['flowsShow', 'people', 'byStepId']);

// Stats People Table
export const getSelectedPeople = state =>
  state.getIn(['flowsShow', 'peopleStatsTable', 'selectedPeople']);
export const getPeopleTableOrderBy = state =>
  state.getIn(['flowsShow', 'peopleStatsTable', 'orderBy']);
export const getPeopleTableOrderDirection = state =>
  state.getIn(['flowsShow', 'peopleStatsTable', 'orderDirection']);
export const getIsMovingPeopleToStep = state =>
  state.getIn(['flowsShow', 'isMovingPeopleToStep']);

// custom merge fields selectors
export const getMergeFieldsTableLoading = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'loading']);
export const getMergeFieldsTableColumns = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'columns']);
export const getMergeFieldsTableOrderBy = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'orderBy']);
export const getMergeFieldsTableOrderDirection = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'orderDirection']);
export const getMergeFieldsTablePage = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'page']);
export const getMergeFieldsTablePerPage = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'perPage']);
export const getMergeFieldsTableTotal = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'table', 'total']);
export const getMergeFieldsPeople = state =>
  state.getIn(['flowsShow', 'customMergeFields', 'people']);

export const downloadStatsCsvInProgress = state =>
  state.getIn(['flowsShow', 'downloadStatsCsvInProgress']);

export const makeGetMergeFieldPersonOfId = personId => {
  return createSelector(getMergeFieldsPeople, people => people.get(personId));
};

export const getPeopleIdsForStepAndFilter = createSelector(
  getPeopleByStepId,
  getSelectedStepId,
  getSelectedStepFilter,
  (peopleByStepId, stepId, filter) => {
    return peopleByStepId.getIn([stepId, 'byFilter', filter]);
  }
);

export const getPeopleForStepAndFilter = createSelector(
  getSelectedStepId,
  getPeople,
  getPeopleIdsForStepAndFilter,
  getPeopleTableOrderBy,
  getPeopleTableOrderDirection,
  (selectedStepId, peopleById, peopleIds, orderBy, orderDir) => {
    if (
      !peopleIds ||
      !peopleIds.size ||
      selectedStepId === ALL_STEPS_SELECTED
    ) {
      return peopleIds;
    }

    const people = peopleIds.map(personId =>
      peopleById.get(personId.toString())
    );

    return sortPeople(people, orderBy, orderDir);
  }
);

export const getPeopleForStepAndFilterCount = createSelector(
  getPeopleForStepAndFilter,
  people => {
    if (!people) return 0;
    return people.size;
  }
);

export const getIsFlowEditable = createSelector(
  getFlow,
  currentUser,
  (flow, user) => {
    if (!flow || !user) return false;

    return (
      flow.get('userId') === user.get('id') || flow.get('hasWritePermissions')
    );
  }
);

// A flow can be edited if it isn't shared or if it is owned by current user.
export const getCanEditFlow = createSelector(
  getFlow,
  currentUser,
  (flow, user) => {
    if (!flow || !user) return false;

    return (
      flow.get('hasWritePermissions') || flow.get('userId') === user.get('id')
    );
  }
);

export const makeActionFieldValue = field => state =>
  state.getIn(['form', ADD_VARIANT_DIALOG_FORM_GROUP_ID, 'fields', field]);

export const getFlowIsSharedAsMaster = createSelector(getFlow, flow => {
  return flow.get('isShared') && flow.get('isMasterFlow');
});

export const getDefaultAddressField = (recordType, addressType) =>
  createSelector(getFlow, flow => {
    const addressField = DEFAULT_ADDRESS_FIELD_NAMES[recordType]?.[addressType];
    if (!addressField) {
      return null;
    }
    return flow.get(addressField);
  });

export const getSelectedPersonStepIds = createSelector(
  getPeople,
  getSelectedPeople,
  (people, peopleIds) => {
    return peopleIds
      .toList()
      .map(personId => people.getIn([`${personId}`, 'personStepId']));
  }
);

export const makeGetSelectedPeopleSfdcId = () => {
  return createSelector(getSelectedPeople, getPeople, (personId, people) => {
    if (people) {
      return people
        .valueSeq()
        .filter(person => personId.includes(person.get('id')))
        .toList();
    }
    return new List();
  });
};

export const makeSelectAutomatedActionByTriggerType = triggerType => {
  return createSelector(getAutomatedActions, automatedActions => {
    return automatedActions
      .filter(
        automatedAction => automatedAction.get('triggerType') === triggerType
      )
      .sort((a, b) => a.id > b.id);
  });
};

export const getNewAutomatedAction = state =>
  state.getIn(
    ['flowsShow', 'automatedActionEdits', NEW_AUTOMATED_ACTION_ID],
    null
  );

export const getNewAutomatedActions = createSelector(
  getNewAutomatedAction,
  newAutomatedAction => newAutomatedAction && new List([newAutomatedAction])
);

export const getSteps = state => state.getIn(['flowsShow', 'steps']);

export const getStepsGroupedByDayNumber = createSelector(getSteps, steps =>
  steps.reduce(
    (groupedSteps, step) =>
      groupedSteps.update(step.get('dayNumber'), steps =>
        (steps || List()).push(step)
      ),
    OrderedMap()
  )
);

export const getDoesFlowAStepWithTemplateOverrideAbility = createSelector(
  getSteps,
  steps => {
    return steps?.find(step => step.get('otherUsersCanOverrideTemplate'));
  }
);

export const getStepStats = state => state.getIn(['flowsShow', 'stepStats']);

export const makeSelectTotalDueCount = () => {
  return createSelector(getSteps, steps => {
    return steps.reduce((sum, step) => {
      return sum + step.get('actionsDueCount');
    }, 0);
  });
};

export const makeSelectActiveCount = () => {
  return createSelector(getSteps, steps => {
    return steps.reduce((sum, step) => {
      return sum + step.get('peopleAtStepCount');
    }, 0);
  });
};

export const getSelectedStep = createSelector(
  getSteps,
  getSelectedStepId,
  (steps, selectedStepId) => {
    if (
      selectedStepId === ALL_STEPS_SELECTED ||
      selectedStepId === STAGED_PEOPLE_QUEUE_STEP
    ) {
      return selectedStepId;
    }

    return (
      steps.find(step => step.get('id') === toNumber(selectedStepId)) ||
      new Step()
    );
  }
);

export const getSelectedStepStats = createSelector(
  getStepStats,
  getSelectedStepId,
  (stepStats, selectedStepId) => {
    return stepStats.get(selectedStepId) || new Map();
  }
);

// Selector to pass into the StepAddEdit component so that
// it can determine the a step's subject IF it is threaded.
// TLDR; you want to find the subject of the first previous
// non-threaded email template step
export const getFirstEmailStepInThreadSubject = () => {
  return createSelector(
    getSteps,
    state => state.getIn(['ui', 'editingStep']),
    state =>
      makeGetFieldValue('keepGmailThreadId')(state, {
        groupId: ADD_EDIT_GROUP_ID_FALLBACK_VALUE,
      }),
    state => getProperty(state, ['showAddStepDialog']),
    (steps, editingStep, keepGmailThreadId, dialogOpen) => {
      const isNewStep = dialogOpen && !editingStep;

      if (!editingStep && !isNewStep) {
        return '';
      }

      if (!keepGmailThreadId) {
        return '';
      }

      let index;

      if (editingStep) {
        index = steps.findIndex(
          step => step.get('id') === editingStep.get('id')
        );
      } else {
        index = steps.size;
      }

      const stepsSubset = steps.slice(0, index).reverse();
      const firstThreadStep = stepsSubset.find(step => {
        if (step.get('stepType') !== 'TEMPLATE') {
          return false;
        }

        if (step.get('keepGmailThreadId')) {
          return false;
        }

        return true;
      });

      if (
        !firstThreadStep ||
        (!firstThreadStep.get('template') && !firstThreadStep.get('subject'))
      ) {
        return '';
      }

      return (
        firstThreadStep.get('subject') ||
        firstThreadStep.getIn(['template', 'subject'])
      );
    }
  );
};

export const getPeopleIdsForCSV = state =>
  state.getIn(['flowsShow', 'people', 'csv']);

export const getPeopleForAllStepsCsvDownload = createSelector(
  getSelectedStepId,
  getPeople,
  getPeopleIdsForCSV,
  getSteps,
  getPeopleTableOrderBy,
  getPeopleTableOrderDirection,
  getSelectedStepFilter,
  (selectedStepId, peopleById, peopleIds, steps, orderBy, orderDir) => {
    if (
      !peopleIds ||
      !peopleIds.size ||
      selectedStepId !== ALL_STEPS_SELECTED
    ) {
      return peopleIds;
    }

    const stepsById = steps.reduce((stepMap, step) => {
      return stepMap.set(step.get('id'), step);
    }, new Map());

    const people = peopleIds.map(personIdAndStep => {
      const step = stepsById.get(personIdAndStep.get('stepId')) || new Map();
      const personId = personIdAndStep.get('personId');
      const person = peopleById.get(personId.toString());
      return person.set('step', step.get('nameWithDayCount'));
    });

    return sortPeople(people, orderBy, orderDir);
  }
);

/**
 * Gets people for people table when the filter is 'all'
 */
export const getPeopleForAllStepsAndFilter = createSelector(
  getSelectedStepId,
  getPeople,
  getPeopleIdsForStepAndFilter,
  getSteps,
  getPeopleTableOrderBy,
  getPeopleTableOrderDirection,
  (selectedStepId, peopleById, peopleIds, steps, orderBy, orderDir) => {
    if (
      !peopleIds ||
      !peopleIds.size ||
      selectedStepId !== ALL_STEPS_SELECTED
    ) {
      return peopleIds;
    }

    const stepsById = steps.reduce((stepMap, step) => {
      return stepMap.set(step.get('id'), step);
    }, new Map());

    const people = peopleIds.map(personIdAndStep => {
      const step = stepsById.get(personIdAndStep.get('stepId')) || new Map();
      const personId = personIdAndStep.get('personId');
      const person = peopleById.get(personId.toString());
      return person.set('step', step.get('nameWithDayCount'));
    });

    return sortPeople(people, orderBy, orderDir);
  }
);

/**
 * Returns true if one of the email steps in the flow has custom merge fields
 * Custom merge fields are in the format: {!flow.someField}
 */
export const getHasCustomMergeFields = createSelector(getSteps, steps => {
  if (!steps) {
    return false;
  }

  return steps.some(step => {
    const [htmlBody, subject] = step.get('template')
      ? [
          // eslint-disable-line no-unused-expressions
          step.getIn(['template', 'htmlBody']) || '',
          step.getIn(['template', 'subject']) || '',
        ]
      : [
          // eslint-disable-line no-unused-expressions
          step.get('htmlBody') || '',
          step.get('subject') || '',
        ];

    return htmlBody.match(/{!flow.[^}]+}/) || subject.match(/{!flow.[^}]+}/);
  });
});

export const getReportInfo = createSelector(
  getFlow,
  currentUser,
  (flow, user) => {
    if (!flow || !user) return false;

    return !flow.get('isShared') || flow.get('userId') === user.get('id');
  }
);

export const getFlowAutoImport = createSelector(getFlow, flow => {
  if (!flow) return null;

  const id = flow.get('autoImportId');

  if (!id) return null;

  const assignOwner = flow.get('autoImportAssignOwner');

  // when it's a number
  if (id.match(/^\d+$/)) {
    return {
      assignOwner,
      importVia: 'SavedSearch',
      id: toNumber(id),
    };
  }

  const reportName =
    flow.get('autoImportMeta') && flow.getIn(['autoImportMeta', 'name']);
  const reportFolder =
    flow.get('autoImportMeta') && flow.getIn(['autoImportMeta', 'folder']);
  const customOwnerField =
    flow.get('autoImportMeta') &&
    flow.getIn(['autoImportMeta', 'customOwnerField']);
  const customOwnerFieldLabel =
    flow.get('autoImportMeta') &&
    flow.getIn(['autoImportMeta', 'customOwnerFieldLabel']);

  return {
    assignOwner,
    importVia: 'SalesforceReport',
    id,
    reportName,
    reportFolder,
    customOwnerField,
    customOwnerFieldLabel,
  };
});

export const getAutomatedActionModes = state =>
  state.getIn(['flowsShow', 'automatedActionModes']);

// selector to determine if Move to Step feature should be enabled or not
// the selector should return false if the people are not in the same step
// or if there are no people selected.

export const getAreAllStepsInSameMultiStepDay = createSelector(
  getSteps,
  steps => {
    const day = steps.first()?.get('dayNumber');
    return steps.every(step => step.get('dayNumber') === day);
  }
);

export const getAllowMoveToStep = createSelector(
  getSelectedStepFilter,
  getSelectedStepId,
  getPeopleByStepId,
  getSelectedPeople,
  getAreAllStepsInSameMultiStepDay,
  (
    currentFilter,
    currentStep,
    peopleAndStep,
    selectedPeopleIds,
    areAllStepsInSameMultiStepDay
  ) => {
    if (
      !selectedPeopleIds ||
      selectedPeopleIds.size === 0 ||
      areAllStepsInSameMultiStepDay
    ) {
      return false;
    }

    if (selectedPeopleIds.size >= 0 && currentStep !== ALL_STEPS_SELECTED) {
      // this is necessary because the other steps besides "all" store peopleIds in a different way which breaks the filter further down
      // so exit early since being in a step has automatically filtered my people for me.
      return true;
    }

    const currentPeople = peopleAndStep.getIn([
      currentStep,
      'byFilter',
      currentFilter,
    ]);

    if (!currentPeople || currentPeople.size === 0) {
      return false;
    }
    const filteredPeople = currentPeople.filter(personAndStep =>
      selectedPeopleIds.includes(personAndStep.get('personId'))
    );
    const compareStepId = filteredPeople.first().get('stepId');
    return filteredPeople.every(
      person => person.get('stepId') === compareStepId
    );
  }
);

const getPeopleForTable = createSelector(
  getSelectedStepId,
  getPeopleForAllStepsAndFilter,
  getPeopleForStepAndFilter,
  (selectedStepId, peopleForAllStepsAndFilter, peopleForStepAndFilter) => {
    const isAllSteps = selectedStepId === ALL_STEPS_SELECTED;
    const people = isAllSteps
      ? peopleForAllStepsAndFilter
      : peopleForStepAndFilter;

    return people || new List();
  }
);

export const getOwnedPeopleIdsForTable = createSelector(
  getPeopleForTable,
  getUserId,
  (people, currentUserId) => {
    return people
      .filter(
        person =>
          person.get('personStepOwnerId') === parseInt(currentUserId, 10)
      )
      .map(person => person.get('id'));
  }
);

export const getPeopleIdsForTable = createSelector(getPeopleForTable, people =>
  people.map(person => person.get('id'))
);

const getOrderedPeopleForTable = createSelector(
  getPeopleForTable,
  peopleForTable => {
    return listToOrderedMap(peopleForTable, person => person.get('id'));
  }
);

export const makeGetPersonForTable = personId =>
  createSelector(getOrderedPeopleForTable, orderedPeopleForTable => {
    return orderedPeopleForTable.get(personId);
  });

export const getPeopleTableVariants = state =>
  state.getIn(['flowsShow', 'variants', 'peopleTable']);

const getDialogVariantId = state =>
  state.getIn(['flowsShow', 'variants', 'dialog', 'variantId']);
export const getDialogStepId = state =>
  state.getIn(['flowsShow', 'variants', 'dialog', 'stepId']);
export const getDialogStep = createSelector(
  getDialogStepId,
  getSteps,
  (stepId, steps) => {
    if (steps) {
      return steps.find(step => step.id === stepId, null, new Step());
    }

    return new Step();
  }
);

const getDialogStepVariants = createSelector(
  getDialogStep,
  step => step.variants
);
export const getDialogVariant = createSelector(
  getDialogStep,
  getDialogVariantId,
  (step, variantId) =>
    step
      .get('variants')
      .find(variant => variant.id === variantId, null, new StepVariant())
);
export const getActiveDialogVariants = createSelector(
  getDialogStepVariants,
  stepVariants => stepVariants.filter(variant => variant.isActive())
);
export const getActiveDialogVariantsTemplates = createSelector(
  getActiveDialogVariants,
  activeDialogVariants => activeDialogVariants.map(variant => variant.template)
);
export const getActiveDialogVariantsTemplateIds = createSelector(
  getActiveDialogVariantsTemplates,
  templates => templates.map(template => template.id)
);

export const showVariantAnalyticsDialog = state =>
  state.getIn(['flowsShow', 'variants', 'showAnalyticsDialog']);
export const getActiveVariantsAnalytics = state =>
  state.getIn(['flowsShow', 'variants', 'analyticsDialog', 'activeAnalytics']);
export const getInactiveVariantsAnalytics = state =>
  state.getIn([
    'flowsShow',
    'variants',
    'analyticsDialog',
    'inactiveAnalytics',
  ]);
export const getSortedAnalyticsDialogColumnName = state =>
  state.getIn([
    'flowsShow',
    'variants',
    'analyticsDialog',
    'sorting',
    'columnName',
  ]);
export const getSortedAnalyticsDialogDirection = state =>
  state.getIn([
    'flowsShow',
    'variants',
    'analyticsDialog',
    'sorting',
    'direction',
  ]);

const COLUMN_NAMES_TO_FIELD_NAME = {
  [COLUMNS.SENT]: 'emailsSent',
  [COLUMNS.OPENED]: 'opens',
  [COLUMNS.CLICKED]: 'clicks',
  [COLUMNS.REPLIED]: 'replies',
};

export const getSortedActiveVariantsAnalytics = createSelector(
  getActiveVariantsAnalytics,
  getSortedAnalyticsDialogColumnName,
  getSortedAnalyticsDialogDirection,
  (activeVariantAnalytics, columnName, direction) => {
    const field = COLUMN_NAMES_TO_FIELD_NAME[columnName];

    if (direction === DESC) {
      return activeVariantAnalytics.sortBy(data => -data.get(field));
    }

    return activeVariantAnalytics.sortBy(data => data.get(field));
  }
);

export const isTemplateSelectorValid = state =>
  makeIsFieldValid(SELECTED_TEMPLATE_FIELD_ID)(state, {
    groupId: ADD_VARIANT_DIALOG_FORM_GROUP_ID,
  });
export const hasTemplateSelectorChanged = state =>
  makeIsFieldDirty(SELECTED_TEMPLATE_FIELD_ID)(state, {
    groupId: ADD_VARIANT_DIALOG_FORM_GROUP_ID,
  });
export const hasLinkTrackingChanged = state =>
  makeIsFieldDirty(LINK_TRACKING_FIELD_ID)(state, {
    groupId: ADD_VARIANT_DIALOG_FORM_GROUP_ID,
  });
export const getLinkTrackingValue = state =>
  makeGetFieldValue(LINK_TRACKING_FIELD_ID)(state, {
    groupId: ADD_VARIANT_DIALOG_FORM_GROUP_ID,
  });

export const getHasMismatchedStatAndPeopleCount = createSelector(
  getSelectedStepFilter,
  getPeopleIdsForTable,
  getSelectedStepStats,
  (selectedStepFilter, peopleIds, selectedStepStats) => {
    const tabsToHideMessage = [
      EMAIL_ACTIVITY_TYPES.OPENED,
      EMAIL_ACTIVITY_TYPES.CLICKED,
      EMAIL_ACTIVITY_TYPES.REPLIED,
    ];
    if (tabsToHideMessage.includes(selectedStepFilter)) return false;

    const currentPeopleCount = peopleIds ? peopleIds.count() : 0;
    const currentFilterStatCount = selectedStepStats.get(selectedStepFilter);

    return currentPeopleCount !== currentFilterStatCount;
  }
);

export const getIsEditingStep = state => state.getIn(['ui', 'editingStep']);
export const getEmailAliases = state =>
  state.getIn(['flowsShow', 'emailAliases']);
export const getFlowAlias = state => state.getIn(['flowsShow', 'flowAlias']);
export const getGlobalAlias = state =>
  state.getIn(['flowsShow', 'globalAlias']);

export const getIsOverridingTemplate = state =>
  state.getIn(['ui', 'overridingTemplateStep']);

export const getCanDisablePersonalization = createSelector(
  getIsMasterFlow,
  getCanEditFlow,
  (isMasterFlow, canEditFlow) => isMasterFlow && canEditFlow
);
