export default Object.freeze([
  'code',
  'image',
  'imagetools',
  'link',
  'lists',
  // TODO @vijaypemmaraju: enable this once we figure out how to configure properly
  // 'powerpaste'
  'paste',
]);
