import {
  GROOVEY_BLUEY,
  GRAY_1,
  WAVY_NAVY,
  WHITE,
} from 'Modules/Shared/constants/colors';

const primaryButtonStyles = {
  fontWeight: 600,
  backgroundColor: `${GROOVEY_BLUEY} !important`,
  color: WAVY_NAVY,
  '&:hover': {
    backgroundColor: `${WAVY_NAVY} !important`,
    color: WHITE,
  },
  '&:active': {
    padding: '9px 13px',
    background: WAVY_NAVY,
    border: `1px solid ${GROOVEY_BLUEY}`,
    boxSizing: 'border-box',
    color: GROOVEY_BLUEY,
  },
  '&:disabled': {
    backgroundColor: `${GRAY_1} !important`,
    color: WHITE,
  },
};

export default primaryButtonStyles;
