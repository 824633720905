import { DELETE, POST, PUT } from '../../HTTPMethod';
import makeHTTPRequest from '../../makeHTTPRequest';

/**
 * @param {number} flowId
 *
 * @return {Promise}
 * */
export const index = flowId =>
  makeHTTPRequest({ path: `/api/v2/flows/${flowId}/automated_actions` });

export const create = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions`,
    method: POST,
    body: { automated_action: { ...automatedAction, id: null } },
  });

export const update = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions/${automatedAction.id}`,
    method: PUT,
    body: { automated_action: automatedAction },
  });

export const destroy = ({ flowId, automatedAction }) =>
  makeHTTPRequest({
    path: `/api/v2/flows/${flowId}/automated_actions/${automatedAction.id}`,
    method: DELETE,
  });
