import { fromJS } from 'immutable';
import { actionTypes as peopleImportActionTypes } from 'Modules/PeopleImportDialog/actions';
import { actionTypes } from './actions';

const initialState = fromJS({
  uploading: false,
  uploadErrorMessage: null,
});

export default function peopleImportDialog(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.UPLOAD_CSV.PROGRESS: {
      return state.set('uploading', true);
    }
    case actionTypes.UPLOAD_CSV.SUCCESS: {
      return state.merge(fromJS(action.payload));
    }
    case actionTypes.UPLOAD_CSV.FAILURE: {
      return state.set('uploading', false);
    }
    case actionTypes.UPDATE_CSV_LOOKUP_STATE: {
      return state.merge(fromJS(action.payload));
    }
    case actionTypes.RESET: {
      return initialState;
    }
    case actionTypes.SET_CSV_ERROR_MESSAGE: {
      return state.set('uploadErrorMessage', action.payload);
    }
    case peopleImportActionTypes.SET_ACTIVE_TAB: {
      const { payload } = action;

      if (payload !== 'csvLookup') {
        return initialState;
      }

      return state;
    }

    default:
      return state;
  }
}
