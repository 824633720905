import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { Input, formGroupConnect } from '@groove-labs/groove-ui';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';

export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    flexGrow: 1,
    padding: '0px 10px',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
    paddingRight: 5,
    cursor: 'pointer',
  },
};
@formGroupConnect()
@withStyles(styles)
export default class SearchInput extends Component {
  static displayName = 'SearchInput';
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    groupId: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    debounceDuration: PropTypes.number,
    disableUnderline: PropTypes.bool,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    placeholder: '',
    debounceDuration: 0,
    disableUnderline: true,
    defaultValue: null,
  };

  constructor(props) {
    super(props);
    const { onInputChange, debounceDuration } = this.props;

    this.handleInputChange = debounce(onInputChange, debounceDuration);
  }

  onIconClick = () => {
    this.inputRef.focus();
  };

  setRef = elm => {
    this.inputRef = elm;
  };

  render() {
    const {
      classes,
      groupId,
      fieldId,
      placeholder,
      disableUnderline,
      defaultValue,
    } = this.props;

    return (
      <div className={classes.root}>
        <SearchIcon className={classes.icon} onClick={this.onIconClick} />
        <Input
          groupId={groupId}
          fieldId={fieldId}
          defaultValue={defaultValue}
          muiProps={{
            placeholder,
            disableUnderline,
            inputRef: this.setRef,
          }}
          onChange={this.handleInputChange}
          hideErrorHelperTextBlock
        />
      </div>
    );
  }
}
