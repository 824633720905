import {
  createActions,
  keyMirror,
  standardActions,
} from '@groove-labs/action-utils';

export const actionTypes = createActions({
  WORKSTEP: {
    ...keyMirror([
      'SET_ACTIVE_PERSON_ID',
      'UPDATE_COMPOSE_SUBJECT',
      'SEND_ALL',
      'FILTER_PEOPLE',
      'ORDER_PEOPLE',
      'SET_OFFSET',
      'CALCULATE_COLUMN_TYPES',
      'GO_TO_PREVIOUS_PERSON',
      'GO_TO_NEXT_PERSON',
      'REMOVE_PERSON_BY_SFDC_ID',
      'CALL_STEP_LOGGER_MOVE_TO_FLOW_CONFIRM',
      'CALL_STEP_LOGGER_MOVE_TO_FLOW_CANCEL',
      'CALL_STEP_LOGGER_PAUSE_DIALOG_CONFIRM',
      'CALL_STEP_LOGGER_PAUSE_DIALOG_CANCEL',
      'RESET_REDUCER',
      'SET_EXTRA_STEP_DATA',
      'REFETCH_WORKSTEP',
      'SET_ACTIVE_PERSON_MESSAGES',
      'CC_BCC_UPDATE',
      'CLOSE_ERROR_DIALOG',
      'SET_VARIANTS',
      'SET_PERSON_VARIANTS',
      'UPDATE_PERSON_VARIANT',
      'UPDATE_ACTIVE_PERSON_TEMPLATE',
      'RESET_MULTI_STEP_VIEW_SCOPE',
      'SET_EMAIL_ALIASES',
      'SET_DEFAULT_ALIAS',
      'SET_PARTIAL_SEND_SIZE',
      'UPDATE_SELECTED_ADDRESS_FIELD',
    ]),
    LOAD_WORKSTEP: standardActions(),
    REMOVE_PERSON_FROM_FLOW: standardActions(),
    SEND_EMAIL: standardActions(),
    UPDATE_FIELD: standardActions(),
    SKIP_STEP: standardActions(),
    SHOW_PREVIEW: standardActions(),
    LOG_CALL_OR_ACTION: standardActions(),
    CHANGE_MULTI_STEP_VIEW_SCOPE: standardActions(),
    CC_SEARCH: standardActions(),
  },
}).WORKSTEP;

export const loadWorkStep = payload => ({
  type: actionTypes.LOAD_WORKSTEP,
  payload,
});

export const setExtraStepData = payload => ({
  type: actionTypes.SET_EXTRA_STEP_DATA,
  payload,
});

export const setActivePersonId = ({ id }) => ({
  type: actionTypes.SET_ACTIVE_PERSON_ID,
  payload: { id },
});

export const updateComposeSubject = ({
  updatedSubjectText,
  extraUpdatedSubjectText,
  id,
}) => ({
  type: actionTypes.UPDATE_COMPOSE_SUBJECT,
  payload: { updatedSubjectText, extraUpdatedSubjectText, id },
});

export const goToPreviousPerson = () => ({
  type: actionTypes.GO_TO_PREVIOUS_PERSON,
});

export const goToNextPerson = () => ({
  type: actionTypes.GO_TO_NEXT_PERSON,
});

export const removePersonFromFlow = ({ flowId, id }) => ({
  type: actionTypes.REMOVE_PERSON_FROM_FLOW.BEGIN,
  payload: { flowId, id },
});

export const updateField = ({ id, fieldName, fieldValue }) => ({
  type: actionTypes.UPDATE_FIELD.BEGIN,
  payload: { id, fieldName, fieldValue },
});

export const orderPeople = ({ orderBy, orderDir }) => ({
  type: actionTypes.ORDER_PEOPLE,
  payload: { orderBy, orderDir },
});

export const filterPeople = value => ({
  type: actionTypes.FILTER_PEOPLE,
  payload: { value },
});

export const setOffset = value => ({
  type: actionTypes.SET_OFFSET,
  payload: { value },
});

export const triggerSendAll = ({ people, sendLater }) => ({
  type: actionTypes.SEND_ALL,
  payload: {
    people,
    sendLater,
  },
});

export const removePersonBySfdcId = ({ sfdcId }) => ({
  type: actionTypes.REMOVE_PERSON_BY_SFDC_ID,
  payload: { sfdcId },
});

export const sendEmail = () => ({
  type: actionTypes.SEND_EMAIL.BEGIN,
});

export const skipStep = () => ({
  type: actionTypes.SKIP_STEP.BEGIN,
});

export const showPreview = () => ({
  type: actionTypes.SHOW_PREVIEW.BEGIN,
});

export const logCallOrAction = (payload = {}) => ({
  type: actionTypes.LOG_CALL_OR_ACTION.BEGIN,
  payload,
});

export const callStepLoggerMoveToFlowConfirm = () => ({
  type: actionTypes.CALL_STEP_LOGGER_MOVE_TO_FLOW_CONFIRM,
});
export const callStepLoggerMoveToFlowCancel = () => ({
  type: actionTypes.CALL_STEP_LOGGER_MOVE_TO_FLOW_CANCEL,
});

export const callStepLoggerPauseDialogConfirm = () => ({
  type: actionTypes.CALL_STEP_LOGGER_PAUSE_DIALOG_CONFIRM,
});

export const callStepLoggerPauseDialogCancel = () => ({
  type: actionTypes.CALL_STEP_LOGGER_PAUSE_DIALOG_CANCEL,
});

export const changeMultiStepViewScopeBegin = payload => ({
  type: actionTypes.CHANGE_MULTI_STEP_VIEW_SCOPE.BEGIN,
  payload,
});

export const changeMultiStepViewScopeSuccess = payload => ({
  type: actionTypes.CHANGE_MULTI_STEP_VIEW_SCOPE.SUCCESS,
  payload,
});

export const refetchWorkstep = () => ({
  type: actionTypes.REFETCH_WORKSTEP,
});

export function setActivePersonMessages(payload) {
  return {
    type: actionTypes.SET_ACTIVE_PERSON_MESSAGES,
    payload,
  };
}
export function fetchCcPeople({ phrase }) {
  return {
    type: actionTypes.CC_SEARCH.BEGIN,
    payload: { phrase },
  };
}

export function ccUpdate({ email }) {
  return {
    type: actionTypes.CC_BCC_UPDATE,
    payload: { value: email, field: 'cc' },
  };
}

export function bccUpdate({ email }) {
  return {
    type: actionTypes.CC_BCC_UPDATE,
    payload: { value: email, field: 'bcc' },
  };
}

export function updateSelectedAddressField({ id, fieldKey, newFieldName }) {
  return {
    type: actionTypes.UPDATE_SELECTED_ADDRESS_FIELD,
    payload: { id, fieldKey, newFieldName },
  };
}

export const closeErrorDialog = () => ({
  type: actionTypes.CLOSE_ERROR_DIALOG,
});

export const setVariants = variants => ({
  type: actionTypes.SET_VARIANTS,
  payload: variants,
});

export const calculateColumnTypes = columnTypes => ({
  type: actionTypes.CALCULATE_COLUMN_TYPES,
  payload: columnTypes,
});

export const setPersonVariants = personVariants => ({
  type: actionTypes.SET_PERSON_VARIANTS,
  payload: personVariants,
});

export const updatePersonVariant = ({ personId, variantId }) => ({
  type: actionTypes.UPDATE_PERSON_VARIANT,
  payload: {
    personId,
    variantId,
  },
});

export const updateActivePersonTemplate = templateId => ({
  type: actionTypes.UPDATE_ACTIVE_PERSON_TEMPLATE,
  payload: templateId,
});

export const resetMultiStepViewScope = () => ({
  type: actionTypes.RESET_MULTI_STEP_VIEW_SCOPE,
});

export function setEmailAliases(emailAliases) {
  return {
    type: actionTypes.SET_EMAIL_ALIASES,
    payload: emailAliases,
  };
}

export function setDefaultAlias(defaultAlias) {
  return {
    type: actionTypes.SET_DEFAULT_ALIAS,
    payload: defaultAlias,
  };
}

export function setPartialSendSize(size) {
  return {
    type: actionTypes.SET_PARTIAL_SEND_SIZE,
    payload: {
      size,
    },
  };
}
