import { createSelector } from 'reselect';
import { camelCase } from 'lodash-es';
import { selectors } from '@groove-labs/groove-ui';
import { List, OrderedMap, Map } from 'immutable';
import { ADDABLE_FLOWS_UI_KEY_PATH } from 'Modules/Shared/constants';
import { getCheckedLabelIds } from 'Modules/Shared/selectors/labels';
import { LABEL_IDS_TO_FILTER_UI_KEY_PATH } from 'Modules/Shared/constants/labels';
import {
  FLOWS_FILTERS_GROUP_ID,
  ANYONE,
  SHARED_WITH_FIELD_ID,
  SHARED_BY_FIELD_ID,
} from 'Modules/Flows/constants';
import { customTagsSelector } from 'Modules/FlowsShow/selectors';
import { getTaskFieldsMeta } from 'Modules/Shared/selectors/salesforceMeta';
import { getTaskField } from 'Modules/Shared/selectors/users';
import { salesforceTaskFieldOptionsGenerator } from './utils';

const { getProperty } = selectors.ui;
const { makeGetFieldValue } = selectors.form;

export const sortingSelector = state => state.getIn(['flows', 'sorting']);

export const addableFlows = state =>
  getProperty(state, ADDABLE_FLOWS_UI_KEY_PATH, new OrderedMap());

export const getAddableFlowsPicklistOptions = createSelector(
  addableFlows,
  addableFlows => addableFlows.valueSeq().toList()
);

export const getPaginatedFlows = state =>
  state.getIn(['flows', 'paginatedFlows']);

export const getPagination = state => state.getIn(['flows', 'pagination']);

export const getIsLoading = state => state.getIn(['flows', 'isLoading']);
export const getFlowFilters = state => state.getIn(['flows', 'filters']);

export const getSearchQuery = state =>
  makeGetFieldValue('search')(state, { groupId: FLOWS_FILTERS_GROUP_ID });

export const listViewEnabled = state =>
  state.getIn(['flows', 'listViewEnabled']);

export const makeSelectFlow = flowId => {
  return createSelector(getPaginatedFlows, flows => flows.get(flowId));
};

export const makeSelectTotalDueCount = flowId => {
  return createSelector(makeSelectFlow(flowId), flow => {
    const steps = flow.get('steps').valueSeq();
    return steps.reduce((sum, step) => {
      return sum + step.get('actionsDueCount', 0);
    }, 0);
  });
};

export const makeSelectActiveCount = flowId => {
  return createSelector(makeSelectFlow(flowId), flow => {
    const steps = flow.get('steps').valueSeq();

    return steps.reduce((sum, step) => {
      return (
        sum +
        (step.get('currentCount', 0) -
          step.get('failedAndUnrecoverableCount', 0))
      );
    }, 0);
  });
};

export const makeSelectFlowSteps = flowId => {
  return createSelector(makeSelectFlow(flowId), flow => {
    if (!flow) return new List();

    return flow.get('steps').valueSeq().toList();
  });
};

export const getFlowStepsHaveFailedCount = flowId =>
  createSelector(makeSelectFlowSteps(flowId), steps => {
    let hasFailedStep = false;
    steps.forEach(step => {
      if (
        step.get('failedAndUnrecoverableCount') ||
        step.get('failedAndRecoverableCount')
      ) {
        hasFailedStep = true;
      }
    });
    return hasFailedStep;
  });

export const makeSelectFlowStepsGroupedByDayNumber = flowId => {
  return createSelector(makeSelectFlowSteps(flowId), steps => {
    return steps
      .sort((original, next) => {
        if (original.get('dayNumber') < next.get('dayNumber')) {
          return -1;
        } else if (original.get('dayNumber') > next.get('dayNumber')) {
          return 1;
        }
        if (original.get('intraDayOrder') < next.get('intraDayOrder')) {
          return -1;
        }
        if (original.get('intraDayOrder') > next.get('intraDayOrder')) {
          return 1;
        }
        return 0;
      })
      .groupBy(step => step.get('dayNumber'))
      .toOrderedMap();
  });
};

export const getSharedWith = state =>
  makeGetFieldValue(SHARED_WITH_FIELD_ID)(state, {
    groupId: FLOWS_FILTERS_GROUP_ID,
  });
export const getSharedBy = state =>
  makeGetFieldValue(SHARED_BY_FIELD_ID)(state, {
    groupId: FLOWS_FILTERS_GROUP_ID,
  });
export const getSharedWithFilter = createSelector(
  getSharedWith,
  sharedWith => sharedWith && sharedWith.get('value')
);
export const getSharedByFilter = createSelector(
  getSharedBy,
  sharedBy => sharedBy && sharedBy.get('id')
);

export const getSearchInputValue = state =>
  state.getIn(['form', 'flowsFilterGroup', 'fields', 'search', 'value'], '');
export const getSearchPreviousInputValue = state =>
  state.getIn(
    ['form', 'flowsFilterGroup', 'fields', 'search', 'previousValue'],
    ''
  );

export const getHasNoAppliedFiltersOrSearch = createSelector(
  getSharedBy,
  getSharedWith,
  getSearchQuery,
  state => getCheckedLabelIds(state, LABEL_IDS_TO_FILTER_UI_KEY_PATH),
  (
    sharedBySelectedData,
    sharedWithSelectedData,
    searchInputUsed,
    checkedLabelIds
  ) => {
    const isDefaultState = sharedData =>
      (sharedData || new Map()).get('name') === ANYONE;

    const sharedByInputIsDefault = isDefaultState(sharedBySelectedData);
    const sharedWithInputIsDefault = isDefaultState(sharedWithSelectedData);
    const hasNoCheckedLabels = checkedLabelIds.isEmpty();

    const noFilteringOrSearchUsed =
      sharedByInputIsDefault && sharedWithInputIsDefault && !searchInputUsed;
    return noFilteringOrSearchUsed && hasNoCheckedLabels;
  }
);

export const getCustomTagDetails = createSelector(
  customTagsSelector,
  getTaskFieldsMeta,
  getTaskField,
  (customTags, taskFieldsMeta, currentTaskField) =>
    salesforceTaskFieldOptionsGenerator({
      customTags,
      taskFieldsMeta,
      currentTaskField,
      getAllCurrentOptions: true,
    })
);

export const getFlowShowSelectedCustomTag = createSelector(
  customTagsSelector,
  getTaskFieldsMeta,
  getTaskField,
  (customTags, taskFieldsMeta, currentTaskField) =>
    salesforceTaskFieldOptionsGenerator({
      customTags,
      taskFieldsMeta,
      currentTaskField,
    })
);

export const makeSelectedCustomTagsDetails = flowId =>
  createSelector(
    getPaginatedFlows,
    getTaskFieldsMeta,
    getTaskField,
    (paginatedFlows, taskFieldsMeta, currentTaskField) =>
      salesforceTaskFieldOptionsGenerator({
        customTags: paginatedFlows.getIn([flowId, 'taskFieldValues'], Map()),
        taskFieldsMeta,
        currentTaskField,
      })
  );

export const getCustomTagOptions = createSelector(
  getTaskFieldsMeta,
  getTaskField,
  (taskFieldsMeta, currentTaskField) =>
    taskFieldsMeta
      .get(camelCase(currentTaskField.toLowerCase()), Map())
      .get('picklist', List())
      .map(option => ({
        value: option.get('value'),
        label: option.get('label'),
      }))
);

export const getFieldType = createSelector(
  getTaskFieldsMeta,
  getTaskField,
  (taskFieldsMeta, currentTaskField) =>
    taskFieldsMeta
      .get(camelCase(currentTaskField.toLowerCase()), Map())
      .get('type', '')
);
