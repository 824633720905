export const FLOW_PREVIEW_DIALOG_OPEN_KEY_PATH = ['showFlowPreviewDialog'];
export const FLOW_ID_IN_PREVIEW_DIALOG_OPEN_KEY_PATH = ['currentPerviewFlowId'];

export const FLOW_PER_PAGE_LIMIT = 10;
export const SEARCH_VISIBLE_KEY_PATH = ['flows', 'searchIsVisible'];
export const FLOWS_FILTERS_GROUP_ID = 'flowsFilterGroup';

export const ANYONE = 'Anyone';

export const TAB_VALUES = Object.freeze({
  ACTIVE: 'active',
  DUE: 'due',
  SHARED: 'shared',
  ARCHIVED: 'archived',
  ALL: 'all',
});

export const SEARCH_FILTER_FIELDS = Object.freeze({
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
});

export const SEARCH_FIELD_FORM_ID = 'search';
export const DISABLED_FLOW_ACTIONS_MESSAGE =
  'Please contact flow owner for access.';

export const FLOW_ACTIONS_LIMIT_REACHED_MESSAGE =
  'Flow Action Limit has been reached.';

export const UNKNOWN_TAG_MESSAGE = 'Unknown Field, Removed from Salesforce';
export const UNKNOWN_PICKLIST_OPTION_MESSAGE = 'Removed Picklist Option for';

export const SHARED_WITH_FIELD_ID = 'sharedWith';
export const SHARED_BY_FIELD_ID = 'sharedBy';
