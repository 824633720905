import querystring from 'querystring';
import { delay, all, fork, call, takeEvery } from 'redux-saga/effects';
import { ROUTE_NAMES } from 'Routing/constants';
import { actionTypes as locationActionTypes } from 'Modules/Shared/actions/location';
import { history } from 'Utils/history';
import runHealthChecks from 'Modules/Shared/sagas/health/handleHealthChecks';
import grooveEngineRootUrl from 'Utils/grooveEngineRootUrl';

import handleSettingQueryParameter from './location/handleSettingQueryParameter';

const HEALTH_CHECK_EXEMPT_PATHS = [
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.ACTION_COMPOSE,
  ROUTE_NAMES.ERROR,
  ROUTE_NAMES.LOGIN_CALLBACK,
  ROUTE_NAMES.ONBOARDING,
  ROUTE_NAMES.ONBOARDING_STEPS,
  ROUTE_NAMES.ONBOARDING_MICROSOFT_STEPS,
  ROUTE_NAMES.SHARED_CONVERSATION,
];

function* redirectToFix() {
  // this is built to call the /fix route and then redirect back to the original page.
  // ensure any changes you make here maintain the blocking behaviour of the function.
  const queryParameters = querystring.stringify({
    original_url: `${window.location.href}`,
    always_redirect: true,
  });

  while (true) {
    window.location.assign(`${grooveEngineRootUrl}/fix?${queryParameters}`);
    yield delay(3000);
  }
}

function* redirectToTroubleshooting() {
  yield call(history.push, '/troubleshooting');
}

function* handleSetActiveTab({
  payload: {
    activeTab,
    clearAllOtherParameters,
    skipSettingQueryParameter = false,
    extraQueryParameters,
  },
}) {
  if (!skipSettingQueryParameter) {
    yield* handleSettingQueryParameter({
      key: 'activeTab',
      value: activeTab,
      clearAllOtherParameters,
      extraQueryParameters,
    });
  }
}

function* watchSetActiveTab() {
  yield takeEvery(locationActionTypes.SET_ACTIVE_TAB, handleSetActiveTab);
}

function* watchLocationUpdateForHealthCheck() {
  yield takeEvery(
    action =>
      action.type === locationActionTypes.UPDATE &&
      !HEALTH_CHECK_EXEMPT_PATHS.includes(action.payload.routeName),
    runHealthChecks
  );
}

export {
  redirectToFix,
  redirectToTroubleshooting,
  handleSettingQueryParameter,
};

export default function* root() {
  yield all([fork(watchSetActiveTab), fork(watchLocationUpdateForHealthCheck)]);
}
