import { stringify } from 'querystring';

import ky, { Options } from 'ky';

import getClient from '../getClient';

let strategy: VisualforceStrategy | null = null;

export type VisualforceStrategy = TunnelStrategy | DirectCallStrategy;

type TunnelStrategy = {
  type: 'TUNNEL';
  options?: never;
};

type DirectCallStrategy = {
  type: 'DIRECT_CALL';
  includePrefix?: boolean;
  options: Options;
};

export const configureClient = (
  strategyToUse: VisualforceStrategy | null,
): void => {
  strategy = strategyToUse;
};

export default (): typeof ky => {
  if (strategy === null) {
    throw new Error(
      'The Visualforce client is not configured. Please call `configureClient` before using it.',
    );
  }
  if (strategy.type === 'DIRECT_CALL') {
    const client = ky.create(strategy.options);
    return {
      get(_, options = {}) {
        const { searchParams, ...otherOptions } = options;
        const { page, ...otherSearchParams } = searchParams as {
          [key: string]: string | number | boolean;
        };
        const hasBody = !!options.body;
        if (hasBody) {
          // https://github.com/sindresorhus/ky/issues/450
          // the above issue is fixed in a newer version of ky
          // but we cannot upgrade because of several issues that
          // the upgrade introduces to outlook-add-in
          // so instead we have to use the fetch API directly
          return {
            json: async () => {
              const response = await fetch(
                `${strategy?.options?.prefixUrl}${
                  (strategy as DirectCallStrategy)?.includePrefix
                    ? `DaScoopComposer__${page}`
                    : `${page}`
                }?${stringify(otherSearchParams as { [key: string]: string })}`,
                {
                  method: 'POST',
                  headers: strategy?.options?.headers as HeadersInit,
                  body: options.body,
                  redirect: 'follow',
                  credentials: 'omit',
                },
              );
              return response.json();
            },
          };
        }

        return client.get(
          (strategy as DirectCallStrategy)?.includePrefix
            ? `DaScoopComposer__${page}`
            : `${page}`,
          {
            ...otherOptions,
            ...(Object.keys(otherSearchParams).length > 0 && {
              searchParams: otherSearchParams,
            }),
          },
        );
      },
    } as typeof ky;
  }
  return getClient();
};
