import { eventChannel } from 'redux-saga';
import { store } from 'Src/index';
import {
  all,
  delay,
  fork,
  takeEvery,
  take,
  put,
  select,
  call,
} from 'redux-saga/effects';
import {
  actionTypes as usersActionTypes,
  loadFirestoreUserDoc,
} from 'Modules/Shared/actions/users';
import { updateReleaseVersion } from 'Modules/App/actions';
import { currentUser, alertsList } from 'Modules/Shared/selectors/users';
import { getCurrentVersion } from 'Modules/App/selectors';
import { getFirestore } from 'Utils/firestore';
import { createFeatureFlagSelector } from 'Modules/Shared/selectors/featureFlags';
import { CLOUDFRONT_TTL } from 'Modules/Shared/constants';
import { logError } from 'Modules/Shared/actions/errors';
import getOrganizationBasePath from 'Utils/firestore/getOrganizationBasePath';
import {
  FIRESTORE_COLLECTION,
  FIRESTORE_DOCUMENT,
} from 'Modules/Spaces/show/constants';
import { history } from 'Utils/history';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';

const leadMatchingAlertsActiveSelector =
  createFeatureFlagSelector('leadMatchingAlerts');
const accountStatusAlertsActiveSelector = createFeatureFlagSelector(
  'accountStatusAlerts'
);

// -------------- Helpers --------------

export function* waitUntilDocumentOrCollectionExists(path) {
  const firestore = yield* getFirestore();
  const type =
    path.split('/').length % 2 === 0
      ? FIRESTORE_DOCUMENT
      : FIRESTORE_COLLECTION;

  const firestoreMethod =
    type === FIRESTORE_COLLECTION ? firestore.collection : firestore.doc;

  let numberOfAttempts = 0;

  yield call(
    () =>
      new Promise(resolve => {
        const handler = () => {
          firestoreMethod.call(firestore, path).onSnapshot(snapshot => {
            if (
              (type === FIRESTORE_COLLECTION && !snapshot.empty) ||
              (type === FIRESTORE_DOCUMENT && snapshot.exists)
            ) {
              resolve();
            } else {
              console.error(`Firestore ${type} path [${path}] does not exist.`);
              if (numberOfAttempts > 30) {
                store.dispatch(
                  pushSnackbarMessage({
                    message: 'Unable to load space, please try again later.',
                  })
                );
                history.push('/spaces');
                return;
              }
              setTimeout(handler, 1000);
              numberOfAttempts += 1;
            }
          });
        };
        handler();
      })
  );
}

export function* createFirestoreChannel(path, firestoreReference) {
  const firestore = do {
    if (firestoreReference) {
      firestoreReference; // eslint-disable-line no-unused-expressions
    } else {
      yield* getFirestore();
    }
  };

  let docPathExists = true;

  const channel = eventChannel(emit => {
    const type =
      path.split('/').length % 2 === 0
        ? FIRESTORE_DOCUMENT
        : FIRESTORE_COLLECTION;

    const firestoreMethod =
      type === FIRESTORE_COLLECTION ? firestore.collection : firestore.doc;
    const unsubscribe = firestoreMethod
      .call(firestore, path)
      .onSnapshot(snapshot => {
        if (type === FIRESTORE_DOCUMENT && snapshot.exists) {
          emit(snapshot);
        } else if (type === FIRESTORE_COLLECTION && !snapshot.empty) {
          emit(snapshot);
        } else {
          docPathExists = false;
          emit(snapshot);
          console.error(`Firestore ${type} path [${path}] does not exist.`);
        }
      });

    // Returns unsubscribe function
    return () => unsubscribe();
  });

  if (docPathExists) {
    return channel;
  }

  return false;
}

export function* assembleFirestoreUserPath() {
  const userData = yield select(state => currentUser(state));
  const { id, orgId } = userData.toJS();
  return `${getOrganizationBasePath(orgId)}/users/${id}`;
}

function assembleReleasePath() {
  const env = process.env.REACT_APP_DEPLOY_ENV || 'development';
  return `releases/${env}`;
}

// -------------- Handlers --------------
function* initFirestoreReleaseVersionListener(firestoreReference) {
  const releaseVersionPath = assembleReleasePath();

  // If the document path does not exist, createFirestoreChannel() will return `false`
  const channel = yield* createFirestoreChannel(
    releaseVersionPath,
    firestoreReference
  );

  if (!channel) {
    return;
  }

  try {
    while (true) {
      const releasesDocument = yield take(channel);
      const releases = releasesDocument.data() || {};
      const versionInFirestore = releases.febes;

      const currentVersion = yield select(getCurrentVersion);

      if (currentVersion !== versionInFirestore) {
        yield delay(CLOUDFRONT_TTL);
        yield put(updateReleaseVersion({ version: versionInFirestore }));
      }
    }
  } catch (e) {
    yield put(logError({ error: e, isUiError: false }));
  } finally {
    console.info('finally! in initFirestoreReleaseVersionListener'); // eslint-disable-line
  }
}

function* initFireStoreUserListener(firestoreReference) {
  const leadMatchingAlertsActive = yield select(
    leadMatchingAlertsActiveSelector
  );
  const accountStatusAlertsActive = yield select(
    accountStatusAlertsActiveSelector
  );

  // If either feature flag is on, we can expect the user to have some alerts
  if (leadMatchingAlertsActive || accountStatusAlertsActive) {
    const firestoreDocPath = yield* assembleFirestoreUserPath();

    // If the document path does not exist, createFirestoreChannel() will return `false`
    const channel = yield* createFirestoreChannel(
      firestoreDocPath,
      firestoreReference
    );

    if (!channel) {
      return;
    }

    try {
      while (true) {
        const userResponse = yield take(channel);
        const userDoc = userResponse.data() || {};

        yield put(loadFirestoreUserDoc({ userDoc }));
      }
    } catch (e) {
      yield put(logError({ error: e, isUiError: false }));
    } finally {
      console.info('finally! in initFireStoreUserListener'); // eslint-disable-line
    }
  }
}

// Should contain all global firestore logic that needs to kick off after app upstart
function* firestoreUpstart() {
  const firestore = yield* getFirestore();

  yield all([
    call(initFirestoreReleaseVersionListener, firestore),
    call(initFireStoreUserListener, firestore),
  ]);
}

function* deleteAlert({ payload: { alertUid } }) {
  const firestore = yield* getFirestore();
  const alerts = yield select(alertsList);

  const indexToDelete = alerts.findIndex(
    alert => alert.get('uid') === alertUid
  );

  // List.findIndex() return -1 if no match was found
  if (indexToDelete !== -1) {
    const updatedAlertsList = alerts.delete(indexToDelete);
    const firestoreDocPath = yield* assembleFirestoreUserPath();
    firestore
      .doc(firestoreDocPath)
      .update({ alerts: updatedAlertsList.toJS() });
  }
}

// -------------- Watchers --------------
function* watchLoginSuccessToInitFirestore() {
  yield takeEvery(usersActionTypes.LOGIN_SUCCESS, firestoreUpstart);
}

function* watchDeleteAlert() {
  yield takeEvery(usersActionTypes.DELETE_ALERT, deleteAlert);
}

export default function* root() {
  yield all([fork(watchLoginSuccessToInitFirestore), fork(watchDeleteAlert)]);
}
