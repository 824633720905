import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import shortId from 'shortid';

import { greyContent, pulse, shimmer } from 'Modules/Shared/styles/shimmerBar';
import sharedStyles from 'Modules/Actions/containers/Table/styles';

const useStyles = makeStyles(() => ({
  table: {
    ...sharedStyles.tableRoot,
    overflow: 'hidden',
    scroll: 'none',
  },
  rowContainer: {
    height: 56,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    display: 'flex',
  },
  loadingName: {
    ...greyContent,
    width: 160,
    display: 'flex',
    margin: 16,
  },
  loadingData: {
    ...greyContent,
    flex: 1,
    margin: '0px 30px 0px 16px',
  },
  loading: {
    ...pulse,
  },
  ...shimmer,
}));

type LoaderTablePropTypes = {
  failed: boolean;
};

const LoaderTable: React.FC<LoaderTablePropTypes> = ({ failed }) => {
  const classes = useStyles({});
  const chartRows = [...Array(5)].fill(null).map(shortId.generate);

  return (
    <div>
      <div className={classes.table}>
        {chartRows.map(value => (
          <div key={value} className={classes.rowContainer}>
            <div
              className={`${classes.loadingName} ${
                failed ? null : classes.loading
              }`}
            />
            <div
              className={`${classes.loadingData} ${
                failed ? null : classes.loading
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LoaderTable;
