import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import {
  WYSIWYG_EDITOR_VALUE_UI_KEY_PATH,
  WYSIWYG_EDITOR_INITIAL_VALUE_UI_KEY_PATH,
} from 'Modules/Shared/constants/wysiwyg';
import { TEMPLATE_ADD_EDIT_FORM_GROUP } from 'Modules/Shared/constants';
import { getTemplateData } from 'Modules/Shared/selectors/cached';

const makeGetGroupData = groupId => {
  return state => state.getIn(['form', groupId]);
};

export const getWysiwygEditorInitialValue = state =>
  state.getIn(['ui', ...WYSIWYG_EDITOR_INITIAL_VALUE_UI_KEY_PATH]);
export const getWysiwygEditorValue = state =>
  state.getIn(['ui', ...WYSIWYG_EDITOR_VALUE_UI_KEY_PATH]);

export const getTemplateFoldersOnly = state =>
  state
    .getIn(['ui', 'templateFolderStructure'], List())
    .map(templateFolder => templateFolder.delete('templates'));

export const getTemplateFolders = state =>
  state.getIn(['ui', 'templateFolderStructure'], List());

export const getEditableTemplateFoldersOnly = createSelector(
  getTemplateFolders,
  templateFolders => {
    return templateFolders.toList().filter(templateFolder => {
      return !!templateFolder.get('isEditable');
    });
  }
);

export const makeGetTemplateAddEditData = groupId => {
  let groupData;
  if (groupId) {
    groupData = makeGetGroupData(groupId);
  } else {
    groupData = state => state.getIn(['form', TEMPLATE_ADD_EDIT_FORM_GROUP]);
  }

  return createSelector(
    [groupData, getWysiwygEditorValue],
    (group, wysiwygEditorValue) => {
      if (!group) {
        return new Map();
      }

      // These fieldId's are hardcoded in Modules/Shared/TemplateAddEdit/index.js
      const fieldIds = [
        'templateFolder',
        'templateName',
        'templateSubject',
        'trackLink',
        'includeInThread',
        'templateTypeValue',
        'templateType',
        'htmlBody',
      ];

      const baseMap = new Map({ htmlBody: wysiwygEditorValue });

      return group
        .get('fields')
        .entrySeq()
        .reduce((map, keyValuePair) => {
          const fieldId = keyValuePair[0];

          if (!fieldIds.includes(fieldId)) {
            return map;
          }

          //  The 'selectedData' key below will be `null` unless the folder has
          //  been changed, so its possible that `templateFolderId` ends up as
          //  undefined, which is fine if its an edit.
          if (fieldId === 'templateFolder') {
            const templateFolderData =
              keyValuePair[1].get('value') || new Map();
            const templateFolderId = templateFolderData.get('id');
            return map.set('templateFolder', templateFolderId);
          }

          const fieldValue = keyValuePair[1].get('value');
          return map.set(fieldId, fieldValue);
        }, baseMap);
    }
  );
};

const getTemplateEditorTextBody = state =>
  state.getIn(['form', 'addEditStepDialog', 'fields', 'textBody']);

export const getTemplateEditorHasBeenModified = createSelector(
  getTemplateEditorTextBody,
  (textBody = new Map()) =>
    textBody.get('value', null) !== textBody.get('initialValue', null)
);

export const getTemplateFolderStructure = state =>
  state.getIn(['ui', 'templateFolderStructure']);

export const getIsSelectedTemplateEditable = () =>
  createSelector(
    state => getTemplateData(state),
    getTemplateFolderStructure,
    (selectedTemplate, templateFolderStructure) => {
      if (!(selectedTemplate && templateFolderStructure)) return false;

      const selectedTemplateFolder = templateFolderStructure.find(
        folder => folder.get('id') === selectedTemplate.templateFolderId
      );

      if (selectedTemplateFolder) {
        return selectedTemplateFolder.get('isEditable');
      }
      return false;
    }
  );

export const getEditingStep = state => state.getIn(['ui', 'editingStep']);

export const getOverridingTemplateStep = state =>
  state.getIn(['ui', 'overridingTemplateStep']);

export const getTemplateOverride = createSelector(
  getOverridingTemplateStep,
  step => step?.get('templateOverride')?.get('template')
);

export const getIsUseMyOwnTemplateEnabled = state =>
  state.getIn(['ui', 'applyingOwnTemplate']) ||
  state.getIn([
    'form',
    'addEditStepDialog',
    'fields',
    'overrideTemplate',
    'value',
  ]);

export const getOtherUserCanOverrideTemplateEnabled = state =>
  state.getIn(['ui', 'allowingUsersToSelectOwnTemplate']);

export const getIsAllowingUsersToSelectOwnTemplateEnabled = createSelector(
  getEditingStep,
  getOtherUserCanOverrideTemplateEnabled,
  (step, allowingOtherUsersToSelectOwnTemplateEnabled) =>
    allowingOtherUsersToSelectOwnTemplateEnabled ||
    step?.get('otherUsersCanOverrideTemplate') ||
    false
);

export const getAppliedTemplateFromOverrideDialog = createSelector(
  getOverridingTemplateStep,
  getTemplateOverride,
  (step, templateOverride) => templateOverride || step?.get('template')
);

export const getIsDefaultTemplateApplied = createSelector(
  getOverridingTemplateStep,
  getTemplateOverride,
  (overridingTemplate, templateOverride) => {
    return overridingTemplate && !templateOverride;
  }
);
