import { GRAY_3, GRAY_4 } from 'Modules/Shared/constants/colors';

export const greyContent = {
  background: GRAY_3,
  borderRadius: 2,
  height: 21,
};

export const pulse = {
  backgroundColor: GRAY_3,
  animationName: '$pulse',
  animationDuration: '1s',
  animationIterationCount: 'infinite',
};

export const shimmer = {
  '@keyframes pulse': {
    '0%': {
      backgroundColor: GRAY_3,
    },
    '50%': {
      backgroundColor: GRAY_4,
    },
    '100%': {
      backgroundColor: GRAY_3,
    },
  },
};
