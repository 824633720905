import React, { Component } from 'react';
import { List, Map } from 'immutable';
import { withStyles } from '@material-ui/core';

import { Picklist } from '@groove-labs/groove-ui';
import noop from 'lodash-es/noop';

import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import Template from 'Modules/Shared/data/Template';

export const FIELD_ID = 'TEMPLATE_SELECTOR';

const TEMPLATE_FOLDER_TYPE = 'TEMPLATE_FOLDER_TYPE';
const TEMPLATE_TYPE = 'TEMPLATE_TYPE';

const styles = theme => ({
  selectorInputRoot: {
    width: 'unset',
  },
  selectorRoot: {
    width: '100%',
    zIndex: 2,
  },
  selectorPaper: {
    width: '100%',
  },
  selectorMenuList: {
    minHeight: `${theme.spacing.unit * 24}px`,
  },
});

@withStyles(styles)
export default class TemplateSelector extends Component {
  static displayName = 'TemplateSelector';
  // TODO: @jaebradley change the underlying Maps to Records

  static propTypes = {
    classes: PropTypes.shape({
      selectorInputRoot: PropTypes.string.isRequired,
      selectorRoot: PropTypes.string.isRequired,
      selectorPaper: PropTypes.string.isRequired,
      selectorMenuList: PropTypes.string.isRequired,
    }).isRequired,

    // parent props
    groupId: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    updateSelectorActiveStatusTo: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    // TODO: @jaebradley commenting this prop for now, but will use later when setting default template
    initialValue: ImmutablePropTypes.recordOf(Template),
    templateFolders: ImmutablePropTypes.list,
    onSelection: PropTypes.func,
  };

  static defaultProps = {
    templateFolders: List(),
    initialValue: Template(),
    onSelection: noop,
    updateSelectorActiveStatusTo: noop,
    onFocus: noop,
    onBlur: noop,
  };

  static buildTemplateOption(template, folderName) {
    return Map({
      subject: template.get('subject'),
      label: template.get('name'),
      value: `${template.get('id')}-${TEMPLATE_TYPE}-${folderName}`,
      id: template.get('id'),
      type: TEMPLATE_TYPE,
      hasRenderedHTML:
        !!template.get('htmlBody') || !!template.get('unsafeHtmlBody'),
    });
  }

  static getOptionLabel = option => option.get('label');

  buildOptions() {
    const { templateFolders } = this.props;

    return templateFolders.toJSON().map(templateFolder => ({
      label: templateFolder.get('name'),
      value: `${templateFolder.get('id')}-${TEMPLATE_FOLDER_TYPE}`,
      id: templateFolder.get('id'),
      type: TEMPLATE_FOLDER_TYPE,
      options: templateFolder
        .get('templates')
        .map(template =>
          TemplateSelector.buildTemplateOption(
            template,
            templateFolder.get('name')
          )
        )
        .toJSON(),
    }));
  }

  handleOnChange = (option, { action }) => {
    if (action === 'select-option') {
      this.props.onSelection(option);
      this.props.updateSelectorActiveStatusTo(false);
    }
  };

  render() {
    const { groupId, initialValue, classes, active, onFocus, onBlur } =
      this.props;

    return (
      <Picklist
        fieldId={FIELD_ID}
        getOptionLabel={TemplateSelector.getOptionLabel}
        groupId={groupId}
        initialValue={TemplateSelector.buildTemplateOption(initialValue)}
        label="Select a Template"
        onChange={this.handleOnChange}
        options={this.buildOptions()}
        onFocus={onFocus}
        onBlur={onBlur}
        classes={
          active
            ? {
                root: classes.selectorRoot,
                inputRoot: classes.selectorInputRoot,
                paper: classes.selectorPaper,
                menuList: classes.selectorMenuList,
              }
            : undefined
        }
        blurInputOnSelect
        styles={{
          width: '100%',
        }}
      />
    );
  }
}
