import { selectors, formInputFormatters } from '@groove-labs/groove-ui';
import { List, Map, OrderedMap } from 'immutable';
import { getActivityResultOptions } from 'Modules/Shared/selectors/users';

import {
  COMPANY_COLUMN_ID,
  DEFAULT_COLUMNS_FOR_STEP_TYPE,
  DEFAULT_PERSON_DATA_COLUMNS,
  DEFAULT_SCHEDULED_SEND_VALUES as dateTimeDefaults,
  DONT_SHOW_COLUMNS,
  WYSIWYG_DATA_UI_KEY_PATH,
  PREVIOUS_COMPLETED_VIEW_SCOPE,
  VISIBLE_OPTIONAL_COLUMNS_PATH,
  SMS_DATE_CREATED,
} from 'Modules/WorkStep/constants';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';
import { alphabetically } from 'Utils/sort';
import { location } from 'Modules/Shared/selectors/location';

import { prepareAlertValueForSort } from 'Modules/WorkStep/utils';
import { createFeatureFlagSelector } from 'Modules/Shared/selectors/featureFlags';
import { SmsMessage, PhoneNumber, PhoneNumbers } from './records';

const { PhoneNumberFormatter } = formInputFormatters;
const { getProperty } = selectors.ui;

export const getPeople = state =>
  state.getIn(['workStep', 'people'], new Map());
export const getAvailableTemplateTypeValues = state =>
  state.getIn(['workStep', 'availableTemplateTypeValues'], new List());
export const getActivityOutcomeOptions = state =>
  state.getIn(['workStep', 'activityOutcomeOptions'], new List());
export const getOffset = state => state.getIn(['workStep', 'offset']);
export const getStepType = state => state.getIn(['workStep', 'stepType']);
export const getTemplateType = state =>
  state.getIn(['workStep', 'templateType']);
export const getFilter = state => state.getIn(['workStep', 'filter']);
export const getOrderBy = state => state.getIn(['workStep', 'orderBy']);
export const getOrderDir = state => state.getIn(['workStep', 'orderDir']);
export const getLimit = state => state.getIn(['workStep', 'limit']);
export const getOptOutSafeguardDisabled = state =>
  state.getIn(['workStep', 'disableEmailOptOutSafeguard']);
export const getIsRowDisabled = state =>
  state.getIn(['workStep', 'isRowDisabled']);

export const getColumnTypes = state => state.getIn(['workStep', 'columnTypes']);

export const getShowOnlyPreviousStepCompleted = state =>
  state.getIn(['workStep', 'showOnlyPreviousStepCompleted']);
export const getSameDayStepViewScope = state =>
  state.getIn(['workStep', 'sameDayStepViewScope'], 'all');
export const getCcPeople = state =>
  state.getIn(['workStep', 'ccPeople'], new List());

// get people completed in the previous same day step (for multi step day)
export const getPreviousSameDayStepCompletedPeopleIds = state =>
  state.getIn(['workStep', 'previousSameDayStepCompletedPeopleIds'], List());

export const getVisibleOptionalColumns = state => {
  return (
    selectors.ui.getProperty(state, VISIBLE_OPTIONAL_COLUMNS_PATH) || new Map()
  );
};

const getVariableColumns = state => state.getIn(['workStep', 'columns']);

export const getColumns = createSelector(
  getVariableColumns,
  getTemplateType,
  getVisibleOptionalColumns,
  (variableColumns, templateType, visibleOptionalColumns) => {
    return DEFAULT_PERSON_DATA_COLUMNS.reduce((columns, defaultColumnData) => {
      return columns.set(
        defaultColumnData.get('id'),
        defaultColumnData.get('label')
      );
    }, variableColumns)
      .filter((value, field) => !DONT_SHOW_COLUMNS.includes(field))
      .filter((value, field) => {
        return (
          DEFAULT_COLUMNS_FOR_STEP_TYPE[templateType].includes(field) ||
          visibleOptionalColumns.get(field)
        );
      });
  }
);

export const getColumnKeys = createSelector(getColumns, columns =>
  columns.keySeq().toList()
);

export const getOptionalColumns = createSelector(
  getVariableColumns,
  getTemplateType,
  (variableColumns, templateType) => {
    return variableColumns
      .filter((label, field) => !DONT_SHOW_COLUMNS.includes(field))
      .filter(
        (label, field) =>
          !DEFAULT_COLUMNS_FOR_STEP_TYPE[templateType].includes(field)
      );
  }
);

export const getPeopleToDisplay = createSelector(
  getPeople,
  getSameDayStepViewScope,
  getPreviousSameDayStepCompletedPeopleIds,
  (people, viewScope, completedPeopleIds) => {
    let peopleToReturn;

    if (viewScope !== PREVIOUS_COMPLETED_VIEW_SCOPE) {
      peopleToReturn = people;
    } else {
      // return people who has completed previous step in the current day
      peopleToReturn = completedPeopleIds.reduce((peopleRecords, personId) => {
        if (people.get(`${personId}`)) {
          return peopleRecords.set(personId, people.get(`${personId}`));
        }
        return peopleRecords;
      }, new Map());
    }
    return peopleToReturn.sortBy(person => person.get('id'));
  }
);

const getAllEditorData = state =>
  getProperty(state, WYSIWYG_DATA_UI_KEY_PATH, new Map());

const getAllEditorDataWhenOrderByAlerts = createSelector(
  getOrderBy,
  getAllEditorData,
  (orderBy, editorData) => {
    if (orderBy !== 'alerts') {
      return null;
    }
    return editorData;
  }
);

export const getFilteredPeople = createSelector(
  getPeopleToDisplay,
  getFilter,
  getOrderBy,
  getOrderDir,
  getAllEditorDataWhenOrderByAlerts,
  (people, filter, orderBy, orderDir, editorData) => {
    const trimmedFilter = filter.toLowerCase().trim();
    let filteredPeople = people.filter(
      person =>
        (person.get('name') || '').toLowerCase().includes(trimmedFilter) ||
        (person.get('email') || '').toLowerCase().includes(trimmedFilter) ||
        (person.get(COMPANY_COLUMN_ID) || '')
          .toLowerCase()
          .includes(trimmedFilter)
    );

    if (orderBy === 'alerts') {
      // alerts column contains icons so we need to prepare a sortable pseudo-column
      filteredPeople = filteredPeople.map(person => {
        return person.set(
          'alertValue',
          prepareAlertValueForSort(
            person,
            editorData.get(`${person.get('id')}`)
          )
        );
      });
    }

    if (orderBy) {
      filteredPeople = filteredPeople.sortBy(person => {
        if (orderBy === 'tzid') {
          return person.get('localTimeNow');
        }
        if (orderBy === 'alerts') {
          return person.get('alertValue');
        }
        // get column value from fields or from person root - it's the same logic as cell display.
        return person.getIn(['fields', orderBy, 'raw']) || person.get(orderBy);
      }, alphabetically(orderDir === 'asc'));
    }

    return filteredPeople;
  }
);

export const getHasPeopleNotYetDue = createSelector(getPeople, people =>
  people.some(person => person.get('daysUntilDue') > 0)
);

export const getFilteredPeopleCount = createSelector(
  getFilteredPeople,
  filteredPeople => filteredPeople.size
);

export const getFilteredAndPaginatedPeople = createSelector(
  getFilteredPeople,
  getLimit,
  getOffset,
  (filteredPeople, limit, offset) => {
    return filteredPeople
      .slice(offset, offset + limit)
      .keySeq()
      .toList();
  }
);

export const getFlowId = state => state.getIn(['workStep', 'flow', 'id']);
export const getFlowName = state => state.getIn(['workStep', 'flow', 'name']);
export const getStepName = state => state.getIn(['workStep', 'step', 'name']);
export const getStepId = state => state.getIn(['workStep', 'step', 'id']);
export const getDisablePersonalization = state =>
  state.getIn(['workStep', 'step', 'disablePersonalization']);
export const getExtraStepData = state =>
  state.getIn(['workStep', 'extraStepData']);
export const getActivePersonId = state =>
  state.getIn(['workStep', 'activePersonId']);
export const getDateTime = (state, props) =>
  state.getIn(['ui', props.widgetId, 'dateTime']);
export const getDate = (state, props) =>
  state.getIn(['ui', props.widgetId, 'date']);
export const getTime = (state, props) =>
  state.getIn(['ui', props.widgetId, 'time']);
export const getTimeZone = (state, props) =>
  state.getIn(['ui', props.widgetId, 'timeZone']);
export const getIsActivePerson = (state, props) => {
  const activePersonId = state.getIn(['workStep', 'activePersonId']) || '';
  return props.personId === `${activePersonId}`;
};

export const getStepPriority = createSelector(
  getStepId,
  getExtraStepData,
  (stepId, extraStepData) => extraStepData.get(stepId).get('priority')
);

export const getVariants = state =>
  state.getIn(['workStep', 'variants'], List());
export const getActiveVariants = state =>
  getVariants(state).filter(variant => variant.isActive());
export const getActiveVariantTemplates = createSelector(
  getActiveVariants,
  variants => variants.map(variant => variant.template)
);
export const getVariantByTemplateId = templateId =>
  createSelector(getVariants, variants =>
    variants.find(variant => variant.template.id === templateId)
  );
export const getPersonVariants = state =>
  state.getIn(['workStep', 'personVariants'], Map());
export const getActivePersonVariantId = createSelector(
  getPeople,
  getActivePersonId,
  getPersonVariants,
  createFeatureFlagSelector('abTesting'),
  (people, activePersonId, personVariants, hasEnabledAlternateTemplates) => {
    if (people.size > 0 && activePersonId) {
      if (
        hasEnabledAlternateTemplates &&
        personVariants.has(Number(activePersonId))
      ) {
        return personVariants.get(Number(activePersonId));
      }
    }

    return null;
  }
);

export const getActivePerson = createSelector(
  getPeople,
  getActivePersonId,
  getPersonVariants,
  createFeatureFlagSelector('abTesting'),
  (people, activePersonId, personVariants, hasEnabledAbTesting) => {
    if (people.size > 0 && activePersonId) {
      if (hasEnabledAbTesting && personVariants.get(activePersonId)) {
        const variantId = personVariants.get(activePersonId);
        return people.getIn([
          activePersonId.toString(),
          'variants',
          variantId.toString(),
        ]);
      }
      return people.get(activePersonId.toString());
    }

    return new Map({ id: '' });
  }
);

export const getPerson = personId =>
  createSelector(
    getPeople,
    getPersonVariants,
    createFeatureFlagSelector('abTesting'),
    (people, personVariants, hasEnabledAlternateTemplates) => {
      if (people.size > 0 && personId) {
        if (hasEnabledAlternateTemplates && personVariants.has(personId)) {
          const variantId = personVariants.get(personId);
          return people.getIn([
            personId.toString(),
            'variants',
            variantId.toString(),
          ]);
        }

        return people.get(personId.toString());
      }

      return new Map({ id: '' });
    }
  );

export const getActivePersonCcField = createSelector(
  getActivePerson,
  activePerson => activePerson.get('ccRecipients', new List())
);

export const getIsCurrentStepMultiStepDay = createSelector(
  getStepId,
  getExtraStepData,
  (currentStepId, extraStepData) => {
    const currentStep = extraStepData.get(currentStepId);
    const allStepsCurrentDay = extraStepData.filter(
      step =>
        step.get('dayNumber') === currentStep.get('dayNumber') &&
        step.get('id') !== currentStep.get('id')
    );
    return allStepsCurrentDay.size > 0;
  }
);

const getSortedStepsForCurrentDay = createSelector(
  getStepId,
  getExtraStepData,
  (currentStepId, extraStepData = new OrderedMap()) => {
    const currentStep = extraStepData.get(currentStepId, new OrderedMap());
    return extraStepData
      .filter(step => step.get('dayNumber') === currentStep.get('dayNumber'))
      .sortBy(step => step.get('intraDayOrder'));
  }
);

export const getPreviousStepIdInDay = createSelector(
  getStepId,
  getSortedStepsForCurrentDay,
  (currentStepId, sortedStepsForCurrentDay) => {
    const allStepIds = sortedStepsForCurrentDay.keySeq();
    const currentStepIdIndex = allStepIds.indexOf(currentStepId);
    const prevIdx = currentStepIdIndex - 1;
    return prevIdx >= 0 ? allStepIds.get(prevIdx, null) : null;
  }
);

export const getNextStepIdInDay = createSelector(
  getStepId,
  getSortedStepsForCurrentDay,
  (currentStepId, sortedStepsForCurrentDay) => {
    const allStepIds = sortedStepsForCurrentDay.keySeq();

    const currentStepIdIndex = allStepIds.indexOf(currentStepId);
    const nextIdx = currentStepIdIndex + 1;
    return allStepIds.get(nextIdx, null);
  }
);

/**
 * Get the selected date/time from the scheduled send widget
 *
 * Example usage:
 *
 * getSelectedDateTime()(state, { widgetId: 'emailSendWidget' });
 */
export const getSelectedDateTime = createSelector(
  getDateTime,
  getDate,
  getTime,
  getTimeZone,
  (dateTime, date, time, timeZone) => {
    // If one of the predefined values is selected, return it.
    if (dateTime !== 'custom') {
      return dateTime || dateTimeDefaults.dateTime;
    }

    // Return the derived value from the date, time and timezone.
    const selectedDate = date || dateTimeDefaults.date;
    const selectedTime = time || dateTimeDefaults.time;
    const selectedTimeZone = timeZone || dateTimeDefaults.timeZone;

    return moment
      .tz(
        `${selectedDate} ${selectedTime}`,
        'YYYY-MM-DD hh:mm A',
        selectedTimeZone
      )
      .format();
  }
);

export const makeGetPerson = personId => {
  return createSelector(getPeople, people => {
    return people.get(personId.toString());
  });
};

export const getEmailSignature = state =>
  state.getIn(['workStep', 'emailSignature']);
export const getEmailAlias = state => state.getIn(['workStep', 'emailAlias']);
export const getEmailAliasName = state =>
  state.getIn(['workStep', 'emailAliasName']);

export const getCallResultOptions = menuItemClass =>
  createSelector(getActivityResultOptions, activityResultOptions => {
    const sortedFormattedOptions = activityResultOptions.reduce(
      (obj, result) => {
        const resultObject = {
          value: `${result.get('type')}-${result.get('id')}`,
          label: result.get('name'),
          menuItemClass,
        };

        if (result.get('type') !== 'ActivityOutcome') {
          obj.noConversation.push(resultObject);
        } else {
          obj.conversation.push(resultObject);
        }

        return obj;
      },
      { noConversation: [], conversation: [] }
    );

    return [
      { label: '', value: '' },
      { label: 'NO CONVERSATION', value: 'noConversation', disabled: true },
      ...sortedFormattedOptions.noConversation,
      { label: 'CONVERSATION', value: 'conversation', disabled: true },
      ...sortedFormattedOptions.conversation,
    ];
  });

export const getPreviousSameDayStepNonCompletedPeopleCount = createSelector(
  getPeople,
  getPeopleToDisplay,
  (people, peopleToDisplay) => {
    if (people && peopleToDisplay) {
      return people.size - peopleToDisplay.size;
    }
    return null; // didn't have one of the parameters to compute default to null matching check in Component
  }
);

export const getActivePersonPhoneNumbers = createSelector(
  getActivePerson,
  person => {
    const numberFormatter = new PhoneNumberFormatter();
    return ['phone', 'mobilephone'].reduce((acc, phoneNumberType) => {
      const displayNumber = person.getIn(
        ['fields', phoneNumberType],
        new Map()
      );

      if (displayNumber.has('display')) {
        return acc.set(
          phoneNumberType,
          new PhoneNumber({
            raw: displayNumber.get('raw') || null,
            display:
              numberFormatter.format(displayNumber.get('display')) || null,
          })
        );
      }
      return acc;
    }, new PhoneNumbers());
  }
);

export const getSmsHistories = state =>
  state.getIn(['workStep', 'smsHistories']);

const getSmsNumberField = state =>
  state.getIn(['form', 'callLogger', 'fields'], new Map());
export const getFocusedSmsNumber = createSelector(
  getActivePerson,
  getSmsNumberField,
  getActivePersonPhoneNumbers,
  (person, field, activePersonPhoneNumbers) => {
    const focusedNumber = field.getIn(
      [`smsNumber-${person.get('id')}`, 'value'],
      ''
    );
    if (focusedNumber) {
      return focusedNumber;
    }
    const { phone, mobilephone } = activePersonPhoneNumbers;
    return phone.raw || mobilephone.raw;
  }
);

const getFocusedPhoneNumberSmsHistory = createSelector(
  getSmsHistories,
  getFocusedSmsNumber,
  (smsHistories, focusedPhoneNumber) => {
    const smsHistoryIncludesNumber = !!smsHistories.get(focusedPhoneNumber);
    let phoneNumberMsgHistory;
    if (smsHistoryIncludesNumber) {
      phoneNumberMsgHistory = smsHistories.get(focusedPhoneNumber);
    } else {
      phoneNumberMsgHistory = new List();
    }
    return phoneNumberMsgHistory;
  }
);

export const getSmsHistoryGroupedByDay = createSelector(
  getFocusedPhoneNumberSmsHistory,
  focusedNumberSmsHistory => {
    return focusedNumberSmsHistory.reduce((orderedMapAcc, entry) => {
      const date = entry.get(SMS_DATE_CREATED).format('MM DD YYYY');

      const newSmsEntry = new SmsMessage(entry);
      if (orderedMapAcc.has(date)) {
        return orderedMapAcc.update(date, currentList =>
          currentList.push(newSmsEntry)
        );
      }
      return orderedMapAcc.set(date, new List([newSmsEntry]));
    }, new OrderedMap());
  }
);

export const makeGetIsExecutingEarly = () => {
  return createSelector(location, locationMap => {
    const searchParams = locationMap && locationMap.get('searchParams');

    return searchParams && searchParams.get('executingEarly') === 'true';
  });
};

// Returns true if a person with the specified sfdcId exists
export const getPersonWithSfdcIdExists = sfdcId =>
  createSelector(getPeople, people =>
    people.some(person => person.get('sfdcId') === sfdcId)
  );

export const getEmailAliases = (state, moboUser) => {
  const aliases = state.getIn(['workStep', 'emailAliases']);

  if (moboUser && !aliases.find(a => a.email === moboUser.email)) {
    return [...aliases, { name: moboUser.name, email: moboUser.email }];
  }

  return aliases;
};

export const getDefaultAlias = state =>
  state.getIn(['workStep', 'defaultAlias']);

export const getPartialSendSize = state =>
  state.getIn(['workStep', 'partialSendSize']);
