import humps from 'humps';
import { fromJS, Map } from 'immutable';
import { includes, isNull, isUndefined, capitalize } from 'lodash-es';
import { createSelector } from 'reselect';

import { createFeatureFlagSelector } from 'Modules/Shared/selectors/featureFlags';

import { OPTIONAL_SFDC_OBJECT_FIELDS } from 'Modules/FlowsShow/constants/index.ts';

import {
  ADDITIONAL_SFDC_TRIGGER_FIELDS,
  DEFAULT_SFDC_UPDATE_FIELDS,
  REFERENCE_FIELD_TYPE,
  RELATION_FIELD_SEPARATOR,
  SUPPORTED_SFDC_TRIGGER_FIELD_TYPES,
} from 'Modules/Shared/constants/automatedActions';

export const salesforceMeta = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta'], new Map());
export const contactFieldsMeta = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'contact', 'fields'], Map());
export const leadFieldsMeta = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'lead', 'fields'], Map());
export const getTaskFieldsMeta = state =>
  state.getIn(['salesforceMeta', 'sfdcMeta', 'task', 'fields'], Map());
export const accountFieldsMeta = state =>
  state
    .getIn(['salesforceMeta', 'sfdcMeta', 'account', 'fields'])
    .mapKeys(key => key.toLowerCase());
export const contactChildren = state =>
  state.getIn(['salesforceMeta', 'contactChildren'], fromJS([]));

export const getSObjectFieldsMeta = (sObject, state) =>
  state
    .getIn(['salesforceMeta', 'sfdcMeta', sObject, 'fields'])
    .mapKeys(key => key.toLowerCase());
/**
 * This is only meant to be used to build other, more specific selectors. If you REALLY need it,
 * export it. Please think about implementing a better, more specific selector if you are thinking
 * about doing this though, because it allows you to create a better interface (return proper
 * CamelCased object names instead of shittylowercase names).
 *
 * @param {string} objectType Keep in mind that we lowercase the names when saving it on the
 *  backend. It's pure stupidity.
 *
 * @return {function} Yields results as Immutable.Map
 * */
const createGetObjectMetadata = objectType => {
  return createSelector(salesforceMeta, meta => {
    // We do some really dumb shit to sfdc meta on the server. On the client, we recursively
    // camel-case the entire object. When doing a lookup, make sure we camelize the query in the
    // same way to maximize productivity and synergy.
    const normalizedObjectType = humps.camelize(objectType);

    const objectMeta = !isUndefined(meta)
      ? meta.get(normalizedObjectType) || null
      : null;

    if (isNull(objectMeta)) {
      const isOptionalSfdcObjectField =
        OPTIONAL_SFDC_OBJECT_FIELDS.includes(objectType);
      if (isOptionalSfdcObjectField) {
        return Map();
      }

      throw new Error(`Object not found in Salesforce metadata: ${objectType}`);
    }

    return objectMeta;
  });
};

/**
 * Returns list of Maps containing type and label of objects that the User can query in Salesforce.
 * Maps to the legacy method UserConfig#get_queryable_sobject_list in the Groove Engine Rails
 * backend.
 *
 * @return {function} Yields results as Immutable.List
 * */
export const queryableObjectTypes = [
  'contact',
  'lead',
  'account',
  'opportunity',
  'campaign',
];
export const makeGetQueryableObjects = () => {
  return createSelector(salesforceMeta, salesforceMeta => {
    const objects = [];

    queryableObjectTypes.forEach(objectType => {
      if (salesforceMeta.has(objectType)) {
        objects.push({
          type: objectType,
          label: salesforceMeta.getIn([objectType, 'label']),
        });
      }
    });

    return fromJS(objects);
  });
};

/**
 * @return {function} Yields results as Immutable.List
 * */
export const createGetObjectFieldsMetadata = (objectType, filter = f => f) => {
  return createSelector(
    createGetObjectMetadata(objectType, filter),
    objectMetadata => {
      return objectMetadata.has('fields')
        ? objectMetadata
            .get('fields')
            .valueSeq()
            .toList()
            .map(field => field.set('objectType', capitalize(objectType)))
            .filter(filter)
            .sortBy(field => field.get('name'))
        : null;
    }
  );
};

/**
 * @return {function} Yields result as Immutable.Map
 * */
export const createGetFieldMetadata = (objectType, fieldName) => {
  return createSelector(createGetObjectFieldsMetadata(objectType), fields =>
    fields.find(field => field.get('name') === fieldName)
  );
};

export const getFieldsMetadata = createSelector(
  createGetObjectFieldsMetadata('contact'),
  createGetObjectFieldsMetadata('lead'),
  createGetObjectFieldsMetadata('account'),
  createGetObjectFieldsMetadata('opportunity'),
  (contact, lead, account, opportunity) =>
    new Map({
      contact,
      account,
      ...(lead && { lead }),
      ...(opportunity && { opportunity }),
    })
);

const buildOptionsFromFields = (fields, objectName) => {
  return fields.reduce((fieldArray, field) => {
    fieldArray.push(
      fromJS({
        name: `${objectName}.${field.get('name')}`,
        label: field.get('label'),
      })
    );

    return fieldArray;
  }, []);
};

export const getMergeFields = createSelector(salesforceMeta, sfdcMeta => {
  const supportedObjects = [
    'lead',
    'contact',
    'user',
    'account',
    'opportunity',
  ];
  const popularFields = {
    label: 'Most popular',
    options: [
      fromJS({ label: 'First Name', name: 'firstname' }),
      fromJS({ label: 'Name', name: 'name' }),
      fromJS({ label: 'Company', name: 'company' }),
      fromJS({ label: 'Last Name', name: 'lastname' }),
      fromJS({ label: 'User First Name', name: 'user.firstname' }),
    ],
  };
  const relatedObjectField = {
    label: 'Related Objects',
    options: [
      fromJS({
        label: 'Related Object Placeholder',
        name: 'RelatedObject.set_API_name_here',
      }),
    ],
  };

  const mergeFields = sfdcMeta.reduce(
    (result, value, key) => {
      const sfdcObject = key;
      const sfdcObjectAttributes = value;

      if (includes(supportedObjects, sfdcObject)) {
        const sfdcObjectName = sfdcObjectAttributes.get('label');
        const objectFields = sfdcObjectAttributes.get('fields');

        result.push({
          label: sfdcObjectName,
          options: buildOptionsFromFields(objectFields, sfdcObjectName),
        });
      }

      return result;
    },
    [popularFields]
  );

  return [...mergeFields, relatedObjectField];
});

export const getMergeFieldsJS = createSelector(getMergeFields, mergeFields => {
  return mergeFields.map(section => ({
    ...section,
    options: section.options
      .map(option => option.toJS())
      .sort((option1, option2) => option1.label.localeCompare(option2.label)),
  }));
});

export const getObjectLabels = createSelector(
  salesforceMeta,
  salesforceMeta => {
    const objectLabelsMap = {};
    salesforceMeta.forEach((metadata, objectType) => {
      objectLabelsMap[objectType] = metadata.get('label');
    });

    return new Map(objectLabelsMap);
  }
);

export const getObjectFieldsPicklistOptions = createSelector(
  getFieldsMetadata,
  getObjectLabels,
  (fieldsMetadata, objectLabels) =>
    fieldsMetadata
      .map((fields, objectType) => ({
        label: objectLabels.get(objectType),
        options: fields.toList().toArray(),
      }))
      .valueSeq()
      .toList()
);

export const getSupportedSfdcUserFields = createSelector(
  createGetObjectFieldsMetadata('user'),
  fieldsMetadata =>
    fieldsMetadata.filter(field =>
      SUPPORTED_SFDC_TRIGGER_FIELD_TYPES.includes(field.get('type'))
    )
);

// automated action sfdc update trigger user reference field options
export const getSfdcUpdateUserFieldPicklistOptions = createSelector(
  getSupportedSfdcUserFields,
  supportedUserFields =>
    supportedUserFields
      .filter(field =>
        SUPPORTED_SFDC_TRIGGER_FIELD_TYPES.includes(field.get('type'))
      )
      .toArray()
);

// automated action sfdc update trigger options
export const getSfdcUpdateFieldPicklistOptions = createSelector(
  createFeatureFlagSelector('aaSalesforceTriggerMoreSupportedFields'),
  getFieldsMetadata,
  getObjectLabels,
  (showAllSupportedFields, fieldsMetadata, objectLabels) => {
    return fieldsMetadata
      .map((fields, objectType) => {
        let options = [];

        if (showAllSupportedFields) {
          fields.forEach(field => {
            if (
              SUPPORTED_SFDC_TRIGGER_FIELD_TYPES.includes(field.get('type'))
            ) {
              options.push(field);
            }

            if (
              ADDITIONAL_SFDC_TRIGGER_FIELDS[capitalize(objectType)].includes(
                field.get('name').toLowerCase()
              )
            ) {
              options.push(field);
            }

            if (
              field.get('type') === REFERENCE_FIELD_TYPE &&
              field.get('referenceTo') === 'User'
            ) {
              options.push(
                field.merge({
                  name: `${field.get('relationshipName').toLowerCase()}.`,
                })
              );
            }
          });
        } else {
          options = fields
            .filter(field =>
              DEFAULT_SFDC_UPDATE_FIELDS[capitalize(objectType)].includes(
                field.get('name').toLowerCase()
              )
            )
            .toArray();
        }

        return {
          label: objectLabels.get(objectType),
          options: options.sort((option1, option2) =>
            option1.get('label').localeCompare(option2.get('label'))
          ),
        };
      })
      .valueSeq()
      .toList()
      .sort((type1, type2) => type1.label.localeCompare(type2.label));
  }
);

export const getSelectedSfdcUpdateFieldPicklistOption = initialValue =>
  createSelector(
    getSfdcUpdateFieldPicklistOptions,
    sfdcUpdateFieldPicklistOptions => {
      if (!initialValue) return null;

      const getOptionValue = option => option.get('name').toLowerCase();
      // reference field ends with dot '.'
      const optionFieldNameValue = initialValue.includes(
        RELATION_FIELD_SEPARATOR
      )
        ? `${initialValue.split(RELATION_FIELD_SEPARATOR)[0]}.`
        : initialValue;
      return sfdcUpdateFieldPicklistOptions
        .flatMap(group => group.options)
        .find(option => getOptionValue(option) === optionFieldNameValue);
    }
  );

export const getSelectedSfdcUpdateUserFieldPicklistOption = initialValue =>
  createSelector(getSfdcUpdateUserFieldPicklistOptions, picklistOptions => {
    if (!initialValue) return null;

    const getOptionValue = option => option.get('name').toLowerCase();
    // reference field ends with dot '.'
    const optionFieldNameValue = initialValue.split(
      RELATION_FIELD_SEPARATOR
    )[1];

    return picklistOptions.find(
      option => getOptionValue(option) === optionFieldNameValue
    );
  });
