import { decamelizeKeys } from 'humps';
import { POST } from 'GrooveHTTPClient/HTTPMethod';
import makeHTTPRequest from '../../makeHTTPRequest';

const BASE_PATH = '/api/v2/admin/users';

export const index = ({
  format,
  includeInactive,
  limit,
  offset,
  order,
  searchQuery,
  sfdcUserIds,
  skipTotal,
  userIds,
  hiddenUsers,
  status,
  licensed,
  includeFlowUsage,
  includeFlowUnlimited,
  includeCallRecordingSetting,
}) =>
  makeHTTPRequest({
    path: BASE_PATH,
    queryParameters: decamelizeKeys({
      format,
      includeInactive,
      limit,
      offset,
      order,
      searchQuery,
      sfdcUserIds,
      skipTotal,
      userIds,
      hiddenUsers,
      status,
      licensed,
      includeFlowUsage,
      includeFlowUnlimited,
      includeCallRecordingSetting,
    }),
  });

export const batchDeactivate = ({ userIds }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/batch_deactivate`,
    method: POST,
    body: decamelizeKeys({
      userIds,
    }),
  });

export const loginAs = ({ userId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${userId}/login_as`,
    method: POST,
  });

export const batchCreate = ({ users }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/batch_create`,
    method: POST,
    body: decamelizeKeys({
      users,
    }),
  });

export const hide = ({ userIds }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/hide`,
    method: POST,
    body: decamelizeKeys({
      userIds,
    }),
  });

export const revealHidden = ({ userIds }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/reveal_hidden`,
    method: POST,
    body: decamelizeKeys({
      userIds,
    }),
  });

export const userCount = ({ includeInactive }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/user_count`,
    queryParameters: decamelizeKeys({
      includeInactive,
    }),
  });

export const transferFlows = ({
  fromUserId,
  toUserId,
  flowIds,
  includePersonStepsAndFlows,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/transfer_flows`,
    method: POST,
    body: decamelizeKeys({
      fromUserId,
      toUserId,
      flowIds,
      includePersonStepsAndFlows,
    }),
  });
