import { actions, selectors, listToOrderedMap } from '@groove-labs/groove-ui';
import {
  select,
  takeLatest,
  put,
  call,
  all,
  fork,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import { searchWithOpportunities } from 'GrooveHTTPClient/salesforceAccountSearch';
import {
  actionTypes,
  searchByName as searchByNameAction,
  resetReducer,
  clearSelectedObjects,
} from 'Modules/Spaces/import/actions';
import {
  TABS,
  SPACES_IMPORT_DIALOG_ACTIVE_TAB_UI_KEY_PATH,
} from 'Modules/Spaces/shared/constants';
import {
  SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH,
  SPACES_IMPORT_DIALOG_ACTIVE_TAB_IS_LOADING_UI_KEY_PATH,
  ACCOUNTS_I_OWN_USERS_FIELD_VALUE,
  ACCOUNTS_I_OWN_SALESFORCE_FIELD_FIELD_VALUE,
  ACCOUNTS_I_OWN_GROUP_ID,
} from 'Modules/Spaces/import/constants';
import { index as usersIndex } from 'GrooveHTTPClient/users';
import { fromJS } from 'immutable';
import { fetchReportFolders } from 'Modules/PeopleImportDialog/submodules/reports/actions/index';

import { getWorkspaceType } from 'Modules/Spaces/show/selectors';
import { currentUser } from 'Modules/Shared/selectors/users';
import handleSearchByName from './handleSearchByName';
import handleSearchByField from './handleSearchByField';
import handleSearchByReport from './handleSearchByReport';
import handleBatchCreate from './handleCreateSObjects';

const { actionTypes: formActionTypes } = actions.form;
const { actionTypes: uiActionTypes, setProperty } = actions.ui;
const { getGroupFields } = selectors.form;

// -------------- Helper --------------
function* shiftCurrentUserToTop({ users }) {
  const currUser = yield select(currentUser);
  const indexOfCurrentUser = users.findIndex(
    user => currUser.get('id') === user.id
  );
  if (indexOfCurrentUser !== -1) {
    [users[0], users[indexOfCurrentUser]] = [
      users[indexOfCurrentUser],
      users[0],
    ];
    users[0].name = 'Me';
  }
  return users;
}

// -------------- Handlers --------------

function* handleSearchWithOpportunities() {
  yield put({ type: actionTypes.SEARCH_WITH_OPPORTUNITIES.PROGRESS });
  const workspaceType = yield select(getWorkspaceType);

  let response = null;
  try {
    response = yield call(searchWithOpportunities);
  } catch (e) {
    // do nothing; the error is already captured upstream
  } finally {
    if (response) {
      yield put({
        type: actionTypes.SEARCH_WITH_OPPORTUNITIES.SUCCESS,
        payload: {
          results: response.data,
          workspaceType,
        },
      });
      yield put(
        setProperty({
          uiKeyPath: SPACES_IMPORT_DIALOG_PAGE_UI_KEY_PATH,
          data: 1,
        })
      );
    } else {
      yield put({
        type: actionTypes.SEARCH_WITH_OPPORTUNITIES.FAILURE,
        payload: response,
      });
    }
  }
}

function* handleFetchUsers() {
  yield put({ type: actionTypes.FETCH_USERS.PROGRESS });
  let response = null;
  try {
    response = yield call(usersIndex);
  } catch (e) {
    // do nothing; the error is already captured upstream
  } finally {
    if (response && response.meta.success) {
      const rearrangedUsers = yield* shiftCurrentUserToTop({
        users: response.data,
      });
      yield put({
        type: actionTypes.FETCH_USERS.SUCCESS,
        payload: listToOrderedMap(fromJS(rearrangedUsers), user =>
          user.get('id')
        ),
      });
    } else {
      yield put({ type: actionTypes.FETCH_USERS.FAILURE });
    }
  }
}

function* handleOwnershipSearchChange() {
  yield put(clearSelectedObjects());
  const groupFields = yield select(state =>
    getGroupFields(state, { groupId: ACCOUNTS_I_OWN_GROUP_ID })
  );
  const sfdcFieldNameFieldData = groupFields.getIn([
    ACCOUNTS_I_OWN_SALESFORCE_FIELD_FIELD_VALUE,
    'value',
  ]);
  const sfdcFieldValueFieldData = groupFields.getIn([
    ACCOUNTS_I_OWN_USERS_FIELD_VALUE,
    'value',
  ]);
  if (sfdcFieldNameFieldData && sfdcFieldValueFieldData) {
    yield call(handleSearchByField, {
      sfdcFieldName: sfdcFieldNameFieldData.get('name'),
      sfdcFieldValue: sfdcFieldValueFieldData.get('sfdcUserId'),
    });
  }
}

function* handleActiveTabChange({ payload }) {
  yield put(resetReducer());
  yield put(
    setProperty({
      uiKeyPath: SPACES_IMPORT_DIALOG_ACTIVE_TAB_IS_LOADING_UI_KEY_PATH,
      data: true,
    })
  );
  const { data } = payload;
  if (data === TABS.ownershipSearch) {
    yield call(handleFetchUsers);
  }

  if (data === TABS.salesforceReport) {
    yield put(fetchReportFolders());
  }

  if (data === TABS.opportunitySearch) {
    yield delay(300);
    yield call(handleSearchWithOpportunities);
  }
  yield put(
    setProperty({
      uiKeyPath: SPACES_IMPORT_DIALOG_ACTIVE_TAB_IS_LOADING_UI_KEY_PATH,
      data: false,
    })
  );
}

function* handleQuickSearchChange({ payload }) {
  yield put(clearSelectedObjects());
  if (payload.value.length > 1) {
    yield put(searchByNameAction({ name: payload.value }));
  }
}

function isAccountOwnershipField(fieldId) {
  return [
    ACCOUNTS_I_OWN_SALESFORCE_FIELD_FIELD_VALUE,
    ACCOUNTS_I_OWN_USERS_FIELD_VALUE,
  ].includes(fieldId);
}

function* handleAccountOwnershipFieldRegistry() {
  yield delay(300);
  yield call(handleOwnershipSearchChange);
}

// -------------- Watchers --------------

function* watchQuickSearchChange() {
  yield takeEvery(
    action =>
      action.type === formActionTypes.UPDATE_FIELD_VALUE &&
      action.payload.fieldId === 'spacesImportQuickSearch',
    handleQuickSearchChange
  );
}

function* watchOwnershipSearchChange() {
  yield takeLatest(
    action =>
      action.type === formActionTypes.UPDATE_FIELD_VALUE &&
      isAccountOwnershipField(action.payload.fieldId) &&
      action.payload.isValid,
    handleOwnershipSearchChange
  );
}

function* watchSearchByName() {
  yield takeLatest(actionTypes.SEARCH_BY_NAME.BEGIN, handleSearchByName);
}

function* watchAccountOwnershipFieldRegistry() {
  yield takeLatest(
    action =>
      action.type === formActionTypes.REGISTER_FIELD &&
      isAccountOwnershipField(action.payload.fieldId) &&
      action.payload.isValid,
    handleAccountOwnershipFieldRegistry
  );
}

function* watchSearchByReport() {
  yield takeLatest(actionTypes.SEARCH_BY_REPORT.BEGIN, handleSearchByReport);
}

function* watchBatchCreate() {
  yield takeEvery(actionTypes.BATCH_CREATE.BEGIN, handleBatchCreate);
}

function* watchActiveTabChange() {
  yield takeLatest(
    action =>
      action.type === uiActionTypes.SET_PROPERTY &&
      action.payload.uiKeyPath === SPACES_IMPORT_DIALOG_ACTIVE_TAB_UI_KEY_PATH,
    handleActiveTabChange
  );
}

// -------------- Exports --------------

export default function* root() {
  yield all([
    fork(watchQuickSearchChange),
    fork(watchOwnershipSearchChange),
    fork(watchSearchByReport),
    fork(watchBatchCreate),
    fork(watchActiveTabChange),
    fork(watchAccountOwnershipFieldRegistry),
    fork(watchSearchByName),
  ]);
}
