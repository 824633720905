import { Map, Record } from 'immutable';
import moment from 'moment-timezone';
import { PRIORITIES } from 'Modules/Shared/constants/index';
import { SOURCES } from 'Modules/Actions/constants';
import { constructSummaryMetadata } from 'Modules/Actions/utils';

import User from './User';
import Person from './Person';
import Account from './Account';
import FlowMetadata from './FlowMetadata';
import WorkspacesMetadata from './WorkspacesMetadata';
import Failure from './Failure';

const defaults = {
  id: null,
  type: null,
  who: new Person(),
  summary: '',
  source: null,
  assignee: new User(),
  creator: new User(),
  dueAt: null,
  completedAt: null,
  templateName: null,
  account: new Account(),
  sourceMetadata: new Map(),
  failed: null,
  priority: PRIORITIES.NORMAL,
};

const constructSourceMetaData = (source, action) => {
  const summaryMetadata = constructSummaryMetadata(action.get('summary'));
  let actionMetadata;
  if (source === SOURCES.FLOW) {
    const flowMetadata = {
      personFlowId: action?.getIn(['child', 'personFlowId']),
      flowId: action.getIn(['child', 'flowId']),
      stepId: action.getIn(['child', 'stepId']),
      day: summaryMetadata.day,
      flowName: summaryMetadata.flowName,
    };
    actionMetadata = FlowMetadata.from(flowMetadata);
  } else if (source === SOURCES.WORKSPACE) {
    const workspacesMetadata = {
      sObjectTaskId: action.getIn(['child', 'id']),
    };
    actionMetadata = WorkspacesMetadata.from(workspacesMetadata);
  }
  return actionMetadata;
};

export default class Action extends Record(defaults) {
  static from(action) {
    const source = action.get('source');
    const who = action.get('who');
    const account = action.get('account');
    const failed = action.get('failed');

    return new Action({
      id: action.get('id'),
      type: action.get('type'),
      summary: action.get('summary'),
      priority: action.get('priority'),
      templateName: action.getIn(['executionContext', 'templateName']),
      sourceMetadata: constructSourceMetaData(source, action),
      source,
      account: new Account(account),
      ...(failed && { failed: Failure.from(failed) }),
      ...(action.get('who') && { who: Person.from(who) }),
      ...((action.has('assignee') || action.has('creator')) && {
        assignee:
          new User(action.get('assignee')) || new User(action.get('creator')),
      }),
      ...(action.has('creator') && {
        creator: new User(action.get('creator')),
      }),
      ...(action.has('dueAt') && {
        dueAt: moment(action.get('dueAt')),
      }),
      ...(action.has('completedAt') && {
        completedAt: moment(action.get('completedAt')),
      }),
    });
  }
}
