import { actions, selectors } from '@groove-labs/groove-ui';
import { isNull, range, isUndefined } from 'lodash-es';
import { List, Map } from 'immutable';
import { logError } from 'Modules/Shared/actions/errors';
import {
  actionTypes as peopleImportActionTypes,
  requestProcessSearchResults,
  setSearching,
  setNoResultsFound,
} from 'Modules/PeopleImportDialog/actions';
import { resetTableResults } from 'Modules/PeopleImportDialog/submodules/peopleTable/actions';
import {
  actionTypes,
  updateCsvLookupState,
  searchByCsv as searchByCsvAction,
  reset,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/actions';
import {
  CUSTOM_MERGE_FIELD_PREFIX,
  CUSTOM_MERGE_FIELD_REGEXP,
} from 'Modules/PeopleImportDialog/constants';
import {
  ROWS_PER_REQUEST_LIMIT,
  AUTO_CREATION_MODAL_KEY_PATH,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/constants';
import {
  guessImportByColumnIdx,
  isEmailColumnSelected,
  isSfdcIdColumnSelected,
  isValidSfdcId,
  initializeSelectedObject,
  buildPeopleSearchParams,
  combineResponses,
  combineContactAndLeadResponses,
  addCustomField,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/utils';
import { get18DigitId } from 'Modules/PeopleImportDialog/utils';
import {
  getCsvHeader,
  getCsvRows,
  getFoundValidRow,
  getGroupId,
  getInitialSelectedObject,
  getKeyPrefixes,
  getSelectedObjectField,
} from 'Modules/PeopleImportDialog/submodules/csvLookup/selectors';
import { searchPeople, uploadCsv } from 'GrooveHTTPClient/peopleImport';
import { handleInvalidSalesforceConnectionHTTPRequest } from 'GrooveHTTPClient/sagas';
import { pushSnackbarMessage } from 'Modules/Shared/actions/app';
import {
  all,
  call,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { SALESFORCE_OBJECTS } from 'Modules/Shared/constants';
import { getFlowId } from 'Modules/FlowsShow/selectors';

const { actionTypes: formActionTypes, requestDeleteField } = actions.form;
const { makeGetFieldValue, makeGetIsFieldRegistered } = selectors.form;
const { setProperty } = actions.ui;

// -------------- Helpers ---------------

export function* handleCsvLookupResponse(
  data,
  success,
  message,
  customMergeValues
) {
  if (!success) {
    yield put(
      pushSnackbarMessage({
        message: message || 'Something went wrong when searching',
      })
    );

    yield put(resetTableResults());

    return;
  }

  if (!customMergeValues) {
    yield put(requestProcessSearchResults({ data }));

    return;
  }

  const customKeys = Object.keys(customMergeValues);

  // get the first key to read merge field names
  const [firstKey] = customKeys;

  if (!firstKey) {
    yield put(requestProcessSearchResults({ data }));

    return;
  }

  const lookupField = (yield select(getSelectedObjectField)).toLowerCase();

  const { columnData, results, peopleResults } = data;

  const customFields = Object.keys(customMergeValues[firstKey]);

  customFields.forEach(field =>
    columnData.push({
      fieldNameOrPath: `${CUSTOM_MERGE_FIELD_PREFIX}${field}`,
      hidden: false,
      label: `flow.${field}`,
      sortable: true,
      type: 'string',
    })
  );

  const lowerCasingKeys = customKeys.reduce(
    (previous, currentMergeKey) => ({
      ...previous,
      [currentMergeKey.toLowerCase()]: currentMergeKey,
    }),
    {}
  );

  results.forEach(result =>
    addCustomField(
      result,
      lookupField,
      lowerCasingKeys,
      customMergeValues,
      customFields
    )
  );

  peopleResults.forEach(personResult =>
    addCustomField(
      personResult,
      lookupField,
      lowerCasingKeys,
      customMergeValues,
      customFields
    )
  );

  yield put(requestProcessSearchResults({ data }));
}

export function* dropSalesforceObjectField(groupId) {
  const fieldIsRegistered = yield select(
    makeGetIsFieldRegistered(groupId, 'salesforceObject')
  );
  if (fieldIsRegistered) {
    yield put(
      requestDeleteField({
        groupId,
        fieldId: 'salesforceObject',
      })
    );
  }
}

export function* dropLookupByColumnIdxField(groupId) {
  const fieldIsRegistered = yield select(
    makeGetIsFieldRegistered(groupId, 'lookupByColumnIdx')
  );
  if (fieldIsRegistered) {
    yield put(
      requestDeleteField({
        groupId,
        fieldId: 'lookupByColumnIdx',
      })
    );
  }
}

// -------------- Handlers --------------

function* uploadCsvFile({ payload }) {
  try {
    const { file, groupId, cancel } = payload;
    // cancel saga task when you use takeLatest helper
    if (cancel) {
      return;
    }

    yield put({ type: actionTypes.UPLOAD_CSV.PROGRESS });

    const channel = yield call(uploadCsv, file);

    while (true) {
      const { err, success, response } = yield take(channel);
      if (err) {
        yield all([
          put({ type: actionTypes.UPLOAD_CSV.FAILURE }),
          put({
            type: actionTypes.SET_CSV_ERROR_MESSAGE,
            payload: response.error,
          }),
        ]);
      }
      if (success && response.meta.success) {
        const csvRows = response.data.rows;
        const csvHeader = new List(csvRows.shift());
        const csvDataRowCount = response.data.rows.length;
        let lookupByColumnIdx;
        let foundValidRow;
        let rowIndex = 0;
        let error;

        do {
          const row = new List(csvRows[rowIndex]);
          lookupByColumnIdx = guessImportByColumnIdx(row, csvHeader);
          if (typeof lookupByColumnIdx === 'number') {
            foundValidRow = row;
          }
          rowIndex += 1;
        } while (rowIndex < csvDataRowCount && isUndefined(lookupByColumnIdx));

        if (!lookupByColumnIdx) {
          error = 'Email or SFDC ID column was not detected';
        }

        let initialSelectedObject;
        // preset Object Type and field when SFDC IDS
        if (
          foundValidRow &&
          isSfdcIdColumnSelected(foundValidRow, lookupByColumnIdx)
        ) {
          initialSelectedObject = initializeSelectedObject(
            lookupByColumnIdx,
            csvHeader,
            new List(csvRows[lookupByColumnIdx]),
            new Map(response.data.keyPrefixes)
          );
        }

        const stateData = {
          csvHeader,
          csvRows,
          error,
          groupId,
          initialLookupByColumnIdx: lookupByColumnIdx,
          keyPrefixes: response.data.keyPrefixes,
          initialSelectedObject,
          selectedObjectField: null,
          uploading: false,
          foundValidRow,
          warning: response.data.warning,
        };

        yield put({ type: actionTypes.UPLOAD_CSV.SUCCESS, payload: stateData });

        return;
      }
    }
  } catch (e) {
    console.error(e); // eslint-disable-line
    yield put(logError({ error: e, isUiError: false }));
    yield all([
      put(
        pushSnackbarMessage({
          message: 'Something went wrong when uploading your file',
        })
      ),
      put({ type: actionTypes.UPLOAD_CSV.FAILURE }),
    ]);
  }
}

export function* resetUploadedFile() {
  const groupId = yield select(getGroupId);

  yield all([
    put(resetTableResults()),
    put({ type: actionTypes.UPLOAD_CSV.BEGIN, payload: { cancel: true } }),
    call(dropLookupByColumnIdxField, groupId),
    call(dropSalesforceObjectField, groupId),
    put(setNoResultsFound(false)),
  ]);
}

function* changeLookupByColumnIdx({ payload }) {
  try {
    const { value: lookupByColumnIdx, groupId } = payload;

    const csvHeader = yield select(getCsvHeader);
    const csvRows = yield select(getCsvRows);
    const keyPrefixes = yield select(getKeyPrefixes);
    let foundValidRow = yield select(getFoundValidRow);

    // check if new lookup column index value present
    if (!foundValidRow || !foundValidRow.get(lookupByColumnIdx)) {
      const csvDataRowCount = csvRows.size - 1;
      let rowIndex = 0;

      foundValidRow = undefined;

      // search for a new valid row
      do {
        if (csvRows.getIn([rowIndex, lookupByColumnIdx], null)) {
          foundValidRow = csvRows.get(rowIndex);
        }
        rowIndex += 1;
      } while (rowIndex < csvDataRowCount && isUndefined(foundValidRow));
    }

    if (!foundValidRow) {
      yield put({
        type: actionTypes.UPLOAD_CSV.SUCCESS,
        payload: {
          error: 'The selected column is blank',
        },
      });
      return;
    }

    let selectedObjectField;
    let initialSelectedObject;

    if (isSfdcIdColumnSelected(foundValidRow, lookupByColumnIdx)) {
      // now we can guess the object type by sfdc Id - eg. Contact, Account
      initialSelectedObject = initializeSelectedObject(
        lookupByColumnIdx,
        csvHeader,
        foundValidRow,
        keyPrefixes
      );
      selectedObjectField = 'id';
    } else if (isEmailColumnSelected(foundValidRow, lookupByColumnIdx)) {
      // other case search by email
      selectedObjectField = 'Email';
    } else {
      selectedObjectField = 'NOT SUPPORTED';
    }

    const currentSelectedObjectField = yield select(getSelectedObjectField);

    yield all([
      put(
        updateCsvLookupState({
          error: null,
          initialSelectedObject,
          selectedObjectField,
          foundValidRow,
        })
      ),
      put(resetTableResults()),
    ]);

    // don't drop objectField field when switch between email columns
    if (
      currentSelectedObjectField !== selectedObjectField ||
      selectedObjectField !== 'Email'
    ) {
      yield call(dropSalesforceObjectField, groupId);
    }
  } catch (e) {
    console.error(e); // eslint-disable-line
    yield put(logError({ error: e, isUiError: false }));
    yield all([
      put(
        pushSnackbarMessage({
          message: 'Something went wrong',
        })
      ),
    ]);
  }
}

function* selectObject({ payload }) {
  // TODO get rid of this action where we don't fetch object fields anymore
  const { value: selectedObject } = payload;
  const relationshipName = null;

  yield put(
    updateCsvLookupState({
      initialSelectedObject: selectedObject,
      selectedObjectRelationship: relationshipName,
    })
  );
}

function* searchSalesforceObjectsInChunks(
  salesforceObject,
  upperLimit,
  rowsLimit,
  values,
  selectedObjectField
) {
  const flowId = yield select(getFlowId);
  const searchResults = yield all(
    range(0, upperLimit).map(index => {
      const lower = index * rowsLimit;
      const upper = Math.min(lower + rowsLimit, values.size);
      const valuesChunk = values.slice(lower, upper);

      return call(handleInvalidSalesforceConnectionHTTPRequest, searchPeople, {
        objectName: salesforceObject,
        values: buildPeopleSearchParams(valuesChunk),
        selectedObjectField,
        flowId,
      });
    })
  );

  return {
    data: combineResponses(searchResults),
    success: searchResults?.[0].meta.success,
    message: searchResults?.[0].data.message,
  };
}

function* searchByCsv({ payload }) {
  const { rowsLimit = ROWS_PER_REQUEST_LIMIT } = payload;
  try {
    // reset table to remove pagination
    yield put(resetTableResults());

    // Before searching ensure the no results indicator is set to false.
    yield put(setNoResultsFound(false));

    yield put(setSearching(true));

    const groupId = yield select(getGroupId);
    const csvRows = yield select(getCsvRows);
    const lookupByColumnIdx = yield select(
      makeGetFieldValue('lookupByColumnIdx'),
      { groupId }
    );
    const values = csvRows
      .map(row => {
        if (row.get(lookupByColumnIdx)) {
          return row.get(lookupByColumnIdx).trim();
        }
        return undefined;
      })
      .filter(row => row !== undefined);

    // when no values to search by
    if (values.size < 1) {
      yield put(setSearching(false));
      return;
    }

    let selectedObject = yield select(makeGetFieldValue('salesforceObject'), {
      groupId,
    });
    if (!selectedObject) {
      selectedObject = yield select(getInitialSelectedObject);
    }

    const csvHeader = yield select(getCsvHeader);

    const customMergeFieldHeaderMap = csvHeader.reduce((fields, label, idx) => {
      if (isNull(label)) return fields;

      const customMergeFieldMatch = label.match(CUSTOM_MERGE_FIELD_REGEXP);

      if (customMergeFieldMatch) {
        fields.push({
          idx,
          name: customMergeFieldMatch[1],
        });
      }
      return fields;
    }, []);

    // build custom merge field map
    // { qw@fs.sl || 003De43..: { field1: .., field2 }
    const customMergeValuesByLookupColumn = csvRows.reduce(
      (mergeFields, row) => {
        const customMergeFields = customMergeFieldHeaderMap.reduce(
          (fields, header) => {
            fields[header.name] = row.get(header.idx);
            return fields;
          },
          {}
        );
        let lookupColumnValue = row.get(lookupByColumnIdx);
        if (isValidSfdcId(lookupColumnValue)) {
          // Make sure the SFDC ID is 18 char length
          // Note the method throw an error when the sfdc id is invalid
          lookupColumnValue = get18DigitId(lookupColumnValue);
        }
        mergeFields[lookupColumnValue] = customMergeFields;
        return mergeFields;
      },
      {}
    );

    const selectedObjectField = yield select(getSelectedObjectField);

    // If the number of values are smaller than the limit, do a normal non-batch request
    if (values.size <= rowsLimit) {
      // Perform search and parse results
      // and cancel the action when a tab is switched
      const flowId = yield select(getFlowId);
      const {
        response: {
          data: contactSearchResults,
          meta: { success },
        },
        cancel: cancelContactSearch,
      } = yield race({
        response: call(
          handleInvalidSalesforceConnectionHTTPRequest,
          searchPeople,
          {
            objectName: SALESFORCE_OBJECTS.Contact,
            values: buildPeopleSearchParams(values),
            selectedObjectField,
            flowId,
          }
        ),
        cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
      });

      const {
        response: { data: leadSearchResults },
        cancel: cancelLeadSearch,
      } = yield race({
        response: call(
          handleInvalidSalesforceConnectionHTTPRequest,
          searchPeople,
          {
            objectName: SALESFORCE_OBJECTS.Lead,
            values: buildPeopleSearchParams(values),
            selectedObjectField,
            flowId,
          }
        ),
        cancel: take(peopleImportActionTypes.REQUEST_SET_ACTIVE_TAB),
      });

      if (cancelContactSearch || cancelLeadSearch) {
        yield put(setSearching(false));
        return;
      }

      yield* handleCsvLookupResponse(
        combineContactAndLeadResponses(contactSearchResults, leadSearchResults),
        success,
        contactSearchResults?.message,
        customMergeValuesByLookupColumn
      );
    } else {
      // Get the number of calls we need to make. e.g. if the values are 420 and the rowsLimit is 100, we will make
      // 4 calls of 100 each plus one call of 20, totalling to 5 calls.
      const upperLimit = Math.ceil(values.size / rowsLimit);

      // TODO add some kind of progress bar eg. 100 of 900 rows processed;
      // sometimes when user upload huge csv it take even several minutes till
      // we fetch all batch results
      // And

      // break values into chunks by the rows limit and send one call each
      // Use "yield all" to wait for all asynchronous calls to finish
      const [
        { success, message, data: contactSearchResults },
        { data: leadSearchResults },
      ] = yield all([
        searchSalesforceObjectsInChunks(
          SALESFORCE_OBJECTS.Contact,
          upperLimit,
          rowsLimit,
          values,
          selectedObjectField
        ),
        searchSalesforceObjectsInChunks(
          SALESFORCE_OBJECTS.Lead,
          upperLimit,
          rowsLimit,
          values,
          selectedObjectField
        ),
      ]);

      const combinedSearchData = combineContactAndLeadResponses(
        contactSearchResults,
        leadSearchResults
      );

      if (combinedSearchData) {
        yield* handleCsvLookupResponse(
          combinedSearchData,
          success,
          message,
          customMergeValuesByLookupColumn
        );
      }
    }
  } catch (e) {
    const TINY_LIMIT = 40;
    // when we got an error we try to fetch the result in smaller batches
    if (rowsLimit > TINY_LIMIT) {
      yield put(searchByCsvAction({ rowsLimit: TINY_LIMIT }));
    } else {
      console.error(e); // eslint-disable-line
      yield put(
        pushSnackbarMessage({
          message: 'Something went wrong when searching',
        })
      );
      yield put(setSearching(false));
      yield put(resetTableResults());
    }
  }
}

function* handleResetCsvLookup() {
  // When dialog is closed, clear the lookup form data and the csv submodule state
  const groupId = yield select(getGroupId);

  yield all([
    call(dropLookupByColumnIdxField, groupId),
    call(dropSalesforceObjectField, groupId),
    put(reset()),
  ]);
}

function* handleOpenAutoCreationModal() {
  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_MODAL_KEY_PATH,
      data: true,
    })
  );
}

function* handleCloseAutoCreationModal() {
  yield put(
    setProperty({
      uiKeyPath: AUTO_CREATION_MODAL_KEY_PATH,
      data: false,
    })
  );
}

// -------------- Watchers --------------

function* watchUploadCsvFile() {
  yield takeLatest(actionTypes.UPLOAD_CSV.BEGIN, uploadCsvFile);
}

function* watchLookupByColumnIdxChanged() {
  yield takeLatest(
    action =>
      (action.type === formActionTypes.UPDATE_FIELD_VALUE ||
        action.type === formActionTypes.REGISTER_FIELD) &&
      action.payload.fieldId === 'lookupByColumnIdx',
    changeLookupByColumnIdx
  );
}

function* watchSalesforceObjectChanged() {
  yield takeLatest(
    action =>
      (action.type === formActionTypes.UPDATE_FIELD_VALUE ||
        action.type === formActionTypes.REGISTER_FIELD) &&
      action.payload.fieldId === 'salesforceObject',
    selectObject
  );
}

function* watchReset() {
  yield takeLatest(actionTypes.RESET, resetUploadedFile);
}

function* watchSearchByCsv() {
  yield takeLatest(actionTypes.SEARCH_BY_CSV, searchByCsv);
}

function* watchResetPeopleImportDialog() {
  yield takeEvery(peopleImportActionTypes.RESET, handleResetCsvLookup);
}

function* watchOpenAutoCreationModal() {
  yield takeEvery(
    actionTypes.OPEN_AUTO_CREATION_MODAL,
    handleOpenAutoCreationModal
  );
}

function* watchCloseAutoCreationModal() {
  yield takeEvery(
    actionTypes.CLOSE_AUTO_CREATION_MODAL,
    handleCloseAutoCreationModal
  );
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchUploadCsvFile),
    fork(watchLookupByColumnIdxChanged),
    fork(watchSalesforceObjectChanged),
    fork(watchReset),
    fork(watchSearchByCsv),
    fork(watchResetPeopleImportDialog),
    fork(watchOpenAutoCreationModal),
    fork(watchCloseAutoCreationModal),
  ]);
}
