import defaultUpstart from 'Modules/App/sagas/upstarts/default';
import actionComposeUpstart from 'Modules/App/sagas/upstarts/actionCompose';
import workstepUpstart from 'Modules/App/sagas/upstarts/workstep';
import { ROUTE_NAMES } from 'Routing/constants';

const upstarts = {
  [ROUTE_NAMES.ACTION_COMPOSE]: actionComposeUpstart,
  [ROUTE_NAMES.WORKSTEP]: workstepUpstart,
};

export default function determineUpstart({ routeName }) {
  return upstarts[routeName] || defaultUpstart;
}
