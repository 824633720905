import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Palette } from '@material-ui/core/styles/createPalette';

import {
  GROOVEY_BLUEY,
  GROOVEY_BLUEY_1,
  GROOVEY_BLUEY_2,
  WAVY_NAVY,
  WAVY_NAVY_1,
  WAVY_NAVY_2,
  WAVY_NAVY_3,
  SURREAL_TEAL,
  BLACK,
  GRAY_1,
  GRAY_2,
  GRAY_3,
  GRAY_4,
  SPOT_ON_COTTON,
  WHITE,
  SWITCH_TRACK_BLUE,
} from 'Modules/Shared/constants/colors';
import primary from 'Utils/colors/primary';
import secondary from 'Utils/colors/secondary';
import primaryButtonStyles from 'Utils/styles/primaryButton';
import secondaryButtonStyles from 'Utils/styles/secondaryButton';

interface GroovePalette extends Palette {
  tertiary: {
    main: string;
    light: string;
    lighter: string;
  };
  quaternary: {
    main: string;
    dark: string;
    light: string;
    lighter: string;
  };
  text: {
    primary: string;
    secondary: string;
    disabled: string;
    hint: string;
    backgroundColor: string;
    borders: string;
    buttonBorders: string;
    accentGray: string;
    neutralColor: string;
  };
}

export interface ModifiedTheme extends Theme {
  groove: {
    unit: number;
  };
  'Connect(SearchableDropdownMenu)': {
    menuItem: {
      height: number;
    };
  };

  palette: GroovePalette;
}

const modifiedMuiTheme = createTheme({
  palette: {
    primary,
    secondary,
    error: red,
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    MuiTabs: {
      root: {
        color: primary.main,
      },
      indicator: {
        backgroundColor: primary.light,
      },
    },
    MuiInput: {
      root: {
        lineHeight: 'normal',
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${primary.light}`,
        },
      },
    },
    MuiSwitch: {
      colorPrimary: {
        color: GRAY_2,
        '&$checked': {
          color: GROOVEY_BLUEY,
        },
        '&$checked + $track': {
          backgroundColor: SWITCH_TRACK_BLUE,
          opacity: 1,
        },
      },
      switchBase: {
        width: 'auto',
        height: 'auto',
      },
      track: {
        backgroundColor: GRAY_3,
        opacity: 1,
      },
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
  },
  props: {
    MuiFab: {
      color: 'secondary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
  },
});

const GrooveTheme: ModifiedTheme = {
  ...modifiedMuiTheme,
  palette: {
    ...modifiedMuiTheme.palette,
    text: {
      primary: BLACK,
      secondary: GRAY_1,
      disabled: GRAY_1,
      hint: GRAY_1,
      borders: GRAY_2,
      buttonBorders: GRAY_3,
      accentGray: GRAY_4,
      backgroundColor: SPOT_ON_COTTON,
      neutralColor: WHITE,
    },
    tertiary: {
      main: GROOVEY_BLUEY,
      light: GROOVEY_BLUEY_1,
      lighter: GROOVEY_BLUEY_2,
    },
    quaternary: {
      main: SURREAL_TEAL,
      dark: WAVY_NAVY_1,
      light: WAVY_NAVY_2,
      lighter: WAVY_NAVY_3,
    },
  },
  groove: {
    unit: 4,
  },
  'Connect(SearchableDropdownMenu)': {
    menuItem: {
      height: 24,
    },
  },
  typography: {
    ...modifiedMuiTheme.typography,
    fontFamily: '"Open Sans"',
  },
  props: {
    MuiCheckbox: {
      color: 'primary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiTypography: {
      color: 'textPrimary',
      variant: 'body1',
    },
    MuiFab: {
      color: 'secondary',
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: 'subtitle1',
      },
      secondaryTypographyProps: {
        variant: 'body1',
      },
    },
  },
  overrides: {
    ...modifiedMuiTheme.overrides,
    MuiAvatar: {
      root: {
        width: 40,
        height: 40,
      },
    },
    MuiButton: {
      root: {
        height: 36,
        borderRadius: 20,
        padding: '10px 14px',
        minWidth: 88,
        minHeight: 36,
        fontFamily: '"Open Sans"',
        textTransform: 'capitalize',
      },
      containedPrimary: primaryButtonStyles,
      outlinedPrimary: primaryButtonStyles,
      containedSecondary: secondaryButtonStyles,
      outlinedSecondary: {
        ...secondaryButtonStyles,
        border: `0.5px solid ${GRAY_3}`,
        '&:hover': {
          backgroundColor: `${GROOVEY_BLUEY_2} !important`,
          color: WAVY_NAVY,
          border: `0.5px solid ${GRAY_3}`,
        },
      },
      textSecondary: secondaryButtonStyles,
      text: {
        fontSize: 12,
        fontWeight: 600,
        padding: '8px 16px',
        letterSpacing: 0.5,
      },
      containedSizeSmall: {
        padding: '7px 8px',
      },
    },
    MuiCardHeader: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: GROOVEY_BLUEY,
      },
    },
    MuiCardContent: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiChip: {
      avatar: {
        width: 32,
        color: GRAY_3,
        height: 32,
        fontSize: '1rem',
        marginLeft: 0,
        marginRight: '-4px',
      },
      root: {
        '& $avatar': {
          color: undefined,
          height: 32,
          width: 32,
          marginLeft: undefined,
          marginRight: undefined,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
      },
    },
    MuiFab: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'inherit',
        marginTop: 8,
        minHeight: '1em',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -14,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: '0.8125rem',
        letterSpacing: '1.5px',
      },
      root: {
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '@media (min-width: 960px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 'auto',
        minHeight: '1em',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'inherit',
      },
      input: {
        width: '100%',
      },
      fullWidth: {
        '& $input': {
          width: '100%',
        },
      },
    },
    MuiTable: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
      },
      selected: {
        '&$root': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'inherit',
        padding: '4px 56px 4px 24px',
        fontSize: '0.75rem',
        letterSpacing: 'normal',
        lineHeight: 'inherit',
      },
      paddingCheckbox: {
        padding: 'inherit',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      dense: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiListItemText: {
      root: {
        padding: '0 16px',
        marginTop: 0,
        marginBottom: 0,
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'inherit',
        paddingTop: 12,
        paddingBottom: 12,
        height: 24,
        boxSizing: 'content-box',
      },
    },
    MuiSelect: {
      select: {
        width: '100%',
      },
    },
    MuiTypography: {
      root: {
        display: 'block',
        fontFamily: 'inherit',
      },
      body1: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 400,
        letterSpacing: '0.1px',
        lineHeight: '1.46429em',
      },
      body2: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
      caption: {
        fontFamily: 'inherit',
        letterSpacing: 'normal',
      },
      subtitle1: {
        fontWeight: 600,
        fontFamily: 'inherit',
        letterSpacing: 'normal',
      },
      h1: {
        fontFamily: 'inherit',
      },
      h2: {
        fontFamily: 'inherit',
      },
      h3: {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'inherit',
      },
      h4: {
        fontFamily: 'inherit',
      },
      h5: {
        fontFamily: 'inherit',
      },
      h6: {
        fontSize: '1.3125rem',
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: '1.16667em',
        letterSpacing: '0.5px',
      },
      subtitle2: {
        fontFamily: 'inherit',
      },
      button: {
        fontFamily: 'inherit',
      },
      overline: {
        fontFamily: 'inherit',
      },
    },
  },
};

export default GrooveTheme;
