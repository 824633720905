import { actions } from '@groove-labs/groove-ui';
import HttpStatusCodes from 'http-status-codes';
import {
  index as getTemplates,
  show as getTemplate,
  fetchIndexWithoutHtmlBody,
} from 'GrooveHTTPClient/templates';
import { actionTypes } from 'Modules/Shared/actions/templates';
import { storeCachedTemplateData } from 'Modules/Shared/actions/cached';
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { templatesMapSubtypesForFolder } from 'Modules/Templates/utils';
import { Map } from 'immutable';

const { updateFieldValue } = actions.form;
const { setProperty } = actions.ui;

// -------------- Handlers --------------
export function* fetchTemplateFolderStructure() {
  yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: true }));
  const templatesResponse = yield call(getTemplates);
  const templateFolderStructure = templatesMapSubtypesForFolder(
    templatesResponse.data
  );
  yield put(
    setProperty({
      uiKeyPath: ['templateFolderStructure'],
      data: templateFolderStructure,
    })
  );
  yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
}

export function* fetchTemplateFolderStructureWithoutHtml() {
  yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: true }));
  const templatesResponse = yield call(fetchIndexWithoutHtmlBody);
  const templateFolderStructure = templatesMapSubtypesForFolder(
    templatesResponse.data
  );
  yield put(
    setProperty({
      uiKeyPath: ['templateFolderStructure'],
      data: templateFolderStructure,
    })
  );
  yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
}

export function* fetchTemplateHtmlData({ payload }) {
  try {
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: true }));

    const response = yield call(getTemplate, payload.id);
    if (response.status === HttpStatusCodes.OK) {
      yield put(
        storeCachedTemplateData({
          cachedTemplateData: response.data,
        })
      );
    }
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
  } catch (e) {
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
    throw e;
  }
}

export function* fetchTemplateHtmlDataForUiState({ payload }) {
  try {
    const response = yield call(getTemplate, payload.id);
    if (response.status === HttpStatusCodes.OK) {
      const {
        htmlBody,
        unsafeHtmlBody,
        templateFolderId,
        id: templateId,
      } = response.data;
      const templateFolderStructure = yield select(state =>
        state.getIn(['ui', 'templateFolderStructure'])
      );

      const templateFolderStructureWithHTML = templateFolderStructure.map(
        templateFolder => {
          if (templateFolder.get('id') !== templateFolderId) {
            return templateFolder;
          }

          const templatesWithHTML = templateFolder
            .get('templates')
            .map(template => {
              if (template.get('id') !== templateId) {
                return template;
              }

              return template.merge(
                new Map({
                  htmlBody,
                  unsafeHtmlBody,
                })
              );
            });

          return templateFolder.set('templates', templatesWithHTML);
        }
      );

      yield put(
        setProperty({
          uiKeyPath: ['templateFolderStructure'],
          data: templateFolderStructureWithHTML,
        })
      );
    }
  } catch (e) {
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
    throw e;
  }
}

export function* fetchInitialData({ payload }) {
  try {
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: true }));
    const templatesResponse = yield call(fetchIndexWithoutHtmlBody);
    const templateFolderStructure = templatesMapSubtypesForFolder(
      templatesResponse.data
    );
    yield put(
      setProperty({
        uiKeyPath: ['templateFolderStructure'],
        data: templateFolderStructure,
      })
    );
    if (payload.id) {
      const response = yield call(getTemplate, payload.id);
      if (response.status === HttpStatusCodes.OK) {
        yield put(
          updateFieldValue({
            groupId: payload.groupId,
            fieldId: payload.fieldId,
            value: response.data.name,
            selectedData: response.data,
            isDirty: false,
          })
        );
      }
    }
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
  } catch (e) {
    yield put(setProperty({ uiKeyPath: ['isTemplatesLoading'], data: false }));
    throw e;
  }
}

// -------------- Watchers --------------
function* watchFetchTemplateFolderStructure() {
  yield takeEvery(
    actionTypes.FETCH_TEMPLATE_FOLDER_STRUCTURE,
    fetchTemplateFolderStructure
  );
}

function* watchFetchTemplateFolderStructureWithHtmlData() {
  yield takeEvery(
    actionTypes.FETCH_TEMPLATE_FOLDER_STRUCTURE_WITHOUT_HTML_BODY,
    fetchTemplateFolderStructureWithoutHtml
  );
}

function* watchFetchTemplateData() {
  yield takeEvery(actionTypes.FETCH_TEMPLATE_HTML_DATA, fetchTemplateHtmlData);
}

function* watchFetchTemplateDataForUiState() {
  yield takeEvery(
    actionTypes.FETCH_TEMPLATE_HTML_DATA_FOR_UI_STATE,
    fetchTemplateHtmlDataForUiState
  );
}

function* watchfetchInitialData() {
  yield takeEvery(actionTypes.FETCH_INITIAL_DATA, fetchInitialData);
}

export default function* root() {
  yield all([
    fork(watchFetchTemplateFolderStructure),
    fork(watchFetchTemplateData),
    fork(watchFetchTemplateFolderStructureWithHtmlData),
    fork(watchFetchTemplateDataForUiState),
    fork(watchfetchInitialData),
  ]);
}
