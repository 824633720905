import { createActions, keyMirror } from '@groove-labs/action-utils';

export const actionTypes = createActions({
  SHARED: {
    LABELS: {
      ...keyMirror([
        'SET_LABELS',
        'ADD_LABEL',
        'UPDATE_LABEL',
        'UPDATE_CHECKBOX_STATUS',
        'SET_SINGLE_LABEL_FILTER',
        'REQUEST_LABELS_FILTER',
      ]),
    },
  },
}).SHARED.LABELS;

export function setLabels({ labels }) {
  return {
    type: actionTypes.SET_LABELS,
    payload: { labels },
  };
}

export function addLabel({ label }) {
  return {
    type: actionTypes.ADD_LABEL,
    payload: { label },
  };
}

export function updateLabel({ label }) {
  return {
    type: actionTypes.UPDATE_LABEL,
    payload: { label },
  };
}

export function updateCheckboxStatus({ id, checkedLabelsKeyPath }) {
  return {
    type: actionTypes.UPDATE_CHECKBOX_STATUS,
    payload: { id, checkedLabelsKeyPath },
  };
}

export function addLabelFilter({ id }) {
  return {
    type: actionTypes.SET_SINGLE_LABEL_FILTER,
    payload: { id },
  };
}

export function requestLabelsFilter({ checkedLabelIds }) {
  return {
    type: actionTypes.REQUEST_LABELS_FILTER,
    payload: { checkedLabelIds },
  };
}
