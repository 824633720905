import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Emails from 'Modules/Emails/containers/Emails';
import Onboarding from 'Modules/Emails/components/Onboarding';
import { fetchEmails, completeEmailsOnboarding } from 'Modules/Emails/actions';
import {
  getIsFetchingTheNextPageOfEmails,
  getHasNextPage,
  getEmailsOnboardingCompleted,
} from 'Modules/Emails/selectors';
import { Waypoint } from 'react-waypoint';

const styles = {
  root: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
  },
};

@withStyles(styles)
@connect(
  state => {
    return {
      isFetchingTheNextPageOfEmails: getIsFetchingTheNextPageOfEmails(state),
      hasNextPage: getHasNextPage(state),
      emailsOnboardingCompleted: getEmailsOnboardingCompleted(state),
    };
  },
  {
    fetchEmails,
    completeEmailsOnboarding,
  }
)
export default class ModuleRoot extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    fetchEmails: PropTypes.func.isRequired,
    completeEmailsOnboarding: PropTypes.func.isRequired,
    hasNextPage: PropTypes.bool.isRequired,
    emailsOnboardingCompleted: PropTypes.bool.isRequired,
    isFetchingTheNextPageOfEmails: PropTypes.bool.isRequired,
  };

  componentDidMount = () => this.props.fetchEmails();

  onCompleteOnboardingClick = () => {
    this.props.completeEmailsOnboarding();
  };

  render() {
    const {
      classes,
      isFetchingTheNextPageOfEmails,
      hasNextPage,
      fetchEmails,
      emailsOnboardingCompleted,
    } = this.props;

    return (
      <div className={classes.root}>
        {!emailsOnboardingCompleted && (
          <Onboarding onComplete={this.onCompleteOnboardingClick} />
        )}
        {emailsOnboardingCompleted && (
          <Fragment>
            <Emails />
            {hasNextPage && !isFetchingTheNextPageOfEmails && (
              <Waypoint onEnter={fetchEmails} />
            )}
          </Fragment>
        )}
      </div>
    );
  }
}
