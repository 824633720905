import { Dialog, makeStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import { seismicStoreKey } from '../constants';

import SeismicContainer from './SeismicContainer';
import TenantInput from './TenantInput';

const useStyles = makeStyles({
  dialog: {
    overflow: 'hidden',
    maxHeight: 'unset',
    maxWidth: 'unset',
    margin: 10,
  },
});

interface SeismicDialogProps {
  onClose: () => void;
  isOpen: boolean;
  recipientEmail?: string;
}
const SeismicDialog: FC<SeismicDialogProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const [tenant, setTenant] = useState<string | null>(null);

  useEffect(() => {
    const tempTenant = localStorage.getItem(seismicStoreKey);
    setTenant(tempTenant);
  }, [isOpen]);

  const onNext = (tenant: string) => {
    localStorage.setItem(seismicStoreKey, tenant);
    setTenant(tenant);
  };

  const clearTenant = () => {
    localStorage.setItem(seismicStoreKey, '');
    setTenant('');
  };

  let render = <TenantInput onNext={onNext} />;
  if (tenant) {
    render = <SeismicContainer clearTenant={clearTenant} onClose={onClose} />;
  }

  return (
    <Dialog open={isOpen} onClose={onClose} classes={{ paper: classes.dialog }}>
      {render}
    </Dialog>
  );
};

export default SeismicDialog;
