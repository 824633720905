import { makeStyles } from '@material-ui/core';
import * as React from 'react';

import pulse from 'Modules/Shared/components/WorkStepRouteLoader/pulse';

const greyContent = {
  background: 'rgb(235, 235, 235)',
  borderRadius: 2,
  height: 21,
};

const useStyles = makeStyles({
  root: {
    height: 56,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    alignItems: 'center',
    display: 'flex',
  },
  loadingType: {
    ...greyContent,
    ...pulse,
    width: 40,
    display: 'flex',
    margin: 16,
  },
  loadingContent: {
    ...greyContent,
    ...pulse,
    flex: 1,
    margin: '0px 30px 0px 16px',
  },
  '@keyframes pulse': {
    '0%': {
      backgroundColor: 'rgb(202,199,199)',
    },
    '50%': {
      backgroundColor: 'rgb(235, 235, 235)',
    },
    '100%': {
      backgroundColor: 'rgb(202,199,199)',
    },
  },
  playButtonContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
});

const LoaderRow: React.FC = () => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <div className={classes.loadingType} />
      <div className={classes.loadingContent} />
    </div>
  );
};

LoaderRow.displayName = 'LoaderRow';

export default LoaderRow;
