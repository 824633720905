import { decamelizeKeys } from 'humps';
import makeHTTPRequest from 'GrooveHTTPClient/makeHTTPRequest';
import { DELETE, POST, PUT } from 'GrooveHTTPClient/HTTPMethod';

const BASE_PATH = '/api/v2/templates';

export const index = () => makeHTTPRequest({ path: BASE_PATH });

export const fetchIndexWithoutHtmlBody = () =>
  makeHTTPRequest({ path: `${BASE_PATH}/index_without_html_body` });

export const flowUsage = templateId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateId}/usages`,
  });

export const create = ({
  templateName,
  templateSubject,
  htmlBody,
  trackLink,
  templateType,
  templateSubtype,
  templateFolder,
  templateTypeValue,
  includeLabelInstances = false,
}) =>
  makeHTTPRequest({
    path: BASE_PATH,
    method: POST,
    body: {
      name: templateName,
      subject: templateSubject,
      html_body: htmlBody,
      template_folder_id: templateFolder,
      is_link_tracking_on: trackLink,
      template_type: templateType,
      template_subtype: templateSubtype,
      template_type_value: templateTypeValue,
      include_label_instances: includeLabelInstances,
    },
  });

export const update = ({
  templateId,
  templateName,
  templateSubject,
  htmlBody,
  templateFolder,
  trackLink,
  templateTypeValue,
  includeLabelInstances = false,
}) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateId}`,
    method: PUT,
    body: {
      name: templateName,
      subject: templateSubject,
      html_body: htmlBody,
      template_folder_id: templateFolder,
      is_link_tracking_on: trackLink,
      template_type_value: templateTypeValue,
      include_label_instances: includeLabelInstances,
    },
  });

export const copy = ({ id, folderId }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${id}/copy`,
    method: POST,
    body: {
      folderId,
    },
  });

export const sendSample = id =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${id}/send_sample`,
    method: POST,
  });

export const destroy = templateId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateId}`,
    method: DELETE,
  });

export const show = templateId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/${templateId}`,
  });

export const fetchSfdcTemplate = sfdcTemplateId =>
  makeHTTPRequest({
    path: `${BASE_PATH}/fetch_sfdc_template`,
    queryParameters: decamelizeKeys({
      sfdcTemplateId,
    }),
  });

export const filter = ({ query, labelIds }) =>
  makeHTTPRequest({
    path: `${BASE_PATH}/filter`,
    queryParameters: decamelizeKeys({
      query,
      labelIds,
    }),
  });
