import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import { GenerateId } from 'jss';

import GrooveTheme from 'Utils/grooveTheme';
import { Stylesheet } from '@fluentui/react';

Stylesheet.getInstance().setConfig({ namespace: 'web-app' });

const appRender = (
  Component: React.ReactElement<unknown, string>,
  // eslint-disable-next-line
  store: any,
  generateClassName: GenerateId
): void => {
  ReactDOM.render(
    <MuiThemeProvider theme={GrooveTheme}>
      <Provider store={store}>
        <StylesProvider generateClassName={generateClassName}>
          {Component}
        </StylesProvider>
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
  );
};

export default appRender;
