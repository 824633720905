import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Pin from 'Modules/Shared/icons/pin';
import { ModifiedTheme } from 'Utils/grooveTheme';

const useStyles = makeStyles<ModifiedTheme>(theme => ({
  root: {
    width: 36,
    height: 36,
    backgroundColor: theme.palette.primary.light,
    display: 'flex',
    justifyContent: 'center',
    alignItems: ' center',
    borderRadius: '50%',
  },
}));

const PinIcon: React.FC = () => {
  const { root } = useStyles({});

  return (
    <div className={root}>
      <Pin />
    </div>
  );
};

export default PinIcon;
