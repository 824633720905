import { createSelector } from 'reselect';

export const getFlow = state => state.getIn(['flowsWizard', 'flow']);
export const getFlowId = state => state.getIn(['flowsWizard', 'flow', 'id']);
export const getIsMasterFlow = state =>
  state.getIn(['flowsWizard', 'flow', 'isMasterFlow']);
export const getSteps = state => state.getIn(['flowsWizard', 'steps']);
export const getErrors = state => state.getIn(['flowsWizard', 'flowErrors']);
export const getPeople = state => state.getIn(['flowsWizard', 'people']);
export const getCreatingOrUpdatingStep = state =>
  state.getIn(['flowWizard', 'creatingOrUpdatingStep']);

// Selector to pass into the StepAddEdit component so that
// it can determine the a step's subject IF it is threaded.
// TLDR; you want to find the subject of the first previous
// non-threaded email template step
export const getFirstEmailStepInThreadSubject = () => {
  return createSelector(getSteps, steps => {
    const firstThreadStep = steps.find(step => {
      if (step.get('stepType') !== 'TEMPLATE') {
        return false;
      }

      if (step.get('keepGmailThreadId')) {
        return false;
      }

      return true;
    });

    if (
      !firstThreadStep ||
      (!firstThreadStep.get('template') && !firstThreadStep.get('subject'))
    ) {
      return '';
    }

    return (
      firstThreadStep.get('subject') ||
      firstThreadStep.getIn(['template', 'subject'])
    );
  });
};
