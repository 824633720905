import { call } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { identifyUser as identifySegmentUser } from 'Utils/segment';
import setupLogRocket from 'Modules/App/utils/setupLogRocket';
import { logWarningToSentry } from 'Modules/Shared/sagas/errors';
import { OAUTH_PROVIDERS } from 'Modules/Shared/constants';

function* setupAnalytics(currentUser) {
  Sentry.setUser({
    id: currentUser.id,
    email: currentUser.email,
    name: currentUser.name,
  });
  try {
    if (currentUser?.id) {
      identifySegmentUser({
        id: currentUser.id,
        email: currentUser.email,
        userHash: currentUser.intercomUserHash,
        role: currentUser.role,
        orgId: currentUser.orgId,
        provider: Object.keys(OAUTH_PROVIDERS).find(
          key => OAUTH_PROVIDERS[key] === currentUser.provider
        ),
      });
    }
  } catch (e) {
    logWarningToSentry({ error: e, type: 'UNABLE_TO_SETUP_MIXPANEL' });
  }

  try {
    yield call(setupLogRocket, currentUser);
  } catch (e) {
    logWarningToSentry({ error: e, type: 'UNABLE_TO_SETUP_LOG_ROCKET' });
  }
}

export default setupAnalytics;
